'use client';

import { useEffect, useRef } from 'react';
import useAppEventEmitter from './useAppEventEmitter';

/**
 * Hook that triggers a refresh of token accounts once when the component mounts.
 * Useful for components where we want to ensure we have the latest token account data.
 *
 * Use the `enabled` prop to skip the refresh if there are only certain situations where the refresh should happen - will still only run once per component mount.
 */
const useRefreshTokenAccountsOnMount = (enabled = true) => {
	const eventEmitter = useAppEventEmitter();
	const alreadyRan = useRef(false);

	useEffect(() => {
		if (!eventEmitter || !enabled || alreadyRan.current) return;

		eventEmitter.emit('refreshTokenAccounts');
		alreadyRan.current = true;
	}, [eventEmitter, enabled]);
};

export default useRefreshTokenAccountsOnMount;
