import { ReactNode } from 'react';
import { ToastsToIgnoreSettings } from 'src/environmentVariables/EnvironmentVariables';
import useNotificationStore, {
	NotificationStore,
} from '../stores/useNotificationStore';
import { MarketOrderToastState } from '../components/MarketOrderToasts/MarketOrderToastStateTypes';
import { dlog } from '../dev';
import { DriftAppEventEmitter } from 'src/stores/DriftStore/useDriftStore';
import { GLOBAL_SETTINGS_CLIENT } from 'src/providers/settingsProvider';

type CoreToastData = {
	id?: string;
	hidden?: boolean;
	blockSubsequentToasts?: boolean; // if true, will not show another toast if this is already showing. useful if meant to be the last toast in a sequence
	isHovered?: boolean;
};

export type V2AuctionToastDataBody = MarketOrderToastState & CoreToastData;

export type V2AuctionToastData = V2AuctionToastDataBody & {
	type: 'v2MarketOrder';
};

export type SignedMsgOrderToastData = V2AuctionToastDataBody & {
	type: 'signedMsgOrder';
};

export type CallbackAction = {
	label: string;
	callback: () => void;
	type?:
		| 'default' // label with arrow pointing right
		| 'button'
		| 'rainbow-button'
		| 'prize-button'
		| 'points-button';
};

export type TxnLinkAction = {
	txnSig: string | string[];
	type: 'txnLink';
};

export type ExternalLinkAction = {
	label?: string;
	href: string;
	type: 'link';
};

export type CustomAction = {
	type: 'custom';
	content: ReactNode;
};

export type NotificationAction =
	| CallbackAction
	| TxnLinkAction
	| ExternalLinkAction
	| CustomAction;

// NOTE: If you add a new notification type but want it to have it's own clearing logic, make sure to adjust the list of types in useClearStaleNotifications
export type NotificationType =
	| 'error'
	| 'warning'
	| 'info'
	| 'success'
	| 'awaiting'
	| 'auction'
	| 'announcement'
	| 'prize'
	| 'points';

export type NotificationData = CoreToastData & {
	type: NotificationType;
	bgType?: 'error' | 'info' | 'success' | 'swift';
	message?: ReactNode;
	description?: ReactNode;
	subDescription?: ReactNode;
	steps?: ReactNode[]; // steps allows for more than just description + sub description
	action?: NotificationAction;
	offerIgnoreToast?: keyof ToastsToIgnoreSettings;
	customIcon?: ReactNode;
	hideMainIcon?: boolean;
	startTimeMs: number;
	lengthMs: number;
	showUntilCancelled?: boolean;
	statusWarning?: string;
	bottomContent?: ReactNode;
	flags?: string[];
	customIconSizeOverride?: number; // current max width is 26px, set this prop to override,
	overrideBlockSubsequentToasts?: boolean; // if true, will override a blocking toast
	timeoutIfProlongedTxn?: boolean; // used as a fail safe to show the timeout notification if a transaction is taking too long, and the TransactionErrorHandler failed to catch it
	toastBadge?: ReactNode;
};

type NewNotificationProps = Omit<
	NotificationData,
	'startTimeMs' | 'lengthMs' | 'isHovered'
> & {
	lengthMs?: number;
	startTimeMs?: number;
	updatePrevious?: boolean;
	showUntilCancelled?: boolean;
	onlyShowIfPreviousTarget?: boolean;
	bottomContent?: ReactNode;
};

const DEFAULT_PROPS: Partial<NewNotificationProps> = {
	updatePrevious: true,
	showUntilCancelled: false,
	onlyShowIfPreviousTarget: false,
	description: '', // this way if we updatePrevious with no description, old description will be cleared
};

/**
 * Display a notification to the user, in toast format.
 *
 * The optional `id` parameter can be used to overwrite a currently displayed notification (with the same ID) rather than display a whole new notification. You can change any of the current notification's attributes.
 *
 * The showUntilCancelled prop allows you to keep a notification on screen until you overwrite it with a later notification
 *
 * @param {NewNotificationProps} newNotificationProps
 *
 * @returns
 */
export function notify(props: NewNotificationProps) {
	const newNotificationProps = {
		...DEFAULT_PROPS,
		...props,
	};

	dlog(`notifications`, `notify`, {
		newNotificationProps,
	});

	const notifications = useNotificationStore.getState().notifications;
	const notificationStore = useNotificationStore.getState();
	const settingsClient = GLOBAL_SETTINGS_CLIENT;

	const shouldIgnoreToast =
		newNotificationProps.offerIgnoreToast &&
		settingsClient.overallSettingsState.ignoreToasts[
			newNotificationProps.offerIgnoreToast
		];

	if (shouldIgnoreToast) return;

	const newNotificationToAdd = {
		startTimeMs: Date.now(),
		...newNotificationProps,
		lengthMs: newNotificationProps.lengthMs ?? 5000,
		showUntilCancelled: newNotificationProps.showUntilCancelled,
		updatePrevious: newNotificationProps.updatePrevious,
	};

	// Check for notifications with duplicate ID
	//// Unless updating a previous notification via its ID - then we don't want to add a duplicate for the notification
	const targetNotification = notifications.find(
		(notification) =>
			notification.id && notification.id === newNotificationProps.id
	);

	if (targetNotification) {
		// if not updating, but we have an ID clash - skip
		if (!newNotificationProps.updatePrevious) return;

		// if updating, but the previous target is already showing, skip
		if (
			targetNotification.blockSubsequentToasts &&
			!newNotificationProps.overrideBlockSubsequentToasts
		)
			return;
	}

	// if updating previous but no matching target, also skip
	if (newNotificationProps.onlyShowIfPreviousTarget && !targetNotification)
		return;

	if (targetNotification) {
		notificationStore.updateNotificationById(targetNotification.id, {
			...newNotificationToAdd,
			showUntilCancelled: newNotificationProps.showUntilCancelled,
		});
	} else {
		notificationStore.addNotification(newNotificationToAdd);
	}
}

export const getNotificationStateWithIndexRemoved = (
	notificationState: NotificationStore['notifications'],
	index: number
) => [
	...notificationState.slice(0, index),
	...notificationState.slice(index + 1),
];

export const getTimeoutNotificationData = (
	appEventEmitter: DriftAppEventEmitter
): NewNotificationProps => ({
	type: 'warning',
	message: 'Transaction Confirmation Timeout',
	description: `Your transaction wasn't confirmed within 30 seconds, but it may still go through. You can use the Solana explorer to check. If you experience this frequently, you should try changing your Priority Fee Settings.`,
	action: {
		label: 'Priority Fee Settings',
		callback: () => appEventEmitter.emit('showModal', 'showNetworkModal'),
	},
	updatePrevious: true,
	showUntilCancelled: true,
	subDescription: undefined,
});
