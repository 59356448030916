'use client';

import {
	BigNum,
	PRICE_PRECISION_EXP,
	PositionDirection,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import styled from '@emotion/styled';
import MiniChart from 'src/components/MiniChart';
import Tooltip from 'src/components/Tooltip/Tooltip';
import CentreFillScroller from 'src/components/Utils/CentreFillScroller';
import MarketIcon from 'src/components/Utils/MarketIcon';
import NumberDisplayV2 from 'src/components/Utils/NumberDisplayV2';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useIsMobile from 'src/hooks/useIsMobileScreenSize';
import React, {
	CSSProperties,
	MouseEvent,
	PropsWithChildren,
	ReactNode,
	isValidElement,
	useMemo,
	useState,
} from 'react';
import { format } from 'timeago.js';
import Text from 'src/components/Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
import Button from '../Button';
import NumberDisplay from '../Utils/NumberDisplay';
import TableActionButton from '../Buttons/TableActionButton';
import { matchEnum, OpenPosition, UIMarket } from '@drift/common';
import Chevron from '../Icons/Chevron';
import LightningIcon2 from 'src/components/Icons/LightningIcon2';
import useInterval from 'src/hooks/useInterval';
import SortIcon from '../Icons/SortIcon';
import { Hotified } from '@drift-labs/icons';
import GradientText from '../Utils/GradientText';
import { PredictionMarketIcon } from 'src/components/PredictionMarkets/PredictionMarketIcon';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import useSafePush from 'src/hooks/useSafePush';
import { LabelChip } from '../Chips/LabelChip';
import { PageRoute, POOL_NAMES_BY_POOL_ID } from 'src/constants/constants';
import { JSX } from 'react';
import NumberDisplayV3 from '../Utils/NumberDisplayV3';

const Skeleton = (
	props: PropsWithChildren<{
		top: JSX.Element;
		middle: JSX.Element;
		bottom?: JSX.Element;
		hideHeadersOnMobile?: boolean;
		noBorder?: boolean;
		noBg?: boolean;
		notRounded?: boolean;
		fillSpace?: boolean;
		className?: string;
		autoHeight?: boolean;
		innerClassName?: string;
		setIsScrollable?: (isScrollable: boolean) => void;
	}>
) => {
	const isMobile = useIsMobile();

	return (
		<CentreFillScroller
			outerClassName={twMerge(
				`overflow-hidden text-text-emphasis`,
				props.noBorder ? 'border-0' : 'border border-container-border',
				!props.notRounded && 'rounded-lg',
				props.className
			)}
			top={props.hideHeadersOnMobile && isMobile ? null : props.top}
			middle={<BodyRowWrapper noBg>{props.middle}</BodyRowWrapper>}
			bottom={props.bottom}
			fillSpace={props.fillSpace}
			autoHeight={props.autoHeight}
			innerClassName={props.innerClassName}
			overflowAuto
			setIsScrollable={props.setIsScrollable}
		/>
	);
};

// -- ROWS

const TableRowWrapper = styled.div<{
	grid: string;
	noBorder?: boolean;
	topBorder?: boolean;
	lastChildNoBorder?: boolean;
	lastColumnJustify?: string;
	strongBottomBorder?: boolean;
}>`
	display: grid;
	grid-template-columns: ${(props) => props.grid};
	grid-auto-rows: auto;
	grid-auto-flow: row;

	${(props) =>
		props.noBorder
			? ''
			: props.strongBottomBorder
			? 'border-bottom: 3px solid var(--container-border)'
			: 'border-bottom: 1px solid var(--container-border)'};
	${(props) =>
		props.topBorder ? 'border-top: 1px solid var(--container-border)' : ''};

	> * {
		text-align: left;
		align-items: left;
		justify-self: start;
	}

	> *:first-of-type {
		padding-left: 16px;
	}

	> *:last-child {
		padding-right: 16px;
		text-align: left;
		align-items: left;
		justify-self: ${({ lastColumnJustify }) => lastColumnJustify || 'end'};
	}

	${(props) =>
		props.lastChildNoBorder &&
		`:last-child {
		border-bottom: 0px;
	}`}
`;

const HeaderRow = React.forwardRef(function HeaderRow(
	props: PropsWithChildren<{
		grid: string;
		className?: string;
		forceBottomBorder?: boolean;
		header?: boolean;
		lastColumnJustify?: string;
		id?: string;
		/**
		 * Adds padding to the right of the table to account for scrollbar in the table.
		 */
		addScrollPadding?: boolean;
	}>,
	ref: React.ForwardedRef<HTMLDivElement>
) {
	return (
		<TableRowWrapper
			{...props}
			ref={ref}
			className={twMerge(
				`bg-main-bg`,
				props.addScrollPadding && 'pr-2',
				props.className
			)}
			grid={props.grid}
		>
			{props.children}
		</TableRowWrapper>
	);
});

const BodyRowWrapper = ({
	children,
	noBg,
}: PropsWithChildren<{ noBg?: boolean }>): JSX.Element => {
	return (
		<div
			className={`flex flex-col w-full h-full ${noBg ? '' : 'bg-container-bg'}`}
		>
			{children}
		</div>
	);
};

const BodyRow = ({
	children,
	className,
	isDataRow,
	...props
}: PropsWithChildren<{
	grid: string;
	className?: string;
	forceBottomBorder?: boolean;
	header?: boolean;
	lastColumnJustify?: string;
	isDataRow?: boolean;
	noBorder?: boolean;
	onClick?: (event: MouseEvent) => void;
	lastChildNoBorder?: boolean;
	strongBottomBorder?: boolean;
}>): JSX.Element => {
	return (
		<TableRowWrapper
			{...props}
			noBorder={props.noBorder}
			className={twMerge(
				`bg-container-bg hover:bg-container-bg-hover`,
				className
			)}
			grid={props.grid}
			data-puppet-tag={isDataRow ? 'table_data_row' : undefined}
		>
			{children}
		</TableRowWrapper>
	);
};

const SummaryRow = ({
	children,
	className,
	...props
}: PropsWithChildren<{
	grid: string;
	className?: string;
	header?: boolean;
}>): JSX.Element => {
	return (
		<TableRowWrapper
			{...props}
			className={twMerge(`bg-container-bg`, className)}
			grid={props.grid}
			noBorder
			topBorder
		>
			{children}
		</TableRowWrapper>
	);
};

// -- CELLS

const HeaderCellWrapper = ({
	children,
	className,
	alignRight,
	textClassname,
}: PropsWithChildren<{
	className?: string;
	alignRight?: boolean;
	textClassname?: string;
}>) => {
	return (
		<div
			className={twMerge(
				`w-full flex items-center pt-[14px] pb-[12px] text-text-label capitalize select-none bg-main-bg`,
				alignRight && 'justify-end',
				className
			)}
		>
			<Text.BODY2 className={textClassname}>{children}</Text.BODY2>
		</div>
	);
};

const HeaderCell = ({
	children,
	className,
	alignRight,
	textClassname,
}: PropsWithChildren<{
	className?: string;
	alignRight?: boolean;
	textClassname?: string;
}>) => {
	return (
		<HeaderCellWrapper
			className={className}
			alignRight={alignRight}
			textClassname={textClassname}
		>
			{children}
		</HeaderCellWrapper>
	);
};

const TooltipHeaderCell = ({
	children,
	className,
}: PropsWithChildren<{
	className?: string;
}>) => {
	return (
		<HeaderCellWrapper className={`space-x-2 ${className}`}>
			{children}
		</HeaderCellWrapper>
	);
};

const SortableHeaderCell = ({
	children,
	className,
	alignRight,
	sortDirection,
	onChange,
}: PropsWithChildren<{
	sortDirection: 'high' | 'low' | 'none';
	onChange: () => void;
	className?: string;
	alignRight?: boolean;
}>) => {
	return (
		<HeaderCellWrapper
			className={`space-x-2 ${className}`}
			alignRight={alignRight}
		>
			<div
				className="relative flex flex-row items-center justify-start h-5 cursor-pointer select-none pr-7"
				onClick={onChange}
			>
				<div className="max-w-fit shrink">{children}</div>
				<SortIcon
					onClick={onChange}
					state={
						sortDirection === 'low'
							? 'ascending'
							: sortDirection === 'high'
							? 'descending'
							: 'neutral'
					}
					size={20}
				/>
			</div>
		</HeaderCellWrapper>
	);
};

const BodyCellWrapper = ({
	children,
	className,
	label,
	onClick,
	dataPuppetTag,
	flexCol,
	alignCenter,
	alignRight,
	innerClassName,
}: PropsWithChildren<{
	className?: string;
	innerClassName?: string;
	label?: string;
	onClick?: () => void;
	dataPuppetTag?: string;
	flexCol?: boolean;
	alignCenter?: boolean;
	alignRight?: boolean;
}>) => {
	return (
		<div
			className={twMerge(
				`w-full flex flex-row py-2 items-center`,
				alignRight && 'justify-end',
				onClick && 'hover:cursor-pointer',
				className
			)}
			onClick={onClick}
			data-puppet-tag={dataPuppetTag}
		>
			{label && <div className="text-xs text-text-label">{label}</div>}
			<Text.BODY2
				className={twMerge(
					`flex`,
					alignCenter
						? 'items-center'
						: alignRight
						? 'items-end'
						: 'items-start',
					flexCol ? 'flex-col' : 'flex-row',
					innerClassName
				)}
			>
				{children}
			</Text.BODY2>
		</div>
	);
};

const BodyCell = ({
	children,
	className,
	onClick,
	dataPuppetTag,
	flexCol,
	alignCenter,
	alignRight,
	innerClassName,
}: PropsWithChildren<{
	className?: string;
	innerClassName?: string;
	onClick?: (event?: MouseEvent) => void;
	dataPuppetTag?: string;
	flexCol?: boolean;
	alignCenter?: boolean;
	alignRight?: boolean;
}>) => {
	return (
		<BodyCellWrapper
			className={className}
			onClick={onClick}
			dataPuppetTag={dataPuppetTag}
			flexCol={flexCol}
			alignCenter={alignCenter}
			alignRight={alignRight}
			innerClassName={innerClassName}
		>
			{children}
		</BodyCellWrapper>
	);
};

export const MarketCell = (
	props: PropsWithChildren<{
		className?: string;
		innerClassName?: string;
		marketSymbol: string;
		large?: boolean;
		onClick?: () => void;
		isSpot?: boolean;
		direction?: 'short' | 'long' | 'buy' | 'sell';
		expandable?: {
			isParent: boolean;
			expanded?: boolean;
		};
		textClass?: string;
		suffix?: string | ReactNode;
		isNew?: boolean;
		isHot?: boolean;
		highlight?: boolean;
		baseAssetSymbol?: string;
		poolId?: keyof typeof POOL_NAMES_BY_POOL_ID;
	}>
) => {
	const isPredictionMarket = PredictionMarketConfigs.isPredictionMarket(
		props.marketSymbol
	);
	const predictionMarketIndex =
		PredictionMarketConfigs.getPredictionMarketIndex(props.marketSymbol);

	const predictionMarketTitle = isPredictionMarket
		? PredictionMarketConfigs.get(predictionMarketIndex).shortTitle
		: null;

	const poolIdName = POOL_NAMES_BY_POOL_ID[props.poolId];

	return (
		<BodyCell
			className={props.className}
			onClick={props.onClick}
			alignCenter
			innerClassName={props.innerClassName}
		>
			{props.expandable?.isParent && (
				<div className="flex items-center justify-center w-[16px] mr-2 duration-300">
					<Chevron direction={props.expandable.expanded ? 'down' : 'right'} />
				</div>
			)}
			<MarketIcon
				marketSymbol={props.marketSymbol}
				className={'mr-2'}
				sizeClass={props.large ? 'w-8 h-8' : 'w-6 h-6'}
				customHeight={props.large ? 32 : undefined}
				customWidth={props.large ? 32 : undefined}
				isSpot={props.isSpot}
			/>
			<div className="flex flex-col items-start justify-center">
				<div className="flex flex-row items-center justify-center">
					<GradientText disabled={!props.highlight}>
						{isPredictionMarket ? (
							<div
								className={twMerge(
									'normal-case xl:block',
									!props.baseAssetSymbol && 'hidden'
								)}
							>
								<Tooltip
									content={
										<span className="">
											Prediction Market: {predictionMarketTitle}
										</span>
									}
								>
									{
										<span className="overflow-hidden line-clamp-2 text-ellipsis">
											{predictionMarketTitle}
										</span>
									}
								</Tooltip>
							</div>
						) : (
							<Text.BODY2
								className={twMerge([
									`mt-0.5`,
									props.textClass ? props.textClass : 'text-text-emphasis',
									props.highlight && 'text-[14px]',
								])}
							>
								{props.marketSymbol} {props.suffix}
							</Text.BODY2>
						)}
					</GradientText>
					{props.isNew && (
						<div className="ml-2">
							<LightningIcon2 style={{ width: '16px', height: '16px' }} />
						</div>
					)}
					{props.isHot && <Hotified size={18} className="ml-2" />}
				</div>
				{!!props.poolId && !!poolIdName && (
					<div className="text-xs text-text-secondary">
						<Text.MICRO1>{poolIdName}</Text.MICRO1>
					</div>
				)}
				{props.direction && (
					<Text.MICRO1
						className={`${
							props.direction.match(/buy|long/)
								? 'text-positive-green'
								: 'text-negative-red'
						} capitalize mt-1`}
					>
						{props.direction}
					</Text.MICRO1>
				)}
			</div>
		</BodyCell>
	);
};

export const BankCell = (
	props: PropsWithChildren<{
		className?: string;
		bankIndex: number;
	}>
) => {
	const bankSymbol = OrderedSpotMarkets[props.bankIndex].symbol;
	return (
		<BodyCell {...props} className={` ${props.className}`}>
			<MarketIcon marketSymbol={bankSymbol} className="mr-2" />
			<Text.BODY2 className="mt-0.5 text-text-emphasis">
				{bankSymbol}
			</Text.BODY2>
		</BodyCell>
	);
};

export const NotionalCell = (
	props: PropsWithChildren<{
		className?: string;
		semantic?: boolean;
		value: BigNum;
		textOverride?: string;
		label?: string;
		toFixed?: number;
		colourCoded?: boolean;
		softenZeroValues?: boolean;
		hoverTextOverride?: string;
		millify?: boolean;
	}>
) => {
	return (
		<BodyCell {...props} className={`${props.className}`}>
			<Tooltip
				content={
					<span>{`${
						props.hoverTextOverride
							? props.hoverTextOverride
							: props.value.toNotional()
					}`}</span>
				}
			>
				<NumberDisplayV2
					className="pt-0.5"
					displayType="notional"
					subType={props.semantic ? '$Change' : '$'}
					value={props.value}
					colourCoded={props.colourCoded}
					textOverride={
						props.textOverride
							? props.textOverride
							: !props.toFixed && UI_UTILS.isNotionalDust(props.value)
							? '< $0.01'
							: undefined
					}
					toFixed={props.toFixed}
					softenZeroValues={props.softenZeroValues}
					millify={props.millify}
				/>
			</Tooltip>
		</BodyCell>
	);
};

export const TradeSizeCell = (
	props: PropsWithChildren<{
		baseSize: BigNum;
		baseSymbol: string;
		marketSymbol: string;
		hideValues: boolean;
		className?: string;
	}>
) => {
	const isPredictionMarket = PredictionMarketConfigs.isPredictionMarket(
		props.marketSymbol
	);

	return (
		<BodyCell {...props} className={` flex ${props.className}`}>
			<div className="flex flex-col align-left">
				{isPredictionMarket ? (
					UI_UTILS.baseSizeDisplayForPerpMarket(
						props.baseSize,
						props.marketSymbol,
						props.hideValues
					)
				) : (
					<NumberDisplayV3
						value={props.baseSize}
						formattingProps={{
							displayType: 'asset',
							assetSymbol: props.baseSymbol,
						}}
						displayProps={{
							dataPuppetTag: 'asset_base',
						}}
					/>
				)}
			</div>
		</BodyCell>
	);
};

export const AssetCell = (
	props: PropsWithChildren<{
		className?: string;
		semantic?: boolean;
		value: BigNum;
		assetSymbol: string;
		label?: string;
		toFixed?: number;
		toPrecision?: number;
		softenZeroValues?: boolean;
		notionalValue?: BigNum;
		dataPuppetTag?: string;
		textClassname?: string;
		isAccountValueToHide?: boolean;
	}>
) => {
	return (
		<BodyCell {...props} className={` flex ${props.className}`}>
			<div className="flex flex-col align-left">
				<Tooltip
					content={
						<span>
							{props.value.prettyPrint()} {props.assetSymbol}
						</span>
					}
				>
					<NumberDisplayV3
						value={props.value}
						formattingProps={{
							displayType: props.semantic ? 'assetChange' : 'asset',
							assetSymbol: props.assetSymbol,
							toPrecision: props.toFixed ? undefined : props.toPrecision ?? 6,
							toFixed: props.toFixed,
						}}
						displayProps={{
							semanticColouring: props.semantic,
							isAccountValueToHide: props.isAccountValueToHide,
							className: twMerge('pt-0.5', props.textClassname),
							dataPuppetTag: 'asset_base',
						}}
					/>
				</Tooltip>
				{props.notionalValue && (
					<NumberDisplayV3
						value={props.notionalValue}
						formattingProps={{
							displayType: props.semantic ? 'notional$Change' : 'notional$',
							toFixed: 2,
						}}
						displayProps={{
							className: 'pt-0.5 text-text-secondary',
							isAccountValueToHide: props.isAccountValueToHide,
							dataPuppetTag: 'asset_notional',
						}}
					/>
				)}
			</div>
		</BodyCell>
	);
};

export const DateCell = (
	props: PropsWithChildren<{
		className?: string;
		date: Date;
		displayAsTimestamp?: boolean;
		label?: string;
		hideTime?: boolean;
	}>
) => {
	const [timeAgoString, setTimeAgoString] = useState(format(props.date));

	// update timeAgo string every 10 seconds
	useInterval(() => {
		setTimeAgoString(format(props.date));
	}, 10000);

	return (
		<BodyCell className={props.className}>
			{props.displayAsTimestamp ? (
				props.hideTime ? (
					<>{props.date.toLocaleDateString().replace(',', '')}</>
				) : (
					<>{props.date.toLocaleString().replace(',', '')}</>
				)
			) : (
				<Tooltip content={<>{props.date.toLocaleString()}</>}>
					{timeAgoString}
				</Tooltip>
			)}
		</BodyCell>
	);
};

export const ChartCell = (
	props: PropsWithChildren<{
		className?: string;
		chartData: Parameters<typeof MiniChart>[0]['data'];
		useMinMaxTicks?: boolean;
		forcePositive?: boolean;
	}>
) => {
	return (
		<BodyCell className={props.className}>
			<MiniChart
				data={props.chartData}
				useMinMaxTicks={props.useMinMaxTicks}
				isPositive={
					props.forcePositive ?? props.chartData.at(-1) >= props.chartData[0]
				}
			/>
		</BodyCell>
	);
};

export const ActionCell = (
	props: PropsWithChildren<{
		className?: string;
	}>
) => {
	return (
		<BodyCell className={`flex space-x-2 ${props.className}`}>
			{props.children}
		</BodyCell>
	);
};

export interface AccountOpenPosition extends OpenPosition {
	accountName: string;
	accountKey: string;
	isLpPosition?: boolean;
}

export const OpenPositionMarketDirectionCell = (props: {
	position: AccountOpenPosition;
	handleClickMarket?: () => void;
}) => {
	// TODO: Surely something better possible than this?
	const isPerp = props.position.marketSymbol.match('-PERP'); // Not the best, can fix later though

	// TODO: Can probably consolidate the MarketIcon between this and the other one
	return (
		<TableV2.BodyCell className="uppercase" innerClassName="items-center pr-4">
			<button
				onClick={props?.handleClickMarket}
				className="flex flex-row items-center text-left"
			>
				<MarketIcon
					marketSymbol={props.position.marketSymbol}
					className={'mr-2 w-6 h-6'}
					isSpot={!isPerp}
				/>
				<div>
					<div className="hidden pl-2 normal-case xl:block">{`${
						props?.position?.marketSymbol
					}${props.position.isLpPosition ? ' (Incl. BAL)' : ''}`}</div>
					<div
						className={`pl-2 capitalize ${
							props.position.direction === 'long'
								? `text-positive-green`
								: `text-negative-red`
						}`}
					>
						{isPerp
							? props.position.direction === 'long'
								? 'Long'
								: 'Short'
							: props.position.direction === 'long'
							? 'Buy'
							: 'Sell'}
					</div>
				</div>
			</button>
		</TableV2.BodyCell>
	);
};

export const OpenPredictionPositionIdCell = (props: {
	marketIndex: number;
	direction: 'long' | 'short';
	title: string;
	isLpOrder?: boolean; // we dont show long or short for an unfilled lp order
	small?: boolean;
}) => {
	const uiMarket = useMemo(
		() => UIMarket.createPerpMarket(props.marketIndex),
		[props.marketIndex]
	);
	const safePush = useSafePush();

	const handleNavigateToPredictionMarket = () => {
		safePush(`/${PageRoute.bet}/${uiMarket.market.symbol}`);
	};

	return (
		<TableV2.BodyCell
			className="uppercase cursor-pointer"
			innerClassName="items-center pr-4"
			onClick={handleNavigateToPredictionMarket}
		>
			{props.small ? (
				<div className="flex flex-row items-center text-left">
					<PredictionMarketIcon
						symbol={uiMarket.symbol}
						small={props.small}
						className={'w-6 h-6'}
					/>
					<div className="flex flex-col pl-2 space-y-0">
						<div className="pl-2 normal-case">
							<Tooltip
								content={
									<span className="">Prediction Market: {props.title}</span>
								}
							>
								<span className="overflow-hidden line-clamp-2 text-ellipsis">
									{props.title}
								</span>
							</Tooltip>
						</div>
						{!props.isLpOrder && (
							<div
								className={`pl-2 capitalize ${
									props.direction === 'long'
										? `text-positive-green`
										: `text-negative-red`
								}`}
							>
								{props.direction === 'long' ? 'Yes' : 'No'}
							</div>
						)}
					</div>
				</div>
			) : (
				<div className="flex flex-row items-center text-left">
					<PredictionMarketIcon
						symbol={uiMarket.symbol}
						small={props.small}
						className={'w-6 h-6'}
					/>
					<div className="flex flex-col space-y-1">
						<div className="hidden pl-2 normal-case md:block">
							<Tooltip content={<span>{props.title}</span>}>
								<span className={'line-clamp-1 text-ellipsis'}>
									{props.title}
								</span>
							</Tooltip>
						</div>
						{!props.isLpOrder && (
							<div
								className={`pl-2 capitalize ${
									props.direction === 'long'
										? `text-positive-green`
										: `text-negative-red`
								}`}
							>
								{props.direction === 'long' ? 'Yes' : 'No'}
							</div>
						)}
					</div>
				</div>
			)}
		</TableV2.BodyCell>
	);
};

export const EMPTY_TABLE_VALUE = '-';

const EmptyValueFiller = (props: { card?: boolean }) => (
	<div
		className={`h-4 ${
			props.card ? 'inline-block' : 'flex justify-center w-full'
		}`}
	>
		<span className="relative bottom-0.5">{EMPTY_TABLE_VALUE}</span>
	</div>
);

export const TableCard = (
	props: PropsWithChildren<{
		className?: string;
		marketSymbol: string;
		marketString?: string;
		direction?: PositionDirection;
		isPredictionMarket?: boolean;
		actionButtons?: React.ReactNode;
		isDataRow?: boolean;
		useSpotWording?: boolean;
	}>
): JSX.Element => {
	return (
		<div
			className={twMerge(
				`text-text-default bg-container-bg rounded-md py-4 px-2 mx-4 my-4`,
				props.className
			)}
			data-puppet-tag={props.isDataRow ? 'table_data_row' : undefined}
		>
			<div className={'inline-flex items-center space-x-2 text-base'}>
				<MarketIcon marketSymbol={props.marketSymbol} className={`w-5 h-5`} />
				<Text.BODY1 className="text-text-emphasis max-w-[200px] line-clamp-1 text-ellipsis overflow-hidden text-wrap">
					{props.marketString}
				</Text.BODY1>
				<LabelChip
					label={
						matchEnum(PositionDirection.LONG, props.direction)
							? props.isPredictionMarket
								? 'Yes'
								: props.useSpotWording
								? 'Buy'
								: 'Long'
							: props.isPredictionMarket
							? 'No'
							: props?.useSpotWording
							? 'Sell'
							: 'Short'
					}
					positive={matchEnum(PositionDirection.LONG, props.direction)}
					negative={matchEnum(PositionDirection.SHORT, props.direction)}
					className={'capitalize w-auto inline-block'}
				/>
			</div>
			<div className="flex flex-row flex-wrap">{props.children}</div>
			<div className="mt-6">{props.actionButtons}</div>
		</div>
	);
};

const CardValue = ({
	value,
	label,
	icon,
	size,
	highlight,
	isMobileTopRow,
	className,
	onClick,
}: {
	value: ReactNode;
	label: string;
	icon?: JSX.Element;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	highlight?: boolean;
	isMobileTopRow?: boolean;
	className?: string;
	onClick?: () => void;
}) => {
	const memoedClassName = useMemo(
		() =>
			twMerge(
				`flex flex-col items-start`,
				isMobileTopRow ? 'pt-0' : 'pt-2',
				highlight && 'text-lg',
				className
			),
		[isMobileTopRow, highlight, className]
	);

	return (
		<div
			className={memoedClassName}
			onClick={() => {
				onClick?.();
			}}
		>
			<div className="flex space-x-1 text-xs text-text-label">
				<span>{label}</span>
				{icon && icon}
			</div>
			<div className={size ? `text-${size}` : ''}>{value}</div>
		</div>
	);
};

const UnsettledPnlTableCard = (props: {
	key?: string;
	className?: string;
	style?: CSSProperties;
	showAccountValues?: boolean;
	position: OpenPosition & { accountName: string; accountKey: string };
	onClaim: () => void;
	isPredictionMarket?: boolean;
}) => {
	return (
		<div className="flex flex-col p-4 border-b last:border-none last:rounded-b-md bg-container-bg border-container-border">
			<div className="flex items-start justify-between w-full border-cont">
				<div className="flex items-center space-x-2">
					<div className="inline-flex items-center text-neutrals-40">
						<MarketIcon
							marketSymbol={props.position.marketSymbol}
							className={`mr-3 w-5 h-5`}
						/>
						<Text.BODY1 className="flex items-center mt-0.5 text-text-emphasis max-w-[200px] line-clamp-2 text-ellipsis overflow-hidden text-wrap">
							{props?.isPredictionMarket
								? UI_UTILS.getPerpMarketDisplayName(props.position.marketIndex)
								: props.position.marketSymbol}
						</Text.BODY1>
					</div>
				</div>
				<div>
					<Button.Secondary
						size="SMALL"
						disabled={props.position.unsettledPnl.eq(ZERO)}
						onClick={props.onClaim}
					>
						Settle
					</Button.Secondary>
				</div>
			</div>
			<div className="flex items-center justify-between">
				<CardValue
					label="Cost Basis"
					size={'xs'}
					value={
						<NumberDisplay
							displayType="notional"
							value={BigNum.from(
								props.position.costBasis,
								PRICE_PRECISION_EXP
							).toNum()}
							subType="$"
							textOverride={!props.showAccountValues && '∗∗∗∗∗∗'}
						/>
					}
				/>
				<CardValue
					label="Settled"
					size={'xs'}
					value={
						<NumberDisplay
							displayType="notional"
							value={BigNum.from(
								props.position.realizedPnl,
								QUOTE_PRECISION_EXP
							).toNum()}
							subType="$"
							textOverride={!props.showAccountValues && '∗∗∗∗∗∗'}
							colourCoded
						/>
					}
				/>
			</div>
			<div className="flex items-center justify-between">
				<CardValue
					label="Unsettled Funding"
					size={'xs'}
					value={
						<NumberDisplay
							className="inline-flex mr-1"
							displayType="notional"
							value={BigNum.from(
								props.position.unsettledFundingPnl,
								QUOTE_PRECISION_EXP
							).toNum()}
							subType="$"
							colourCoded
							textOverride={!props.showAccountValues && '∗∗∗∗∗∗'}
						/>
					}
				/>
				<CardValue
					label="Claimable / Unsettled"
					size={'xs'}
					value={
						<>
							<NumberDisplay
								className="inline-flex mr-1"
								displayType="notional"
								value={BigNum.from(
									props.position.unsettledPnl,
									QUOTE_PRECISION_EXP
								).toNum()}
								subType="$"
								colourCoded
								textOverride={!props.showAccountValues && '∗∗∗∗∗∗'}
							/>
							<span> / </span>
							<NumberDisplay
								className="inline-flex ml-1"
								displayType="notional"
								value={BigNum.from(
									props.position.totalUnrealizedPnl,
									QUOTE_PRECISION_EXP
								).toNum()}
								subType="$"
								colourCoded
								textOverride={!props.showAccountValues && '∗∗∗∗∗∗'}
							/>
						</>
					}
				/>
			</div>
		</div>
	);
};

// TODO: When we do a proper mobile-table refresh, this stuff is a good candidate for cleanup. It's pretty complicated / random.
const TableRowDiv = styled.div<{ grid: string; 'data-puppet-tag': string }>`
	display: grid;
	grid-template-columns: ${(props) => props.grid};
	grid-auto-rows: auto;
	grid-auto-flow: row;
`;

type ActionProp = {
	label: string;
	positive?: boolean;
	neutral?: boolean;
	onClick: () => void;
};

export const ResponsiveTableRow = (
	props: PropsWithChildren<{
		grid?: string;
		className?: string;
		forceBottomBorder?: boolean;
		header?: boolean;
		onClick?: () => void;
		marketString?: string;
		direction?: PositionDirection;
		action?: ActionProp | React.ReactNode;
		isDataRow?: boolean;
		useSpotWording?: boolean;
		isPredictionMarket?: boolean;
	}>
): JSX.Element => {
	const isMobile = useIsMobile();

	const grid = props.grid ?? `1fr 1fr`;

	if (!isMobile)
		return <BodyRow grid={grid} isDataRow={props.isDataRow} {...props} />;

	return (
		<TableRowDiv
			{...props}
			className={twMerge(
				`text-text-default bg-container-bg border-container-border border-t py-4 px-2 mx-4 my-4`,
				props.className
			)}
			grid={grid}
			data-puppet-tag={props.isDataRow ? 'table_data_row' : undefined}
		>
			<div className="inline-flex items-center space-x-2 text-base">
				<Text.BODY1 className="text-text-emphasis max-w-[200px] line-clamp-2 text-ellipsis overflow-hidden text-wrap">
					{props.marketString}
				</Text.BODY1>
				<div
					className={`text-xs ${
						matchEnum(PositionDirection.LONG, props.direction)
							? 'text-positive-green'
							: 'text-negative-red'
					}`}
				>
					{matchEnum(PositionDirection.LONG, props.direction)
						? props.isPredictionMarket
							? 'Yes'
							: props.useSpotWording
							? 'Buy'
							: 'Long'
						: props.isPredictionMarket
						? 'No'
						: props?.useSpotWording
						? 'Sell'
						: 'Short'}
				</div>
			</div>
			<div className="inline-flex justify-end">
				{isValidElement(props.action) ? (
					<>{props.action}</>
				) : props.action ? (
					<>
						{(props.action as ActionProp).neutral ? (
							<Button.Secondary
								className="justify-end p-0"
								size="SMALL"
								onClick={(props.action as ActionProp).onClick}
							>
								<Text.H5>{(props.action as ActionProp).label}</Text.H5>
							</Button.Secondary>
						) : (
							<TableActionButton
								positive={(props.action as ActionProp).positive}
								label={(props.action as ActionProp).label}
								handleClick={(props.action as ActionProp).onClick}
								className="justify-end p-0"
							/>
						)}
					</>
				) : null}
			</div>
			{props.children}
		</TableRowDiv>
	);
};

const TableV2 = {
	Skeleton,
	HeaderRow,
	BodyRow,
	SummaryRow,
	BodyRowWrapper,
	HeaderCell,
	TooltipHeaderCell,
	SortableHeaderCell,
	BodyCell,
	MarketCell,
	BankCell,
	NotionalCell,
	AssetCell,
	DateCell,
	ChartCell,
	ActionCell,
	OpenPositionMarketDirectionCell,
	EmptyValueFiller,
	UnsettledPnlTableCard,
	TableCard,
	ResponsiveTableRow,
	CardValue,
	OpenPredictionPositionIdCell,
	TradeSizeCell,
};

export default TableV2;
