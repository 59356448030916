import { PREDICTION_MARKET_CATEGORIES_LOOKUP } from 'src/constants/predictionMarkets/categories';
import {
	IPredictionMarketConfig,
	PREDICTION_MARKET_CATEGORY,
	PredictionMarketConfig,
} from '../../@types/predictionMarkets';
import { PREDICTION_MARKET_CONFIGS } from 'src/constants/predictionMarkets/markets';

export class PredictionMarketConfigs {
	static configs: PredictionMarketConfig[] = PREDICTION_MARKET_CONFIGS;

	static setConfigs(configList: IPredictionMarketConfig[]) {
		this.configs = configList.map(
			(config) => new PredictionMarketConfig(config)
		);
	}

	static get(marketIndex: number) {
		return this.configs.find((config) => config.marketIndex === marketIndex);
	}

	static getAllConfigs(liveOnly = false) {
		if (liveOnly) {
			return this.configs.filter((config) => config.isLive);
		}

		return this.configs;
	}

	static getPredictionMarketIndex(marketSymbol: string) {
		return this.configs.find((config) => config.symbol === marketSymbol)
			?.marketIndex;
	}
	static isPredictionMarket(marketSymbol: string) {
		return !!this.configs.find((config) => config.symbol === marketSymbol);
	}
	static getConfigFromMarketSymbol(marketSymbol: string) {
		if (
			marketSymbol === 'TRUMP-WIN-2024-PREDICT' ||
			marketSymbol === 'KAMALA-POPULAR-VOTE-2024-PREDICT'
		) {
			// this is a workaround to handle devnet configs that were not updated
			marketSymbol = marketSymbol.replace('PREDICT', 'BET');
		}

		return this.configs.find((config) => config.symbol === marketSymbol);
	}
	static getAllCategories() {
		return Object.keys(PREDICTION_MARKET_CATEGORIES_LOOKUP)
			.map((category) => ({
				category,
				...PREDICTION_MARKET_CATEGORIES_LOOKUP[
					category as PREDICTION_MARKET_CATEGORY
				],
			}))
			.filter((category) => !category.hide);
	}
	static getCategory(category?: PREDICTION_MARKET_CATEGORY) {
		if (!category) return null;

		return PREDICTION_MARKET_CATEGORIES_LOOKUP[category];
	}
	static getMarketsForCategory(category: PREDICTION_MARKET_CATEGORY) {
		if (category === PREDICTION_MARKET_CATEGORY.ALL) {
			return this.configs;
		}

		return this.configs.filter(
			(marketConfig) => marketConfig.category === category
		);
	}
}
