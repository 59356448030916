"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOracleId = exports.getOracleSourceNum = void 0;
const types_1 = require("../types");
function getOracleSourceNum(source) {
    if ('pyth' in source)
        return types_1.OracleSourceNum.PYTH;
    if ('pyth1K' in source)
        return types_1.OracleSourceNum.PYTH_1K;
    if ('pyth1M' in source)
        return types_1.OracleSourceNum.PYTH_1M;
    if ('pythPull' in source)
        return types_1.OracleSourceNum.PYTH_PULL;
    if ('pyth1KPull' in source)
        return types_1.OracleSourceNum.PYTH_1K_PULL;
    if ('pyth1MPull' in source)
        return types_1.OracleSourceNum.PYTH_1M_PULL;
    if ('switchboard' in source)
        return types_1.OracleSourceNum.SWITCHBOARD;
    if ('quoteAsset' in source)
        return types_1.OracleSourceNum.QUOTE_ASSET;
    if ('pythStableCoin' in source)
        return types_1.OracleSourceNum.PYTH_STABLE_COIN;
    if ('pythStableCoinPull' in source)
        return types_1.OracleSourceNum.PYTH_STABLE_COIN_PULL;
    if ('prelaunch' in source)
        return types_1.OracleSourceNum.PRELAUNCH;
    if ('switchboardOnDemand' in source)
        return types_1.OracleSourceNum.SWITCHBOARD_ON_DEMAND;
    if ('pythLazer' in source)
        return types_1.OracleSourceNum.PYTH_LAZER;
    if ('pythLazer1K' in source)
        return types_1.OracleSourceNum.PYTH_LAZER_1K;
    if ('pythLazer1M' in source)
        return types_1.OracleSourceNum.PYTH_LAZER_1M;
    if ('pythLazerStableCoin' in source)
        return types_1.OracleSourceNum.PYTH_LAZER_STABLE_COIN;
    throw new Error('Invalid oracle source');
}
exports.getOracleSourceNum = getOracleSourceNum;
function getOracleId(publicKey, source) {
    return `${publicKey.toBase58()}-${getOracleSourceNum(source)}`;
}
exports.getOracleId = getOracleId;
