import { twMerge } from 'tailwind-merge';
import { SwiftLightning } from './SwiftLightning';

const getSwiftLogoClassName = (variant: 'inactive' | 'active' | 'disabled') => {
	switch (variant) {
		case 'inactive':
			return 'text-text-label';
		case 'disabled':
			return 'text-text-disabled';
		case 'active':
			return 'text-swift-gradient';
	}
};

export const SwiftLogo = ({
	size = 'medium',
	variant = 'active',
}: {
	size?: 'small' | 'medium';
	variant?: 'inactive' | 'active' | 'disabled';
}) => {
	return (
		<div
			className={twMerge('flex items-center', getSwiftLogoClassName(variant))}
		>
			<SwiftLightning size={size} variant={variant} />
			<span className={twMerge('text-xs font-semibold')}>SWIFT</span>
		</div>
	);
};
