import { DEFAULT_USER_NAME, BN, OracleSource } from '@drift-labs/sdk';
import { ComputeUnits } from '../@types/types';
import { Commitment } from '@solana/web3.js';
import { CurrentPerpMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { JLP_POOL_ID, MAIN_POOL_ID, UIMarket } from '@drift/common';

export * from './math';

export const brandName = 'Drift';

export const DEFAULT_COMMITMENT_LEVEL: Commitment = 'confirmed'; // Default commitment level for fast ui updates.

/**
 * Explanation for the below parameters:
 *
 * - We're using blockheights to display the tx confirmation countdown in the new market order toasts.
 * - We expect this countdown to be IN SYNC with the time that the txSender in the driftClient throws an error for the expired transaction.
 * - The actual time before a block becomes expired is 151 slots (see https://solana.com/docs/advanced/confirmation)
 * - For some reason, when we fetch lastValidBlockHeight from the RPC, it gives a number +300 instead of +151 as expected. This is a "known" issue (see https://solana.stackexchange.com/questions/6238/why-is-lastvalidblockheight-300-blocks-ahead-than-current-blockheight-if-hashes#comment5177_6240)
 * - To account for the above, we keep the OFFSET as -150 , because adding that to the returned value will bring is back in sync with when the tx sender will throw an error.
 * - ^ note that this basically only applies to the whileValidTxSender ... if we started using a different tx sender we would have to rework this code a bit too, or update the new sending to do a similar confirmation check.
 *
 * ----------------
 *
 * ⭐️ Update 26th August 2024 :: Looks like the lastValidBlockHeight value being returned is correct now, as of this change https://github.com/solana-labs/solana/issues/24526#event-12622363063 .. leaving this comment and constant here for posterity but changing the value to zero
 */
export const TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL: Commitment =
	DEFAULT_COMMITMENT_LEVEL;
export const SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY_OFFSET = -150;
export const SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY = 151;

export {
	NEW_ACCOUNT_BASE_COST,
	NEW_ACCOUNT_BASE_RENT,
	NEW_ACCOUNT_DONATION,
	MIN_LEFTOVER_SOL,
} from '@drift/common';

/**
 * Banned character matcher for referral links to prevent messed up urls and XSS attempts
 *
 * Matching means a character is banned. Alphanumerical and dashes don't match, everything else matches.
 */
export const referralLinkBannedCharactersRegex = /[\s\n\t?/#<>%&[\]@".]/g;

export const ACCOUNT_HEALTH_PERCENT_WARNING = 15;
export const ACCOUNT_HEALTH_PERCENT_CRITICAL = 5;

export enum PageRoute {
	disclaimer = 'disclaimer',
	privacy = 'privacy',
	terms = 'terms',
	overview = 'overview',
	funding = 'funding',
	leaderboards = 'leaderboards',
	redeem = 'redeem',
	borrowLend = 'earn/borrow-lend',
	stake = 'stake',
	bridge = 'bridge',
	swap = 'swap',
	BAL = 'BAL',
	marketMakerRewards = 'mm-rewards',
	stats = 'stats',
	trade = '',
	elections = 'elections',
	bet = 'bet',
	vaults = 'vaults',
	fuel = 'fuel',
}

export const PageRoutes = [
	PageRoute.disclaimer,
	PageRoute.privacy,
	PageRoute.terms,
	PageRoute.overview,
	PageRoute.funding,
	PageRoute.leaderboards,
	PageRoute.redeem,
	PageRoute.borrowLend,
	PageRoute.stake,
	PageRoute.bridge,
	PageRoute.swap,
	PageRoute.BAL,
	PageRoute.marketMakerRewards,
	PageRoute.stats,
	PageRoute.elections,
	PageRoute.bet,
	PageRoute.vaults,
	PageRoute.fuel,
	// trade needs to be the last one in the array; since the path is an empty string, it will match any route
	PageRoute.trade,
];

export const NonTradeRoutes = PageRoutes.filter(
	(route) => route !== PageRoute.trade
);

export const newMarketCutoffTime = Date.now() - 1000 * 60 * 60 * 24 * 7;

export type CollateralConfig = {
	fullName: string;
	symbol: string;
	baseAssetSymbol: string;
	marketIndex: number;
	mintAddress?: string;
	oracleSource: OracleSource;
};

export const CROSS_COLLATERAL_MARKETS: CollateralConfig[] = [
	{
		fullName: 'USDC',
		symbol: 'USDC-PERP',
		baseAssetSymbol: 'USDC',
		marketIndex: 100,
		mintAddress: '8zGuJQqwhZafTah7Uc7Z4tXRnguqkn5KLFAP8oV6PHe2',
		oracleSource: OracleSource.PYTH,
	},
	{
		fullName: 'Solana',
		symbol: 'SOL-PERP',
		baseAssetSymbol: 'SOL',
		marketIndex: 0,
		mintAddress: 'Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr',
		oracleSource: OracleSource.PYTH,
	},
	{
		fullName: 'Bitcoin',
		symbol: 'BTC-PERP',
		baseAssetSymbol: 'BTC',
		marketIndex: 1,
		mintAddress: 'Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr',
		oracleSource: OracleSource.PYTH,
	},
	{
		fullName: 'Ethereum',
		symbol: 'ETH-PERP',
		baseAssetSymbol: 'ETH',
		marketIndex: 2,
		mintAddress: 'Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr',
		oracleSource: OracleSource.PYTH,
	},
];

export const INITIAL_RECORD_FETCH = 100;

export const COINGECKO_API_URL = 'https://pro-api.coingecko.com/api/v3';

export const WORMHOLE_WALLET_WHITELIST = ['Phantom'];

export const SUPER_STAKE_ACCOUNT_NAMES = [
	'Super Stake SOL',
	'Super Stake JitoSOL',
	'Super Stake bSOL',
];

export const DEFAULT_ACCOUNT_NAMES_BY_POOL_ID: Record<number, string> = {
	[MAIN_POOL_ID]: DEFAULT_USER_NAME,
	[JLP_POOL_ID]: 'JLP Market - Isolated Pool',
};

export const FORCED_NAME_POOL_IDS = [JLP_POOL_ID];

export const POOL_NAMES_BY_POOL_ID: Record<number, string> = {
	[MAIN_POOL_ID]: 'Main Market',
	[JLP_POOL_ID]: 'JLP Market',
};

export const ALLOW_TRANSFER_FOR_NON_MAIN_POOLS = false;

export const MAX_NUMBER_OF_PERP_POSITIONS = 8;

export const LP_SUBACCOUNT_NAME = 'Drift Liquidity Provider';
export const NEW_LP_SUBACCOUNT_NAME = 'Backstop AMM Liquidity';

export const STABLE_COIN_SYMBOLS = ['usdc', 'usdt', 'USDC', 'USDT'];

export const CUSTOM_RPC_LABEL = 'Custom';

export const DRIFT_MAYAN_REFERRER_ADDRESS =
	'drifthvYa35LEu1p56CrVbHgcWBLHqmx7tCDt7xhaE9';

export const S3_BUCKET_DRIFT_PUBLIC_MAINNET =
	'https://drift-public.s3.eu-central-1.amazonaws.com';

export const MARKET_ICON_PATH = `${S3_BUCKET_DRIFT_PUBLIC_MAINNET}/assets/icons/markets`;

export const WALLET_PROVIDER_BG_COLORS: Record<string, string> = {
	MathWallet: '#000',
	Solflare: '#26262A',
	Backpack: '#000',
	'Connect by Drift': '#FFF',
	Brave: '#000',
	WalletConnect: '#000',
};

type BridgeOptionConfig = {
	imageUrl: string;
	title: string;
	description: string;
	route: string;
	bridge: string;
};

const CCTP_BRIDGE_OPTION_CONFIG: BridgeOptionConfig = {
	imageUrl: '/assets/icons/circle-logo.svg',
	title: 'Circle (USDC Only)',
	description:
		'Circle’s cross-chain transfer protocol enables USDC to be sent across blockchains without needing to be converted. All transfer are permissionless and executed on chain.',
	route: '/bridge/cctp',
	bridge: 'cctp',
};

const MAYAN_BRIDGE_OPTION_CONFIG: BridgeOptionConfig = {
	imageUrl: '/assets/icons/mayan-logo.svg',
	title: 'Cross Chain Swaps with Mayan',
	description:
		'Easily swap assets from Ethereum, Arbitrum, and BNB to deposit onto Drift. You can choose to include gas drop off (SOL) in the same transaction.',
	route: '/bridge/mayan',
	bridge: 'mayan',
};

const WORMHOLE_BRIDGE_OPTION_CONFIG: BridgeOptionConfig = {
	imageUrl: '/assets/icons/wormhole-logo.svg',
	title: 'Bridge with Wormhole',
	description:
		'Bridge USDC from Ethereum to Solana. Bridged USDCet will need to be swapped for native USDC.',
	route: '/bridge/wormhole',
	bridge: 'wormhole',
};

export const BRIDGE_OPTIONS = [
	MAYAN_BRIDGE_OPTION_CONFIG,
	WORMHOLE_BRIDGE_OPTION_CONFIG,
	CCTP_BRIDGE_OPTION_CONFIG,
];

export const DRIFT_WALLET_CONNECT_PROJECT_ID =
	'c9af800e9b964d79cff882d8c1ef13f0';

export const PRETTY_ACCOUNT_RENT_MINIMUM = '0.0313';

export const MAX_COMPUTE_UNITS = 1_400_000 as ComputeUnits;

export const MAX_LEVERAGE_OPTIONS: {
	value: number | undefined;
	label: string;
	selectedTextClass?: string;
}[] = [
	{
		value: undefined,
		label: 'No Limit',
		selectedTextClass: 'text-text-secondary',
	},
	{
		value: 1,
		label: '1x',
	},
	{
		value: 2,
		label: '2x',
	},
	{
		value: 5,
		label: '5x',
	},
	{
		value: 10,
		label: '10x',
	},
	{
		value: 15,
		label: '15x',
	},
];

export const DRIFT_DISCORD_URL = 'https://discord.com/invite/95kByNnDy5';

// Error codes for SettleMultiplePnls that may not fail the transaction but could appear in the logs
export const SETTLE_MULTIPLE_PNL_ERROR_TYPES = {
	PNL_POOL_ERROR: ['PnlPoolCantSettleUser'],
	ORACLE_ERROR: [
		'OracleInvalid',
		'OracleTooVolatile',
		'OracleTooUncertain',
		'OracleStaleForMargin',
		'OracleInsufficientDataPoints',
		'OracleStaleForAMM',
	],
};

export const LAST_DRIFT_DRAW_TS = 1719194400000; // 2 UTC, June 24 2024

export const PERP_MARKET_OPTIONS = CurrentPerpMarkets.filter(
	(mkt) => !mkt.category?.includes('Prediction')
).map((mkt) => {
	const marketName = UIMarket.createPerpMarket(mkt.marketIndex).marketName;
	return {
		label: marketName,
		value: mkt.marketIndex,
	};
});

export const NOTIFICATION_CONFIGURATIONS = [
	{
		value: 'bottomRight',
		label: 'Bottom Right',
	},
	{
		value: 'bottomLeft',
		label: 'Bottom Left',
	},
	{
		value: 'topRight',
		label: 'Top Right',
	},
];

export const DRIFT_GRAFANA_METRICS_URL =
	'https://metrics.drift.trade/d/27H9DCM4z/perp-market-metrics?orgId=1&refresh=1m';

export const REALMS_URL = 'https://app.realms.today/dao/DRIFT';
export const FUTARCHY_URL = 'https://futarchy.metadao.fi/drift';
export const DRIFT_FOUNDATION_URL = 'https://drift.foundation';

/* hard code an additional number of ticks to apply to the end price of a perp auction */
export const ADDITIONAL_AUCTION_BUFFERS: { marketIndex: number; ticks: BN }[] =
	[{ marketIndex: 62, ticks: new BN(10) }];

// VAULTS UI CONFIG
export const SHOW_VAULTS_ALL_TIME_EARNINGS = false;
export const SHOW_VAULTS_PNL_GRAPH = false;
export const SHOW_VAULTS_APY_GRAPH = false;
export const SHOW_VAULTS_ROI_GRAPH = true;

// SLIPPAGE CONFIG
export const SLIPPAGE_PRESETS_MAJORS = [0.1, 0.5, 1];
export const SLIPPAGE_PRESETS_NON_MAJORS = [0.5, 1, 2.5];
