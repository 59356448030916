import { BigNum } from '@drift-labs/sdk';
import millify from './millify';
import React, { useMemo, useEffect, useState } from 'react';
import { compareDriftProps } from '../../utils/compareProps';
import useShowAccountValues from '../../hooks/useShowAccountValues';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import ColourCodedValue from './ColourCodedValue';

type DisplayType =
	| 'price'
	| 'price$'
	| 'notional$'
	| 'notional$Change'
	| 'notionalUsdc'
	| 'notionalUsdcChange'
	| 'percentageChange'
	| 'percentage'
	| 'asset'
	| 'assetChange'
	| 'int';

type FormattingProps = {
	displayType: DisplayType;
	millifyWithPrecision?: number; // Millify and apply this much precision
	toFixed?: number; // Fix the value to this many decimal places
	preserveTrailingZeroes?: boolean; // Whether to preserve trailing zeroes
	toPrecision?: number; // Format the value to this many significant figures
	trimZeroes?: boolean; // Trim trailing zeroes from the value
	assetSymbol?: string; // The symbol of the asset to display
};

type DisplayProps = {
	semanticColouring?: boolean; // Whether to apply semantic colouring to the value
	isAccountValueToHide?: boolean; // Whether to hide the value if the "hide account values" flag is on
	softenZeroValues?: boolean; // Whether to soften the display of zero values
	dataPuppetTag?: string; // A tag to help with e2e testing
	loading?: boolean; // Whether to show a loading indicator
	showSkeletonInsteadOfValue?: boolean; // Whether to show a skeleton placeholder instead of the value if the value is invalid
	keepStaleValues?: boolean; // Whether to keep the value stale (i.e. show the previous value) if the current value is invalid
	skeletonDimensionsClassName?: string; // The class name to apply to the skeleton dimensions
	className?: string; // Custom class name for additional styling
};

type NumberDisplayV3Props = {
	value: BigNum;
	formattingProps: FormattingProps;
	displayProps?: DisplayProps;
};

function numberToUnicodeSubscript(number: number) {
	// Map of normal numbers to their subscript Unicode equivalents
	const subscriptMap = {
		'0': '₀',
		'1': '₁',
		'2': '₂',
		'3': '₃',
		'4': '₄',
		'5': '₅',
		'6': '₆',
		'7': '₇',
		'8': '₈',
		'9': '₉',
	};

	// Convert each character using the map, or keep original if not a number
	return number
		.toString()
		.split('')
		.map((char) => subscriptMap[char as keyof typeof subscriptMap] || char)
		.join('');
}

const VERY_SMALL_NUMBER_LEADING_ZEROES = 3; // Consider a number with more than 4 leading zeroes after the decimal point to be very small
const VERY_LARGE_NUMBER_LEADING_DIGITS = 6; // Consider a number with more than 6 digits before the decimal point to be very large

/**
 * Is a very small number if the number of leading zeroes after the decimal point is greater than or equal to the threshold
 * @param value
 * @returns
 */
const isVerySmallValue = (value: string): boolean => {
	if (!value.includes('.')) return false;

	const splitValue = value.split('.');

	// Return regular value if all remaining digits are zero
	if (splitValue[1].match(/^0+$/)) {
		return false;
	}

	const leadingZeroes = splitValue[1].match(/^0+/)?.[0].length ?? 0;

	return leadingZeroes > VERY_SMALL_NUMBER_LEADING_ZEROES;
};

const isVeryLargeValue = (value: string): boolean => {
	return value.split('.')[0].length > VERY_LARGE_NUMBER_LEADING_DIGITS;
};

/**
 * Convert a very small number to a human readable form using a subscript value to represent the decimal place. E.g. 0.00001 => 0.0<sub>4</sub>1
 * @param value
 */
export const renderVerySmallValue = (value: string) => {
	const splitValue = value.split('.');

	if (!splitValue[1] || !isVerySmallValue(value)) {
		return value;
	}

	// Get the number of leading zeroes after the decimal
	const leadingZeroes = splitValue[1].match(/^0+/)?.[0].length ?? 0;

	const valueAfterLeadingZeroes = splitValue[1].slice(leadingZeroes);

	// Return regular value if all remaining digits are zero
	if (splitValue[1].match(/^0+$/)) {
		return value;
	}

	return `${splitValue[0]}.0${numberToUnicodeSubscript(
		leadingZeroes
	)}${valueAfterLeadingZeroes}`;
};

/**
 * Convert a very large number to human readable form (using millified notation)
 * @param value
 * @returns
 */
export const renderVeryLargeValue = (value: string, precision = 6) => {
	if (!isVeryLargeValue(value)) {
		return value;
	}

	return millify(Number(value), {
		precision,
		trimEndingZeroes: true,
	});
};

/**
 * Method which runs through the renderVerySmallValue or renderVeryLargeValue methods if necessary
 * @param value
 * @returns MarkdownValue
 */
export const handleSpecialRendering = (
	value: string,
	fallBackRenderingValue?: string
): string => {
	if (typeof value !== 'string') return value; // TODO :: Caught this happening in Network settings panel but should never happen
	if (!value) return value;
	if (isVerySmallValue(value)) {
		return renderVerySmallValue(value);
	}
	if (isVeryLargeValue(value)) {
		return renderVeryLargeValue(value);
	}
	return fallBackRenderingValue ?? value;
};

const numIsInvalid = (val: BigNum) => {
	if (val === null) return true;
	if (typeof val === 'undefined') return true;
	return false;
};

export const formatValue = (
	value: BigNum,
	formattingProps: FormattingProps
) => {
	const isInvalid = numIsInvalid(value);

	let prefix = '';
	let formattedValue = value?.printShort() ?? '';
	let suffix = '';

	if (isInvalid) {
		formattedValue = '';
		// TODO :: HANDLE INVALID VALUES
		return '';
	}

	const absValue = value.abs();

	// # Handle Core Number Formatting
	if (formattingProps.millifyWithPrecision) {
		formattedValue = absValue.toMillified(formattingProps.millifyWithPrecision);
	} else if (formattingProps.toPrecision) {
		const fallback = formattingProps.preserveTrailingZeroes
			? absValue.toPrecision(formattingProps.toPrecision)
			: absValue.prettyPrint(false, formattingProps.toPrecision);
		formattedValue = handleSpecialRendering(
			absValue.toPrecision(formattingProps.toPrecision),
			fallback
		);
	} else if (formattingProps.toFixed) {
		formattedValue = handleSpecialRendering(
			absValue.toFixed(formattingProps.toFixed),
			BigNum.fromPrint(
				absValue.toFixed(formattingProps.toFixed),
				absValue.precision
			).prettyPrint()
		);
	} else if (formattingProps.trimZeroes) {
		formattedValue = handleSpecialRendering(absValue.printShort());
	} else {
		const isNotionalDisplayType =
			formattingProps.displayType === 'notional$' ||
			formattingProps.displayType === 'notionalUsdc' ||
			formattingProps.displayType === 'notional$Change' ||
			formattingProps.displayType === 'notionalUsdcChange';

		if (isNotionalDisplayType) {
			// Print to 2 decimal places for notional display types
			formattedValue = handleSpecialRendering(absValue.toFixed(2));
		} else {
			formattedValue = handleSpecialRendering(
				absValue.print(),
				absValue.prettyPrint()
			);
		}
	}

	// Handle Prefix and Suffix Formatting
	const isNegative = value.ltZero();
	const gtZero = value.gtZero();

	switch (formattingProps.displayType) {
		case 'notional$':
		case 'notional$Change':
		case 'notionalUsdc':
		case 'notionalUsdcChange': {
			if (formattingProps.assetSymbol) {
				throw new Error(
					'Asset symbol not supported for notional display types'
				);
			}

			if (formattingProps.displayType === 'notional$') {
				prefix = '$';
			} else if (formattingProps.displayType === 'notionalUsdc') {
				suffix = ' USDC';
			} else if (formattingProps.displayType === 'notional$Change') {
				prefix = '$';
				if (gtZero) {
					prefix = `+${prefix}`;
				}
			} else if (formattingProps.displayType === 'notionalUsdcChange') {
				suffix = ' USDC';
				if (gtZero) {
					prefix = `+${prefix}`;
				}
			}

			if (isNegative) {
				prefix = `-${prefix}`;
			}
			break;
		}
		case 'percentage':
			suffix = '%';
			break;
		case 'percentageChange':
			if (isNegative) {
				prefix = '-';
			} else {
				prefix = '+';
			}
			suffix = '%';
			break;
		case 'asset':
			if (!formattingProps.assetSymbol) break;
			if (isNegative) {
				prefix = '-';
			}
			suffix = ` ${formattingProps.assetSymbol}`;
			break;
		case 'assetChange':
			if (!formattingProps.assetSymbol) break;
			if (isNegative) {
				prefix = '-';
			} else if (gtZero) {
				prefix = '+';
			}
			suffix = ` ${formattingProps.assetSymbol}`;
			break;
		case 'int':
			formattedValue = value.toString();
			break;
		case 'price':
			break;
		case 'price$':
			prefix = '$';
			break;
		default: {
			const _exhaustiveCheck: never = formattingProps.displayType;
			throw new Error(`Unhandled display type: ${_exhaustiveCheck}`);
		}
	}

	return `${prefix}${formattedValue}${suffix}`;
};

export const NumberDisplayV3: React.FC<NumberDisplayV3Props> = ({
	value,
	formattingProps,
	displayProps = {},
}) => {
	if (
		displayProps?.showSkeletonInsteadOfValue &&
		!displayProps.skeletonDimensionsClassName
	) {
		throw new Error(
			'Skeleton dimensions are required if showSkeletonInsteadOfValue is true'
		);
	}

	const showAccountValues = useShowAccountValues();
	const [staleValue, setStaleValue] = useState<string | null>(null);

	// Format the value according to the formatting props
	const formattedValue = useMemo(() => {
		const isInvalid = numIsInvalid(value);

		if (isInvalid && displayProps.keepStaleValues && staleValue) {
			return staleValue;
		}

		return formatValue(value, formattingProps);
	}, [value, formattingProps, displayProps.keepStaleValues, staleValue]);

	// Update stale value when formatted value changes
	useEffect(() => {
		if (formattedValue) {
			setStaleValue(formattedValue);
		}
	}, [formattedValue]);

	// Handle loading state
	if (displayProps.loading) {
		return (
			<SkeletonValuePlaceholder
				data-puppet={displayProps.dataPuppetTag}
				loading={true}
				className={`${displayProps.skeletonDimensionsClassName ?? ''} ${
					displayProps.className ?? ''
				}`}
			/>
		);
	}

	// Handle invalid value with skeleton
	if (numIsInvalid(value) && displayProps.showSkeletonInsteadOfValue) {
		return (
			<SkeletonValuePlaceholder
				data-puppet={displayProps.dataPuppetTag}
				loading={false}
				className={`${displayProps.skeletonDimensionsClassName ?? ''} ${
					displayProps.className ?? ''
				}`}
			/>
		);
	}

	// Handle hidden account values
	if (displayProps.isAccountValueToHide && !showAccountValues) {
		return (
			<span
				data-puppet={displayProps.dataPuppetTag}
				className={displayProps.className}
			>
				******
			</span>
		);
	}

	// Handle semantic coloring
	if (displayProps.semanticColouring) {
		return (
			<ColourCodedValue
				value={value.toNum()}
				data-puppet={displayProps.dataPuppetTag}
				className={`${
					displayProps.softenZeroValues && value?.eqZero() ? 'opacity-50' : ''
				} ${displayProps.className ?? ''}`}
			>
				{formattedValue}
			</ColourCodedValue>
		);
	}

	// Default display
	return (
		<span
			data-puppet={displayProps.dataPuppetTag}
			className={`${
				displayProps.softenZeroValues && value?.eqZero() ? 'opacity-50' : ''
			} ${displayProps.className ?? ''}`}
		>
			{formattedValue}
		</span>
	);
};

export default React.memo(NumberDisplayV3, compareDriftProps);
