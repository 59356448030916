import { DRIFT_WALLET_CONNECT_PROJECT_ID } from '../constants/constants';
import { DriftEnv } from '@drift-labs/sdk';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { solana, solanaDevnet } from '@reown/appkit/networks';
import { createAppKit } from '@reown/appkit/react';
import { SolanaAdapter } from '@reown/appkit-adapter-solana/react';
import {
	PhantomWalletAdapter,
	SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';

const setupAppKitWallet = (_env: DriftEnv) => {
	const metadata = {
		name: 'Drift Protocol',
		description:
			'Drift is the leading perpetuals DEX on Solana with the most CEX-like trader experience',
		url: 'https://app.drift.trade/',
		icons: [
			'https://drift-public.s3.eu-central-1.amazonaws.com/DriftLogoCircular.png',
		],
	};

	const solanaAdapter = new SolanaAdapter({
		wallets: [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
	});

	createAppKit({
		projectId: DRIFT_WALLET_CONNECT_PROJECT_ID,
		metadata,
		networks: [solana, solanaDevnet],
		adapters: [solanaAdapter],
		showWallets: false,
	});
};

setupAppKitWallet(Env.sdkEnv);
