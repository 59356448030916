import produce, { Draft } from 'immer';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
	NotificationData,
	SignedMsgOrderToastData,
	V2AuctionToastData,
} from '../utils/notifications';

export type Notification =
	| NotificationData
	| V2AuctionToastData
	| SignedMsgOrderToastData;

const DEFAULT_NOTIFICATIONS: Array<Notification> = [];

export interface NotificationStore {
	notifications: Array<Notification>;
	set: (x: (s: NotificationStore) => void) => void;
	get: () => NotificationStore;
	addNotification: (notification: Notification) => void;
	clearAllNotifications: () => void;
	removeNotificationById: (notificationId: string) => void;
	removeNotificationByIndex: (index: number) => void;
	updateNotificationById: (
		notificationId: string,
		newNotification: Notification
	) => void;
	updateNotificationByIndex: (
		index: number,
		newNotification: Partial<Notification>
	) => void;
	updateOrAddNotificationById: (
		notificationId: string,
		newNotification: Notification
	) => void;
	hideNotificationById: (notificationId: string) => void;
}

const useNotificationStore = create(
	devtools<NotificationStore>((set, get): NotificationStore => {
		const immerSet = (fn: (s: NotificationStore) => void) => set(produce(fn));

		const addNotification: NotificationStore['addNotification'] = (
			notification
		) => {
			immerSet((s) => {
				s.notifications.unshift(notification);
			});
		};

		/**
		 * Inner utility method to remove notifications by index. Should be used inside of immerSet.
		 * @param s
		 * @param index
		 */
		const _removeNotificationByIndex = (
			s: Draft<NotificationStore>,
			index: number
		) => {
			s.notifications.splice(index, 1);
		};

		const removeNotificationById: NotificationStore['removeNotificationById'] =
			(notificationId) => {
				immerSet((s) => {
					const index = s.notifications.findIndex(
						(notification) => notification.id === notificationId
					);

					if (index !== -1) {
						_removeNotificationByIndex(s, index);
					}
				});
			};

		const removeNotificationByIndex: NotificationStore['removeNotificationByIndex'] =
			(index) => {
				immerSet((s) => {
					_removeNotificationByIndex(s, index);
				});
			};

		/**
		 * ⭐️ Should only be used inside of immerSet
		 * @param s
		 * @param index
		 * @param newNotification
		 */
		const _updateNotificationByIndex = (
			s: Draft<NotificationStore>,
			index: number,
			newNotification: Partial<Notification>
		) => {
			// @ts-ignore
			s.notifications[index] = {
				...s.notifications[index],
				...newNotification,
			};
		};

		const updateNotificationById: NotificationStore['updateNotificationById'] =
			(notificationId, newNotification) => {
				immerSet((s) => {
					const index = s.notifications.findIndex(
						(notification) => notification.id === notificationId
					);

					if (index !== -1) {
						_updateNotificationByIndex(s, index, newNotification);
					}
				});
			};

		const updateNotificationByIndex: NotificationStore['updateNotificationByIndex'] =
			(index, newNotification) => {
				immerSet((s) => {
					_updateNotificationByIndex(s, index, newNotification);
				});
			};

		const updateOrAddNotificationById: NotificationStore['updateOrAddNotificationById'] =
			(notificationId, newNotification) => {
				const index = get().notifications.findIndex(
					(notification) => notification.id === notificationId
				);

				if (index !== -1) {
					updateNotificationByIndex(index, newNotification);
				} else {
					addNotification(newNotification);
				}
			};

		const hideNotificationById: NotificationStore['hideNotificationById'] = (
			notificationId
		) => {
			updateNotificationById(notificationId, {
				...get().notifications.find((n) => n.id === notificationId),
				hidden: true,
			});
		};

		const clearAllNotifications = () => {
			immerSet((s) => {
				s.notifications = [];
			});
		};

		return {
			notifications: DEFAULT_NOTIFICATIONS,
			set: immerSet,
			get: () => get(),
			addNotification,
			clearAllNotifications,
			removeNotificationById,
			removeNotificationByIndex,
			updateNotificationById,
			updateNotificationByIndex,
			updateOrAddNotificationById,
			hideNotificationById,
		};
	})
);

export default useNotificationStore;
