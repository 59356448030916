import {
	BigNum,
	BN,
	BulkAccountLoader,
	DriftClient,
	isVariant,
	LAMPORTS_EXP,
	MarketType,
	QUOTE_PRECISION_EXP,
	QuoteResponse,
	SpotMarketConfig,
	SwapMode,
	TokenFaucet,
	UserStatsAccount,
	ZERO,
} from '@drift-labs/sdk';
import {
	MarketDetails24H,
	Trade,
	UIMarket,
	UISerializableLeaderboardResult,
	UISerializableOrderActionRecord,
	UISerializableOrderRecord,
	UISerializableOrder,
	MarketMakerRewardRecord,
	UISerializableLiquidationRecord,
	OpenPosition,
	UIOrderType,
	UI_ORDER_TYPES,
	MarketId,
	RpcEndpoint,
	DownloadRecordType,
	NEW_ACCOUNT_DONATION,
	NEW_ACCOUNT_BASE_COST,
	NEW_ACCOUNT_BASE_RENT,
	MIN_LEFTOVER_SOL,
	MAIN_POOL_ID,
} from '@drift/common';
import { Wallet } from '@solana/wallet-adapter-react';
import {
	BaseMessageSignerWalletAdapter,
	WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
	Connection,
	PublicKey,
	Transaction,
	VersionedTransaction,
} from '@solana/web3.js';
import EventEmitter from 'events';
import produce from 'immer';
import { TriggerOrderInfo } from 'src/components/TradeForm/BracketOrderForm';
import Env, {
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import { BorrowMarketData } from 'src/hooks/useBorrowLendBalances';
import DriftAppEvents from 'src/utils/DriftAppEvents';
import StrictEventEmitter from 'strict-event-emitter-types';
import { create } from 'zustand';
import { SEMANTIC_STATUS } from '../../components/Utils/SemanticChip';
import { PageRoute } from '../../constants/constants';
import { PriceImpactInfo } from '../../hooks/usePriceImpact';
import WalletConnectionState from '../../utils/WalletConnectionState';
import { LiquidityPool } from 'src/hooks/useLiquidityPools';
import { DriftStoreModals } from './driftStoreTypes';
import { TradeFormMessageType } from 'src/@types/trade';
import { ReactNode } from 'react';
import { Token } from '../types';
import { UiVaultConfig } from 'src/@types/vaults';
import { Vault, VaultDepositor } from '@drift-labs/vaults-sdk';
import { VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY } from 'src/constants/vaults/misc';
import {
	SimpleFundingRateRecord,
	SlippageTolerance,
	StakeAccountInfo,
} from 'src/@types/types';
import { AccountCreationCostBreakdown } from 'src/hooks/useAccountCreationCost';
import { AssetListAsset } from 'src/@types/assets';

export const DEFAULT_AVOID_HISTORY_SERVER_LOAD =
	Env.localEnv === 'mainnet-beta';

type BaseAlertData<T> = {
	untilMs?: number;
} & T;

export type AlertData =
	| {
			alertType: 'feature';
			alertData: BaseAlertData<{
				heading: string;
				description: string;
				linkUrl?: string;
				linkText?: string;
				linkNewTab?: boolean;
			}>;
	  }
	| {
			alertType: 'market';
			alertData: BaseAlertData<{
				baseSymbol: string;
			}>;
	  }
	| {
			alertType: '' | 'driftmas';
			alertData: '';
	  }
	| {
			alertType: 'warning';
			alertData: BaseAlertData<{
				heading: string;
				description: string;
				linkUrl?: string;
				linkText?: string;
			}>;
	  }
	| {
			alertType: 'collateral';
			alertData: BaseAlertData<{
				baseSymbol: string;
			}>;
	  }
	| {
			alertType: 'downloadReady';
			alertData: '';
	  };

export type DriftAppEventEmitter = StrictEventEmitter<
	EventEmitter,
	DriftAppEvents
>;

export const INITIAL_WALLET_STATE: DriftStore['wallet'] = {
	current: {
		adapter: undefined,
		readyState: WalletReadyState.Unsupported,
	},
	currentPublicKeyString: undefined,
	usdcBalance: BigNum.zero(QUOTE_PRECISION_EXP),
	usdcAssociatedAccount: null,
	currentSolBalance: BigNum.zero(LAMPORTS_EXP),
	whitelistTokenBalance: null,
	info: {
		collateral: 0,
		quoteAssetNotionalAmount: 0,
		lastCumFunding: 0,
		baseAssetAmount: 0,
	},
	isMagicAuth: false,
	isAppKit: false,
	isMetamask: false,
	isPasskeys: false,
	connectionState: new WalletConnectionState(),
	storedSettings: {
		mostRecentSignatureHash: undefined,
	},
	overrideTxVersion: undefined,
};

type EditOrderPopupOptions = {
	orderToEdit: UISerializableOrder & { accountName?: string };
	popupTargetId: string;
};

export interface DriftStore {
	latestDeployId: string;
	status: {
		connected: boolean;
	};
	connection: {
		rpc: RpcEndpoint;
		accountLoader: BulkAccountLoader;
		current: Connection;
		usdcMint: string;
	};
	wallets: Wallet[];
	isEmulatingAccount: boolean;
	wallet: {
		current: {
			adapter: BaseMessageSignerWalletAdapter;
			readyState: WalletReadyState;
		};
		currentPublicKeyString: string;
		usdcBalance: BigNum;
		usdcAssociatedAccount: PublicKey | undefined;
		currentSolBalance: BigNum;
		whitelistTokenBalance: number;
		info: {
			collateral: number;
			quoteAssetNotionalAmount: number;
			lastCumFunding: number;
			baseAssetAmount: number;
		};
		isMagicAuth: boolean;
		isAppKit: boolean;
		isMetamask: boolean;
		isPasskeys: boolean;
		lastRouteBeforeConnect?: string;
		connectionState: WalletConnectionState;
		storedSettings: {
			mostRecentSignatureHash: string;
		};
		overrideTxVersion?: 'legacy' | 0;
	};
	settings: {
		uiLocked: boolean;
	};
	tradeForm: {
		side: 'buy' | 'sell';
		leadSide: 'quote' | 'base';
		slippageTolerance: SlippageTolerance;
		allowInfSlippage: boolean;
		closingPosition: boolean;
		useAdvSettings: boolean;
		leverage: number;
		orderType: UIOrderType;
		reduceOnly: boolean;
		postOnly: boolean;
		// Setting these to readonly to try force usage of the methods inside useSetTradeFormInputState hook when writing to these values. This helps with debugging and standardisation. If your use-case must write to these values seperately, you can use @ts-expect-error to ignore the typescript error.
		readonly quoteSizeStringValue: string;
		readonly baseSizeStringValue: string;
		readonly priceBoxStringValue: string;
		readonly secondaryPriceBoxStringValue: string;
		immediateOrCancel: boolean;
		priceImpact: PriceImpactInfo;
		maxLeverageSelected: boolean;
		showBracketOrderForm: boolean;
		bracketOrders: {
			takeProfit: TriggerOrderInfo;
			stopLoss: TriggerOrderInfo;
			anyInvalid?: boolean;
		};
		stepSize: number; // e.g. 0.01
		savedLimitPrice: {
			market: MarketId;
			price: number;
		};

		/**
		 * Whether the user is attempting a trade that is opposite from their current non-zero position.
		 */
		isFlippingPosition: boolean;
		isInDepositToTradeFlow: boolean;
		messageType: TradeFormMessageType;
		message: ReactNode;
		skipMessageInConfirmationModal: boolean;
		scaledOrders: {
			orderCount: number;
			sizeDistribution: 'flat' | 'ascending' | 'descending';
		};
		isSwiftSelected: boolean;
	};
	selectedMarket: {
		name: string;
		address: string;
		current: UIMarket | null;
		orderBook: any[];
		mantissa: BN;
		priceChangePercent: number;
		priceChangeAbsolute: number;
		marketId: MarketId;
	};
	set: (x: (s: DriftStore) => void) => void;
	get: () => DriftStore;
	driftClient: {
		isSubscribed: boolean;
		client?: DriftClient;
	};
	userStatsAccount: UserStatsAccount;
	faucet: TokenFaucet | undefined;
	doneUpdate: boolean;
	loadingElements: {
		[key in UIElementKeys]?: {
			isLoading: boolean;
			callback?: () => void;
		};
	};
	marketTradeHistory: {
		market: UIMarket;
		trades: UISerializableOrderActionRecord[];
		mostRecentTradeScore: number;
	};
	allOpenOrders: UISerializableOrderRecord[];
	ordersAwaitingPnlModalDisplay: Record<
		string,
		{ openPositionInfo: OpenPosition; orderId: number }
	>;
	/**
	 * Array of signed txes to auto-cancel existing orders in 1 or more markets
	 * Sent out by the useAutoCancelExistingOrdersListener hook once all positions in marketIndexesOfOrders have been closed
	 */
	ordersAwaitingAutoCancel: {
		marketIndexesOfOrders: number[];
		signedCancelExistingOrdersTx: Transaction | VersionedTransaction;
	}[];
	/* Use same logic to automatically settle pnl after a close */
	pnlsAwaitingAutoSettle: {
		marketIndexesOfPnls: number[];
		signedSettlePnlsTx: Transaction | VersionedTransaction;
	}[];
	currentOrderbook: {
		bids: {
			authority: null;
			cumulativeSize: number;
			maxSizePercent: number;
			price: number;
			size: number;
			sizePercent: number;
		}[];
		asks: {
			authority: null;
			cumulativeSize: number;
			maxSizePercent: number;
			price: number;
			size: number;
			sizePercent: number;
		}[];
		spread: number;
		spreadPercentage: number;
	};
	currentOrderbookGrouping: number;
	hasAcknowledgedTerms: boolean;
	hasAcknowledgedAlphaTicket: boolean;
	countryCode: string;
	isGeoblocked: boolean;
	modals: DriftStoreModals;
	modalsProps: {
		readonly intentState: {
			readonly modalCollateralIndex: number | null;
			readonly modalCollateralPoolId: number | null;
			readonly modalTargetAccountKey: string | null;
			readonly modalDefaultLargestBalance: boolean | null;
		};
		showStakeModal?: {
			isDriftStaking?: boolean;
		};
		closeAllPositionsModal?: {
			perpMarketIndexesToClose: number[];
		};
		showVaultDepositWithdrawFormModal?: {
			uiVaultConfig: UiVaultConfig;
			vaultAccountData: Vault | undefined;
			vaultDepositorAccountData: VaultDepositor | undefined;
			isVaultDepositorLoaded: boolean;
			isWithdraw: boolean;
		};
		showVaultTermsDisclaimerModal?: {
			depositCallback: () => Promise<void>;
		};
		showAssetSelectModal?: {
			source: 'wallet' | 'subaccount';
			assetList: AssetListAsset[];
			selectedAsset?: Token;
			showStakeAccountsTab?: boolean;
			selectedStakeAccount?: StakeAccountInfo;
		};
	};
	currentPageRoute: PageRoute;
	userInfoTable: {
		currentTab: string;
		tradeHistoryMarketFilter: string;
	};
	popups: {
		editOrderPopupOptions: EditOrderPopupOptions;
		closePositionPopupOptions: {
			marketIndex: number;
			uiOrderType: UIOrderType;
			isStopLossOnly: boolean;
			isTakeProfitOnly: boolean;
		};
	};
	authorityParam: string;
	referrerParam: string;
	referrerParamFailed: boolean;
	driftStatus: {
		mainStatus: {
			status: SEMANTIC_STATUS;
			message: string;
		};
		historyServerStatus: {
			maintenanceMode: boolean;
			maintenanceModeEstimateTs: number;
		};
	};
	driftFeatureAlert: AlertData;
	appEventEmitter: DriftAppEventEmitter;
	utmParams: string;
	pnlLeaderboard: {
		startTime: number;
		endTime: number;
		data: UISerializableLeaderboardResult;
	};
	recentTradesCurrentTab: string;
	challengers: string[];
	marketsData24H: MarketDetails24H[];
	pricePoints24H: {
		pricePoints: number[];
		marketType: MarketType;
		marketIndex: number;
	}[];
	predictedFundings: { marketIndex: number; fundingRate: number }[];
	marketFundingHistory: { [marketIndex: number]: SimpleFundingRateRecord[] };
	tvMarketHistory: {
		market: UIMarket;
		trades: Trade[];
	}[];
	subAccountsInfo: {
		showDropdown: boolean;
		accountIdToEdit: number;
		accountIdToDelete: number;
	};
	overviewPage: {
		selectedView: 'overview' | 'accountList';
		lastRoute: string;
	};
	downloadType: DownloadRecordType;
	pnlModalPosition: {
		position: Pick<
			OpenPosition,
			| 'marketSymbol'
			| 'marketIndex'
			| 'baseSize'
			| 'entryPrice'
			| 'quoteEntryAmount'
			| 'direction'
		>;
		actualExitPrice: number;
		pnl: number;
		isClosed: boolean;
		isLpPosition: boolean;
	};
	// should this be CollateralConfig? not sure if thats actively being used
	selectedAsset: SpotMarketConfig;
	/**
	 * This is just a SpotMarketConfig but it tells the IF stake modal what IF stake pool to show by default
	 */
	selectedIfStakeMarket: SpotMarketConfig;
	/**
	 * Currently selected trade to display in the TradeDetailsModal. Only for Mobile.
	 */
	selectedTradeDetails: {
		baseAssetAmountFilled: BigNum;
		baseAssetSymbol: string;
		avgEntryPrice: string;
		fee: BigNum;
		isLong: boolean;
		isSpot: boolean;
		marketName: string;
		quoteAssetAmountFilled: BigNum;
		ts: BN;
	};
	borrowLendData: BorrowMarketData[];
	borrowLendMarketDefault: 'deposit' | 'borrow';
	totalUserCount: number;
	hasCompletedOnboarding: boolean;
	rpcUrl: string;
	currentBulkAccountLoader: BulkAccountLoader;
	showGridCustomiser: boolean;
	referredByAuthority: PublicKey;
	referredBy: string;
	referredByLoaded: boolean;
	selectedAssetDetailsSymbol: BorrowMarketData['symbol'];
	/* Swap page params */
	swap: {
		fromMarketIndex: number;
		toMarketIndex: number;
		poolId: number;
		swapDetails?: {
			userKey: string;
			amount: BN;
			fromMarketIndex: number;
			toMarketIndex: number;
			swapMode: SwapMode;
			quote: QuoteResponse;
			onlyDirectRoutes?: boolean;
			slippageBps?: number;
		};
	};
	liquidityPoolInfo: {
		currentUserKey: string;
		pools: LiquidityPool[];
	};
	pollingMultiplier: number;
	avoidHistoryServerLoad: boolean;
	marketMakerRewards: {
		records: MarketMakerRewardRecord[];
		totalRewardsPaid: number;
		lastUpdated: Date;
	};
	allLiquidationHistory: {
		liquidations: UISerializableLiquidationRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
	};
	insuranceFundStats: {
		totalRevenue: number;
		totalPayment: number;
		totalSocializedLoss: number;
		perpLiquidationTotal: number;
		spotLiquidationTotal: number;
		lastPerpBankruptcy: number;
		lastSpotBankruptcy: number;
	};
	showMetamaskFirstTimeHelper: boolean;
	showMagicLinkFirstTimeHelper: boolean;
	statusBars: {
		showNewVersionStatusBar: boolean;
	};
	countUpOnce: Record<string, boolean>;
	tradePageTableFilters: {
		showOnlyCurrentMarket?: boolean;
		showPredictionMarkets?: boolean;
		showSpotMarkets?: boolean; // only for orders table
		showPerpMarkets?: boolean;
	};
	predictionsPageTableFilters: {
		// separate state for predictions and trade page
		// We might want to do this for overview too?
		showOnlyCurrentMarket?: boolean;
		showPredictionMarkets?: boolean;
		showPerpMarkets?: boolean;
		showSpotMarkets?: boolean;
	};
	overviewPageTableFilters: {
		showOnlyCurrentMarket?: boolean;
		showPredictionMarkets?: boolean;
		showPerpMarkets?: boolean;
		showSpotMarkets?: boolean;
	};
	tutorial: {
		step?: number;
	};
	bankruptcyStats: {
		'24h': {
			totalAmount: number;
			totalCount: number;
			ifPayment: number;
			socialLoss: number;
		};
	};
	liquidationStats: {
		'24h': {
			totalAmount: number;
			totalCount: number;
		};
		'30d': {
			totalAmount: number;
			totalCount: number;
		};
	};
	fuelStats: {
		userRank: number;
		tradingVolume: number;
		leaderboard: {
			rank: number;
			fuelTotal: number;
			authority: string;
		}[];
		milestonesReached: number;
	};

	scrollToJlpIsolatedPoolEarnSection?: boolean;

	/**
	 * Checks if given props are a sell prediction market. If props are not given,
	 * it checks the currently selected market and the current trade form params.
	 */
	checkIsSellPredictionMarket: (props?: {
		isPredictionMarket?: boolean;
		isSellSide?: boolean;
	}) => boolean;

	[VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY]: boolean;

	accountCreationCost: AccountCreationCostBreakdown;
}

const useDriftStore = create<DriftStore>((set, get): DriftStore => {
	const immerSet = (fn: (s: DriftStore) => void) => set(produce(fn));
	const defaultMarket = UIMarket.createPerpMarket(0);

	return {
		latestDeployId: undefined,
		status: {
			connected: true,
		},
		connection: {
			rpc: null,
			accountLoader: null,
			current: null,
			usdcMint: null,
		},
		settings: {
			uiLocked: true,
		},
		isEmulatingAccount: false,
		popups: {
			editOrderPopupOptions: {
				popupTargetId: '',
				orderToEdit: null,
			},
			// Actually used for the popup AND the modal
			closePositionPopupOptions: {
				marketIndex: -1,
				uiOrderType: null,
				isStopLossOnly: false,
				isTakeProfitOnly: false,
			},
		},
		tradeForm: {
			side: 'buy',
			leadSide: 'quote',
			slippageTolerance: 1.0,
			allowInfSlippage: false,
			closingPosition: false,
			useAdvSettings: false,
			leverage: 1,
			orderType: UI_ORDER_TYPES.limit.value,
			baseSizeStringValue: '',
			quoteSizeStringValue: '',
			priceBoxStringValue: '',
			secondaryPriceBoxStringValue: '',
			reduceOnly: false,
			postOnly: false,
			immediateOrCancel: false,
			priceImpact: {
				marketIndex: 0,
				marketType: MarketType.PERP,
				entryPrice: ZERO,
				priceImpact: ZERO,
				baseAvailable: ZERO,
				bestPrice: ZERO,
				worstPrice: ZERO,
				priceImpactInputBaseSize: ZERO,
				showPriceEstimateOracleDivergenceWarning: false,
				exceedsLiquidity: false,
			},
			maxLeverageSelected: false,
			showBracketOrderForm: false,
			bracketOrders: undefined,
			stepSize: 0,
			savedLimitPrice: undefined,
			isFlippingPosition: false,
			isInDepositToTradeFlow: false,
			messageType: undefined,
			message: undefined,
			skipMessageInConfirmationModal: false,
			scaledOrders: {
				orderCount: 2,
				sizeDistribution: 'flat',
			},
			isSwiftSelected: false,
		},
		wallets: [],
		wallet: INITIAL_WALLET_STATE,
		set: immerSet,
		get: () => get(),
		driftClient: {
			isSubscribed: false,
			client: null,
		},
		userStatsAccount: undefined,
		faucet: null,
		selectedMarket: {
			name: defaultMarket.market.symbol,
			address: process.env.NEXT_PUBLIC_SOL_MARKET,
			current: defaultMarket,
			orderBook: [],
			mantissa: new BN(10 ** 9),
			priceChangePercent: undefined,
			priceChangeAbsolute: undefined,
			marketId: defaultMarket.marketId,
		},
		doneUpdate: false,
		//@ts-ignore
		//actions,
		loadingElements: {},
		marketTradeHistory: {
			market: defaultMarket,
			trades: [],
			mostRecentTradeScore: 0,
		},
		allOpenOrders: [],
		ordersAwaitingPnlModalDisplay: {},
		ordersAwaitingAutoCancel: [],
		pnlsAwaitingAutoSettle: [],
		currentOrderbook: {
			bids: [],
			asks: [],
			spread: 0,
			spreadPercentage: 0,
		},
		currentOrderbookGrouping: 0.0001,
		hasAcknowledgedTerms: false,
		hasAcknowledgedAlphaTicket: true,
		countryCode: undefined,
		isGeoblocked: false,
		modals: {},
		modalsProps: {
			intentState: {
				modalCollateralIndex: null,
				modalCollateralPoolId: null,
				modalTargetAccountKey: null,
				modalDefaultLargestBalance: null,
			},
		},
		currentPageRoute: PageRoute.trade,
		userInfoTable: {
			currentTab: 'positions',
			tradeHistoryMarketFilter: '',
		},
		authorityParam: null,
		referrerParam: null,
		referrerParamFailed: false,
		driftStatus: {
			mainStatus: {
				status: 'green',
				message: '',
			},
			historyServerStatus: {
				maintenanceMode: false,
				maintenanceModeEstimateTs: undefined,
			},
		},
		driftFeatureAlert: {
			alertData: '',
			alertType: '',
		},
		appEventEmitter: new EventEmitter(),
		utmParams: '',
		pnlLeaderboard: {
			startTime: 0,
			endTime: 0,
			data: undefined,
		},
		recentTradesCurrentTab: 'recentTrades',
		challengers: null,
		marketsData24H: [],
		pricePoints24H: [],
		predictedFundings: [],
		marketFundingHistory: {},
		tvMarketHistory: [],
		subAccountsInfo: {
			showDropdown: false,
			accountIdToEdit: null,
			accountIdToDelete: null,
		},
		overviewPage: {
			selectedView: 'overview',
			lastRoute: '',
		},
		downloadType: 'trades',
		pnlModalPosition: null,
		selectedAsset: isVariant(defaultMarket?.marketType, 'spot')
			? (defaultMarket?.market as SpotMarketConfig)
			: OrderedSpotMarkets[0],
		selectedIfStakeMarket: undefined,
		selectedTradeDetails: undefined,
		borrowLendData: [],
		borrowLendMarketDefault: 'deposit',
		totalUserCount: 0,
		hasCompletedOnboarding: true,
		rpcUrl: '',
		currentBulkAccountLoader: undefined,
		showGridCustomiser: false,
		referredByAuthority: undefined,
		referredBy: '',
		referredByLoaded: false,
		selectedAssetDetailsSymbol: undefined,
		swap: {
			fromMarketIndex: 0,
			toMarketIndex: 1,
			poolId: MAIN_POOL_ID,
		},
		liquidityPoolInfo: {
			currentUserKey: undefined,
			pools: [],
		},
		pollingMultiplier: 1,
		avoidHistoryServerLoad: DEFAULT_AVOID_HISTORY_SERVER_LOAD, // We use this param as an escape hatch to turn off history server polling in case something goes wrong. You can see in the use drift status hook we check if this has been set true in amazon s3 and then update it. This is a temporary solution while we're evaluating how well the history server polling mode works.
		marketMakerRewards: {
			records: [],
			totalRewardsPaid: 0,
			lastUpdated: undefined,
		},
		allLiquidationHistory: {
			liquidations: [],
			totalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
		},
		insuranceFundStats: undefined,
		showMetamaskFirstTimeHelper: false,
		showMagicLinkFirstTimeHelper: false,
		statusBars: {
			showNewVersionStatusBar: false,
		},
		countUpOnce: {},
		tradePageTableFilters: {},
		predictionsPageTableFilters: {},
		overviewPageTableFilters: {},
		tutorial: {
			step: 0,
		},
		bankruptcyStats: {
			'24h': {
				totalAmount: 0,
				totalCount: 0,
				ifPayment: 0,
				socialLoss: 0,
			},
		},
		liquidationStats: {
			'24h': {
				totalAmount: 0,
				totalCount: 0,
			},
			'30d': {
				totalAmount: 0,
				totalCount: 0,
			},
		},
		fuelStats: {
			userRank: 0,
			tradingVolume: 0,
			leaderboard: [],
			milestonesReached: 0,
		},
		checkIsSellPredictionMarket: (props?: {
			isPredictionMarket?: boolean;
			isSellSide?: boolean;
		}) => {
			const isPredictionMarket =
				props?.isPredictionMarket !== undefined
					? props.isPredictionMarket
					: get().selectedMarket.current.isPredictionMarket;
			const isSellSide =
				props?.isSellSide !== undefined
					? props.isSellSide
					: get().tradeForm.side === 'sell';

			return isPredictionMarket && isSellSide;
		},
		[VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY]: false,
		accountCreationCost: {
			loaded: false,
			totalCost: NEW_ACCOUNT_BASE_COST,
			newAccountDonation: NEW_ACCOUNT_DONATION,
			baseAccountRent: NEW_ACCOUNT_BASE_RENT,
			extraRent: NEW_ACCOUNT_BASE_COST.sub(NEW_ACCOUNT_BASE_RENT).sub(
				NEW_ACCOUNT_DONATION
			),
			minSolToDeposit: NEW_ACCOUNT_BASE_COST.add(MIN_LEFTOVER_SOL),
			minSolForFees: MIN_LEFTOVER_SOL,
		},
	};
});

export default useDriftStore;
