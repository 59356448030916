import {
	AMM_RESERVE_PRECISION_EXP,
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	calculateDepositRate,
	calculateInterestRate,
	DriftClient,
	DriftEnv,
	Event,
	LiquidationType,
	MARGIN_PRECISION,
	MarketType,
	OracleSource,
	OrderAction,
	OrderTriggerCondition,
	OrderType,
	PerpMarketConfig,
	PositionDirection,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	PublicKey,
	QUOTE_PRECISION,
	QUOTE_PRECISION_EXP,
	ReferrerInfo,
	SPOT_MARKET_BALANCE_PRECISION,
	SPOT_MARKET_RATE_PRECISION_EXP,
	SpotMarketAccount,
	SpotMarketConfig,
	TxParams,
	User,
	WRAPPED_SOL_MINT,
	ZERO,
	getTokenProgramForSpotMarket,
	UserAccount,
	SpotBalanceType,
	FUNDING_RATE_BUFFER_PRECISION,
	QuoteResponse,
	FUNDING_RATE_PRECISION_EXP,
	PerpMarketAccount,
} from '@drift-labs/sdk';
import {
	UIMatchedOrderRecordAndAction,
	UISerializableOrderActionRecord,
	UIMarket,
	ENUM_UTILS,
	COMMON_UI_UTILS,
	UI_ORDER_TYPES_LIST,
	MarketId,
	matchEnum,
	COMMON_UTILS,
	UISerializableFundingRateRecord,
	EnvironmentConstants,
} from '@drift/common';
import {
	Keypair,
	LAMPORTS_PER_SOL,
	TransactionInstruction,
} from '@solana/web3.js';
import accounting from 'accounting';
import { AccountData } from 'src/stores/useDriftAccountsStore';
import Env, {
	CurrentPerpMarkets,
	CurrentSpotMarkets,
	OrderedPerpMarkets,
	OrderedSpotMarkets,
	PERP_MARKETS_LOOKUP,
	PYUSD_BANK_INDEX,
	syncGetCurrentSettings,
	TRADER_PROFILE_OPTIONS,
	USDS_MARKET_INDEX,
	UserSettings,
} from '../environmentVariables/EnvironmentVariables';
import { delimiter, splitter } from './locale';
import NumLib from './NumLib';
import {
	ComputeUnits,
	PriorityFee,
	SimpleFundingRateRecord,
	SlippageTolerance,
} from '../@types/types';
import { PnlChartType, ZeroKey } from 'src/hooks/usePnlChartData';
import {
	AggregateLiqState,
	GroupedLiqState,
	LiqdBankBalanceInfo,
	LiqdPerpInfo,
	LiqdPerpPnlInfo,
} from 'src/hooks/Liquidations/useAggregateLiquidationDataForUser';
import {
	ONBOARDING_PATHS,
	ONBOARDING_STEP,
} from 'src/components/Onboarding/OnboardingPageContent';
import { EMPTY_TABLE_VALUE } from 'src/components/Tables/TableV2';
import {
	ADDITIONAL_AUCTION_BUFFERS,
	LP_SUBACCOUNT_NAME,
	NEW_LP_SUBACCOUNT_NAME,
	ONE_DAY_MS,
	ONE_HOUR_MS,
	ONE_MINUTE_MS,
	SLIPPAGE_PRESETS_MAJORS,
	SLIPPAGE_PRESETS_NON_MAJORS,
} from 'src/constants/constants';
import { notify } from './notifications';
import BREAKPOINTS from 'src/constants/breakpoints';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { PageRoute } from 'src/constants/constants';
import {
	PnLTimeSeriesDataPoint,
	PnlTimePeriodOption,
} from 'src/@types/performance';
import { BorrowMarketData } from 'src/hooks/useBorrowLendBalances';
import { getPythPullUpdateIxs } from './oracle/pythPullCrank';
import { getSwitchboardUpdateIxs } from './oracle/switchboardCrank';
import { WalletName } from '@solana/wallet-adapter-base';
import { getPythLazerUpdateIxs } from './oracle/pythLazerCrank';

export enum ZINDEX {
	pageGrid = `z-10`,
	navbar = `z-20`,
	modal = `z-30`,
	tooltip = `z-40`,
	onTop = `z-50`,
	xTop = 'z-100',
}

const getStyleValue = (value: string) => {
	if (!isWindowDefined()) throw 'trying to getStyleValue when unmounted';

	return window.getComputedStyle(document.body).getPropertyValue(value);
};

/**
 * Prints the amount of days, hours, or minutes from the current time to the searchDate.
 * For example "3 hours" or "25 minutes" (from now).
 * Switches from days to hours to minutes when the diff goes below 1 of that unit.
 */
const findDaysHoursMinutesFromNow = (
	searchDate: Date,
	shortform = false,
	finishedWording = 'Now'
): string => {
	const timeDiff = Math.max(searchDate.getTime() - Date.now(), 0);
	const dateString = [];

	// Don't allow it to be negative
	// "now" might not be the best thing to return here for grammatical correctness
	// but the component calling this function should prob just check that and adjust
	if (timeDiff === 0) return finishedWording;

	const daysDiffRound = Math.round(timeDiff / ONE_DAY_MS);
	const daysDiffFloor = Math.floor(timeDiff / ONE_DAY_MS); // used to avoid e.g. 13 hours rounding up to 1 day
	if (daysDiffRound > 0 && daysDiffFloor > 0) {
		if (shortform) {
			dateString.push(`${daysDiffFloor}d`);
		} else {
			dateString.push(daysDiffFloor === 1 ? '1 day' : `${daysDiffFloor} days`);
		}
	}

	const timeDiffModDay = timeDiff % ONE_DAY_MS;
	const hoursDiffRound = Math.round(timeDiffModDay / ONE_HOUR_MS);
	const hoursDiffFloor = Math.floor(timeDiffModDay / ONE_HOUR_MS);
	if (hoursDiffRound > 0 && hoursDiffFloor > 0) {
		if (shortform) {
			dateString.push(hoursDiffFloor === 1 ? '1hr' : `${hoursDiffFloor}hrs`);
		} else {
			dateString.push(
				hoursDiffFloor === 1 ? '1 hour' : `${hoursDiffFloor} hours`
			);
		}
	}

	const timeDiffModHour = timeDiffModDay % ONE_HOUR_MS;
	const minutesDiffRound = Math.round(timeDiffModHour / ONE_MINUTE_MS);
	const minutesDiffFloor = Math.floor(timeDiffModHour / ONE_MINUTE_MS);
	if (minutesDiffRound > 0 && minutesDiffFloor > 0 && daysDiffFloor === 0) {
		// we don't want to show mins if it is at least 1 day
		if (shortform) {
			dateString.push(
				minutesDiffFloor === 1 ? '1min' : `${minutesDiffFloor}mins`
			);
		} else {
			dateString.push(
				minutesDiffFloor === 1 ? '1 minute' : `${minutesDiffFloor} minutes`
			);
		}
	}

	if (!dateString.length) {
		return '<1 minute';
	}

	return dateString.join(' ');
};

const findDaysOrHoursAgo = (searchDate: Date, unit: string): number => {
	const divisor = 1000 * 3600 * (unit === 'days' ? 24 : 1);
	const timeDiff = new Date(Date.now()).getTime() - searchDate.getTime();
	return Math.floor(timeDiff / divisor);
};

const nowTs = () => Math.floor(Date.now() / 1000);

const getUrlForAccount = (account: string) => {
	const isMainnet = Env.sdkEnv === 'mainnet-beta';

	const explorerPreference = syncGetCurrentSettings()?.explorer;

	const explorerUrlPrefix =
		explorerPreference === 'xray'
			? `https://xray.helius.xyz/account/`
			: explorerPreference === 'solscan'
			? `https://solscan.io/account/`
			: explorerPreference === 'solanafm'
			? `https://solana.fm/address/`
			: `https://explorer.solana.com/address/`;

	return explorerUrlPrefix + account + `${isMainnet ? '' : '?cluster=devnet'}`;
};

const getUrlForTx = (txSig: string) => {
	const isMainnet = Env.sdkEnv === 'mainnet-beta';

	const explorerPreference = syncGetCurrentSettings().explorer;

	const explorerUrlPrefix =
		explorerPreference === 'xray'
			? `https://xray.helius.xyz/tx/`
			: explorerPreference === 'solscan'
			? `https://solscan.io/tx/`
			: explorerPreference === 'solanafm'
			? `https://solana.fm/tx/`
			: `https://explorer.solana.com/tx/`;

	return explorerUrlPrefix + txSig + `${isMainnet ? '' : '?cluster=devnet'}`;
};

const openUrl = (url: string, opts?: { append?: boolean }) =>
	window.open(opts?.append ? `${window.location.href}${url}` : url, '_blank');

const copyToClipboard = (stringToCopy: string) => {
	navigator.clipboard.writeText(stringToCopy);
};

const waitUntilValue = async <T>(callback: () => T, maxWaitMs: number) => {
	if (maxWaitMs < 200) {
		throw 'maxWaitMs should be longer';
	}

	return new Promise<T>((res, rej) => {
		const timer = setInterval(() => {
			const val = callback();

			if (val) {
				res(val);
				clearInterval(timer);
			}
		}, 100);

		setTimeout(() => {
			clearInterval(timer);
			rej();
		}, maxWaitMs);
	});
};

const toNotional = (val: number, precision = 2) => {
	const notionalStr = accounting.formatMoney(val, {
		symbol: '$',
		decimal: delimiter,
		thousand: splitter,
		precision,
	});

	return notionalStr.includes('$-')
		? notionalStr.replace('$-', '-$')
		: notionalStr;
};

function removeDuplicates<T>(arr: T[], prop: keyof T): T[] {
	return arr.filter(
		(v, i, a) =>
			a.findIndex((t) => t[prop].toString() === v[prop].toString()) === i
	);
}

const encodeName = (name: string): number[] => {
	const MAX_NAME_LENGTH = 32;

	if (name.length > MAX_NAME_LENGTH) {
		throw Error(`User name (${name}) longer than 32 characters`);
	}

	const buffer = Buffer.alloc(32);
	buffer.fill(name);
	buffer.fill(' ', name.length);

	return Array(...buffer);
};

const decodeName = (bytes: number[]): string => {
	const buffer = Buffer.from(bytes);
	return buffer.toString('utf8').trim();
};

const isNotionalDust = (val: BigNum) => {
	return !val.eqZero() && val.abs().toNum() < 0.01;
};

const getTradeInfoFromMatchedRecord = (
	user: PublicKey,
	tradeRecord: UIMatchedOrderRecordAndAction
) => {
	const isTaker = tradeRecord.actionRecord.taker?.equals(user) ?? false;

	const baseAssetAmount = tradeRecord.orderRecord.order.baseAssetAmount;

	const totalBaseAmountFilled =
		(isTaker
			? tradeRecord.actionRecord.takerOrderCumulativeBaseAssetAmountFilled
			: tradeRecord.actionRecord.makerOrderCumulativeBaseAssetAmountFilled) ??
		BigNum.zero(BASE_PRECISION_EXP);

	const totalQuoteAmountFilled =
		(isTaker
			? tradeRecord.actionRecord.takerOrderCumulativeQuoteAssetAmountFilled
			: tradeRecord.actionRecord.makerOrderCumulativeQuoteAssetAmountFilled) ??
		BigNum.zero(QUOTE_PRECISION_EXP);

	const baseAssetAmountFilledInAction =
		tradeRecord?.actionRecord?.baseAssetAmountFilled ??
		BigNum.zero(BASE_PRECISION_EXP);
	const quoteAssetAmountFilled =
		tradeRecord.actionRecord.quoteAssetAmountFilled;
	const marketIndex = tradeRecord.actionRecord.marketIndex;
	const marketType = ENUM_UTILS.toStr(tradeRecord.actionRecord.marketType);

	const direction = tradeRecord.orderRecord.order.direction;

	const fee = isTaker
		? tradeRecord.actionRecord.takerFee
		: tradeRecord.actionRecord.makerFee;

	const ts = tradeRecord.actionRecord.ts;
	const slot = tradeRecord.actionRecord.slot;

	const orderType = tradeRecord.orderRecord.order.orderType;

	const action = tradeRecord.actionRecord.action;

	return {
		baseAssetAmount,
		totalBaseAmountFilled,
		totalQuoteAmountFilled,
		baseAssetAmountFilledInAction,
		quoteAssetAmountFilled,
		direction,
		marketType,
		marketIndex,
		ts,
		slot,
		fee,
		orderType,
		action,
	};
};

export type TradeRecord = Pick<
	UISerializableOrderActionRecord,
	| 'taker'
	| 'maker'
	| 'takerOrderBaseAssetAmount'
	| 'makerOrderBaseAssetAmount'
	| 'baseAssetAmountFilled'
	| 'quoteAssetAmountFilled'
	| 'marketIndex'
	| 'marketType'
	| 'takerOrderDirection'
	| 'makerOrderDirection'
	| 'takerOrderCumulativeBaseAssetAmountFilled'
	| 'makerOrderCumulativeBaseAssetAmountFilled'
	| 'takerOrderCumulativeQuoteAssetAmountFilled'
	| 'makerOrderCumulativeQuoteAssetAmountFilled'
	| 'ts'
	| 'slot'
	| 'takerFee'
	| 'makerFee'
	| 'txSig'
>;

const getTradeInfoFromOrderActionRecord = (
	user: PublicKey,
	tradeRecord: TradeRecord
) => {
	const isTaker = tradeRecord.taker?.equals(user) ?? false;

	const baseAssetAmount = isTaker
		? tradeRecord.takerOrderBaseAssetAmount
		: tradeRecord.makerOrderBaseAssetAmount;
	const baseAssetAmountFilled = tradeRecord.baseAssetAmountFilled;
	const quoteAssetAmountFilled = tradeRecord.quoteAssetAmountFilled;
	const marketIndex = tradeRecord.marketIndex;
	const marketType = ENUM_UTILS.toStr(tradeRecord.marketType);

	const direction = isTaker
		? tradeRecord.takerOrderDirection
		: tradeRecord.makerOrderDirection;

	const fee = isTaker ? tradeRecord.takerFee : tradeRecord.makerFee;

	const ts = tradeRecord.ts;
	const slot = tradeRecord.slot;

	const counterparty = isTaker ? tradeRecord.maker : tradeRecord.taker;

	return {
		totalBaseAmountFilled:
			(isTaker
				? tradeRecord.takerOrderCumulativeBaseAssetAmountFilled
				: tradeRecord.makerOrderCumulativeBaseAssetAmountFilled) ??
			BigNum.zero(BASE_PRECISION_EXP),
		totalQuoteAmountFilled:
			(isTaker
				? tradeRecord.takerOrderCumulativeQuoteAssetAmountFilled
				: tradeRecord.makerOrderCumulativeQuoteAssetAmountFilled) ??
			BigNum.zero(QUOTE_PRECISION_EXP),
		baseAssetAmount: baseAssetAmount ?? BigNum.zero(BASE_PRECISION_EXP),
		baseAssetAmountFilled:
			baseAssetAmountFilled ?? BigNum.zero(BASE_PRECISION_EXP),
		quoteAssetAmountFilled:
			quoteAssetAmountFilled ?? BigNum.zero(QUOTE_PRECISION_EXP),
		direction,
		marketType,
		marketIndex,
		ts,
		slot,
		fee,
		counterparty,
		isTaker,
		txSig: tradeRecord.txSig,
	};
};

const getSpotLiqPriceNum = (
	user: User,
	marketIndex: number,
	baseSize?: BigNum,
	isLong = true
): number => {
	if (!user || !user.isSubscribed) return 0;

	let baseAssetBN = baseSize ? baseSize.val : undefined;

	if (!isLong && baseAssetBN) {
		baseAssetBN = baseAssetBN.mul(new BN(-1));
	}

	const liqPriceBn = user.spotLiquidationPrice(marketIndex, baseAssetBN);

	const liqPriceNum = BigNum.from(liqPriceBn, PRICE_PRECISION_EXP).toNum();

	return liqPriceNum < 0 ? 0 : liqPriceNum;
};

const SEVEN_DECIMALS = new BN(7);

/**
 * Gets fees for a market from drift user client as bps and percentages
 */
const getMarketFees = (
	user: User,
	marketType: MarketType,
	marketIndex: number,
	driftClient: DriftClient
): {
	makerFeeMultiplier: number;
	makerFeePct: number;
	makerFeeBps: number;
	takerFeeMultiplier: number;
	takerFeePct: number;
	takerFeeBps: number;
} => {
	const canBeFetched =
		driftClient &&
		driftClient.isSubscribed &&
		driftClient.userStats &&
		driftClient.userStats.isSubscribed;

	const marketFees =
		canBeFetched && driftClient.getMarketFees(marketType, marketIndex, user);

	if (!canBeFetched || !marketFees) {
		return {
			makerFeeMultiplier: 0,
			makerFeePct: 0,
			makerFeeBps: -Env.defaultMakerRebateBps,
			takerFeeMultiplier: 0,
			takerFeePct: 0,
			takerFeeBps: Env.defaultTakerFeeBps,
		};
	}

	// Maker fee is a rebate when it's positive
	const makerFeeMultiplier = marketFees?.makerFee || 0;
	const makerFeeMultBigNum = BigNum.fromPrint(
		makerFeeMultiplier.toString(),
		SEVEN_DECIMALS
	).toRounded(2);

	const makerFeePct = makerFeeMultBigNum.mul(new BN(100)).toNum();
	const makerFeeBps = makerFeeMultBigNum.mul(new BN(10000)).toNum();

	const takerFeeMultiplier = marketFees?.takerFee || 0;
	const takerFeeMultBigNum = BigNum.fromPrint(
		takerFeeMultiplier.toString(),
		SEVEN_DECIMALS
	);

	const takerFeePct = takerFeeMultBigNum.mul(new BN(100)).toNum();
	const takerFeeBps = takerFeeMultBigNum.mul(new BN(10000)).toNum();

	return {
		makerFeeMultiplier,
		makerFeePct,
		makerFeeBps,
		takerFeeMultiplier,
		takerFeePct,
		takerFeeBps,
	};
};

const getSpotLiqPriceStr = (
	user: User,
	marketIndex: number,
	baseSize?: BigNum,
	isLong = true
): string => {
	if (!user || !user.isSubscribed) return '';

	const liqPriceNum = getSpotLiqPriceNum(user, marketIndex, baseSize, isLong);

	if (liqPriceNum <= 0) return 'None';

	const liqPriceUi = NumLib.formatNum.toDisplayPrice(liqPriceNum);

	return `$${liqPriceUi}`;
};

const createFakeKeypairForPubKey = (pubKey: PublicKey) => {
	return new Keypair({
		publicKey: pubKey.toBytes(),
		secretKey: new Keypair().publicKey.toBytes(),
	});
};

/* Market utils */

const findMarketBySymbol = (
	symbol: string,
	allowSpotUsdc = true,
	solPerpDefault = false
): UIMarket => {
	const solPerp = UIMarket.createPerpMarket(0);

	// default to first perp market
	if (!symbol) {
		return solPerpDefault ? solPerp : undefined;
	}

	const lowerCaseSymbol = symbol.toLowerCase();
	let market: UIMarket;

	let configFound: PerpMarketConfig | SpotMarketConfig =
		CurrentPerpMarkets.find(
			(mkt) => mkt.symbol.toLowerCase() === lowerCaseSymbol
		);

	if (!configFound) {
		const baseAssetSymbol = COMMON_UI_UTILS.getBaseAssetSymbol(symbol);
		configFound = CurrentSpotMarkets.find(
			(mkt) => mkt.symbol.toLowerCase() === baseAssetSymbol.toLowerCase()
		);

		if (configFound) {
			market = UIMarket.createSpotMarket(configFound.marketIndex);
		}
	} else {
		market = UIMarket.createPerpMarket(configFound.marketIndex);
	}

	if (!market) {
		return solPerpDefault ? solPerp : undefined;
	}

	if (market.isUsdcMarket && !allowSpotUsdc) {
		return solPerpDefault ? solPerp : undefined;
	}

	return market;
};

const getSymbolFromFullMarketName = (marketName: string) => {
	if (marketName.includes('-PERP')) return marketName;
	return marketName.replace('/USDC', '');
};

const getUIOrderTypeFromSdkOrderType = (
	orderType: OrderType,
	triggerCondition: OrderTriggerCondition,
	direction: PositionDirection,
	oracleOffset: BigNum | undefined
) => {
	const side = ENUM_UTILS.match(direction, PositionDirection.LONG)
		? 'buy'
		: 'sell';

	// This is more complicated -- you also need to know the trigger condition
	const returnOrderTypes = UI_ORDER_TYPES_LIST.filter((uiOrderType) => {
		return ENUM_UTILS.match(uiOrderType.orderType, orderType);
	});

	if (returnOrderTypes.length === 1) {
		return returnOrderTypes[0];
	} else if (ENUM_UTILS.match(orderType, OrderType.LIMIT)) {
		if (!oracleOffset || oracleOffset.eq(ZERO)) {
			return returnOrderTypes.find((uiOrderType) =>
				ENUM_UTILS.match(uiOrderType.orderType, OrderType.LIMIT)
			);
		} else {
			return returnOrderTypes.find(
				(uiOrderType) => uiOrderType.value === 'oracleLimit'
			);
		}
	} else {
		if (side === 'buy') {
			if (ENUM_UTILS.match(triggerCondition, OrderTriggerCondition.BELOW)) {
				// Buy side + trigger below: take profit for a short position
				return returnOrderTypes.find((uiOrderType) =>
					uiOrderType.value.includes('takeProfit')
				);
			} else {
				// Buy side + trigger above: stop loss for a short position
				return returnOrderTypes.find((uiOrderType) =>
					uiOrderType.value.includes('stop')
				);
			}
		} else {
			if (ENUM_UTILS.match(triggerCondition, OrderTriggerCondition.ABOVE)) {
				// Sell side + trigger above: take profit for a long position
				return returnOrderTypes.find((uiOrderType) =>
					uiOrderType.value.includes('takeProfit')
				);
			} else {
				// Sell side + trigger below: stop loss for a long position
				return returnOrderTypes.find((uiOrderType) =>
					uiOrderType.value.includes('stop')
				);
			}
		}
	}
};

const getReferrerInfo = async (
	driftClient: DriftClient,
	referrerName: string
): Promise<ReferrerInfo> => {
	let referrerInfo: ReferrerInfo = undefined;

	if (!driftClient || !driftClient.isSubscribed) return undefined;

	if (referrerName) {
		try {
			const referrerNameAccount = await driftClient.fetchReferrerNameAccount(
				referrerName
			);

			if (referrerNameAccount) {
				referrerInfo = {
					referrer: referrerNameAccount.user,
					referrerStats: referrerNameAccount.userStats,
				};
			}
		} catch (err) {
			// We should never get here because we check if the referrer is valid when loading the page
			console.log(err);
			return undefined;
		}
	}

	return referrerInfo;
};

const getStoredReferrerParam = (mobileOnly = false) => {
	if (typeof window === 'undefined') {
		return null;
	}

	const isMobile = window.innerWidth < BREAKPOINTS.SMALL;
	if (!isMobile && mobileOnly) {
		return null;
	}

	const storedReferrerParam = window.localStorage.getItem('referrerParam');
	const storedReferrerAppliedTimestamp = parseInt(
		window.localStorage.getItem('referrerAppliedTimestamp')
	);

	// Expire the referrer param after 1 hour
	const isExpired =
		isNaN(storedReferrerAppliedTimestamp) ||
		Date.now() - storedReferrerAppliedTimestamp > 1000 * 60 * 60;

	if (!isExpired && storedReferrerParam) {
		return storedReferrerParam;
	} else {
		if (isExpired) {
			window.localStorage.removeItem('referrerParam');
			window.localStorage.removeItem('referrerAppliedTimestamp');
		}
		return null;
	}
};

const getDelegateAccountLabel = (account: AccountData): string => {
	if (!account) return '';
	return `${abbreviateAddress(account.authority)} (${account.userId})`;
};

const disallowNegativeStringInput = (str: string): string => {
	if (str && str.charAt(0) === '-') {
		return '0';
	}
	return str;
};

/**
 * Converts a number to a fixed locale string at specificed decimals
 * Same as .toFixed method of number, but includes locale splitter for large numbers
 * Maybe this should be added to NumLib? -- not sure
 * @param num
 * @param decimals
 */
const toFixedLocaleString = (num: number, decimals: number) => {
	const rounded = Math.round(num * 10 ** decimals) / 10 ** decimals;
	const [leftSide, rightSide = ''] = rounded
		.toLocaleString(Env.locale, { minimumFractionDigits: decimals })
		.split(delimiter);
	const zerosToAdd = decimals - rightSide.length;
	const rightSidePadded =
		zerosToAdd > 0
			? `${rightSide}${Array(zerosToAdd).fill('0').join('')}`
			: rightSide;
	return `${leftSide === 'NaN' ? 0 : leftSide}${
		rightSidePadded === '' ? '' : delimiter
	}${rightSidePadded}`;
};

const findLocalStorageKeysWithMatch = (query: RegExp) => {
	const results: { key: string; val: any }[] = [];
	for (const i in localStorage) {
		if (window.localStorage.getItem(i)) {
			if (i.match(query) || (!query && typeof i === 'string')) {
				const value = window.localStorage.getItem(i);
				results.push({ key: i, val: value });
			}
		}
	}
	return results;
};

const clickedInsideElement = (event: MouseEvent, element: HTMLElement) => {
	let target = event.target as HTMLElement;
	let clickedInsideElement = false;
	if (element) {
		while (target.parentNode) {
			if (element.contains(target)) {
				clickedInsideElement = true;
				break;
			}
			target = target.parentNode as HTMLElement;
		}
	}
	return clickedInsideElement;
};

const encodeQueryParams = (
	params: Record<string, string | number | boolean>
) => {
	const queryParams = Object.entries(params)
		.filter(([_key, param]) => param !== undefined)
		.map(([key, param]) => {
			return `${key}=${param}`;
		});

	const queryParamsString = `${queryParams.join('&')}`;

	return queryParamsString;
};

/**
 * Sort is by default ascending when you return a-b. This is just converting the sort to use an equivalent for BNs
 * @param bnA
 * @param bnB
 * @returns
 */
const sortBnAsc = (bnA: BN, bnB: BN) => {
	if (bnA.gt(bnB)) return 1;
	if (bnA.eq(bnB)) return 0;
	if (bnA.lt(bnB)) return -1;
};

// To do desc sort just call asc sort with the parameters switched
const sortBnDesc = (bnA: BN, bnB: BN) => sortBnAsc(bnB, bnA);

const printDate = (date: Date) => {
	return date.toLocaleString(undefined, {
		hour12: true,
		timeStyle: 'short',
		dateStyle: 'short',
	});
};

const elementIsVisible = (element: HTMLElement) => {
	const rect = element.getBoundingClientRect();

	const isVisible = rect.height > 0 && rect.width > 0;

	return isVisible;
};

const urlHasParam = (param: string) => {
	const urlSearchParams = new URLSearchParams(window.location.search);

	return urlSearchParams.has(param);
};
const getPerpMarketId = (perpMarket: PerpMarketConfig): MarketId =>
	MarketId.createPerpMarket(perpMarket.marketIndex);

const getSpotMarketId = (spotMarket: SpotMarketConfig): MarketId =>
	MarketId.createSpotMarket(spotMarket.marketIndex);

const getSubAccountStorageKey = (authority: PublicKey, env: DriftEnv): string =>
	authority ? `${authority?.toString()}_subacct_${env}` : '';

const timeAgo = (date: Date): number => {
	const now = Date.now();

	const timeAgo = now - date.getTime();

	return timeAgo;
};

const timeAgoPreciseString = (date: Date): string => {
	const seconds = Math.floor((Date.now() - +date) / 1000);

	// 1 year
	let interval = seconds / 31536000;

	if (interval > 1) {
		return interval.toFixed(2) + ' years';
	}

	// 1 month
	interval = seconds / 2592000;
	if (interval > 1) {
		return interval.toFixed(2) + ' months';
	}

	// 1 week
	interval = seconds / 604800;
	if (interval > 1) {
		return interval.toFixed(2) + ' weeks';
	}

	// 1 day
	interval = seconds / 86400;
	if (interval > 1) {
		return interval.toFixed(2) + ' days';
	}

	// 1 hour
	interval = seconds / 3600;
	if (interval > 1) {
		return interval.toFixed(2) + ' hours';
	}

	// 1 minute
	interval = seconds / 60;
	if (interval > 1) {
		return interval.toFixed(2) + ' minutes';
	}

	return Math.floor(seconds) + ' seconds';
};

const periodTimeSize = (unit: PnlTimePeriodOption): number => {
	const hr = 1000 * 60 * 60;
	switch (unit) {
		case '24h':
			return hr * 24;
		case '7d':
			return hr * 24 * 7;
		case '30d':
			return hr * 24 * 30;
		case '90d':
			return hr * 24 * 90;
		case '6m':
			return hr * 24 * 182.125;
		case 'all':
			return Infinity;
	}
};

const periodOffset = (unit: PnlTimePeriodOption): number => {
	const hr = 1000 * 60 * 60;

	const now = Date.now();

	switch (unit) {
		case '24h':
			return now % hr;
		case '7d':
			return now % (hr * 24);
		case '30d':
		case '90d':
			return now % (hr * 24);
		case '6m':
			return now % (hr * 24);
		case 'all':
			return Infinity;
	}
};

/**
 * This method prints the estimated exit price with the appropriate amount of decimals. It does this by connsidering the price of the asset and the tick size. If the tick size is e-6 and the price is e3 then there should be three decimals
 * @param price
 * @param minTickSize
 */
const prettyPrintPriceBasedOnTick = (
	price: BigNum,
	tickSizeExponent: number
) => {
	return price.toNum().toLocaleString(undefined, {
		minimumFractionDigits: Math.max(2, tickSizeExponent),
	});
};

const applyTimeFilter = (
	data: PnLTimeSeriesDataPoint[],
	filter: PnlTimePeriodOption
) => {
	return data.filter(
		(point) =>
			timeAgo(point.date) <= periodTimeSize(filter) + periodOffset(filter)
	);
};

const formatPnlData = (data: PnLTimeSeriesDataPoint[]) => {
	return data.map((point) => {
		const cleanedPoint: PnLTimeSeriesDataPoint = {
			date: point.date,
			totalPnl: Number(point.totalPnl?.toFixed(2) ?? 0),
			accountValue: Number(point.accountValue?.toFixed(2) ?? 0),
			referralRewards: Number(point.referralRewards?.toFixed(2) ?? 0),
			referralVolume: Number(point.referralVolume?.toFixed(2) ?? 0),
			referralCount: point.referralCount ?? 0,
		};

		return cleanedPoint;
	});
};

const normalisePnlPoints = (
	points: PnLTimeSeriesDataPoint[],
	zeroKey: ZeroKey
) => {
	if (!points || points.length === 0) return points;

	const initialValue = points[0][zeroKey];

	return points.map((point) => ({
		...point,
		[zeroKey]: point[zeroKey] - initialValue,
	}));
};

/* yes I just invented the word "cumulatize" (to make a timeseries into a cumulative timeseries)*/
const cumulatizePnlPoints = (
	points: PnLTimeSeriesDataPoint[],
	zeroKey: ZeroKey
) => {
	if (!points || points.length === 0) return points;

	const initialValue = points[0][zeroKey];

	return points.map((point, index) => ({
		...point,
		[zeroKey]:
			points.slice(0, index).reduce((a, b) => a + b[zeroKey], 0) -
			(index === 0 ? 0 : initialValue),
	}));
};

const addInitialZero = (
	pnlHistory: PnLTimeSeriesDataPoint[],
	period: PnlTimePeriodOption
) => {
	const initialPoint = pnlHistory?.[0];

	if (!initialPoint) return pnlHistory;

	const expectedPointTs =
		period === 'all'
			? initialPoint.date.getTime() - 1000 * 60 * 60
			: Date.now() - periodOffset(period) - periodTimeSize(period);

	if (initialPoint.date.getTime() > expectedPointTs) {
		return [
			{
				date: new Date(expectedPointTs),
				totalPnl: 0,
				spotPnl: 0,
				perpPnl: 0,
				accountValue: 0,
				perpRoi: 0,
				spotRoi: 0,
				referralRewards: 0,
				referralVolume: 0,
				referralCount: 0,
				hideTooltip: true,
			},
			{
				date: new Date(initialPoint.date.getTime() - 1000 * 60 * 60),
				totalPnl: 0,
				spotPnl: 0,
				perpPnl: 0,
				accountValue: 0,
				perpRoi: 0,
				spotRoi: 0,
				referralRewards: 0,
				referralVolume: 0,
				referralCount: 0,
				hideTooltip: true,
			},
			...pnlHistory,
		];
	}

	return pnlHistory;
};

/**
 * Add "zeroes" to the data where the values cross between positive and negative
 * @param data
 * @param zeroesKey
 * @returns
 */
const addZeroes = (
	data: PnLTimeSeriesDataPoint[],
	zeroesKey?: keyof Pick<PnLTimeSeriesDataPoint, PnlChartType>
) => {
	const newData: PnLTimeSeriesDataPoint[] = [];

	data.forEach((dataPoint, index) => {
		const thisPoint = dataPoint;
		const nextPoint = data[index + 1];

		const pointsToAdd: PnLTimeSeriesDataPoint[] = [dataPoint];

		// skip last datapoint
		if (index === data.length - 1) {
			newData.push(...pointsToAdd);
			return;
		}

		if (
			Math.sign(thisPoint[zeroesKey]) !== Math.sign(nextPoint[zeroesKey]) &&
			Math.sign(thisPoint[zeroesKey]) !== 0 &&
			Math.sign(nextPoint[zeroesKey]) !== 0
		) {
			const timeDiff = nextPoint.date.getTime() - thisPoint.date.getTime();

			const YDiff = nextPoint[zeroesKey] - thisPoint[zeroesKey];

			const YDiffRatio = Math.abs(thisPoint[zeroesKey] / YDiff);

			const zeroTimeDiff = timeDiff * Math.abs(YDiffRatio);

			const newPoint = {
				date: new Date(thisPoint.date.getTime() + zeroTimeDiff),
				accountValue: (thisPoint.accountValue + nextPoint.accountValue) / 2,
				totalPnl: (thisPoint.totalPnl + nextPoint.totalPnl) / 2,
				referralRewards:
					(thisPoint.referralRewards + nextPoint.referralRewards) / 2,
				referralVolume:
					(thisPoint.referralVolume + nextPoint.referralVolume) / 2,
				referralCount: (thisPoint.referralCount + nextPoint.referralCount) / 2,
				hideTooltip: true,
				[zeroesKey]: 0,
			};

			pointsToAdd.push(newPoint);
		}

		newData.push(...pointsToAdd);
	});

	return newData;
};

const getBigNumRoundedToStepSize = (baseSize: BigNum, stepSize: BN) => {
	const baseSizeRounded = baseSize.div(stepSize).mul(stepSize);
	return baseSizeRounded;
};

const roundToStepSizeIfLargeEnough = (value: string, stepSize?: number) => {
	const parsedValue = parseFloat(value);
	if (isNaN(parsedValue) || stepSize === 0 || !value || parsedValue === 0) {
		return value;
	}

	const stepSizeExp = stepSize.toString().split('.')[1]?.length ?? 0;
	const truncatedValue = truncateInputToPrecision(value, new BN(stepSizeExp));

	// remove trailing decimal if it's there
	if (truncatedValue.charAt(truncatedValue.length - 1) === '.') {
		return truncatedValue.slice(0, -1);
	}

	return truncatedValue;
};

const truncateInputToPrecision = (
	input: string,
	marketPrecisionExp: SpotMarketConfig['precisionExp']
) => {
	const decimalPlaces = input.split('.')[1]?.length ?? 0;

	if (decimalPlaces > marketPrecisionExp.toNumber()) {
		return input.slice(
			0,
			input.length - (decimalPlaces - marketPrecisionExp.toNumber())
		);
	}

	return input;
};

const valueIsBelowStepSize = (value: string, stepSize: number) => {
	const parsedValue = parseFloat(value);

	if (isNaN(parsedValue)) return false;

	return parsedValue < stepSize;
};

/**
 * Formats value with displayDecimals, with a minDecimals option to make sure at least the first N non-zero values are displayed after the decimal point
 *
 * This can be used to force display of extra-small values that would get cut off by .toFixed, while keeping the .toFixed formatting for larger values
 *
 * Example: The value is 0.0000002995. The display decimals are set to 4. Using number.toFixed(4) would result in 0.0000.
 * formatWithMinDecimals(number, 4) with the default minDecimals of 2 would result in 0.00000029. formatWithMinDecimals(number, 4, 3) would result in 0.000000299, etc.
 *
 * @param value - The number to format
 * @param displayDecimals - Standard display decimals if the number is large enough to show up, similar to .toFixed
 * @param minDecimals - [optional] The minimum number of non-zero decimals to show after the decimal point (default: 2)
 */
const formatWithMinDecimals = (
	value: number,
	displayDecimals: number,
	minDecimals = 2
) => {
	if (value === undefined) return '0';

	const string = value.toString();

	if (string === '0' || string === 'NaN') return '0';

	if (string.includes('e')) {
		const exponent = parseInt(string.match(/e-(.*)/)?.[1]);
		return value.toFixed(exponent + (minDecimals - 1));
	}

	const zeroesMatches = string.match(/^0.(0+)/);
	if (zeroesMatches?.length) {
		const diffInZeroes = Math.max(
			0,
			zeroesMatches[1].length + minDecimals - displayDecimals
		);
		return value.toFixed(displayDecimals + diffInZeroes);
	} else {
		return toFixedLocaleString(value, displayDecimals);
	}
};

/**
 * Used by user liquidation table and general liquidation table to summarize groups of liquidations into a readable summary
 *
 * @param groupedLiqLookup - Map of liquidation records grouped by user
 */
const getAggregateLiqs = (
	groupedLiqLookup: GroupedLiqState
): AggregateLiqState[] => {
	let newAggregateLiqState: AggregateLiqState[] = [];

	Object.entries(groupedLiqLookup).forEach(([liqId, groupedLiqs]) => {
		// Compile base information about liq record
		const id = liqId;
		// This is a little odd, but when displaying liqs to a user we want to rank them by when the liqs first started, because then they have context for when a position started being liquidated. When displaying all-time exchange liqs I think we want to do the opposite
		const ts = Math.max(...groupedLiqs.map((liq) => liq.ts.toNumber()));
		const liquidations = groupedLiqs;

		// initialise values to add to new aggregate state
		let notionalAmount = BigNum.zero(QUOTE_PRECISION_EXP);

		const liqdPerpsLookup: { [symbol: string]: LiqdPerpInfo } = {};
		const liqdDepositsLookup: { [symbol: string]: LiqdBankBalanceInfo } = {};
		const liqdBorrowsLookup: { [symbol: string]: LiqdBankBalanceInfo } = {};
		const liqdPerpPnlLookup: { [symbol: string]: LiqdPerpPnlInfo } = {};

		// For each of the liquidations, update new aggregate state
		liquidations.forEach((liq) => {
			// New state depends on the type of the liquidation
			if (
				ENUM_UTILS.match(liq.liquidationType, LiquidationType.LIQUIDATE_SPOT)
			) {
				const record = liq.liquidateSpot;
				const liabilityAssetSymbol =
					OrderedSpotMarkets[record.liabilityMarketIndex].symbol;

				// Do Borrow Side First
				const borrowBaseSize = record.liabilityTransfer;
				const borrowQuoteSize = borrowBaseSize
					.mul(record.liabilityPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

				const previousBorrowAggregate = liqdBorrowsLookup[liabilityAssetSymbol];

				const newBorrowQuoteSize = borrowQuoteSize.add(
					previousBorrowAggregate?.quoteSize ??
						BigNum.zero(borrowQuoteSize.precision)
				);

				const newBorrowBaseSize = borrowBaseSize.add(
					previousBorrowAggregate?.baseSize ??
						BigNum.zero(borrowBaseSize.precision)
				);

				liqdBorrowsLookup[liabilityAssetSymbol] = {
					bankIndex: record.liabilityMarketIndex,
					quoteSize: newBorrowQuoteSize,
					baseSize: newBorrowBaseSize,
					avgPrice: COMMON_UI_UTILS.calculateAverageEntryPrice(
						newBorrowQuoteSize,
						newBorrowBaseSize
					),
				};

				// Do Deposit Side Next
				const depositAssetSymbol =
					OrderedSpotMarkets[record.assetMarketIndex].symbol;

				const depositBaseSize = record.assetTransfer;
				const depositQuoteSize = depositBaseSize
					.mul(record.assetPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

				const previousDepositAggregate = liqdDepositsLookup[depositAssetSymbol];

				const newDepositQuoteSize = depositQuoteSize.add(
					previousDepositAggregate?.quoteSize ??
						BigNum.zero(depositQuoteSize.precision)
				);

				const newDepositBaseSize = depositBaseSize.add(
					previousDepositAggregate?.baseSize ??
						BigNum.zero(depositBaseSize.precision)
				);

				liqdDepositsLookup[depositAssetSymbol] = {
					bankIndex: record.assetMarketIndex,
					quoteSize: newDepositQuoteSize,
					baseSize: newDepositBaseSize,
					avgPrice: COMMON_UI_UTILS.calculateAverageEntryPrice(
						newDepositQuoteSize,
						newDepositBaseSize
					),
				};

				notionalAmount = notionalAmount.add(depositQuoteSize);
				notionalAmount = notionalAmount.add(borrowQuoteSize);
			} else if (
				ENUM_UTILS.match(
					liq.liquidationType,
					LiquidationType.LIQUIDATE_BORROW_FOR_PERP_PNL
				)
			) {
				const record = liq.liquidateBorrowForPerpPnl;

				// borrows
				const liabilityAssetSymbol =
					OrderedSpotMarkets[record.liabilityMarketIndex].symbol;

				const borrowBaseSize = record.liabilityTransfer;
				const borrowQuoteSize = borrowBaseSize
					.mul(record.liabilityPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

				const previousBorrowAggregate = liqdBorrowsLookup[liabilityAssetSymbol];

				const newBorrowQuoteSize = borrowQuoteSize.add(
					previousBorrowAggregate?.quoteSize ??
						BigNum.zero(borrowQuoteSize.precision)
				);

				const newBorrowBaseSize = borrowBaseSize.add(
					previousBorrowAggregate?.baseSize ??
						BigNum.zero(borrowBaseSize.precision)
				);

				liqdBorrowsLookup[liabilityAssetSymbol] = {
					bankIndex: record.liabilityMarketIndex,
					quoteSize: newBorrowQuoteSize,
					baseSize: newBorrowBaseSize,
					avgPrice: COMMON_UI_UTILS.calculateAverageEntryPrice(
						newBorrowQuoteSize,
						newBorrowBaseSize
					),
				};

				notionalAmount = notionalAmount.add(borrowQuoteSize);

				// perp pnl
				const perpMarket = OrderedPerpMarkets[record.perpMarketIndex];
				const perpMarketSymbol = perpMarket.symbol;

				const direction = record.pnlTransfer.gteZero()
					? PositionDirection.LONG
					: PositionDirection.SHORT;

				const pnlSize = record.pnlTransfer;
				const pnlTransferQuoteSize = record.pnlTransfer
					.abs()
					.shiftTo(QUOTE_PRECISION_EXP);

				const previousAggregate = liqdPerpPnlLookup[perpMarketSymbol];

				const newPnlSize = pnlSize.add(
					previousAggregate?.pnlSize ?? BigNum.zero(pnlSize.precision)
				);

				const newTransferQuoteSize = pnlTransferQuoteSize.add(
					previousAggregate?.transferQuoteSize ??
						BigNum.zero(pnlTransferQuoteSize.precision)
				);

				liqdPerpPnlLookup[perpMarketSymbol] = {
					market: perpMarket,
					direction,
					pnlSize: newPnlSize,
					transferQuoteSize: newTransferQuoteSize,
				};

				notionalAmount = notionalAmount.add(pnlTransferQuoteSize);
			} else if (
				ENUM_UTILS.match(liq.liquidationType, LiquidationType.LIQUIDATE_PERP)
			) {
				// Compile data for liqd perp record
				const record = liq.liquidatePerp;
				const market = OrderedPerpMarkets[record.marketIndex];

				const direction: PositionDirection = record.baseAssetAmount.gteZero()
					? PositionDirection.LONG
					: PositionDirection.LONG;
				const baseSize: BigNum = record.baseAssetAmount;
				const quoteSize: BigNum = record.quoteAssetAmount;
				const lpSharesBurned: BigNum =
					record.lpShares ?? BigNum.zero(AMM_RESERVE_PRECISION_EXP);

				// Update aggregate liqd perp data
				//// Handle in a way that if there is no previous record, it inserts new data without crashing
				const previousAggregate = liqdPerpsLookup[market.baseAssetSymbol];

				const newAggregateBaseSize = baseSize.add(
					previousAggregate?.baseSize ?? BigNum.zero(baseSize.precision)
				);
				const newAggregateQuoteSize = quoteSize.add(
					previousAggregate?.quoteSize ?? BigNum.zero(quoteSize.precision)
				);
				const currentRecordFee =
					record.liquidatorFee?.add(record.ifFee ?? BigNum.zero()) ??
					record.ifFee ??
					BigNum.zero();
				const newAggregateFee = currentRecordFee.add(
					previousAggregate?.fee ?? BigNum.zero(quoteSize.precision)
				);
				const newLpSharesBurned = lpSharesBurned.add(
					previousAggregate?.lpShares ?? BigNum.zero(AMM_RESERVE_PRECISION_EXP)
				);

				liqdPerpsLookup[market.baseAssetSymbol] = {
					market,
					direction,
					baseSize: newAggregateBaseSize,
					quoteSize: newAggregateQuoteSize,
					avgPrice: COMMON_UI_UTILS.calculateAverageEntryPrice(
						newAggregateQuoteSize,
						newAggregateBaseSize
					),
					fee: newAggregateFee,
					lpShares: newLpSharesBurned,
				};

				notionalAmount = notionalAmount.add(quoteSize);
			} else if (
				ENUM_UTILS.match(
					liq.liquidationType,
					LiquidationType.LIQUIDATE_PERP_PNL_FOR_DEPOSIT
				)
			) {
				const record = liq.liquidatePerpPnlForDeposit;

				const perpMarket = OrderedPerpMarkets[record.perpMarketIndex];

				const perpMarketSymbol = perpMarket.symbol;

				const direction = record.assetTransfer.gteZero()
					? PositionDirection.LONG
					: PositionDirection.SHORT;

				// pnl
				const pnlSize = record.pnlTransfer;
				const pnlTransferQuoteSize = record.pnlTransfer
					.abs()
					.shiftTo(QUOTE_PRECISION_EXP);

				const previousAggregate = liqdPerpPnlLookup[perpMarketSymbol];

				const newPnlSize = pnlSize.add(
					previousAggregate?.pnlSize ?? BigNum.zero(pnlSize.precision)
				);

				const newTransferQuoteSize = pnlTransferQuoteSize.add(
					previousAggregate?.transferQuoteSize ??
						BigNum.zero(pnlTransferQuoteSize.precision)
				);

				liqdPerpPnlLookup[perpMarketSymbol] = {
					market: perpMarket,
					direction,
					pnlSize: newPnlSize.neg(),
					transferQuoteSize: newTransferQuoteSize,
				};

				notionalAmount = notionalAmount.add(pnlTransferQuoteSize);

				// deposit
				const depositMarket = OrderedSpotMarkets[record.assetMarketIndex];
				const depositMarketSymbol = depositMarket.symbol;

				const depositBaseSize = record.assetTransfer;
				const depositQuoteSize = depositBaseSize
					.mul(record.assetPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

				const previousDepositAggregate =
					liqdDepositsLookup[depositMarketSymbol];

				const newDepositQuoteSize = depositQuoteSize.add(
					previousDepositAggregate?.quoteSize ??
						BigNum.zero(depositQuoteSize.precision)
				);

				const newDepositBaseSize = depositBaseSize.add(
					previousDepositAggregate?.baseSize ??
						BigNum.zero(depositBaseSize.precision)
				);

				liqdDepositsLookup[depositMarketSymbol] = {
					bankIndex: record.assetMarketIndex,
					quoteSize: newDepositQuoteSize,
					baseSize: newDepositBaseSize,
					avgPrice: COMMON_UI_UTILS.calculateAverageEntryPrice(
						newDepositQuoteSize,
						newDepositBaseSize
					),
				};

				notionalAmount = notionalAmount.add(depositQuoteSize);
			}
		});

		const liqdPerps = Object.values(liqdPerpsLookup);
		const liqdDeposits = Object.values(liqdDepositsLookup);
		const liqdBorrows = Object.values(liqdBorrowsLookup);

		const liqdPerpPnls = Object.values(liqdPerpPnlLookup);

		const positionsAffected = liqdPerps.length;
		const borrowsAffected = liqdBorrows.length + liqdDeposits.length;
		const assetsAffected = liqdPerpPnls.length;

		// Update the aggregate state
		newAggregateLiqState.push({
			ts,
			id,
			notionalAmount,
			summary: {
				positionsAffected,
				borrowsAffected,
				assetsAffected,
			},
			liqdPerps,
			liqdBorrows,
			liqdDeposits,
			liqdPerpPnls,
			liquidations,
		});
	});

	// Order liqs by latest slot
	newAggregateLiqState = newAggregateLiqState.sort((liqA, liqB) => {
		return (
			Math.max(...liqB.liquidations.map((liq) => liq.slot)) -
			Math.max(...liqA.liquidations.map((liq) => liq.slot))
		);
	});

	return newAggregateLiqState;
};

const getDescriptionForLiqRecord = (liqRecord: AggregateLiqState) => {
	const positionsCount = liqRecord.summary.positionsAffected;
	const balancesCount = liqRecord.summary.borrowsAffected;

	const positionsString = positionsCount
		? `${positionsCount} position${positionsCount > 1 ? 's' : ''}`
		: '';

	const balancesString = balancesCount
		? `${balancesCount} balance${balancesCount > 1 ? 's' : ''}`
		: '';

	const strings = [positionsString, balancesString].filter(
		(string) => string !== ''
	);

	const coreString = strings
		.map((str, index) => {
			return index === 0
				? str
				: index === strings.length - 1
				? `, and ${str}`
				: `, ${str}, `;
		})
		.join('');

	return `Impacted: ${coreString}`;
};

const secondsToMinutes = (seconds: number, floored?: boolean): string => {
	if (seconds < 60) return `${seconds} seconds`;

	return `${(seconds / 60).toFixed(floored ? 0 : 1)} minute${
		seconds >= 120 ? 's' : ''
	}`;
};

function getOnboardingPath(step: ONBOARDING_STEP) {
	for (const key in ONBOARDING_PATHS) {
		if (ONBOARDING_PATHS[key as keyof typeof ONBOARDING_PATHS] === step) {
			return key;
		}
	}
}

/* Check if numbers fit evenly into one or the other
 ** use toFixed 9 decimal places until i figure out why typescript
 ** tells me 5.1 / 0.1 = 50.99999999999999
 */
const numbersFitEvenly = (numberOne: number, numberTwo: number): boolean => {
	if (isNaN(numberOne) || isNaN(numberTwo)) return false;
	if (numberOne === 0 || numberTwo === 0) return true;

	return (
		Number.isInteger(Number((numberOne / numberTwo).toFixed(9))) ||
		numberOne % numberTwo === 0
	);
};

const getUniqueSortedEvents = (
	existingEvents: Event<any>[],
	newEvents: Event<any>[]
): Event<any>[] => {
	const newUnseenEvents = newEvents.filter(
		(newEvent) =>
			!existingEvents
				.map((existingEvent) => existingEvent.txSig)
				.includes(newEvent.txSig)
	);
	return (
		[...newUnseenEvents, ...existingEvents].sort(
			(a, b) => b.ts.toNumber() - a.ts.toNumber()
		) || []
	);
};

async function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function roundToDecimal(value: number, decimals: number | undefined | null) {
	return decimals ? Math.round(value * 10 ** decimals) / 10 ** decimals : value;
}

function abbreviateAddress(address: PublicKey | string, size = 4) {
	const base58 = address?.toString() ?? '';
	return base58.slice(0, size) + '…' + base58.slice(-size);
}

function abbreviateAccountName(
	name: string,
	size = 8,
	opts?: {
		ellipsisMiddle?: boolean;
	}
) {
	if (name.length < size) return name;

	if (opts?.ellipsisMiddle) {
		const length = Math.floor(name.length);
		const sizeMid = Math.floor(size / 2);

		return name.slice(0, sizeMid) + '...' + name.slice(length - sizeMid);
	}

	return name.slice(0, size) + '...';
}

/**
 * NOTE: If this method ever has to be used I think that a better solution is probably using the `useMounted` hook
 * @returns
 */
const isWindowDefined = () => typeof window !== 'undefined';

/**
 * Used to prettify prizes from competitions by rounding them up if it is 1 cent away from a round number.
 * i.e. $XX.99
 */
const prettifyPrize = (prize: BigNum) => {
	const prizeNum = prize.toNum();
	const roundedNum = Math.round(prizeNum);
	const shouldRoundUp = roundedNum - 0.01 < prizeNum && roundedNum > prizeNum; // round up only if prize is $x.99

	if (shouldRoundUp) {
		return `$${roundedNum.toFixed(2)}`;
	} else {
		return prize.toNotional();
	}
};

const getOrderStatusString = (
	action: OrderAction,
	totalBaseAmountFilled: BigNum,
	baseAssetAmount: BigNum
) => {
	return matchEnum(action, OrderAction.CANCEL)
		? totalBaseAmountFilled.gtZero()
			? 'Partially Filled (Cancelled)'
			: 'Cancelled'
		: matchEnum(action, OrderAction.FILL) &&
		  baseAssetAmount.eq(totalBaseAmountFilled)
		? 'Filled'
		: matchEnum(action, OrderAction.PLACE)
		? 'Open'
		: matchEnum(action, OrderAction.EXPIRE)
		? 'Expired'
		: totalBaseAmountFilled.gtZero()
		? 'Partially Filled'
		: 'Triggered';
};

const getDirectionString = (
	positionDirection: PositionDirection,
	isPerp: boolean
) => {
	if (isPerp) {
		return matchEnum(positionDirection, PositionDirection.LONG)
			? 'long'
			: 'short';
	} else {
		return matchEnum(positionDirection, PositionDirection.LONG)
			? 'buy'
			: 'sell';
	}
};

const getFlagsFromOrder = (
	flagsLookup: {
		reduceOnly: boolean;
		postOnly: boolean;
		immediateOrCancel: boolean;
	},
	defaultValue = EMPTY_TABLE_VALUE
) => {
	if (
		!flagsLookup.reduceOnly &&
		!flagsLookup.postOnly &&
		!flagsLookup.immediateOrCancel
	) {
		return defaultValue;
	}

	const flags = [];

	if (flagsLookup.reduceOnly) flags.push('Reduce Only');
	if (flagsLookup.postOnly) flags.push('Post Only');
	if (flagsLookup.immediateOrCancel) flags.push('IOC');

	return flags.join(', ');
};

const getTriggerPriceFromOrder = (
	orderRecord: {
		orderType: OrderType;
		triggerPrice: BigNum;
	},
	defaultValue = EMPTY_TABLE_VALUE
) => {
	const orderType = orderRecord.orderType;
	if (
		matchEnum(orderType, OrderType.TRIGGER_LIMIT) ||
		matchEnum(orderType, OrderType.TRIGGER_MARKET)
	) {
		return orderRecord.triggerPrice.toNotional(true);
	} else {
		return defaultValue;
	}
};

const getLimitPriceFromOrder = (
	orderRecord: {
		orderType: OrderType;
		price: BigNum;
		oraclePriceOffset?: BigNum;
	},
	defaultValue = EMPTY_TABLE_VALUE
) => {
	if (matchEnum(orderRecord.orderType, OrderType.MARKET)) {
		return orderRecord.price.eq(ZERO)
			? defaultValue
			: orderRecord.price.toNotional(true);
	} else if (
		matchEnum(orderRecord.orderType, OrderType.LIMIT) ||
		matchEnum(orderRecord.orderType, OrderType.TRIGGER_LIMIT)
	) {
		if (orderRecord.price.eqZero() && !!orderRecord.oraclePriceOffset) {
			return getOracleOffsetLabel(orderRecord.oraclePriceOffset.toNum());
		}

		return orderRecord.price.toNotional(true);
	} else {
		return defaultValue;
	}
};

/**
 * Get the borrow rate of a spot market after a deposit/borrow as a percentage BigNum
 * @param amount the delta base amount. pass negative value for borrow, positive for deposit
 * @param spotMarketAccount the spot market to use
 */
const getBorrowRateFromDelta = (
	amount: BN,
	spotMarketAccount: SpotMarketAccount
): BigNum => {
	if (!spotMarketAccount) return;

	return BigNum.from(
		calculateInterestRate(spotMarketAccount, amount),
		SPOT_MARKET_RATE_PRECISION_EXP
	).mul(new BN(100));
};

/**
 * Get the deposit rate of a spot market after a deposit/borrow as a percentage BigNum
 * @param amount the delta base amount. pass negative value for borrow, positive for deposit
 * @param spotMarketAccount the spot market to use
 */
const getDepositRateFromDelta = (
	amount: BN,
	spotMarketAccount: SpotMarketAccount
): BigNum => {
	if (!spotMarketAccount) return;

	return BigNum.from(
		calculateDepositRate(spotMarketAccount, amount),
		SPOT_MARKET_RATE_PRECISION_EXP
	).mul(new BN(100));
};

const getSwapToastId = (inMarketIndex: number, outMarketIndex: number) => {
	return `swap-${inMarketIndex}-${outMarketIndex}`;
};

const MIN_HOT_THRESHOLD = 100_000;

/* Market needs to meet a 24h vol threshold to qualify for hot */
const marketIsHot = (
	marketZScore: number,
	medianZScore: number,
	volume24h: number
) => {
	return volume24h >= MIN_HOT_THRESHOLD && marketZScore - medianZScore > 2;
};

const getOracleOffsetLabel = (offset: number) => {
	return `Oracle Price ${
		offset < 0 ? '-' : '+'
	}$${COMMON_UI_UTILS.trimTrailingZeros(
		NumLib.formatNum.toDisplayPrice(Math.abs(offset)),
		2
	)}`;
};

const openSupportWidget = (
	authority: string | undefined,
	isEmulatingAccount: boolean
) => {
	try {
		if (authority && !isEmulatingAccount) {
			//@ts-ignore
			window.FreshworksWidget('prefill', 'ticketForm', {
				name: authority,
			});
		}
		//@ts-ignore
		window.FreshworksWidget('open');
	} catch (e) {
		console.error('Error opening feedback widget', e);
	}
};

const getOrdinalSuffix = (num: number) => {
	const j = num % 10;
	const k = num % 100;
	if (j === 1 && k !== 11) {
		return 'st';
	}
	if (j === 2 && k !== 12) {
		return 'nd';
	}
	if (j === 3 && k !== 13) {
		return 'rd';
	}
	return 'th';
};

function splitByCapitalLetters(word: string) {
	return word.replace(/([A-Z])/g, ' $1').trim();
}

function lowerCaseNonFirstWords(sentence: string): string {
	const words = sentence.split(' ');
	for (let i = 1; i < words.length; i++) {
		words[i] = words[i].toLowerCase();
	}
	return words.join(' ');
}

function getCloseBorrowAmountWithBuffer(
	driftClient: DriftClient,
	spotMarketIndex: number,
	borrowAmount: BigNum
): BigNum {
	if (!driftClient || !driftClient.isSubscribed) return BigNum.zero();

	const borrowApr = COMMON_UTILS.getBorrowAprForMarket(
		spotMarketIndex,
		MarketType.SPOT,
		driftClient
	);

	const simpleBorrowInterest = borrowApr / 100 / 365 / 24 / 60; // estimated 1 min of borrow interest (trial and error "sweet spot", based on time spent on app before closing a borrow)

	const newWeight = 1 + simpleBorrowInterest;

	const bufferedSwapToAmount = borrowAmount
		.abs()
		.mul(BigNum.from(newWeight * SPOT_MARKET_BALANCE_PRECISION.toNumber())) // spot market balance precision is used here arbitrarily to ensure the floating precision of the new weight does not get cut out
		.div(SPOT_MARKET_BALANCE_PRECISION);

	return bufferedSwapToAmount;
}

// use math.round bc sometimes it can be .00001 or so -- refactor this if we ever add fraction or custom options
const convertMarginRatioToLeverage = (
	marginRatio: number,
	decimals?: number
): number => {
	if (!marginRatio) return undefined;

	const leverage = 1 / (marginRatio / MARGIN_PRECISION.toNumber());

	return decimals
		? parseFloat(leverage.toFixed(decimals))
		: Math.round(leverage);
};

const convertLeverageToMarginRatio = (leverage: number): number => {
	if (!leverage) return undefined;
	return (1 / leverage) * MARGIN_PRECISION.toNumber();
};

const updateCurrentSubAccount = (
	newValue: number,
	authority: PublicKey,
	driftClient: DriftClient,
	setAccountStore: any
) => {
	const userKey = COMMON_UI_UTILS.getUserKey(newValue, authority);
	setAccountStore((s: any) => {
		s.currentUserKey = userKey;
	});
	driftClient.switchActiveUser(newValue, authority);
};
function getSpotEquityOfUser(user: User) {
	const netSpotValue = user.getNetSpotMarketValue();
	const unrealizedPnl = user.getUnrealizedPNL(true, undefined, undefined);

	return netSpotValue.add(unrealizedPnl);
}

const getMaxDailyDrawdown = (
	history: { allTimeTotalPnl: number; totalAccountValue: number }[]
) => {
	let maxDrawdown = 0;

	for (let i = 0; i < history.length - 1; i++) {
		const currentDayAllTimeDayPnl = history[i].allTimeTotalPnl;
		const previousDayAllTimeDayPnl = history[i - 1]?.allTimeTotalPnl ?? 0;

		if (currentDayAllTimeDayPnl > previousDayAllTimeDayPnl) continue; // made profit for that day; no drawdown

		const currentDayPnl = currentDayAllTimeDayPnl - previousDayAllTimeDayPnl;
		const currentDayTotalAccValue = history[i].totalAccountValue;
		const drawdown = currentDayPnl / (currentDayTotalAccValue || 1);

		if (drawdown < maxDrawdown) maxDrawdown = drawdown;
	}

	return maxDrawdown;
};

const getCurrentTraderProfile = (
	currentSettings: Partial<UserSettings>
): 'custom' | 'passive' | 'aggressive' | 'default' => {
	if (currentSettings?.traderProfile) {
		return currentSettings.traderProfile;
	}

	let currentProfile = 'custom';

	TRADER_PROFILE_OPTIONS.slice(0, -1).forEach((option) => {
		let match = true;
		Object.keys(option.value)
			.filter((settingKey) => settingKey !== 'traderProfile')
			.forEach((settingKey) => {
				if (
					option.value[settingKey as keyof UserSettings]?.toString() !=
					currentSettings[settingKey as keyof UserSettings]?.toString()
				) {
					match = false;
				}
			});

		if (match) {
			currentProfile = option.key;
		}
	});

	return currentProfile as 'custom' | 'passive' | 'aggressive' | 'default';
};

const checkIfObjectsDiffer = (
	newSettings: Partial<UserSettings>,
	oldSettings: Partial<UserSettings>
): boolean => {
	let changed = false;

	for (const key of Object.keys(newSettings)) {
		if (
			newSettings[key as keyof UserSettings]?.toString() !==
			oldSettings[key as keyof UserSettings]?.toString()
		) {
			changed = true;
			break;
		}
	}

	return changed;
};

const capitalizeWord = (word: string) => {
	return word.charAt(0).toUpperCase() + word.slice(1);
};

const getInputPriceStepIncrement = (markPrice: number) => {
	if (markPrice < 0.01) return 0.00001;
	if (markPrice < 0.1) return 0.0001;
	if (markPrice < 1) return 0.001;
	if (markPrice < 10) return 0.01;
	if (markPrice < 100) return 0.1;
	if (markPrice < 1000) return 1;

	return 10;
};

const getTokenAddressForDepositAndWithdraw = async (
	spotMarketAccount: SpotMarketAccount,
	authority: PublicKey
) => {
	const isSol = spotMarketAccount.mint.equals(WRAPPED_SOL_MINT);

	return isSol
		? authority
		: await getAssociatedTokenAddress(
				spotMarketAccount.mint,
				authority,
				true,
				getTokenProgramForSpotMarket(spotMarketAccount)
		  );
};

/* Helper function checking for LP account since we will have to account for accounts created previously before the rebrand */
const accountNameIsLp = (accountName: string) =>
	[LP_SUBACCOUNT_NAME, NEW_LP_SUBACCOUNT_NAME].includes(accountName);

const getAccountsInUse = (user: User): number => {
	if (!user || !user.isSubscribed) return 0;
	return (
		user.getActivePerpPositions().length + user.getActiveSpotPositions().length
	);
};

const handleRefresh = (message: string, msDelay = 2000) => {
	notify({
		type: 'info',
		message,
	});
	setTimeout(() => {
		window.location.reload();
	}, msDelay);
};

/* Pagination */
const getPageNumForCount = (recordCount: number, pageSize = 20) => {
	return Math.ceil(recordCount / pageSize);
};

const getBeforeOrAfterTs = (
	records: any[],
	currentPage: number,
	newPage: number
): { beforeTs: number; afterTs: number } => {
	// invalid
	if (currentPage === newPage || !records?.length || newPage < 0)
		return { beforeTs: undefined, afterTs: undefined };

	const timestamps = records.map((record) => record.ts.toNumber());

	const newestTs = Math.max(...timestamps);
	const oldestTs = Math.min(...timestamps);

	if (currentPage > newPage) {
		// going to a more recent page, get AFTER the NEWEST TS
		return { beforeTs: undefined, afterTs: newestTs };
	} else {
		// going to a less recent page, get BEFORE the OLDEST TS
		return { beforeTs: oldestTs, afterTs: undefined };
	}
};

const getPaginationQueryString = ({
	baseQuery,
	newPage,
	pageSize,
	oldPage,
	beforeTs,
	afterTs,
}: {
	baseQuery: string;
	newPage: number;
	pageSize: number;
	oldPage?: number;
	beforeTs?: number;
	afterTs?: number;
}) => {
	const hasParamsAlready = baseQuery.includes('?');

	// nullify these params if jumping straight to last page
	if (newPage < 0) {
		oldPage = undefined;
		beforeTs = undefined;
		afterTs = undefined;
	}

	return `${baseQuery}${
		hasParamsAlready ? '&' : '/?'
	}targetPageIndex=${newPage}&pageSize=${pageSize}${
		oldPage != undefined ? `&currPageIndex=${oldPage}` : ''
	}${beforeTs != undefined ? `&beforeTs=${beforeTs}` : ''}${
		afterTs != undefined ? `&afterTs=${afterTs}` : ''
	}`;
};

const sortRecordsByTs = (
	records: any[],
	direction: 'asc' | 'desc' = 'desc'
) => {
	if (!records || !records?.length) return [];

	return direction === 'desc'
		? [...records].sort((a, b) => b.ts.toNumber() - a.ts.toNumber())
		: [...records].sort((a, b) => a.ts.toNumber() - b.ts.toNumber());
};

const isPythOracle = (oracleSource: OracleSource) =>
	ENUM_UTILS.toStr(oracleSource)?.toLowerCase()?.includes('pyth') ?? false;

const isPullOracle = (oracleSource: OracleSource) =>
	ENUM_UTILS.toStr(oracleSource)?.toLowerCase()?.includes('pull') ||
	ENUM_UTILS.toStr(oracleSource)?.toLowerCase()?.includes('lazer');

const getMarketPrecision = (marketId: MarketId) => {
	return marketId.isPerp
		? BASE_PRECISION_EXP
		: OrderedSpotMarkets[marketId.marketIndex].precisionExp;
};

/**
 *
 * @param computeUnits
 * @param priorityFeeToUse
 * @param useSimulatedComputeUnits Pass true to use simulated compute units (and fallback to the hardcoded budget). Defaults to Env.useSimulatedComputeUnitBudget
 * @param simulatedComputeUnitBufferMultiplier Pass a number to multiply the simulated compute unit budget by.
 * @returns
 */
const getTxParams = (
	getPriorityFeeForComputeUnits: (computeUnits: ComputeUnits) => PriorityFee,
	computeUnits: ComputeUnits = 600_000 as ComputeUnits,
	priorityFeeToUse: PriorityFee = Env.minimumPriorityFee,
	useSimulatedComputeUnits = Env.useSimulatedComputeUnitBudget,
	simulatedComputeUnitBufferMultiplier = Env.computeUnitBufferMultiplier,
	lowerBoundCu?: number
): TxParams => {
	console.log(
		`Using compute unit price ${priorityFeeToUse} for tx with ${computeUnits} compute units. Estimated priority fee = ${
			(priorityFeeToUse * computeUnits) / 10 ** 6 / LAMPORTS_PER_SOL
		} SOL`
	);

	return {
		computeUnitsPrice: priorityFeeToUse,
		computeUnits: computeUnits,
		useSimulatedComputeUnits: useSimulatedComputeUnits,
		computeUnitsBufferMultiplier: simulatedComputeUnitBufferMultiplier,
		useSimulatedComputeUnitsForCUPriceCalculation:
			Env.useSimulatedComputeUnitBudgetForFees,
		getCUPriceFromComputeUnits: Env.useSimulatedComputeUnitBudgetForFees
			? (computeUnits) => {
					return getPriorityFeeForComputeUnits(computeUnits as ComputeUnits);
			  }
			: undefined,
		lowerBoundCu,
	};
};

const getReferralLink = (accountDecodedName?: string) => {
	if (!accountDecodedName) return '';

	const referralLink = `app.drift.trade/ref/${accountDecodedName}`;
	return referralLink;
};

const getOracleUrl = (
	oracleSource: OracleSource,
	symbol: string,
	oraclePubKey: string,
	_isDevnet = false
): string => {
	if (isPythOracle(oracleSource)) {
		return `https://pyth.network/price-feeds/crypto-${symbol.toLowerCase()}-usd`;
	} else if (ENUM_UTILS.match(oracleSource, OracleSource.SWITCHBOARD)) {
		return `https://app.switchboard.xyz/solana/mainnet/feed/${oraclePubKey}`;
	} else if (
		ENUM_UTILS.match(oracleSource, OracleSource.SWITCHBOARD_ON_DEMAND)
	) {
		return `https://ondemand.switchboard.xyz/solana/mainnet/feed/${oraclePubKey}`;
	} else {
		return '';
	}
};

const getPredictionPositionPotentialPnl = (
	positionSize: BN,
	positionEntryPrice: BN,
	positionDirection: PositionDirection
) => {
	/**
	 * size is in BASE_PRECISION
	 * entryPrice is in PRICE_PRECISION
	 * need to return pnl in QUOTE_PRECISION
	 * for long: 1 - price * size
	 * for short: price * size
	 */

	let unadjustedPnl: BN;

	// Get unadjusted pnl
	if (ENUM_UTILS.match(positionDirection, PositionDirection.LONG)) {
		unadjustedPnl = PRICE_PRECISION.sub(positionEntryPrice).mul(positionSize);
	}

	if (ENUM_UTILS.match(positionDirection, PositionDirection.SHORT)) {
		unadjustedPnl = positionEntryPrice.mul(positionSize);
	}

	// Then adjust for precision
	return unadjustedPnl
		.mul(QUOTE_PRECISION)
		.div(PRICE_PRECISION)
		.div(BASE_PRECISION);
};

const roundBigNumToDecimalPlace = (
	bignum: BigNum,
	decimalPlaces: number
): BigNum => {
	const factor = Math.pow(10, decimalPlaces);
	const newNum = Math.round(bignum.toNum() * factor) / factor;
	return BigNum.fromPrint(newNum.toString(), bignum.precision);
};

const baseSizeDisplayForPerpMarket = (
	baseSize: BigNum,
	marketSymbol: string,
	hideValue: boolean,
	decimals?: number
) => {
	const isPredictionmarket =
		PredictionMarketConfigs.isPredictionMarket(marketSymbol);

	if (decimals) {
		baseSize = roundBigNumToDecimalPlace(baseSize, decimals);
	}

	return `${hideValue ? '∗∗∗∗' : baseSize.prettyPrint()} ${
		isPredictionmarket
			? `Shares`
			: COMMON_UI_UTILS.getBaseAssetSymbol(marketSymbol)
	}`;
};

const applyPredictionsFilter = (
	marketIndex: number,
	predictionMarketsOnly = false
) =>
	UIMarket.createPerpMarket(marketIndex).isPredictionMarket ==
	predictionMarketsOnly;

const getPerpMarketDisplayName = (marketIndex: number) => {
	const isPredictionMarket =
		UIMarket.createPerpMarket(marketIndex).isPredictionMarket;

	if (isPredictionMarket) {
		return PredictionMarketConfigs.get(marketIndex).title;
	} else {
		const marketConfig = PERP_MARKETS_LOOKUP[marketIndex];
		return marketConfig.symbol;
	}
};

const getStepSizeForMarket = (marketId: MarketId, driftClient: DriftClient) => {
	return marketId.isPerp
		? driftClient.getPerpMarketAccount(marketId.marketIndex).amm.orderStepSize
		: driftClient.getSpotMarketAccount(marketId.marketIndex).orderStepSize;
};

const userHasNonUsdcDeposits = (userAccount: UserAccount) => {
	if (!userAccount || !userAccount?.spotPositions) return false;

	const openNonUsdcDeposits = userAccount?.spotPositions?.filter(
		(spotPosition) => {
			return (
				spotPosition.marketIndex !== 0 &&
				!spotPosition.scaledBalance.eq(ZERO) &&
				matchEnum(spotPosition.balanceType, SpotBalanceType.DEPOSIT)
			);
		}
	);

	return openNonUsdcDeposits && openNonUsdcDeposits.length > 0;
};

const userHasNonUsdcBorrows = (userAccount: UserAccount) => {
	if (!userAccount || !userAccount?.spotPositions) return false;

	const openNonUsdcBorrows = userAccount?.spotPositions?.filter(
		(spotPosition) => {
			return (
				spotPosition.marketIndex !== 0 &&
				!spotPosition.scaledBalance.eq(ZERO) &&
				matchEnum(spotPosition.balanceType, SpotBalanceType.BORROW)
			);
		}
	);

	return openNonUsdcBorrows && openNonUsdcBorrows.length > 0;
};

const downloadFileFromS3 = async (downloadUrl: string) => {
	fetch(downloadUrl, {
		headers: {
			'Cache-Control': 'no-cache',
		},
	})
		.then(async (response) => {
			const blob = await response.blob();
			const a = window.document.createElement('a');
			a.href = window.URL.createObjectURL(blob);

			const pathArr = downloadUrl.split('/');
			const fileTitleArr = pathArr[pathArr.length - 1].split('_');

			a.download = fileTitleArr.slice(0, 4).join('-');

			document.body.appendChild(a);
			a.click();

			document.body.removeChild(a);

			return true;
		})
		.catch((err) => {
			console.error(err);
			return false;
		});
};

/* Not a constant so that it can automatically append new years */
const getDownloadPeriods = () => {
	const DownloadPeriods = [
		{
			label: 'This week',
			value: 'week',
		},
		{
			label: 'This month',
			value: 'month',
		},
		{
			label: 'Last 3 months',
			value: '3mo',
		},
		{
			label: 'Year to date',
			value: 'ytd',
		},
		{
			label: 'Last 12 months',
			value: 'year',
		},
		{
			label: '2022',
			value: 'custom_1',
		},
		{
			label: '2023',
			value: 'custom_2',
		},
		{
			label: '2024',
			value: 'custom_3',
		},
	];

	const currentYear = new Date().getFullYear();

	while (
		parseInt(DownloadPeriods[DownloadPeriods.length - 1].label) < currentYear
	) {
		const nextCustomNum =
			parseInt(
				DownloadPeriods[DownloadPeriods.length - 1].value.split('_')[1]
			) + 1;

		const nextYear =
			parseInt(DownloadPeriods[DownloadPeriods.length - 1].label) + 1;

		DownloadPeriods.push({
			label: `${nextYear}`,
			value: `custom_${nextCustomNum}`,
		});
	}

	return DownloadPeriods;
};

const getFundingRate = (rate: BN, oracleTwap: BN): number => {
	return (
		BigNum.from(
			rate.mul(PRICE_PRECISION.mul(new BN(100))).div(oracleTwap),
			PRICE_PRECISION_EXP
		).toNum() / FUNDING_RATE_BUFFER_PRECISION.toNumber()
	);
};

const getSimpleFundingRateRecord = (
	record: UISerializableFundingRateRecord
): SimpleFundingRateRecord => {
	const isAsymmetric = !record.fundingRateLong.eq(record.fundingRateShort);

	return {
		marketIndex: record.marketIndex,
		ts: record.ts.toNumber() * 1000,
		fundingRate: getFundingRate(
			record.fundingRate.val,
			record.oraclePriceTwap.val
		),
		marketPrice: record.markPriceTwap.toNum(),
		isAsymmetric,
		fundingRateLong: getFundingRate(
			record.fundingRateLong.val,
			record.oraclePriceTwap.val
		),
		fundingRateShort: getFundingRate(
			record.fundingRateShort.val,
			record.oraclePriceTwap.val
		),
	};
};

const calcSwapAmountAtMaxSlippage = (
	isExactIn: boolean,
	quoteDetails: QuoteResponse,
	slippage: number,
	swapFromMarket: SpotMarketConfig,
	swapToMarket: SpotMarketConfig
): string => {
	const otherAmount = +(
		(isExactIn ? quoteDetails?.outAmount : quoteDetails?.inAmount) ?? '0'
	);
	const slippageToApply = isExactIn ? 1 - slippage / 100 : 1 + slippage / 100;

	const precision = isExactIn
		? swapToMarket.precision
		: swapFromMarket.precision;
	const precisionExp = isExactIn
		? swapToMarket.precisionExp
		: swapFromMarket.precisionExp;

	const otherAmountAtMaxSlippage = (
		(otherAmount * slippageToApply) /
		(precision.toNumber() ?? 1)
	).toFixed(precisionExp.toNumber() ?? 0);

	return otherAmountAtMaxSlippage;
};

/**
 * To get funding rate as a percentage, you need to multiply by the funding rate buffer precision
 * @param rawFundingRate
 * @returns
 */
const getFundingRatePct = (rawFundingRate: BN) => {
	return BigNum.from(
		rawFundingRate.mul(FUNDING_RATE_BUFFER_PRECISION),
		FUNDING_RATE_PRECISION_EXP
	);
};

const getMarketFromUrl = (pathname: string, searchParams?: URLSearchParams) => {
	const firstSubPath = pathname.split('/')[1];
	let possibleMarketSymbol = firstSubPath;

	// Handle special bet route case
	if (firstSubPath === PageRoute.bet) {
		possibleMarketSymbol = pathname.split('/')[2];
	}

	// Try URL path first, then search params
	const query = possibleMarketSymbol ?? searchParams?.get('market');

	// Try exact match first
	const targetUiMarket = findMarketBySymbol(query, false, false);
	if (targetUiMarket) {
		return {
			marketIndex: targetUiMarket.market.marketIndex,
			marketType: targetUiMarket.marketType,
		};
	}

	// Try fuzzy match as fallback
	const fuzzyMatchMarket = findMarketBySymbol(query, false, true);
	if (fuzzyMatchMarket) {
		return {
			marketIndex: fuzzyMatchMarket.market.marketIndex,
			marketType: fuzzyMatchMarket.marketType,
		};
	}

	return null;
};

/* Return list of available RPCs for the given env excluding any set in the env var */
const getRpcOptions = (driftEnv: DriftEnv) => {
	return (
		driftEnv === 'devnet'
			? EnvironmentConstants.rpcs.dev
			: EnvironmentConstants.rpcs.mainnet
	).filter((rpcOption) => !Env.rpcsToExclude.includes(rpcOption.value));
};

/* Get additional apr from a spot market if it has any */
const getBoostedApr = (marketData: BorrowMarketData): number => {
	let weeklyBonus = 0;

	if (marketData.bankIndex === PYUSD_BANK_INDEX) {
		weeklyBonus = Env.pyusdBonusPerWeek;
	} else if (marketData.bankIndex === USDS_MARKET_INDEX) {
		weeklyBonus = Env.usdsBonusPerWeek;
	} else {
		return 0;
	}

	const annualBonus = BigNum.fromPrint(
		`${weeklyBonus * 52}`,
		marketData.bankConfig.precisionExp
	);

	const extraApr = marketData?.totalDepositsBase?.gtZero()
		? (annualBonus.toNum() / marketData.totalDepositsBase.toNum()) * 100
		: 0;

	return extraApr;
};

/* Get additional offset for auction end price from a given perpMarketAccount */
const getAdditionalEndPriceBuffer = (perpMarketAccount: PerpMarketAccount) => {
	if (!perpMarketAccount || !perpMarketAccount.amm) return undefined;

	const tickMultiplier = ADDITIONAL_AUCTION_BUFFERS.find(
		(item) => item.marketIndex === perpMarketAccount.marketIndex
	)?.ticks;

	if (!tickMultiplier) return undefined;

	return tickMultiplier.mul(perpMarketAccount.amm.orderTickSize);
};

const getOracleCrankIxs = async (
	marketIds: MarketId[],
	driftClient: DriftClient,
	currentWalletName: WalletName<string>,
	maxCountPythPull = 1, // currently facing an error in getPythPullUpdateIxs for multiple feeds,
	stalestFirst = true,
	preceedingIxsCount = 2 // this is for pyth lazer and we are never setting it currently so just defaulting to 2, it is important to note that this has caused errors in the past
): Promise<TransactionInstruction[]> => {
	let sortedMarketIds = marketIds;

	// squadsX adds extra ixs so we can't fit the oracle cranks ixs in there
	if (currentWalletName === 'SquadsX') return [];

	// TODO: we did this because the tx is too large, we should probably consider some logic where if simulating the tx fails bec
	// because it's too big, then we retry signing without optional ixs if possible...
	if (Env.disablePervasiveOracleCrank) return [];

	if (stalestFirst) {
		sortedMarketIds = marketIds
			.map((id) => {
				const oracleSlot = id.isPerp
					? driftClient.getOracleDataForPerpMarket(id.marketIndex).slot
					: driftClient.getOracleDataForSpotMarket(id.marketIndex).slot;
				return {
					marketId: id,
					slotLastUpdated: oracleSlot?.toNumber() ?? 0,
				};
			})
			.sort((a, b) => a.slotLastUpdated - b.slotLastUpdated)
			.map((idAndSlot) => {
				return idAndSlot.marketId;
			});
	}

	const optionalIxs: TransactionInstruction[] = [];

	const switchboardUpdateIxs = await getSwitchboardUpdateIxs(
		sortedMarketIds,
		driftClient
	);

	const pythPullUpdateIxs = await getPythPullUpdateIxs(
		sortedMarketIds,
		driftClient,
		maxCountPythPull
	);

	const pythLazerUpdateIxs = await getPythLazerUpdateIxs(
		sortedMarketIds,
		driftClient,
		preceedingIxsCount
	);

	if (switchboardUpdateIxs.length > 0) {
		optionalIxs.push(...switchboardUpdateIxs);
	}

	if (pythPullUpdateIxs.length > 0) {
		optionalIxs.push(...pythPullUpdateIxs);
	}

	if (pythLazerUpdateIxs.length > 0) {
		optionalIxs.push(...pythLazerUpdateIxs);
	}

	return optionalIxs;
};

const isMajorMarket = (marketId: MarketId) => {
	return marketId.isPerp && marketId.marketIndex < 3; // SOL, BTC, ETH
};

const isCustomSlippage = (
	slippageTolerance: SlippageTolerance,
	marketId: MarketId
) => {
	return (
		slippageTolerance !== 'dynamic' &&
		(isMajorMarket(marketId)
			? !SLIPPAGE_PRESETS_MAJORS.includes(slippageTolerance)
			: !SLIPPAGE_PRESETS_NON_MAJORS.includes(slippageTolerance))
	);
};

const UI_UTILS = {
	abbreviateAccountName,
	abbreviateAddress,
	accountNameIsLp,
	addInitialZero,
	addZeroes,
	applyPredictionsFilter,
	applyTimeFilter,
	baseSizeDisplayForPerpMarket,
	calcSwapAmountAtMaxSlippage,
	capitalizeWord,
	checkIfObjectsDiffer,
	clickedInsideElement,
	convertLeverageToMarginRatio,
	convertMarginRatioToLeverage,
	copyToClipboard,
	createFakeKeypairForPubKey,
	cumulatizePnlPoints,
	decodeName,
	disallowNegativeStringInput,
	downloadFileFromS3,
	elementIsVisible,
	encodeName,
	encodeQueryParams,
	findDaysHoursMinutesFromNow,
	findDaysOrHoursAgo,
	findLocalStorageKeysWithMatch,
	findMarketBySymbol,
	formatPnlData,
	formatWithMinDecimals,
	getAccountsInUse,
	getAdditionalEndPriceBuffer,
	getAggregateLiqs,
	getBeforeOrAfterTs,
	getBoostedApr,
	getBorrowRateFromDelta,
	getCloseBorrowAmountWithBuffer,
	getCurrentTraderProfile,
	getDelegateAccountLabel,
	getDepositRateFromDelta,
	getDescriptionForLiqRecord,
	getDirectionString,
	getDownloadPeriods,
	getFlagsFromOrder,
	getFundingRate,
	getFundingRatePct,
	getInputPriceStepIncrement,
	getLimitPriceFromOrder,
	getMarketFees,
	getMaxDailyDrawdown,
	getOracleCrankIxs,
	getOracleOffsetLabel,
	getOracleUrl,
	getOrdinalSuffix,
	getPageNumForCount,
	getPaginationQueryString,
	getPerpMarketId,
	getPerpMarketDisplayName,
	getPredictionPositionPotentialPnl,
	getReferrerInfo,
	getReferralLink,
	getRpcOptions,
	getSimpleFundingRateRecord,
	getSpotEquityOfUser,
	getSpotLiqPriceNum,
	getSpotLiqPriceStr,
	getSpotMarketId,
	getStepSizeForMarket,
	getStoredReferrerParam,
	getStyleValue,
	getSubAccountStorageKey,
	getSwapToastId,
	getSymbolFromFullMarketName,
	getTokenAddressForDepositAndWithdraw,
	getTradeInfoFromMatchedRecord,
	getTradeInfoFromOrderActionRecord,
	getUIOrderTypeFromSdkOrderType,
	getOnboardingPath,
	getOrderStatusString,
	getTriggerPriceFromOrder,
	getTxParams,
	getUniqueSortedEvents,
	getUrlForAccount,
	getUrlForTx,
	handleRefresh,
	isCustomSlippage,
	isMajorMarket,
	isNotionalDust,
	isWindowDefined,
	lowerCaseNonFirstWords,
	normalisePnlPoints,
	nowTs,
	numbersFitEvenly,
	openUrl,
	openSupportWidget,
	periodOffset,
	periodTimeSize,
	prettyPrintPriceBasedOnTick,
	printDate,
	removeDuplicates,
	roundBigNumToDecimalPlace,
	roundToDecimal,
	roundToStepSizeIfLargeEnough,
	secondsToMinutes,
	sleep,
	sortBnAsc,
	sortBnDesc,
	sortRecordsByTs,
	splitByCapitalLetters,
	timeAgo,
	timeAgoPreciseString,
	toFixedLocaleString,
	toNotional,
	truncateInputToPrecision,
	updateCurrentSubAccount,
	urlHasParam,
	userHasNonUsdcBorrows,
	userHasNonUsdcDeposits,
	valueIsBelowStepSize,
	waitUntilValue,
	prettifyPrize,
	marketIsHot,
	isPullOracle,
	isPythOracle,
	getMarketPrecision,
	getBigNumRoundedToStepSize,
	getMarketFromUrlPathname: getMarketFromUrl,
};

export default UI_UTILS;
