"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findAllMarketAndOracles = exports.getMarketsAndOraclesForSubscription = exports.initialize = exports.getConfig = exports.configs = exports.DEFAULT_CONFIRMATION_OPTS = exports.PYTH_LAZER_STORAGE_ACCOUNT_KEY = exports.PTYH_LAZER_PROGRAM_ID = exports.DRIFT_ORACLE_RECEIVER_ID = exports.DRIFT_PROGRAM_ID = void 0;
const _1 = require(".");
const perpMarkets_1 = require("./constants/perpMarkets");
const spotMarkets_1 = require("./constants/spotMarkets");
const on_demand_1 = require("@switchboard-xyz/on-demand");
const oracleId_1 = require("./oracles/oracleId");
exports.DRIFT_PROGRAM_ID = 'dRiftyHA39MWEi3m9aunc5MzRF1JYuBsbn6VPcn33UH';
exports.DRIFT_ORACLE_RECEIVER_ID = 'G6EoTTTgpkNBtVXo96EQp2m6uwwVh2Kt6YidjkmQqoha';
exports.PTYH_LAZER_PROGRAM_ID = 'pytd2yyk641x7ak7mkaasSJVXh6YYZnC7wTmtgAyxPt';
exports.PYTH_LAZER_STORAGE_ACCOUNT_KEY = new _1.PublicKey('3rdJbqfnagQ4yx9HXJViD4zc4xpiSqmFsKpPuSCQVyQL');
exports.DEFAULT_CONFIRMATION_OPTS = {
    preflightCommitment: 'confirmed',
    commitment: 'confirmed',
};
exports.configs = {
    devnet: {
        ENV: 'devnet',
        PYTH_ORACLE_MAPPING_ADDRESS: 'BmA9Z6FjioHJPpjT39QazZyhDRUdZy2ezwx4GiDdE2u2',
        DRIFT_PROGRAM_ID: exports.DRIFT_PROGRAM_ID,
        JIT_PROXY_PROGRAM_ID: 'J1TnP8zvVxbtF5KFp5xRmWuvG9McnhzmBd9XGfCyuxFP',
        USDC_MINT_ADDRESS: '8zGuJQqwhZafTah7Uc7Z4tXRnguqkn5KLFAP8oV6PHe2',
        SERUM_V3: 'DESVgJVGajEgKGXhb6XmqDHGz3VjdgP7rEVESBgxmroY',
        PHOENIX: 'PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY',
        OPENBOOK: 'opnb2LAfJYbRMAHHvqjCwQxanZn7ReEHp1k81EohpZb',
        V2_ALPHA_TICKET_MINT_ADDRESS: 'DeEiGWfCMP9psnLGkxGrBBMEAW5Jv8bBGMN8DCtFRCyB',
        PERP_MARKETS: perpMarkets_1.DevnetPerpMarkets,
        SPOT_MARKETS: spotMarkets_1.DevnetSpotMarkets,
        /** @deprecated use MARKET_LOOKUP_TABLES */
        MARKET_LOOKUP_TABLE: 'FaMS3U4uBojvGn5FSDEPimddcXsCfwkKsFgMVVnDdxGb',
        MARKET_LOOKUP_TABLES: ['FaMS3U4uBojvGn5FSDEPimddcXsCfwkKsFgMVVnDdxGb'],
        DRIFT_ORACLE_RECEIVER_ID: exports.DRIFT_ORACLE_RECEIVER_ID,
        SB_ON_DEMAND_PID: on_demand_1.ON_DEMAND_DEVNET_PID,
    },
    'mainnet-beta': {
        ENV: 'mainnet-beta',
        PYTH_ORACLE_MAPPING_ADDRESS: 'AHtgzX45WTKfkPG53L6WYhGEXwQkN1BVknET3sVsLL8J',
        DRIFT_PROGRAM_ID: exports.DRIFT_PROGRAM_ID,
        JIT_PROXY_PROGRAM_ID: 'J1TnP8zvVxbtF5KFp5xRmWuvG9McnhzmBd9XGfCyuxFP',
        USDC_MINT_ADDRESS: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        SERUM_V3: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
        PHOENIX: 'PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY',
        OPENBOOK: 'opnb2LAfJYbRMAHHvqjCwQxanZn7ReEHp1k81EohpZb',
        V2_ALPHA_TICKET_MINT_ADDRESS: 'Cmvhycb6LQvvzaShGw4iDHRLzeSSryioAsU98DSSkMNa',
        PERP_MARKETS: perpMarkets_1.MainnetPerpMarkets,
        SPOT_MARKETS: spotMarkets_1.MainnetSpotMarkets,
        /** @deprecated use MARKET_LOOKUP_TABLES */
        MARKET_LOOKUP_TABLE: 'Fpys8GRa5RBWfyeN7AaDUwFGD1zkDCA4z3t4CJLV8dfL',
        MARKET_LOOKUP_TABLES: [
            'Fpys8GRa5RBWfyeN7AaDUwFGD1zkDCA4z3t4CJLV8dfL',
            'EiWSskK5HXnBTptiS5DH6gpAJRVNQ3cAhTKBGaiaysAb',
        ],
        SERUM_LOOKUP_TABLE: 'GPZkp76cJtNL2mphCvT6FXkJCVPpouidnacckR6rzKDN',
        DRIFT_ORACLE_RECEIVER_ID: exports.DRIFT_ORACLE_RECEIVER_ID,
        SB_ON_DEMAND_PID: on_demand_1.ON_DEMAND_MAINNET_PID,
    },
};
let currentConfig = exports.configs.devnet;
const getConfig = () => currentConfig;
exports.getConfig = getConfig;
/**
 * Allows customization of the SDK's environment and endpoints. You can pass individual settings to override the settings with your own presets.
 *
 * Defaults to master environment if you don't use this function.
 * @param props
 * @returns
 */
const initialize = (props) => {
    var _a, _b;
    //@ts-ignore
    if (props.env === 'master')
        return { ...exports.configs['devnet'], ...((_a = props.overrideEnv) !== null && _a !== void 0 ? _a : {}) };
    currentConfig = { ...exports.configs[props.env], ...((_b = props.overrideEnv) !== null && _b !== void 0 ? _b : {}) };
    return currentConfig;
};
exports.initialize = initialize;
function getMarketsAndOraclesForSubscription(env, perpMarkets, spotMarkets) {
    const perpMarketsToUse = (perpMarkets === null || perpMarkets === void 0 ? void 0 : perpMarkets.length) > 0 ? perpMarkets : perpMarkets_1.PerpMarkets[env];
    const spotMarketsToUse = (spotMarkets === null || spotMarkets === void 0 ? void 0 : spotMarkets.length) > 0 ? spotMarkets : spotMarkets_1.SpotMarkets[env];
    const perpMarketIndexes = [];
    const spotMarketIndexes = [];
    const oracleInfos = new Map();
    for (const market of perpMarketsToUse) {
        perpMarketIndexes.push(market.marketIndex);
        oracleInfos.set((0, oracleId_1.getOracleId)(market.oracle, market.oracleSource), {
            publicKey: market.oracle,
            source: market.oracleSource,
        });
    }
    for (const spotMarket of spotMarketsToUse) {
        spotMarketIndexes.push(spotMarket.marketIndex);
        oracleInfos.set((0, oracleId_1.getOracleId)(spotMarket.oracle, spotMarket.oracleSource), {
            publicKey: spotMarket.oracle,
            source: spotMarket.oracleSource,
        });
    }
    return {
        perpMarketIndexes: perpMarketIndexes,
        spotMarketIndexes: spotMarketIndexes,
        oracleInfos: Array.from(oracleInfos.values()),
    };
}
exports.getMarketsAndOraclesForSubscription = getMarketsAndOraclesForSubscription;
async function findAllMarketAndOracles(program) {
    const perpMarketIndexes = [];
    const spotMarketIndexes = [];
    const oracleInfos = new Map();
    const perpMarketProgramAccounts = (await program.account.perpMarket.all());
    const spotMarketProgramAccounts = (await program.account.spotMarket.all());
    for (const perpMarketProgramAccount of perpMarketProgramAccounts) {
        const perpMarket = perpMarketProgramAccount.account;
        perpMarketIndexes.push(perpMarket.marketIndex);
        oracleInfos.set((0, oracleId_1.getOracleId)(perpMarket.amm.oracle, perpMarket.amm.oracleSource), {
            publicKey: perpMarket.amm.oracle,
            source: perpMarket.amm.oracleSource,
        });
    }
    for (const spotMarketProgramAccount of spotMarketProgramAccounts) {
        const spotMarket = spotMarketProgramAccount.account;
        spotMarketIndexes.push(spotMarket.marketIndex);
        oracleInfos.set((0, oracleId_1.getOracleId)(spotMarket.oracle, spotMarket.oracleSource), {
            publicKey: spotMarket.oracle,
            source: spotMarket.oracleSource,
        });
    }
    return {
        perpMarketIndexes,
        perpMarketAccounts: perpMarketProgramAccounts.map((account) => account.account),
        spotMarketIndexes,
        spotMarketAccounts: spotMarketProgramAccounts.map((account) => account.account),
        oracleInfos: Array.from(oracleInfos.values()),
    };
}
exports.findAllMarketAndOracles = findAllMarketAndOracles;
