import { twMerge } from 'tailwind-merge';
import {
	WarningFilled,
	ErrorFilled,
	SuccessFilled,
	Close,
} from '@drift-labs/icons';
import ArcLoadingSpinner from './Icons/LoadingSpinner/ArcLoadingSpinner';
import { Typo } from './Text/Typo';
import { useState } from 'react';
import Button from './Button';

const IconCircleBg = ({
	children,
	bgColor,
	className,
}: {
	children: React.ReactNode;
	bgColor: string;
	className?: string;
}) => {
	return (
		<div
			className={twMerge(
				'flex items-center justify-center p-1 rounded-full',
				bgColor,
				className
			)}
		>
			{children}
		</div>
	);
};

export type AlertType =
	| 'warning'
	| 'error'
	| 'success'
	| 'awaiting'
	| 'info'
	| 'no-icon';

const AlertIcon = (props: { type: AlertType }) => {
	let Icon;

	switch (props.type) {
		case 'warning':
			Icon = (
				<IconCircleBg bgColor="bg-brand-yellow-secondary-bg">
					<WarningFilled size={24} />
				</IconCircleBg>
			);
			break;
		case 'error':
			Icon = (
				<IconCircleBg bgColor="bg-negative-red-secondary-bg">
					<ErrorFilled size={24} />
				</IconCircleBg>
			);
			break;
		case 'success':
			Icon = (
				<IconCircleBg bgColor="bg-positive-green-secondary-bg">
					<SuccessFilled size={24} />
				</IconCircleBg>
			);
			break;
		case 'awaiting':
			Icon = (
				<IconCircleBg bgColor="bg-interactive-secondary-bg">
					<ArcLoadingSpinner size={24} />
				</IconCircleBg>
			);
			break;
		case 'info':
			Icon = (
				<IconCircleBg bgColor="bg-interactive-secondary-bg">
					<WarningFilled
						size={24}
						className="[&>svg>path]:fill-interactive-link"
					/>
				</IconCircleBg>
			);
			break;
		case 'no-icon':
			Icon = null;
			break;
		default: {
			const _exhaustiveCheck: never = props.type;
			throw new Error(`Unhandled type: ${_exhaustiveCheck}`);
		}
	}

	return Icon;
};

export const Alert = (props: {
	type: AlertType;
	className?: string;
	message: React.ReactNode;
	description?: React.ReactNode;
	isClosable?: boolean;
	radialGradientClassName?: string;
	customIcon?: React.ReactNode;
	noIcon?: boolean;
	onClick?: () => void;
}) => {
	const [isClosed, setIsClosed] = useState(false);
	return (
		<div
			className={twMerge(
				'flex items-center gap-4 px-4 py-3 bg-container-bg border border-container-border relative overflow-hidden rounded',
				props.className,
				isClosed && 'hidden',
				props.onClick && 'cursor-pointer'
			)}
			onClick={props.onClick}
		>
			<div
				className={twMerge(
					'absolute min-w-[260px] w-full h-[260px] rounded-full -translate-x-1/2 -translate-y-1/2 top-1/2',
					props.type === 'success'
						? `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(49,203,158,0.20)_0%,rgba(0,209,140,0.00)_100%)]`
						: props.type === 'error'
						? `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(229,77,72,0.20)_0%,rgba(229,77,72,0.00)_100%)]`
						: props.type === 'warning'
						? `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(242,201,76,0.20)_0%,rgba(242,201,76,0.00)_100%)]`
						: `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(153,69,255,0.16)_0%,rgba(153,69,255,0.00)_100%)]`,
					props.radialGradientClassName
				)}
			>
				{/** Radial Gradient */}
			</div>

			{props.customIcon ? (
				props.customIcon
			) : !props.noIcon ? (
				<AlertIcon type={props.type} />
			) : null}

			<div className="flex flex-col w-full gap-2 text-text-default">
				<div className="flex items-center justify-between w-full gap-1">
					<Typo.T4
						className={twMerge(
							props.type === 'success' && 'text-positive-green',
							props.type === 'error' && 'text-negative-red',
							props.type === 'warning' && 'text-warn-yellow',
							props.type === 'awaiting' && 'text-text-default',
							props.className
						)}
					>
						{props.message}
					</Typo.T4>
				</div>
				{props.description && (
					<Typo.T5 className="z-20">{props.description}</Typo.T5>
				)}
			</div>
			{props.isClosable && (
				<Button.Ghost
					className="rounded-sm"
					size="SMALL"
					onClick={() => setIsClosed(true)}
				>
					<Close size={24} />
				</Button.Ghost>
			)}
		</div>
	);
};
