import { SwiftLogo } from 'src/components/Icons/SwiftLogo';

export const SwiftToastBadge = () => {
	return (
		<div>
			<div className="bg-container-bg swift-gradient-border py-1 pl-0.5 pr-1.5 -mt-[10px] relative overflow-hidden">
				<div className="absolute w-[260px] z-0 h-[260px] rounded-full -translate-x-1/2 -translate-y-1/2 top-1/2 bg-[radial-gradient(50%_50%_at_50%_50%,rgba(153,69,255,0.16)_0%,rgba(153,69,255,0.00)_100%)]"></div>
				<div className="relative z-10">
					<SwiftLogo size="small" variant="active" />
				</div>
			</div>
		</div>
	);
};
