import {
	VersionedSettingsHandler,
	VersionedSettingsRules,
	VersionedSettingsRuleFactory,
	SettingHandlerType,
	EnvironmentConstants,
} from '@drift/common';
import { UserSettings } from '../environmentVariables/EnvironmentVariableTypes';
import { SANITY_CHECK_ABS_MAX_FEE_IN_SOL } from './PriorityFeeCalculator';
import UI_UTILS from './uiUtils';
import { TRADER_PROFILE_OPTIONS } from 'src/environmentVariables/DefaultSettings';

const DriftUISettingsFactory = (
	...props: Parameters<typeof VersionedSettingsRuleFactory<UserSettings>>
) => {
	return VersionedSettingsRuleFactory<UserSettings>(...props);
};

/**
 * A list of all of the settings versioning rules for the Drift UI. We SHOULD never need to remove rules, only add new ones and increase the associated version number. If a rule is ever removed the changes should be reviewed in detail.
 */

// 🚨 IMPORTANT NOTE : When we add more settings versions we need to change how the CTA's action gets created. Currently it's hardcoded to open the network settings modal. You can see this in settingsProvider.tsx

const DriftUISettingsRules: VersionedSettingsRules<UserSettings> = [
	DriftUISettingsFactory(
		1,
		'priorityFee',
		(settings) => {
			const priorityFeeSetting = settings.priorityFee;
			const customPriorityFeeSetting = settings.customPriorityFee;

			const defaultPriorityFeeSetting = 'dynamic';

			let transformationWasApplied = false;

			const resetFeeSettingsToDefault = () => {
				settings.priorityFee = defaultPriorityFeeSetting;
				settings.customPriorityFee = undefined;
			};

			// Handle case where setting is invalid type
			if (
				priorityFeeSetting &&
				priorityFeeSetting !== 'custom' &&
				priorityFeeSetting !== 'dynamic' &&
				priorityFeeSetting !== 'boosted' &&
				priorityFeeSetting !== 'turbo'
			) {
				resetFeeSettingsToDefault();
				transformationWasApplied = true;
			}

			// Handle case where custom fee is set but custom priority fee option is not selected
			if (priorityFeeSetting !== 'custom' && !!customPriorityFeeSetting) {
				resetFeeSettingsToDefault();
				transformationWasApplied = true;
			}

			// Reset custom fees to dynamic if they are on the custom option, because the context of the values have changed too much
			if (priorityFeeSetting === 'custom') {
				resetFeeSettingsToDefault();
				transformationWasApplied = true;
			}

			if (
				customPriorityFeeSetting &&
				customPriorityFeeSetting > SANITY_CHECK_ABS_MAX_FEE_IN_SOL
			) {
				resetFeeSettingsToDefault();
				transformationWasApplied = true;
			}

			return {
				transformationWasApplied,
			};
		},
		SettingHandlerType.Transformative,
		`Improvements to Priority Fees were rolled out in the latest version of Drift and your Custom Priority Fee Settings have been reset to defaults. You can review them in Settings.`,
		'Your priority fee settings were reset to defaults'
	), // Rule 1 : Changed allowed values for priorityFee to support new dynamic|boosted|turbo modes
	DriftUISettingsFactory(
		3.06, // Every time we update default profile settings, increase this number to make the rule run again
		'traderProfile',
		(settings) => {
			const traderProfile = settings.traderProfile;

			// if already set to custom, do nothing
			if (traderProfile === 'custom') {
				return {
					transformationWasApplied: false,
				};
			}

			const derivedTraderProfile = UI_UTILS.getCurrentTraderProfile(settings);

			let transformationWasApplied = false;

			if (derivedTraderProfile !== 'custom') {
				const newDefaultsForProfile = TRADER_PROFILE_OPTIONS.find(
					(profile) => profile.key === derivedTraderProfile
				).value;

				// We determine that we've changed params if the value has changed and the previous value isn't undefined (which implies a default .. e.g. tradeProfile goes from undefined by default to agressive (as at current time))
				Object.entries(newDefaultsForProfile).forEach(([key, value]) => {
					if (
						settings[key as keyof UserSettings] !== value &&
						settings[key as keyof UserSettings] !== undefined
					) {
						//@ts-ignore
						settings[key as keyof UserSettings] =
							newDefaultsForProfile[key as keyof UserSettings];
						transformationWasApplied = true;
					}
				});
			}

			return {
				transformationWasApplied,
			};
		},
		SettingHandlerType.Transformative,
		`Improvements to trade settings presets were rolled out in the latest version of Drift. You can review them in Settings.`,
		'Improvements to trade settings presets were rolled out in the latest version of Drift. You can review them in Settings.'
	), // Rule 2 : if a user has selected a trading profile but doesn't have the latest defaults for that profile, update them
	DriftUISettingsFactory(
		3.03,
		'rpcEndpoint',
		(settings) => {
			const rpcEndpoint = settings.rpcEndpoint?.value;

			// if they werent using helius, do nothing
			if (rpcEndpoint !== 'https://cold-hanni-fast-mainnet.helius-rpc.com/') {
				return {
					transformationWasApplied: false,
				};
			}

			settings.rpcEndpoint = EnvironmentConstants.rpcs.mainnet.find(
				(rpc) => rpc.label === 'Helius 1'
			);

			return { transformationWasApplied: true };
		},
		SettingHandlerType.Transformative,
		`Your default RPC endpoint was automatically migrated to the new Helius endpoint.`,
		'RPC endpoint updated'
	), // Rule 3 : update users using old Helius endpoint
];

export const DriftUISettingsHandler = new VersionedSettingsHandler(
	DriftUISettingsRules
);
