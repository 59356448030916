"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateApplyProfitShare = calculateApplyProfitShare;
exports.calculateProfitShare = calculateProfitShare;
exports.calculateRealizedVaultDepositorEquity = calculateRealizedVaultDepositorEquity;
exports.calculateVaultUnsettledFuelPerShare = calculateVaultUnsettledFuelPerShare;
exports.calculateVaultDepositorUnsettledFuel = calculateVaultDepositorUnsettledFuel;
exports.calculateVaultDepositorFuel = calculateVaultDepositorFuel;
const sdk_1 = require("@drift-labs/sdk");
const types_1 = require("../types/types");
const constants_1 = require("../constants");
/**
 * Calculates the unrealized profitShare for a vaultDepositor
 * @param vaultDepositor
 * @param vaultEquity
 * @param vault
 * @returns
 */
function calculateApplyProfitShare(vaultDepositor, vaultEquity, vault) {
    const amount = (0, sdk_1.unstakeSharesToAmount)(vaultDepositor.vaultShares, vault.totalShares, vaultEquity);
    const profitShareAmount = calculateProfitShare(vaultDepositor, amount, vault);
    const profitShareShares = (0, sdk_1.stakeAmountToShares)(profitShareAmount, vault.totalShares, vaultEquity);
    return {
        profitShareAmount,
        profitShareShares,
    };
}
function calculateProfitShare(vaultDepositor, totalAmount, vault, vaultProtocol) {
    const profit = totalAmount.sub(vaultDepositor.netDeposits.add(vaultDepositor.cumulativeProfitShareAmount));
    let profitShare = vault.profitShare;
    if (vaultProtocol) {
        profitShare += vaultProtocol.protocolProfitShare;
    }
    if (profit.gt(sdk_1.ZERO)) {
        const profitShareAmount = profit
            .mul(new sdk_1.BN(profitShare))
            .div(sdk_1.PERCENTAGE_PRECISION);
        return profitShareAmount;
    }
    return sdk_1.ZERO;
}
/**
 * Calculates the equity across deposits and realized profit for a vaultDepositor
 * @param vaultDepositor vault depositor account
 * @param vaultEquity total vault equity
 * @param vault vault account
 * @param vaultProtocol if vault account has "vaultProtocol" then this is needed
 * @returns
 */
function calculateRealizedVaultDepositorEquity(vaultDepositor, vaultEquity, vault, vaultProtocol) {
    const vdAmount = (0, sdk_1.unstakeSharesToAmount)(vaultDepositor.vaultShares, vault.totalShares, vaultEquity);
    const profitShareAmount = calculateProfitShare(vaultDepositor, vdAmount, vault, vaultProtocol);
    return vdAmount.sub(profitShareAmount);
}
function calculateVaultUnsettledFuelPerShare(vault, vaultUserStats, fuelOverflow) {
    if ((vaultUserStats.fuelOverflowStatus & sdk_1.FuelOverflowStatus.Exists) === 1 &&
        !fuelOverflow) {
        throw new Error('UserStats requires a FuelOverflow account to calculate total fuel');
    }
    const userStatsTotalFuel = new sdk_1.BN(vaultUserStats.fuelInsurance)
        .add(new sdk_1.BN(vaultUserStats.fuelDeposits))
        .add(new sdk_1.BN(vaultUserStats.fuelBorrows))
        .add(new sdk_1.BN(vaultUserStats.fuelPositions))
        .add(new sdk_1.BN(vaultUserStats.fuelTaker))
        .add(new sdk_1.BN(vaultUserStats.fuelMaker));
    const overflowFuel = fuelOverflow
        ? new sdk_1.BN(fuelOverflow.fuelInsurance)
            .add(fuelOverflow.fuelDeposits)
            .add(fuelOverflow.fuelBorrows)
            .add(fuelOverflow.fuelPositions)
            .add(fuelOverflow.fuelTaker)
            .add(fuelOverflow.fuelMaker)
        : sdk_1.ZERO;
    const totalFuel = userStatsTotalFuel.add(overflowFuel);
    if (totalFuel > vault.cumulativeFuel) {
        let shareDenominator = vault.userShares;
        if (vault.fuelDistributionMode === types_1.FuelDistributionMode.UsersOnly) {
            if (vault.userShares.eq(sdk_1.ZERO)) {
                shareDenominator = vault.totalShares;
            }
            else {
                shareDenominator = vault.userShares;
            }
        }
        else if (vault.fuelDistributionMode === types_1.FuelDistributionMode.UsersAndManager) {
            shareDenominator = vault.totalShares;
        }
        if (shareDenominator.gt(sdk_1.ZERO)) {
            const fuelDelta = totalFuel.sub(vault.cumulativeFuel);
            const fuelDeltaPerShare = fuelDelta
                .mul(constants_1.FUEL_SHARE_PRECISION)
                .div(shareDenominator);
            return vault.cumulativeFuelPerShare.add(fuelDeltaPerShare);
        }
    }
    return vault.cumulativeFuelPerShare;
}
function calculateVaultDepositorUnsettledFuel(vaultDepositor, vault, vaultUserStats, fuelOverflow) {
    // If timestamp hasn't changed, no new fuel to calculate
    if (Date.now() / 1000 <= vaultDepositor.lastFuelUpdateTs) {
        return vaultDepositor.fuelAmount;
    }
    // Special case for initial fuel setup
    if (vaultDepositor.lastFuelUpdateTs === constants_1.MAGIC_FUEL_START_TS) {
        return vaultDepositor.fuelAmount;
    }
    const cumulativeFuelPerShare = calculateVaultUnsettledFuelPerShare(vault, vaultUserStats, fuelOverflow);
    // If vault's cumulative fuel per share is less than depositor's recorded amount,
    // this means the vault's fuel was reset - no new fuel to add
    if (cumulativeFuelPerShare.lt(vaultDepositor.cumulativeFuelPerShareAmount)) {
        return vaultDepositor.fuelAmount;
    }
    // Calculate new fuel
    const fuelPerShareDelta = cumulativeFuelPerShare.sub(vaultDepositor.cumulativeFuelPerShareAmount);
    const newFuel = fuelPerShareDelta
        .mul(vaultDepositor.vaultShares)
        .div(constants_1.FUEL_SHARE_PRECISION);
    return vaultDepositor.fuelAmount.add(newFuel);
}
function calculateVaultDepositorFuel(vaultDepositor, vault, vaultUserStats, fuelOverflow) {
    const vdFuel = vaultDepositor.fuelAmount;
    const unsettledFuel = calculateVaultDepositorUnsettledFuel(vaultDepositor, vault, vaultUserStats, fuelOverflow);
    return vdFuel.add(unsettledFuel);
}
