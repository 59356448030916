import { CandleType } from '@drift/common';
import { UserSettings } from './EnvironmentVariableTypes';

// --- User Settings

export const DEFAULT_MARKET_AUCTION_DURATION = 20;
export const DEFAULT_LIMIT_AUCTION_DURATION = 60;
const DEFAULT_AUCTION_START_PRICE_OFFSET = -0.025;
const DEFAULT_AUCTION_END_PRICE_OFFSET = 0.1;
const DEFAULT_MAX_PRIORITY_FEE_SOL_CAP = 0.001;
const DEFAULT_AUCTION_END_PRICE_FROM = 'worst';

// Trader Profile Presets
// Bump version number in TraderProfile rule in utils/settings.ts if these defaults are updated
const CLASSIC_TRADER_PROFILE: Partial<UserSettings> = {
	allowInfSlippage: false,
	slippageTolerance: 'dynamic',
	auctionDuration: DEFAULT_MARKET_AUCTION_DURATION,
	auctionStartPriceOffset: DEFAULT_AUCTION_START_PRICE_OFFSET,
	auctionEndPriceOffset: DEFAULT_AUCTION_END_PRICE_OFFSET,
	auctionStartPriceOffsetFrom: 'marketBased',
	auctionEndPriceOffsetFrom: DEFAULT_AUCTION_END_PRICE_FROM,
	oracleOffsetOrdersEnabled: true,
	placeAndTakeEnabled: false,
	traderProfile: 'default', // still using this key even though we default to aggressive now, to avoid adding another transformation
};

const AGGRO_TRADER_PROFILE: Partial<UserSettings> = {
	allowInfSlippage: false,
	slippageTolerance: 'dynamic',
	auctionDuration: 20,
	auctionStartPriceOffset: 'marketBased',
	auctionEndPriceOffset: DEFAULT_AUCTION_END_PRICE_OFFSET,
	auctionEndPriceOffsetFrom: DEFAULT_AUCTION_END_PRICE_FROM,
	auctionStartPriceOffsetFrom: 'marketBased',
	oracleOffsetOrdersEnabled: true,
	placeAndTakeEnabled: true,
	traderProfile: 'aggressive',
};

const PASSIVE_TRADER_PROFILE: Partial<UserSettings> = {
	allowInfSlippage: false,
	slippageTolerance: '0.25',
	auctionDuration: 30,
	auctionStartPriceOffset: -0.1,
	auctionEndPriceOffset: DEFAULT_AUCTION_END_PRICE_OFFSET,
	auctionStartPriceOffsetFrom: 'best',
	auctionEndPriceOffsetFrom: DEFAULT_AUCTION_END_PRICE_FROM,
	oracleOffsetOrdersEnabled: true,
	placeAndTakeEnabled: false,
	traderProfile: 'passive',
};

//@ts-ignore
export const defaultTradeSettings: Pick<
	UserSettings,
	| 'allowInfSlippage'
	| 'slippageTolerance'
	| 'priorityFee'
	| 'customPriorityFee'
	| 'maxPriorityFeeCap'
	| 'preferredCandleType'
	| 'auctionDuration'
	| 'auctionStartPriceOffset'
	| 'auctionEndPriceOffset'
	| 'auctionStartPriceOffsetFrom'
	| 'auctionEndPriceOffsetFrom'
	| 'oracleOffsetOrdersEnabled'
	| 'customMarginRatio'
	| 'accountSubscriberType'
	| 'eventSubscriberType'
	| 'placeAndTakeEnabled'
	| 'traderProfile'
> = {
	priorityFee: 'dynamic',
	customPriorityFee: undefined,
	maxPriorityFeeCap: DEFAULT_MAX_PRIORITY_FEE_SOL_CAP,
	preferredCandleType: CandleType.FILL_PRICE,
	customMarginRatio: undefined,
	accountSubscriberType: 'polling',
	eventSubscriberType: 'websocket',
	...AGGRO_TRADER_PROFILE,
	// so we can tell if its a user's first time using trading profiles or not
	traderProfile: undefined,
};

export const defaultUserSettings: UserSettings = {
	mainnetOverride: false,
	stagingHistoryServerOverride: false,
	enableVersionedTx: true,
	showPnlOnClose: true,
	includeLeverageInPnl: false,
	showTradeConfirmation: true,
	showAccountValues: true,
	showFillNotifications: true,
	showZeroValues: true,
	blinkOnValueChange: true,
	showPositionsAndOrdersOnChart: false,
	showMarketBar: true,
	loadSavedChartColors: false,
	perpPageTableSizePreference: undefined,
	enableSwapLeverage: false,
	explorer: 'solana',
	ignoreToasts: {
		walletVersionedTxCompatibility: false,
	},
	// </ ignore toast options >
	cancelExistingOrdersOnClosePosition: true,
	rpcEndpoint: undefined,
	directRouteSwaps: false,
	version: undefined,
	theme: null,
	...defaultTradeSettings,
};

export const TRADER_PROFILE_OPTIONS = [
	{
		label: 'Fast trade execution (recommended)',
		description: 'Immediate fills and shorter auction durations',
		value: AGGRO_TRADER_PROFILE,
		key: 'aggressive',
	},
	{
		label: 'Classic',
		description: 'Market orders are routed through standard auctions',
		value: CLASSIC_TRADER_PROFILE,
		key: 'default',
	},
	{
		label: 'Best possible fills',
		description: 'Longer auction duration for better fill prices',
		value: PASSIVE_TRADER_PROFILE,
		key: 'passive',
	},
	{
		label: 'Custom',
		description: 'For advanced traders only',
		value: undefined,
		key: 'custom',
	},
];
