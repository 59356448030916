import { ENUM_UTILS, MarketId } from '@drift/common';
import { GET_API_ROUTE } from 'src/constants/api';
import { DriftClient, OracleSource } from '@drift-labs/sdk';
import {
	OrderedPerpMarkets,
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import { TransactionInstruction } from '@solana/web3.js';

export const getPythPullUpdateIxs = async (
	marketIds: MarketId[],
	driftClient: DriftClient,
	maxCount = 1
): Promise<TransactionInstruction[]> => {
	const feedIds = [
		...new Set(
			marketIds
				.map((marketId) => {
					return marketId.isPerp
						? OrderedPerpMarkets[marketId.marketIndex]
						: OrderedSpotMarkets[marketId.marketIndex];
				})
				.filter((mktConfig) => isPythPull(mktConfig.oracleSource))
				.map((mktConfig) => mktConfig.pythFeedId)
		),
	].slice(0, maxCount);

	if (feedIds.length === 0) {
		console.log('No Pyth Pull oracles to crank');
		return [];
	}

	if (feedIds.length > 2) {
		console.warn(
			'More than 2 Pyth Pull oracles to update before withdrawal, only the first 2 will be updated'
		);
	}

	const response = await fetch(
		`${GET_API_ROUTE('pyth-pull-crank')}?feedIds=${feedIds.join(',')}`
	);

	const result = await response.json();

	if (result.error) {
		console.error(
			`Error getting pyth pull update from HermesClient: `,
			result.error
		);
		return [];
	}

	try {
		const crankIxs = await driftClient.getPostPythPullOracleUpdateAtomicIxs(
			result.data,
			feedIds.slice(0, 2)
		);

		return crankIxs;
	} catch (e) {
		console.error(`Error getting pyth pull crank ix from driftClient: `, e);
		return [];
	}
};

export const isPythPull = (oracleSource: OracleSource) => {
	return (
		ENUM_UTILS.match(OracleSource.PYTH_PULL, oracleSource) ||
		ENUM_UTILS.match(OracleSource.PYTH_1K_PULL, oracleSource) ||
		ENUM_UTILS.match(OracleSource.PYTH_1M_PULL, oracleSource) ||
		ENUM_UTILS.match(OracleSource.PYTH_STABLE_COIN_PULL, oracleSource)
	);
};
