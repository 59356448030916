'use client';

import { useAppKit, useAppKitProvider } from '@reown/appkit/react';
import { WalletAdapter } from '@solana/wallet-adapter-base';
import { useEffect, useRef } from 'react';

/**
 * AppKit doesn't let us "just connect" - it opens a window first where the user has to select a wallet before we are able to trigger our internal connection logic. This hook handles abstracting that away.
 *
 * Takes a callback that will be called when the user has connected with AppKit.
 *
 * Returns a function that should be called when the user wants to connect with AppKit.
 *
 * @returns
 */
const useTryConnectAppKit = ({
	onConnectedCb,
}: {
	onConnectedCb: () => void;
}) => {
	// # Handle AppKitWallet Lifecycle
	//// AppKit doesn't let us detect when connected in a friendly way, these refs and the below effect hook ensure we trigger the necessary logic when the user wants to connect with AppKit
	const hasHandledAppKitConnectRequestRef = useRef(false);
	const { open: openAppKitModal } = useAppKit();

	const appkitWalletProvider = useAppKitProvider<WalletAdapter>('solana');

	const hasClickedConnectAppKitRef = useRef(false);

	const handleConnectAppKit = () => {
		hasClickedConnectAppKitRef.current = true;
		openAppKitModal({ view: 'Connect' });
	};

	/**
	 * Handling for AppKit disconnect events
	 */
	useEffect(() => {
		if (!appkitWalletProvider?.walletProvider) {
			return;
		}

		const handler = () => {
			hasClickedConnectAppKitRef.current = false;
		};

		appkitWalletProvider?.walletProvider.on('disconnect', handler);

		return () => {
			appkitWalletProvider?.walletProvider.removeListener(
				'disconnect',
				handler
			);
		};
	}, [appkitWalletProvider?.walletProvider]);

	/**
	 * Handling for AppKit connection events
	 */
	useEffect(() => {
		if (!appkitWalletProvider?.walletProvider) {
			return;
		}

		const handler = () => {
			if (hasHandledAppKitConnectRequestRef.current) return;
			hasHandledAppKitConnectRequestRef.current = true;
			onConnectedCb();
		};

		appkitWalletProvider?.walletProvider.on('connect', handler);

		return () => {
			appkitWalletProvider?.walletProvider.removeListener('connect', handler);
		};
	}, [appkitWalletProvider?.walletProvider]);

	/**
	 * - Run disconnect if we have a new wallet provider available in context but the user hasn't clicked connect
	 * - Else, if the user has clicked connect, and we have a wallet provider available, trigger the onConnectedCb
	 */
	useEffect(() => {
		if (!appkitWalletProvider.walletProvider) {
			return;
		}

		if (!hasClickedConnectAppKitRef.current) {
			if (appkitWalletProvider.walletProvider) {
				appkitWalletProvider.walletProvider.disconnect();
			}
			return;
		}

		if (hasHandledAppKitConnectRequestRef.current === true) {
			return;
		}

		if (appkitWalletProvider.walletProvider) {
			hasHandledAppKitConnectRequestRef.current = true;
			onConnectedCb();
		}
	}, [appkitWalletProvider]);

	return handleConnectAppKit;
};

export default useTryConnectAppKit;
