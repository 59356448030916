'use client';

import {
	ArrowRight,
	Close,
	ErrorFilled,
	SuccessFilled,
	WarningFilled,
} from '@drift-labs/icons';
import { useEffect } from 'react';
import { useSettingsContext } from 'src/providers/settingsProvider';
import { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import {
	CallbackAction,
	NotificationData,
	getTimeoutNotificationData,
	notify,
} from 'src/utils/notifications';
import { GradientButton } from '../Button';
import CheckboxInput from '../CheckboxInput';
import Toast from './Toast';
import useNotificationStore from '../../stores/useNotificationStore';
import Text from '../Text/Text';
import { twMerge } from 'tailwind-merge';
import ArcLoadingSpinner from '../Icons/LoadingSpinner/ArcLoadingSpinner';
import useAppEventEmitter from 'src/hooks/useAppEventEmitter';
import NotificationLink from './NotificationLink';
import { UserSettings } from 'src/environmentVariables/EnvironmentVariables';
import { IconCircleBg } from '../Icons/IconCircleBg';

const TIMEOUT_FAILSAFE_MS = 50_000;

export const LoadingStep = ({ children }: { children: React.ReactNode }) => {
	return (
		<Text.BODY3 className="flex items-center gap-2">
			<ArcLoadingSpinner size={16} />
			{children}
		</Text.BODY3>
	);
};

export const ConfirmTransactionStep = ({
	subDescription,
}: {
	subDescription?: string;
}) => {
	return (
		<LoadingStep>
			<div className="flex flex-col gap-[2px]">
				<span>Confirm the transaction in your wallet</span>
				{subDescription && (
					<span className="text-text-secondary text-[10px]">
						{subDescription}
					</span>
				)}
			</div>
		</LoadingStep>
	);
};

export const SentTransactionStep = ({
	subDescription,
}: {
	subDescription?: string;
}) => {
	return (
		<LoadingStep>
			<div className="flex flex-col gap-[2px]">
				<span>You have confirmed and sent the transaction</span>
				{subDescription && <span>{subDescription}</span>}
			</div>
		</LoadingStep>
	);
};

const NotificationIcon = ({ type }: { type: NotificationData['type'] }) => {
	switch (type) {
		case 'warning':
			return (
				<IconCircleBg bgColor="bg-brand-yellow-secondary-bg">
					<WarningFilled size={24} />
				</IconCircleBg>
			);
		case 'error':
			return (
				<IconCircleBg bgColor="bg-negative-red-secondary-bg">
					<ErrorFilled size={24} />
				</IconCircleBg>
			);
		case 'success':
			return (
				<IconCircleBg bgColor="bg-positive-green-secondary-bg">
					<SuccessFilled size={24} />
				</IconCircleBg>
			);
		case 'awaiting':
			return (
				<IconCircleBg bgColor="bg-interactive-secondary-bg">
					<ArcLoadingSpinner size={24} />
				</IconCircleBg>
			);
		default:
			return null;
	}
};

const Notification = ({
	index,
	notificationData,
	statusWarning,
	statusType,
}: {
	index: number;
	notificationData: NotificationData;
	statusWarning: DriftStore['driftStatus']['mainStatus']['message'];
	statusType: DriftStore['driftStatus']['mainStatus']['status'];
}) => {
	const notificationStore = useNotificationStore();
	const settingsContext = useSettingsContext();
	const appEventEmitter = useAppEventEmitter();

	const targetIgnoreToastEnabled =
		notificationData.offerIgnoreToast &&
		settingsContext.currentSettings.ignoreToasts[
			notificationData.offerIgnoreToast
		];

	const handleToggleTargetIgnoreToast = () => {
		settingsContext.settingsClient.updateSettings({
			ignoreToasts: {
				...settingsContext.currentSettings.ignoreToasts,
				[notificationData.offerIgnoreToast]:
					!settingsContext.settingsClient.overallSettingsState[
						notificationData.offerIgnoreToast as keyof UserSettings
					],
			},
		});
	};

	const closeNotification = () => {
		notificationStore.removeNotificationByIndex(index);
	};

	const setHovered = () => {
		notificationStore.updateNotificationByIndex(index, {
			isHovered: true,
		});
	};

	const unsetHovered = () => {
		notificationStore.updateNotificationByIndex(index, {
			isHovered: false,
		});
	};

	const {
		message,
		description,
		subDescription,
		steps,
		type,
		customIcon,
		timeoutIfProlongedTxn: timeoutForProlongedTxnFailSafe,
		hideMainIcon,
		toastBadge,
	} = notificationData;

	// this is a failsafe to ensure that if a transaction is taking too long, we update
	// the toast to inform the user that the transaction may have timed out
	useEffect(() => {
		if (timeoutForProlongedTxnFailSafe) {
			const timeout = setTimeout(() => {
				notify({
					...notificationData,
					...getTimeoutNotificationData(appEventEmitter),
					timeoutIfProlongedTxn: false,
				});
			}, TIMEOUT_FAILSAFE_MS);
			return () => clearTimeout(timeout);
		}
	}, [timeoutForProlongedTxnFailSafe]);

	const colourTypeToUse = notificationData.bgType
		? notificationData.bgType
		: notificationData.type;

	return (
		<Toast
			onMouseEnter={setHovered}
			onMouseLeave={unsetHovered}
			type={colourTypeToUse}
			toastBadge={toastBadge}
		>
			<div className="flex items-center w-full gap-4">
				{!hideMainIcon &&
					(customIcon ? customIcon : <NotificationIcon type={type} />)}

				<div className="flex flex-col gap-3 grow w-full">
					<div className={twMerge(`flex justify-between gap-2`)}>
						<div className={twMerge('flex gap-1')}>
							<Text.TITLE4>{message}</Text.TITLE4>
						</div>

						<Close
							color="var(--text-default)"
							onClick={(e) => {
								e.stopPropagation();
								closeNotification();
							}}
						/>
					</div>

					{description && <Text.BODY3>{description}</Text.BODY3>}

					{subDescription && <Text.BODY3>{subDescription}</Text.BODY3>}

					{steps && <>{steps}</>}

					{notificationData.action && (
						<div
							onClick={(e) => {
								e.stopPropagation();
								(notificationData.action as CallbackAction).callback?.();
								closeNotification();
							}}
							className="cursor-pointer"
						>
							{notificationData.action.type === 'link' ||
							notificationData.action.type === 'txnLink' ? (
								<NotificationLink
									linkData={notificationData.action}
									notificationType={notificationData.type}
								/>
							) : notificationData.action.type === 'rainbow-button' ? (
								<>
									<GradientButton size="MEDIUM">
										{notificationData.action.label}
									</GradientButton>
								</>
							) : notificationData.action.type === 'prize-button' ? (
								<>
									<GradientButton type="prize" size="MEDIUM">
										{notificationData.action.label}
									</GradientButton>
								</>
							) : notificationData.action.type === 'points-button' ? (
								<>
									<GradientButton type="points" size="MEDIUM">
										{notificationData.action.label}
									</GradientButton>
								</>
							) : notificationData.action.type === 'custom' ? (
								notificationData.action.content
							) : (
								<Text.BODY3 className="flex items-center gap-1 transition-all text-text-default hover:brightness-125">
									<span>{notificationData.action.label}</span>
									<ArrowRight size={12} />
								</Text.BODY3>
							)}
						</div>
					)}

					{statusWarning && (
						<div className="flex items-center space-x-4 text-xs text-neutrals-40">
							<div
								className="relative flex items-center justify-center flex-shrink-0"
								style={{ width: '26px', height: '10px' }}
							>
								<div
									className={`w-2 h-2 rounded-full ${
										statusType === 'green'
											? 'bg-positive-green'
											: statusType === 'amber'
											? 'bg-warn-yellow'
											: 'bg-negative-red'
									}`}
								/>
							</div>
							<div className={`flex flex-grow space-y-2`}>{statusWarning}</div>
						</div>
					)}

					{notificationData.offerIgnoreToast && (
						<div
							className="flex items-left"
							onClick={(e) => {
								// Things like attached URLs work if any click event bubbles up, we want to prevent that for this part of the toast.
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<CheckboxInput
								label="Don't show again"
								checked={targetIgnoreToastEnabled}
								onChange={handleToggleTargetIgnoreToast}
								secondaryStyle
							/>
						</div>
					)}

					{notificationData.bottomContent && (
						<>{notificationData.bottomContent}</>
					)}
				</div>
			</div>
		</Toast>
	);
};

export default Notification;
