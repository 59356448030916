import { MarketId } from '@drift/common';

export type PreLaunchMarketResource = {
	marketId: MarketId;
	startTs: number;
	endTs: number;
};

export const PRELAUNCH_MARKET_RESOURCE_CONSTANTS: {
	devnet: PreLaunchMarketResource[];
	mainnet: PreLaunchMarketResource[];
} = {
	devnet: [
		{
			marketId: MarketId.createPerpMarket(23),
			startTs: new Date('2024-03-01T00:00:00Z').getTime(),
			endTs: new Date('2024-04-01T00:00:00Z').getTime(),
		},
	],
	mainnet: [
		{
			marketId: MarketId.createPerpMarket(27),
			startTs: new Date('2024-03-19T00:00:00Z').getTime(),
			endTs: new Date('2024-03-20T00:00:00Z').getTime(),
		},
		{
			marketId: MarketId.createPerpMarket(31),
			startTs: new Date('2024-06-05T18:45:00Z').getTime(),
			endTs: new Date('2024-06-06T18:45:00Z').getTime(),
		},
		{
			marketId: MarketId.createPerpMarket(32),
			startTs: new Date('2024-06-10T16:30:00Z').getTime(),
			endTs: new Date('2024-06-11T00:00:00Z').getTime(),
		},
		{
			marketId: MarketId.createPerpMarket(33),
			startTs: new Date('2024-06-26T20:00:00Z').getTime(),
			endTs: new Date('2024-06-27T00:00:00Z').getTime(),
		},
		{
			marketId: MarketId.createPerpMarket(69),
			startTs: new Date('2025-02-13T00:00:00Z').getTime(),
			endTs: new Date('2025-02-20T00:00:00Z').getTime(),
		},
	],
};

export const PRE_MARKET_ACKNOWLEDGED_WARNING_KEY =
	'pre_market_acknowledged_warning';
