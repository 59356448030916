/* eslint-disable react/prop-types */

import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export interface RoundedGradientBorderBoxProps {
	className?: string;
	borderColour?: string;
	borderWidth?: string;
	borderHoverClass?: string;
	borderRadius?: string;
	active?: boolean;
	style?: React.CSSProperties;
	onClick?: () => void;
	leftOnly?: boolean;
	rightOnly?: boolean;
	topOnly?: boolean;
	bottomOnly?: boolean;
	disabled?: boolean;
	id?: string;
	shadow?: boolean;
	bgColor?: string;
	children?: React.ReactNode;
	disableGradientBorder?: boolean;
}

const Wrapper = styled.div<RoundedGradientBorderBoxProps>`
	background-clip: padding-box;
	border-radius: ${(props) => props.borderRadius};
	background: ${(props) =>
		props.bgColor ?? props.active
			? props.borderHoverClass ?? props.borderColour
			: props.borderColour};
	${(props) => (props.shadow ? 'position: relative;' : '')};
	${(props) =>
		props.leftOnly
			? 'padding-left'
			: props.rightOnly
			? 'padding-right'
			: props.topOnly
			? 'padding-top'
			: props.bottomOnly
			? 'padding-bottom'
			: 'padding'}: ${(props) => props.borderWidth};

	> * {
		border-radius: ${(props) => props.borderRadius};
	}

	&:hover {
		background: ${(props) => props.borderHoverClass ?? props.borderColour};
	}

	${(props) =>
		props.shadow &&
		`
      &::before {
        content: '';
        position: absolute;
        inset: -1px; /* control the spread */
        pointer-events: none; /* Makes the shadow non-interactive */
        z-index: -1;
        filter: blur(4px); /* control the blur */
        opacity: 0.5;
        border-radius: ${props.borderRadius};
        background: ${props.borderColour};
      }
    `}
`;

/**
 * Wrapper component which displays a border around the child elements, with given border specifications. This utility class enables a rounded border which changes on hover.
 * @param props
 * @returns
 */
const RoundedGradientBorderBox = React.forwardRef<
	HTMLDivElement,
	RoundedGradientBorderBoxProps
>(
	(
		{
			borderColour = `var(--app-gradient)`,
			borderWidth = '1px',
			borderRadius = '0.5rem',
			borderHoverClass = `var(--app-gradient)`,
			shadow = false,
			children,
			...props
		}: PropsWithChildren<RoundedGradientBorderBoxProps>,
		ref
	) => {
		return props.disableGradientBorder ? (
			children
		) : (
			<Wrapper
				ref={ref}
				borderHoverClass={borderHoverClass}
				borderRadius={borderRadius}
				borderColour={borderColour}
				borderWidth={borderWidth}
				shadow={shadow}
				className={twMerge('overflow-hidden', props.className)}
				{...props}
			>
				{children}
			</Wrapper>
		);
	}
);

RoundedGradientBorderBox.displayName = 'RoundedGradientBorderBox';

export default RoundedGradientBorderBox;
