"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultEventSubscriptionOptions = void 0;
exports.DefaultEventSubscriptionOptions = {
    eventTypes: [
        'DepositRecord',
        'FundingPaymentRecord',
        'LiquidationRecord',
        'OrderRecord',
        'OrderActionRecord',
        'FundingRateRecord',
        'NewUserRecord',
        'SettlePnlRecord',
        'LPRecord',
        'InsuranceFundRecord',
        'SpotInterestRecord',
        'InsuranceFundStakeRecord',
        'CurveRecord',
        'SwapRecord',
        'SpotMarketVaultDepositRecord',
        'SignedMsgOrderRecord',
        'DeleteUserRecord',
        'FuelSweepRecord',
        'FuelSeasonRecord',
    ],
    maxEventsPerType: 4096,
    orderBy: 'blockchain',
    orderDir: 'asc',
    commitment: 'confirmed',
    maxTx: 4096,
    logProviderConfig: {
        type: 'websocket',
    },
};
