const {
	allColors,
	generatedSemanticClasses,
	brandGradientStops,
} = require('./figmaGenerated');

const manualSemanticClasses = {
	'app-gradient': 'var(--app-gradient)',
	'input-border-hover': 'var(--input-border-hover)',
	'icon-default': 'var(--icon-default)',
	'icon-selected': 'var(--icon-selected)',
	'meme-bg': 'var(--meme-bg)',
};

const semanticClasses = {
	...manualSemanticClasses,
	...generatedSemanticClasses,
};

// TODO: check if all in use
const gradients = {
	'primary-gradient': `linear-gradient(90deg, ${brandGradientStops['0']} 0%, ${brandGradientStops['50']} 50%, ${brandGradientStops['100']} 100%)`,
	'brand-gradient': `linear-gradient(
			114.67deg,
			rgba(255, 255, 255, 0.2) 16.59%,
			rgba(0, 0, 0, 0) 56.74%
		),
		linear-gradient(137.87deg, #f6f063 00%, rgba(224, 119, 116, 0) 30%),
		linear-gradient(83.36deg, #ff3873 3.72%, #9162f6 46.75%, #3fe5ff 94.51%)`,
	'light-app-gradient':
		'linear-gradient(90deg, #F7648F 0%, #9162F6 50%, #11C7E3 100%)',
	'dark-app-gradient':
		'linear-gradient(90deg, #e8a2a0 0%, #9468f1 50%, #71cce9 100%)',
	'icon-gradient':
		'linear-gradient(111deg, #E8A2A0 0%, #9468F1 50%, #71CCE9 100%)',
	'buy-gradient': 'linear-gradient(91.2deg, #34CB88 0.14%, #29B074 99.86%)',
	'sell-gradient': 'linear-gradient(90deg, #E54D48 0%, #C94641 100%)',
	'prize-gradient':
		'linear-gradient(96deg, #FFDEAD -0.21%, #FFCF52 53.39%, #FCA239 106.99%)',
	'alpha-program-gradient': 'linear-gradient(90deg, #009AB2 0%, #10243E 100%)',
	// need to include both variants as linear-gradient's can't be saved to css variables
	'negative-bg-gradient-dark-theme':
		'linear-gradient(90deg, rgba(255, 136, 127, 0.15) 0%, rgba(255, 136, 127, 0) 100%), #080F1800',
	'negative-bg-gradient-light-theme':
		'linear-gradient(90deg, rgba(255, 136, 127, 0.15) 0%, rgba(255, 136, 127, 0) 100%), rgba(255, 255, 255, 0)',
	'warning-bg-gradient-dark-theme':
		'linear-gradient(90deg, rgba(242, 201, 76, 0.2) 0%, rgba(242, 201, 76, 0) 100%), #080F1800',
	'warning-bg-gradient-light-theme':
		'linear-gradient(90deg, rgba(242, 201, 76, 0.2) 0%, rgba(242, 201, 76, 0) 100%), #080F1800',
	'faq-bg-gradient-dark-theme':
		'radial-gradient(ellipse at top, rgba(25, 29, 44, 0.5) 0%, rgba(25, 29, 44, 0) 100%)',
	'faq-bg-gradient-light-theme':
		'radial-gradient(ellipse at top, rgba(222, 235, 248, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',
	'dark-fuel-gradient':
		'linear-gradient(181deg, #132236 0%, #F3ABFF 41%, #69EBFF 62%, #132236 84%)',
	'light-fuel-gradient':
		'linear-gradient(181deg, #97B1D2 0%, #F3ABFF 41%, #69EBFF 62%, #97B1D2 84%)',
	'dark-swift-gradient': 'linear-gradient(90deg, #C046FA 0%, #4070FF 100%)',
	'light-swift-gradient': 'linear-gradient(90deg, #C046FA 0%, #4070FF 100%)',
};

module.exports = {
	...require('./figmaGenerated'),
	allColors,
	semanticClasses,
	gradients,
	brandGradientStops,
};
