'use client';
import posthog, { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactNode, useEffect } from 'react';
import { dlog } from '../../dev';
import { POSTHOG_CONFIG } from './posthogConfig';
import { DriftWindow } from 'src/window/driftWindow';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const MOBILE_WALLET_APP_NAMES = {
	PHANTOM: 'PHANTOM',
	SOLFLARE: 'SOLFLARE',
	BACKPACK: 'BACKPACK',
	GLOW: 'GLOW',
};

const ENABLE_PERSISTENCE = false;

const isMobileWalletApp = () => {
	const userAgent = navigator.userAgent.toLowerCase();

	for (const mobileApp in MOBILE_WALLET_APP_NAMES) {
		if (userAgent.includes(mobileApp.toLowerCase())) {
			return true;
		}
	}

	return false;
};

const getOptionalPosthogParams = () => {
	const postHogParams: Partial<PostHogConfig> = {
		enable_recording_console_log: POSTHOG_CONFIG.consoleRecordingEnabled,
		session_recording: POSTHOG_CONFIG.sessionRecordingEnabled ? {} : undefined,
		capture_pageview: POSTHOG_CONFIG.capturePageViewEnabled,
		capture_pageleave: POSTHOG_CONFIG.capturePageViewEnabled,
		disable_session_recording: POSTHOG_CONFIG.sessionRecordingEnabled
			? undefined
			: true, // If session recording is enabled, then undefined so we ignore this config. If session recording DISABLED, then we set this to true. Session recording can be turned on again using posthog.startSessionRecording, controlled by some dev events
	};

	return postHogParams;
};

const alreadyEnabledPosthog = { current: false };

export default function PHProvider({ children }: { children: ReactNode }) {
	const appEventEmitter = useDriftStore((s) => s.appEventEmitter);

	useEffect(() => {
		if (alreadyEnabledPosthog.current) return;
		if (!appEventEmitter) return;

		dlog(`posthog`, `posthog_enabled`);

		alreadyEnabledPosthog.current = true;

		posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
			api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
			persistence: ENABLE_PERSISTENCE ? 'localStorage+cookie' : 'memory',
			loaded: (posthog) => {
				posthog.setPersonPropertiesForFlags({
					$current_url: window.location.href,
				});

				posthog.onFeatureFlags((flags) => {
					dlog(`feature_flags`, `onFeatureFlags`, flags);

					appEventEmitter.emit('featureFlagsLoaded', flags);
				});
			},
			autocapture: false,
			...getOptionalPosthogParams(),
		});
	}, [appEventEmitter]);

	useEffect(() => {
		DriftWindow.devEventEmitter.on(
			'set_session_recording',
			(enabled: boolean) => {
				if (enabled) {
					dlog(`posthog`, `session_recording_enabled`);
					posthog.startSessionRecording();
				} else {
					dlog(`posthog`, `session_recording_disabled`);
					posthog.stopSessionRecording();
				}
			}
		);
	}, []);

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

export const POSTHOG_UTILS = {
	isMobileWalletApp,
};
