import { Wallet } from '@passkeys/core';
import {
	BaseMessageSignerWalletAdapter,
	WalletName,
	WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
	PublicKey,
	Transaction,
	TransactionVersion,
	VersionedTransaction,
} from '@solana/web3.js';
import {
	DRIFT_CUSTOM_WALLET_ICONS,
	DRIFT_CUSTOM_WALLET_OPTIONS,
} from 'src/constants/wallets';

type LegacyOrVersionedTransaction = Transaction | VersionedTransaction;

export class PasskeysAdapter extends BaseMessageSignerWalletAdapter {
	public name =
		DRIFT_CUSTOM_WALLET_OPTIONS.PASSKEYS_WALLET_ADAPTER_NAME as WalletName;
	public icon = DRIFT_CUSTOM_WALLET_ICONS.PASSKEYS_WALLET_ADAPTER;
	public url = 'https://passkeys.foundation';
	public readyState = WalletReadyState.Loadable;
	public connecting = false;

	public get publicKey(): PublicKey | null {
		return this.provider.publicKey;
	}

	public get supportedTransactionVersions(): ReadonlySet<TransactionVersion> {
		return this.provider.supportedTransactionVersions;
	}

	private provider: Wallet['providers']['solana'];

	public constructor(wallet: Wallet) {
		super();
		this.provider = wallet.providers.solana;
	}

	public signTransaction = <T extends LegacyOrVersionedTransaction>(
		unsignedTx: T
	): Promise<T> => this.provider.signTransaction(unsignedTx);

	public signAllTransactions = <T extends LegacyOrVersionedTransaction>(
		unsignedTxs: T[]
	): Promise<T[]> => this.provider.signAllTransactions(unsignedTxs);

	public signMessage = async (message: Uint8Array): Promise<Uint8Array> => {
		const { signature } = await this.provider.signMessage(message);
		return signature;
	};

	public autoConnect = async (): Promise<void> => this.connect();

	public connect = async (): Promise<void> => {
		this.connecting = true;

		try {
			const { publicKey } = await this.provider.connect();
			this.emit('connect', publicKey);
		} catch (error: any) {
			this.emit('error', error);
			throw error;
		} finally {
			this.connecting = false;
		}
	};

	public disconnect = async () => {
		await this.provider.disconnect();
		this.emit('disconnect');
	};
}

const createPasskeysAdapter = (wallet: Wallet) => {
	const adapter = new PasskeysAdapter(wallet);
	return adapter;
};

export default createPasskeysAdapter;
