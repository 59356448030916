import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { Checkmark } from '@drift-labs/icons';

type CheckboxProps = {
	checked: boolean;
	checkedColor?: string;
	useCustomIcon?: boolean;
	className?: string;
};

const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
	return (
		<div
			className={twMerge(
				`w-4 h-4 bg-main-bg transition-all border rounded-sm`,
				props.checked
					? 'border-transparent bg-purple-50'
					: 'border-text-secondary hover:bg-input-bg-hover',
				props.className
			)}
		>
			<div className="inline-flex items-center justify-center w-full h-full">
				{props.useCustomIcon
					? props.children
					: props.checked && (
							<Checkmark
								className={`w-full h-full`}
								color="var(--container-bg)"
							/>
					  )}
			</div>
		</div>
	);
};

export const CheckboxSmallSecondary = (
	props: PropsWithChildren<CheckboxProps>
) => {
	return (
		<div
			className={twMerge(
				`w-[13px] h-[13px] relative top-[-1px] bg-main-bg transition-all border rounded-sm`,
				props.checked && props.checkedColor
					? props.checkedColor
					: 'border-text-label',
				props.className
			)}
		>
			<div className="flex items-center justify-center w-full h-full">
				{props.useCustomIcon
					? props.children
					: props.checked && (
							<Checkmark
								className={`${
									props.checked && props.checkedColor
										? props.checkedColor
										: 'text-text-label'
								} transition-all w-full h-full`}
								color={
									props.checked && props.checkedColor
										? props.checkedColor
										: 'var(--text-label)'
								}
							/>
					  )}
			</div>
		</div>
	);
};

export default Checkbox;
