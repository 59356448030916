import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	BulkAccountLoader,
	DriftClient,
	DriftClientConfig,
	DriftEnv,
	FOUR,
	getLimitOrderParams,
	getTriggerLimitOrderParams,
	getTriggerMarketOrderParams,
	getUserStatsAccountPublicKey,
	IWallet,
	JupiterClient,
	MakerInfo,
	MarketType,
	MAX_LEVERAGE_ORDER_SIZE,
	OptionalOrderParams,
	OrderStatus,
	OrderTriggerCondition,
	OrderType,
	PositionDirection,
	PostOnlyParams,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	promiseTimeout,
	QUOTE_PRECISION_EXP,
	QuoteResponse,
	ReferrerInfo,
	SpotMarketConfig,
	SwapMode,
	TokenFaucet,
	TWO,
	TxParams,
	User,
	UserAccount,
	Wallet,
	ZERO,
	WhileValidTxSender,
	RetryTxSender,
	ForwardOnlyTxSender,
	oraclePriceBands,
	OraclePriceData,
	SpotMarketAccount,
	SettlePnlMode,
	OneShotUserAccountSubscriber,
	getUserAccountPublicKeySync,
	UserStatsAccount,
	TransactionConfirmationManager,
	MarginMode,
	DelistedMarketSetting,
	UserStats,
	PerpMarketAccount,
	digestSignature,
	OrderParams,
	getSignedMsgUserAccountPublicKey,
} from '@drift-labs/sdk';
import { SnapWalletAdapter } from '@drift-labs/snap-wallet-adapter';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	EnvironmentConstants,
	MarketId,
	matchEnum,
	OpenPosition,
	UI_ORDER_TYPES,
	UIMarket,
	UIOrderType,
	UIOrderTypeValue,
	UISerializableOrder,
	EMPTY_AUCTION_PARAMS,
	RpcEndpoint,
	ACTION_HELPERS,
	COMMON_UTILS,
	AuctionParams,
	MAIN_POOL_ID,
} from '@drift/common';
import {
	BaseMessageSignerWalletAdapter,
	MessageSignerWalletAdapter,
	WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
	ConfirmOptions,
	Connection,
	Keypair,
	LAMPORTS_PER_SOL,
	PublicKey,
	Signer,
	StakeProgram,
	Transaction,
	TransactionInstruction,
	TransactionSignature,
	VersionedTransaction,
} from '@solana/web3.js';
import bs58 from 'bs58';
import { useRouter } from 'next/navigation';
import { ComputeUnits, DEFAULT_PUBLIC_KEY } from 'src/@types/types';
import Button from 'src/components/Button';
import Env, {
	SubscriberType,
	CurrentPerpMarkets,
	CurrentSpotMarkets,
	isDev,
	DEFAULT_LIMIT_AUCTION_DURATION,
	DEFAULT_MARKET_AUCTION_DURATION,
	OrderedPerpMarkets,
	OrderedSpotMarkets,
	SPOT_MARKETS_LOOKUP,
	syncGetCurrentSettings,
	PERP_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import { invertPriceImpact, PriceImpactInfo } from 'src/hooks/usePriceImpact';
import {
	ACCOUNT_INITIAL_STATE,
	AccountData,
	DriftAccountsStore,
	INITIAL_MARGIN_INFO,
} from 'src/stores/useDriftAccountsStore';
import { setLocalStorageItem } from 'src/utils/localStorageUtils';
import captureException from 'src/utils/captureException';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import createMagicWalletAdapter, {
	MagicAuthLoginOptions,
} from 'src/utils/magicAuthAdapter';
import { notify } from 'src/utils/notifications';
import NumLib from 'src/utils/NumLib';
import TransactionErrorHandler, {
	AnchorError,
	tryGetDriftErrorCode,
} from 'src/utils/TransactionErrorHandler';
import UI_UTILS from 'src/utils/uiUtils';
import {
	CUSTOM_RPC_LABEL,
	DEFAULT_ACCOUNT_NAMES_BY_POOL_ID,
	MAX_COMPUTE_UNITS,
	NEW_ACCOUNT_DONATION,
	PREDICTION_MARKET_AUCTION_PRICE_CAPS,
	SETTLE_MULTIPLE_PNL_ERROR_TYPES,
	TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL,
} from '../../constants/constants';
import {
	DRIFT_CUSTOM_WALLET_OPTIONS,
	WALLETS_WITH_NO_SIGN_MESSAGE_METHOD,
} from 'src/constants/wallets';
import { DriftCustomWalletName } from 'src/constants/wallets';
import { MarginInfo } from '../driftStoreTypes';
import { DriftStore } from './useDriftStore';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import { DerivedMarketTradeState } from '../useMarketStateStore';
import { WalletBalancesStore } from '../useWalletBalancesStore';
import { DriftModalKey, DriftStoreModals } from './driftStoreTypes';
import { IfDataStore } from '../ifDataStore/useIfDataStore';
import { dlog } from '../../dev';
import { orderTypeSwitch } from 'src/utils/trade';
import { AuctionToastEvent } from '../../utils/DriftAppEvents';
import { TEST_ENV } from '../../environmentVariables/testEnvironmentVariables';
import { PriorityFeeStore } from '../usePriorityFeeStore';
import { DriftWindow } from '../../window/driftWindow';
import { generateAccountLoader } from './utils';
import { SettledPnlAlertContent } from 'src/components/Toasts/SettledPnlAlert';
import {
	ConfirmTransactionStep,
	LoadingStep,
	SentTransactionStep,
} from 'src/components/Toasts/Notification';
import {
	FinalPreppedMarketOrderProps,
	ORDER_PREP_UTILS,
	PerpTradeFormOutputProps,
	SpotTradeFormOutputProps,
	TRADE_PREP_UTILS,
} from './actions/actionHelpers/orderPrep';
import Select from 'src/components/Inputs/Select';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { DEPOSIT_TO_TRADE_CONFIG } from 'src/constants/featureConfigs/depositToTradeConfig';
import { GLOBAL_SETTINGS_CLIENT } from 'src/providers/settingsProvider';
import { PostHogCapture } from 'src/hooks/posthog/usePostHogCapture';
import {
	calculatePriceDistribution,
	calculateScaledOrderSizes,
} from 'src/utils/scaledOrders';
import {
	Vault,
	VAULT_PROGRAM_ID,
	VaultClient,
	VaultDepositor,
	VaultDepositorAccount,
	WithdrawUnit,
} from '@drift-labs/vaults-sdk';
import { safeCheckDevSwitchIsOn } from 'src/hooks/useDevSwitchIsOn';
import {
	InvariantCheckingContext,
	InvariantResult,
} from 'src/utils/UIInvariants/types';
import { InvariantChecker } from 'src/utils/UIInvariants/InvariantChecker';
import { INVARIANT_CHECKER_INPUT_IDS } from 'src/utils/UIInvariants/constants';
import posthog from 'posthog-js';
import { POSTHOG_ERROR_TYPES } from 'src/providers/posthog/constants';
import SwiftClient from 'src/utils/swiftClient';
import createAppKitAdapter from '../../utils/AppKitAdapter';
import createPasskeysAdapter from 'src/utils/PasskeysAdapter';
import { PASSKEYS_WALLET_INSTANCE } from '../../utils/passkeysWalletSetup';
import { prepSignedMsgOrder, signOrderMsg } from 'src/utils/signedMsgs';
import { MSG_SIGNING_BEFORE_AUCTION_END_SLOT_BUFFER } from 'src/constants/signedOrderMsgs';
import { isAuctionEmpty } from 'src/utils/auctions';
import { SwiftToastBadge } from 'src/components/Toasts/MarketOrders/SwiftToastBadge';
import { ErrorFilled } from '../../../../drift-common/icons/dist/icons';
import { IconCircleBg } from 'src/components/Icons/IconCircleBg';
import { SlotExpiryProgress } from 'src/components/Utils/SlotExpiryProgress';

const DRIFT_CLIENT_OPTS: ConfirmOptions = {
	skipPreflight: false,
	commitment: DEFAULT_COMMITMENT_LEVEL,
	preflightCommitment: DEFAULT_COMMITMENT_LEVEL,
};

// # Set tx sender to use
type TxSenderType =
	| 'while_valid_tx_sender'
	| 'retry_tx_sender'
	| 'forward_only_tx_sender';
const FALLBACK_DEFAULT_TX_SENDER_TYPE: TxSenderType = 'while_valid_tx_sender';
const txSenderTypeToUse: TxSenderType = TEST_ENV.TEST_TX_FORWARDING
	? 'forward_only_tx_sender'
	: process.env.NEXT_PUBLIC_TX_SENDER_TYPE === 'while_valid_tx_sender'
	? 'while_valid_tx_sender'
	: process.env.NEXT_PUBLIC_TX_SENDER_TYPE === 'retry_tx_sender'
	? 'retry_tx_sender'
	: process.env.NEXT_PUBLIC_TX_SENDER_TYPE === 'forward_only_tx_sender'
	? 'forward_only_tx_sender'
	: FALLBACK_DEFAULT_TX_SENDER_TYPE;

const TX_SENDER_TO_USE =
	txSenderTypeToUse === 'while_valid_tx_sender'
		? WhileValidTxSender
		: txSenderTypeToUse === 'forward_only_tx_sender'
		? ForwardOnlyTxSender
		: RetryTxSender;

// A place to store the earliest time that we start prepping transactions that we want to track these metrics for. We already have utils to time how long the SDK takes to prepare a transaction, but for SOME transactions we do some client-side prep work before we call the SDK so this is how we can stitch the two together
const TX_PREP_TIME_METRIC_START_TIME_CACHE = new Map<string, number>();

const updateAfterTx = (driftClient: DriftClient, user: User) => {
	setTimeout(() => {
		user.fetchAccounts();
		driftClient.fetchAccounts();
	}, 400);
};

const doInvariantChecks = (
	invariantResults: InvariantResult[],
	context: {
		marketIndex: number;
		marketType: MarketType;
		orderType: OrderType;
		direction: PositionDirection;
	},
	failureDescription?: string
): {
	success: boolean;
} => {
	try {
		const result = InvariantChecker.checkInvariants(invariantResults);
		const isDevMode = safeCheckDevSwitchIsOn();

		if (!result.passed) {
			const contextMessage = `${ENUM_UTILS.toStr(context.marketType)}_${
				context.marketIndex
			}_${context.orderType}_${context.direction}`;

			posthog.capture(POSTHOG_ERROR_TYPES.INVARIANT_CHECK_FAILED, {
				env: Env.localEnv,
				description: `Description: ${result.message} :: Context: ${contextMessage}`,
			});

			if (isDevMode) {
				// Developer-facing invariant failure
				// Report invariant error to developer
				notify({
					type: 'error',
					message: `Invariant Check Failed : ${result.message}`,
				});
			} else {
				// Handle user-facing invariant failure
				// Report a generic warning
				notify({
					type: 'warning',
					message:
						(failureDescription
							? failureDescription
							: 'Something went wrong preparing your transaction.') +
						' Please try again, or refresh the page if this continues.',
				});
			}
			return;
		} else {
			if (isDevMode) {
				notify({
					type: 'info',
					message: 'Invariant check passed',
				});
			}
		}

		if (result.passed && DriftWindow.forceInvariantCheckFailure) {
			posthog.capture(POSTHOG_ERROR_TYPES.INVARIANT_CHECK_FAILED, {
				env: Env.localEnv,
				description: `DEV forced invariant failure`,
			});

			notify({
				type: 'warning',
				message: 'Triggering forced invariant check failure',
			});
			return {
				success: false,
			};
		}

		return {
			success: result.passed,
		};
	} catch (e: any) {
		console.error(
			`Error checking invariants. Returning success true to not block User.`,
			e
		);
		return {
			success: true,
		};
	}
};

const doInvariantChecksForContext = (
	context: InvariantCheckingContext,
	orderParams: OptionalOrderParams,
	{
		maxLeverageSelected,
	}: {
		maxLeverageSelected: boolean;
	}
) => {
	/**
	 * Currently implemented for Asset Types:
	 * - Perp Markets
	 * - Prediction Markets
	 *
	 * Currently implemented for Order Types:
	 * - Market (and ORACLE Market)
	 * - Limit
	 *
	 * TODO:
	 * - [ ] Spot Markets
	 * - [ ] Other order types
	 * - [ ] Other contexts (e.g. close position popup)
	 */
	if (
		(context === 'prediction-trade-form' || context === 'perp-trade-form') &&
		(ENUM_UTILS.match(orderParams.orderType, OrderType.MARKET) ||
			ENUM_UTILS.match(orderParams.orderType, OrderType.LIMIT) ||
			ENUM_UTILS.match(orderParams.orderType, OrderType.ORACLE))
	) {
		const result = doInvariantChecks(
			[
				InvariantChecker.checkMarketAgainstCurrentUrlInvariant({
					marketIndex: orderParams.marketIndex,
				}),
				InvariantChecker.checkBaseInvariant(
					orderParams.baseAssetAmount,
					maxLeverageSelected,
					InvariantChecker.getIdQuerySelector(
						INVARIANT_CHECKER_INPUT_IDS.baseSizeInput
					),
					context
				),
				InvariantChecker.checkOrderDirectionInvariant(
					orderParams.direction,
					context
				),
			],
			{
				marketIndex: orderParams.marketIndex,
				marketType: orderParams.marketType,
				orderType: orderParams.orderType,
				direction: orderParams.direction,
			}
		);

		return result;
	}

	return {
		success: true,
	};
};

const getOrderToastMessage = ({
	marketId,
	stage,
	baseSize,
	direction,
	orderType,
	marketName,
	priceOne,
	priceTwo,
	isSpot,
	editingOrder,
	numOrders,
	stepSize,
}: {
	marketId: MarketId;
	stage: 'placing' | 'placed';
	baseSize: BigNum;
	stepSize: BN;
	direction: DriftStore['tradeForm']['side'];
	orderType: DriftStore['tradeForm']['orderType'];
	marketName: string;
	priceOne?: number;
	priceTwo?: number;
	isSpot?: boolean;
	editingOrder?: boolean;
	numOrders?: number;
}): {
	message: string;
	description: React.ReactNode;
} => {
	const isPredictionMarket = UIMarket.fromMarketId(marketId).isPredictionMarket;

	const message = `${
		stage === 'placing'
			? editingOrder
				? 'Editing'
				: 'Placing'
			: editingOrder
			? 'Edited'
			: 'Placed'
	} ${
		numOrders
			? `${numOrders} Orders`
			: `${UI_ORDER_TYPES[orderType].label} Order`
	}`;

	const formattedDirection = isSpot
		? direction.charAt(0).toUpperCase() + direction.slice(1)
		: direction === 'buy'
		? isPredictionMarket
			? 'Yes'
			: 'Long'
		: isPredictionMarket
		? 'No'
		: 'Short';

	const roundedBaseSize = UI_UTILS.getBigNumRoundedToStepSize(
		baseSize,
		stepSize
	);

	const formattedBaseSize = roundedBaseSize.prettyPrint();

	const formattedPriceOne = NumLib.formatNum.toTradePrecision(priceOne ?? 0);
	const formattedPriceTwo = NumLib.formatNum.toTradePrecision(priceTwo ?? 0);

	const orderDescriptionEnd = orderTypeSwitch(orderType, {
		market: ``,
		limit: ` with Limit Price $${formattedPriceOne}`,
		stopMarket: ` with Trigger Oracle Price $${formattedPriceOne}`,
		stopLimit: ` with Trigger Oracle Price $${formattedPriceOne} and Limit Price $${formattedPriceTwo}`,
		takeProfitMarket: ` with Trigger Oracle Price $${formattedPriceOne}`,
		takeProfitLimit: ` with Trigger Oracle Price $${formattedPriceOne} and Limit Price $${formattedPriceTwo}`,
		oracle: ``,
		oracleLimit: ``,
		scaledOrders: `with Limit Prices from $${formattedPriceOne} to $${formattedPriceTwo}`,
	});

	if (isPredictionMarket) {
		return {
			message,
			description: (
				<>
					{formattedBaseSize}{' '}
					<span
						className={twMerge(
							direction === 'buy' ? 'text-positive-green' : 'text-negative-red'
						)}
					>
						{formattedDirection}
					</span>{' '}
					Shares for{' '}
					{PredictionMarketConfigs.get(marketId.marketIndex).shortTitle}{' '}
					{orderDescriptionEnd}
				</>
			),
		};
	}

	return {
		message,
		description: (
			<>
				<span
					className={twMerge(
						direction === 'buy' ? 'text-positive-green' : 'text-negative-red'
					)}
				>
					{formattedDirection}
				</span>{' '}
				{formattedBaseSize} {marketName} {orderDescriptionEnd}
			</>
		),
	};
};

export const userAccountDataHandler = {
	initialData: (): MarginInfo => {
		return INITIAL_MARGIN_INFO();
	},
	newData: (userAccount: User): Omit<MarginInfo, 'totalUnsettledPnl'> => {
		const collateral = userAccount.getNetSpotMarketValue();
		const unrealisedFundingPnL = userAccount.getUnrealizedFundingPNL();
		const unrealisedPnL = userAccount.getUnrealizedPNL();
		const userTotalCollateral = userAccount.getTotalCollateral();
		const userTotalMaintenanceCollateral =
			userAccount.getTotalCollateral('Maintenance');
		const userFreeCollateral = userAccount.getFreeCollateral();
		const maintenanceFreeCollateral =
			userAccount.getFreeCollateral('Maintenance');
		const maintenanceReq = userAccount.getMaintenanceMarginRequirement();
		const initialReq = userAccount.getInitialMarginRequirement();
		const netUsdValue = collateral.add(unrealisedPnL);
		const userLeverage = userAccount?.getLeverage();
		let userMarginRatio = userAccount?.getMarginRatio();

		// weird to display massive fraction when really it just means that their position is zero
		if (userMarginRatio.eq(new BN(Number.MAX_SAFE_INTEGER)))
			userMarginRatio = ZERO;

		return {
			netUsdValue,
			collateral,
			unrealisedPnL,
			unrealisedFundingPnL,
			totalCollateral: userTotalCollateral,
			totalMaintenanceCollateral: userTotalMaintenanceCollateral,
			freeCollateral: userFreeCollateral,
			maintenanceFreeCollateral,
			leverage: BigNum.from(userLeverage, FOUR).toNum(),
			marginRatioPct: BigNum.from(userMarginRatio, TWO).toNum(),
			maintenanceReq,
			initialReq,
			quoteInOpenOrders: ZERO,
			quoteInLpOrders: ZERO,
		};
	},
};

type EmitterHandler<T> = (data: T) => void;
type DriftClientTxSignedHandler = EmitterHandler<
	DriftClient['metricsEventEmitter'][' _emitType']['txSigned']
>;

const PREVIOUS_TX_SIGNED_HANDLER: {
	current: DriftClientTxSignedHandler;
} = {
	current: undefined,
};

const addTxSignedEventHandler = (
	driftClient: DriftClient,
	handler: DriftClientTxSignedHandler
) => {
	if (PREVIOUS_TX_SIGNED_HANDLER.current) {
		removeTxSignedEventHandler(driftClient, PREVIOUS_TX_SIGNED_HANDLER.current);
	}

	driftClient.metricsEventEmitter.on('txSigned', handler);
	PREVIOUS_TX_SIGNED_HANDLER.current = handler;
};

const removeTxSignedEventHandler = (
	driftClient: DriftClient,
	handler: DriftClientTxSignedHandler
) => {
	driftClient.metricsEventEmitter.off('txSigned', handler);
	PREVIOUS_TX_SIGNED_HANDLER.current = undefined;
};

type TxMetricsOpts = {
	measurePreSignTimeMetricKey?: string;
	measurePreSignTime?: boolean;
};

async function sendTxAndRecordConfirmationTime<T>(
	driftClient: DriftClient,
	txPromise: T,
	onSigned?: DriftClientTxSignedHandler,
	opts?: TxMetricsOpts
) {
	let handler: DriftClientTxSignedHandler;
	const signaturePromise = new Promise<void>((resolve) => {
		handler = (signedTxs) => {
			resolve();
			onSigned?.(signedTxs);
		};
		addTxSignedEventHandler(driftClient, handler);
	});
	const txSignedEventEmitter = driftClient.metricsEventEmitter;

	const txSignatureTimePromise = COMMON_UTILS.timedPromise(signaturePromise);
	const txConfirmationTimePromise = COMMON_UTILS.timedPromise(txPromise);

	// @ts-ignore
	txPromise.catch(() => {
		removeTxSignedEventHandler(driftClient, handler);
	});

	if (opts?.measurePreSignTime) {
		let handler: () => void;
		const preSignPromise = new Promise<void>((resolve) => {
			handler = resolve;
			txSignedEventEmitter.on('preTxSigned', handler);
		});
		const preSignTimePromise = COMMON_UTILS.timedPromise(preSignPromise);
		preSignTimePromise.then((result) => {
			const cachedStartTime = TX_PREP_TIME_METRIC_START_TIME_CACHE.get(
				opts.measurePreSignTimeMetricKey
			);
			const shouldAdjustMeasuredTime = !!cachedStartTime;

			const metricTime = shouldAdjustMeasuredTime
				? Date.now() - cachedStartTime
				: result.promiseTime;

			const metricKey = opts.measurePreSignTimeMetricKey ?? 'unlabelled';

			dlog(`ui_metrics`, `pre_sign_time_measurement ${metricTime}ms`, {
				metricKey,
				promiseTime: result.promiseTime,
				metricTime: metricTime,
				delta: metricTime - result.promiseTime,
				shouldAdjustMeasuredTime,
			});

			DriftWindow.metricsBus?.next({
				type: `transaction_prep_time`,
				value: {
					timeMs: metricTime,
					key: metricKey,
				},
			});
		});
	}

	Promise.all([txSignatureTimePromise, txConfirmationTimePromise]).then(
		(results) => {
			const txSignatureTime = results[0].promiseTime;
			const txConfirmationTime = results[1].promiseTime;

			const actualConfirmationTime = txConfirmationTime - txSignatureTime;

			if (actualConfirmationTime <= 0) {
				// Caught an edge case once where the txConfirmationTime was less than the txSignatureTime. Don't see how this is possible not how we can "gracefully" handle it, so just going to skip logging this transaction time.
				dlog(`ui_metrics`, `bug::confirmation_time_less_than_sig_time`, {
					txSignatureTime,
					txConfirmationTime,
					diff: actualConfirmationTime,
				});
				return;
			}

			const formattedPromiseTime = +(actualConfirmationTime / 1000).toFixed(1);

			dlog(
				`ui_metrics`,
				`confirmation_time_measurement ${formattedPromiseTime}s`,
				{
					txSignatureTime,
					txConfirmationTime,
					diff: actualConfirmationTime,
				}
			);

			DriftWindow.metricsBus?.next({
				type: 'transaction_confirmation_time',
				value: formattedPromiseTime,
			});

			removeTxSignedEventHandler(driftClient, handler);
		}
	);

	return txPromise;
}

const DriftStoreActions = (
	get: DriftStore['get'],
	set: DriftStore['set'],
	accountsGet: DriftAccountsStore['get'],
	accountsSet: DriftAccountsStore['set'],
	priceInfoGetter: (marketId: MarketId) => DerivedMarketTradeState,
	oracleInfoGetter: (marketId: MarketId) => OraclePriceData,
	getWalletBalancesStore: WalletBalancesStore['get'],
	getIfStore: IfDataStore['get'],
	getPriorityFeeToUse: PriorityFeeStore['getPriorityFeeToUse'],
	captureEvent: PostHogCapture,
	router: ReturnType<typeof useRouter>
) => {
	/**
	 * Utility method to do any handling required when sending transactions
	 * @param txPromise
	 * @returns
	 */
	async function handleTxChain<T>(
		txPromise: T,
		onSigned?: DriftClientTxSignedHandler,
		opts?: TxMetricsOpts
	) {
		return sendTxAndRecordConfirmationTime(
			get()?.driftClient?.client,
			txPromise,
			onSigned,
			opts
		);
	}

	const getTxParams = (props?: {
		computeUnits?: ComputeUnits;
		useSimulatedComputeUnits?: boolean;
		simulatedComputeUnitMultiplier?: number;
		marketId?: MarketId;
		lowerBoundCu?: number;
	}): TxParams => {
		const computeUnits = props?.computeUnits ?? (600_000 as ComputeUnits);
		const useSimulatedComputeUnits = props?.useSimulatedComputeUnits;
		const simulatedComputeUnitMultiplier =
			props?.simulatedComputeUnitMultiplier;
		const marketId = props?.marketId;

		return UI_UTILS.getTxParams(
			(computeUnits) => getPriorityFeeToUse(undefined, computeUnits, marketId),
			computeUnits,
			getPriorityFeeToUse(undefined, computeUnits, marketId),
			useSimulatedComputeUnits,
			simulatedComputeUnitMultiplier,
			props?.lowerBoundCu
		);
	};

	const fetchMarginAccountInfo = async (userKey: string) => {
		const userAccount = accountsGet().accounts[userKey].client;

		if (userAccount) {
			set((state) => {
				state.wallet.info.collateral = userAccount
					.getFreeCollateral()
					.toNumber();
			});
		}
	};

	const setUserAccountMarginInfo = (user: User) => {
		const newData = userAccountDataHandler.newData(user);
		const userId = user.getUserAccount().subAccountId;
		const authority = user.getUserAccount().authority;
		const userKey = COMMON_UI_UTILS.getUserKey(userId, authority);

		accountsSet((s) => {
			s.accounts[userKey].marginInfo = {
				...newData,
				...s.accounts[userKey].marginInfo,
			};
		});
	};

	const deleteUser = async (user: User) => {
		const userAccount = user.getUserAccount();
		const toastId = `delete-user-${userAccount.subAccountId}`;
		if (!userAccount.authority.equals(get().wallet.current.adapter.publicKey))
			return;

		notify({
			type: 'info',
			id: toastId,
			message: 'Deleting subaccount',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			// Need to get the account from accountState before deleting the user so we can unsubscribe after its deleted. Otherwise we can't unsubscribe from it because it was already removed and we will get an error
			const accountsState = accountsGet() as DriftAccountsStore;
			const driftClient = get().driftClient;
			const userKey = driftClient.client.getUserMapKey(
				userAccount.subAccountId,
				userAccount.authority
			);
			const account = accountsState.accounts[userKey];
			const userStatsAccount = get().userStatsAccount;

			await ACTION_HELPERS.ACCOUNT_DELETION_HELPERS.tryDeleteUserAccount(
				driftClient.client,
				user,
				userStatsAccount,
				await driftClient.client.connection.getSlot()
			);

			// If we're deleting the currently selected account then switch accounts too
			if (accountsState.currentUserKey === userKey) {
				const userKeys = Object.keys(accountsState.accounts).map(String);

				const userKeyToSwitchTo = userKeys.find(
					(accountUserKey) => accountUserKey !== userKey
				);

				const { userId, userAuthority } =
					COMMON_UI_UTILS.getIdAndAuthorityFromKey(userKeyToSwitchTo);

				await driftClient.client.switchActiveUser(userId, userAuthority);

				// If there are no other accounts then this will set the current account to undefined which is the correct thing to do
				accountsSet((s) => {
					s.currentUserKey = userKeyToSwitchTo;
				});
			}

			// Remove deleted user from store
			accountsSet((s) => {
				delete s.accounts[userKey];
			});

			try {
				await UI_UTILS.sleep(500);
				await account.client?.unsubscribe();
			} catch (e: any) {
				// Don't think we will ever get here but just doing something in case.
				account.client?.eventEmitter.removeAllListeners();
			}

			notify({
				type: 'success',
				message: `Subaccount deleted`,
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
				lengthMs: 5000,
			});
		} catch (e: any) {
			console.error(e);
			notify({
				type: 'error',
				message: `Subaccount could not be deleted`,
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
				lengthMs: 5000,
			});
		}
	};

	const subscribeToUpdatesAndGenerateInitialStateForUserAccount = (
		userClient: User,
		userAccount: UserAccount,
		opts?: {
			newAccountId?: number;
		}
	): AccountData => {
		const wallet = get().wallet.current;
		const accountsState = accountsGet();
		const userAccountToSubscribe = userClient?.getUserAccount() ?? userAccount;
		const userId = userAccountToSubscribe.subAccountId;
		const userKey = COMMON_UI_UTILS.getUserKey(
			userId,
			userAccountToSubscribe.authority
		);

		const handleUserUpdate = (userAccount: User) => {
			const isSubscribed = userAccount.isSubscribed;

			if (!isSubscribed) return;

			setUserAccountMarginInfo(userAccount);

			removeLoadingItemFromQueue({ key: 'marginInfo' });
		};

		const accountData: AccountData = accountsState.accounts[userKey];
		let accountState;

		// make sure we don't overwrite account data if it's already loaded
		if (accountData && accountData?.isSubscribed) {
			accountState = accountData;
		} else {
			// initialize account state in store
			accountState = ACCOUNT_INITIAL_STATE(userKey);

			accountState = {
				...accountState,
				userKey: userKey,
				userId: userId,
				name: UI_UTILS.decodeName(userAccountToSubscribe.name),
				pubKey: userClient.getUserAccountPublicKey(),
				authority: userAccountToSubscribe.authority,
				isSubscribed: true,
				client: userClient,
				marginEnabled: userClient.getUserAccount().isMarginTradingEnabled,
				delegate: userAccount.delegate,
				isDelegatedTo: userAccount.delegate.equals(wallet.adapter.publicKey),
				isDelegatedAway:
					!userAccount.delegate.equals(wallet.adapter.publicKey) &&
					!userAccount.delegate.equals(DEFAULT_PUBLIC_KEY),
				poolId: userAccount.poolId,
			};

			// init marginInfo data
			const initialMarginInfo = userAccountDataHandler.newData(userClient);

			accountState.marginInfo = {
				...initialMarginInfo,
				...accountState.marginInfo,
			};
		}

		// add update handler to userAccount event emitter
		userClient.eventEmitter.on('update', () => handleUserUpdate(userClient));

		try {
			if (opts?.newAccountId !== undefined) {
				accountState.depositWithdrawalHistory.initialHistoryLoaded = true;
				accountState.fundingHistory.initialHistoryLoaded = true;
				accountState.liquidationHistory.initialHistoryLoaded = true;
				accountState.orderHistory.initialHistoryLoaded = true;
				accountState.settlePnlHistory.initialHistoryLoaded = true;
				accountState.tradeHistory.initialHistoryLoaded = true;
			}
		} catch (e: any) {
			console.error(e);
		}

		return accountState;
	};

	/**
	 * Subscribes to all subaccounts for the user. Doesn't try to subscribe to any accounts which are already subscribed. Returns false if subscribing to any of the accounts failed.
	 * @returns
	 */
	const setupUserAccountStateAndListeners = async (
		subAccountPubKey?: PublicKey,
		opts?: { newAccountId?: number },
		emulate?: boolean
	) => {
		console.log('setting up user accounts');
		try {
			const driftClient = get().driftClient.client;

			const allUsers = await COMMON_UI_UTILS.fetchUserClientsAndAccounts(
				driftClient
			);

			for (const user of allUsers) {
				if (!user.user.isSubscribed)
					throw new Error(
						'Accounts need to be subscribed before calling setupUserAccountStateAndListeners'
					);
			}

			// Generate state for accounts
			const userAccountStates = allUsers
				.map((subAccountUser) => {
					return subscribeToUpdatesAndGenerateInitialStateForUserAccount(
						subAccountUser.user,
						subAccountUser.userAccount,
						opts
					);
				})
				.filter((accountState) => !!accountState);

			// Add user accounts state to store
			accountsSet((s) => {
				userAccountStates.forEach((accountState) => {
					s.accounts[accountState.userKey] = accountState;
				});
			});

			if (emulate) {
				accountsSet((s) => {
					s.currentUserKey = userAccountStates[0]?.userKey ?? '';
				});
				driftClient.switchActiveUser(
					userAccountStates[0]?.userId ?? 0,
					userAccountStates[0]?.authority ??
						get().wallet.current.adapter.publicKey
				);
			}

			if (subAccountPubKey) {
				const selectedAccountData: AccountData = userAccountStates.find(
					(acct) => acct.pubKey.equals(subAccountPubKey)
				);

				if (selectedAccountData) {
					accountsSet((s) => {
						s.currentUserKey = selectedAccountData.userKey;
					});
					driftClient.switchActiveUser(
						selectedAccountData.userId,
						selectedAccountData.authority
					);
				}
			}

			removeLoadingItemFromQueue({ key: 'marginInfo' });
		} catch (err: any) {
			console.error(err);
		}
	};

	const subscribeToDriftClientData = async (driftClient: DriftClient) => {
		try {
			const subscriptionResult = await driftClient.subscribe();

			if (!subscriptionResult) {
				driftClient.unsubscribe();
			}

			set((s) => {
				s.driftClient.isSubscribed = subscriptionResult;
			});

			return subscriptionResult;
		} catch (e: any) {
			console.error(e);
			driftClient.unsubscribe();
			return false;
		}
	};

	const subscribeToUserUSDCBalance = async (pubkeyOverride?: PublicKey) => {
		const faucet = get().faucet;

		const userPubKey =
			pubkeyOverride ?? get().wallet.current?.adapter?.publicKey;

		faucet.subscribeToTokenAccount({
			userPubKey,
			callback: (accountInfo) => {
				set((s) => {
					s.wallet.usdcBalance = BigNum.from(
						// @ts-ignore
						accountInfo.amount.toNumber(),
						PRICE_PRECISION_EXP
					);
					s.wallet.usdcAssociatedAccount = accountInfo.address;
				});
			},
		});
	};

	interface WithdrawCollateralParams {
		amount: BigNum;
		spotMarketConfig: SpotMarketConfig;
		userAccount?: UserAccount;
		isBorrow?: boolean;
		isMax?: boolean;
		updateFuel?: boolean;
	}

	const withdrawCollateral = async ({
		amount,
		spotMarketConfig,
		userAccount,
		isBorrow = false,
		isMax = false,
	}: WithdrawCollateralParams) => {
		const state = get();
		const accountsState = accountsGet();

		if (!userAccount) {
			userAccount =
				accountsState.accounts[
					accountsState.currentUserKey
				].client.getUserAccount();
		}

		// can't withdraw from a delegate account
		if (!userAccount.authority.equals(get().wallet.current.adapter.publicKey))
			return;

		await state.driftClient.client.switchActiveUser(
			userAccount.subAccountId,
			userAccount.authority
		);

		// Settle fuel if user has unesttled fuel earnings
		const nowTs = new BN(Math.floor(Date.now() / 1000));
		const user = state.driftClient.client?.getUser();
		const unsettledFuel = user?.getFuelBonus(nowTs, false, true);
		const updateFuel = unsettledFuel?.depositFuel?.gt(ZERO);

		addLoadingItemToQueue({ key: 'transaction' });

		const toastId = `${
			isBorrow ? 'borrow' : 'withdraw'
		}-${amount.toString()}-${Date.now()}`;

		notify({
			type: 'info',
			message: `${
				isBorrow ? 'Borrowing' : 'Withdrawing'
			} ${amount.prettyPrint()} ${spotMarketConfig.symbol}`,
			description: <ConfirmTransactionStep />,
			id: toastId,
			showUntilCancelled: true,
		});

		const spotAssetDecimals = spotMarketConfig.precisionExp;

		let transferAmount = amount.shiftTo(new BN(spotAssetDecimals));

		const reduceOnly = !isBorrow;

		// Need to over-estimate max withdraw amount if we want the account to really go to 0 because account will earn a tiny of a interest after the instruction is created
		if (reduceOnly && isMax) {
			transferAmount = transferAmount.mul(new BigNum(2));
		}

		const spotMarketAccount = get().driftClient.client.getSpotMarketAccount(
			spotMarketConfig.marketIndex
		);

		const account = accountsState.accounts[accountsState.currentUserKey];

		const perpMarketIds = account.openPerpPositions.map((position) =>
			MarketId.createPerpMarket(position.marketIndex)
		);

		const spotMarketIds = account.spotBalances.map((balance) =>
			MarketId.createSpotMarket(balance.asset.marketIndex)
		);

		const allActiveMarketIds = [...perpMarketIds, ...spotMarketIds];

		try {
			const optionalIxs = await UI_UTILS.getOracleCrankIxs(
				allActiveMarketIds,
				get().driftClient.client,
				state.wallet.current.adapter.name,
				undefined,
				true,
				2
			);

			const withdrawIxs = await state.driftClient.client.getWithdrawalIxs(
				transferAmount.val,
				spotMarketConfig.marketIndex,
				await UI_UTILS.getTokenAddressForDepositAndWithdraw(
					spotMarketAccount,
					state.wallet.current?.adapter.publicKey
				),
				reduceOnly,
				undefined,
				updateFuel
			);

			const tx = await state.driftClient.client.buildTransaction(
				withdrawIxs,
				getTxParams({
					marketId: MarketId.createSpotMarket(spotMarketConfig.marketIndex),
					computeUnits: 800_000 as ComputeUnits,
					lowerBoundCu: 450_000,
				}),
				undefined,
				[],
				undefined,
				undefined,
				optionalIxs
			);

			const { slot } = await state.driftClient.client.sendTransaction(
				tx,
				[],
				state.driftClient.client.opts
			);

			state.driftClient.client.spotMarketLastSlotCache.set(
				spotMarketConfig.marketIndex,
				slot
			);

			notify({
				type: 'success',
				message: `${isBorrow ? 'Borrow' : 'Withdraw'} Successful`,
				id: toastId,
				updatePrevious: true,
			});

			removeLoadingItemFromQueue({ key: 'transaction' });

			const userKey = COMMON_UI_UTILS.getUserKey(
				userAccount.subAccountId,
				userAccount.authority
			);

			updateAfterTx(
				state.driftClient.client,
				accountsState.accounts[userKey].client
			);

			return true;
		} catch (e: any) {
			console.error(e);
			captureException(e);
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			removeLoadingItemFromQueue({ key: 'transaction' });
			return false;
		}
	};

	const transferCollateral = async (
		amount: BigNum,
		spotMarket: SpotMarketConfig,
		fromAccountKey: string,
		toAccountKey: string
	) => {
		const state = get();
		const accountsState = accountsGet();
		const fromAccount = accountsState.accounts[fromAccountKey];
		const toAccount = accountsState.accounts[toAccountKey];

		const toastId = `transfer-${amount.toString()}-${Date.now()}`;

		notify({
			type: 'info',
			message: `Transferring ${amount.prettyPrint()} ${
				spotMarket.symbol
			} from ${fromAccount.name} to ${toAccount.name}`,
			description: <ConfirmTransactionStep />,
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			await handleTxChain(
				state.driftClient.client.transferDeposit(
					amount.val,
					spotMarket.marketIndex,
					fromAccount.userId,
					toAccount.userId,
					getTxParams({
						marketId: MarketId.createSpotMarket(spotMarket.marketIndex), // TODO : Confirm this transaction should rely on this spot markets tx fee samples,
						simulatedComputeUnitMultiplier:
							Env.computeUnitBufferMultiplier + 0.3,
					})
				)
			);

			notify({
				type: 'success',
				description: 'Transfer Successful',
				id: toastId,
				updatePrevious: true,
			});

			updateAfterTx(state.driftClient.client, fromAccount?.client);

			return true;
		} catch (e: any) {
			captureException(e);
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const depositCollateralForExistingAccount = async (
		amount: BigNum,
		spotMarketConfig: SpotMarketConfig,
		userKey?: string,
		isMaxBorrowRepayment?: boolean
	) => {
		const state = get();
		if (state.isGeoblocked && state.countryCode !== 'US') {
			notify({
				type: 'error',
				message: 'Geoblocked',
				description:
					'You are not allowed to use Drift from a restricted territory',
				lengthMs: 5000,
			});
			return;
		}

		if (!userKey) {
			userKey = accountsGet().currentUserKey;
		}

		const accountsState = accountsGet();
		const accountData = accountsState.accounts[userKey];

		await state.driftClient.client.switchActiveUser(
			accountData.userId,
			accountData.authority
		);

		const toastId = `deposit-${amount.toString()}-${Date.now()}`;

		notify({
			type: 'info',
			message: `Depositing ${amount.prettyPrint()} ${spotMarketConfig.symbol}`,
			description: <ConfirmTransactionStep />,
			id: toastId,
			showUntilCancelled: true,
		});

		const spotAssetDecimals = spotMarketConfig.precisionExp;

		let amountToSend = amount;

		const reduceOnly = isMaxBorrowRepayment;

		if (isMaxBorrowRepayment) {
			// We're doubling the amount to send here because it's a max borrow repayment (and reduce-only MUST be true) .. we do this to ensure no dust position gets left behind
			amountToSend = amountToSend.scale(2, 1);
		}

		const transferAmount = amountToSend.shiftTo(new BN(spotAssetDecimals));

		const spotMarketAccount = state.driftClient.client.getSpotMarketAccount(
			spotMarketConfig.marketIndex
		);

		try {
			await state.driftClient.client.deposit(
				transferAmount.val,
				spotMarketConfig.marketIndex,
				await UI_UTILS.getTokenAddressForDepositAndWithdraw(
					spotMarketAccount,
					state.wallet.current.adapter.publicKey
				),
				undefined,
				reduceOnly,
				getTxParams({
					marketId: MarketId.createSpotMarket(spotMarketConfig.marketIndex), // TODO : Confirm this transaction should rely on this spot markets tx fee samples
				})
			);

			notify({
				type: 'success',
				message: 'Deposit Successful',
				id: toastId,
				updatePrevious: true,
			});

			updateAfterTx(
				state.driftClient.client,
				accountsState.accounts[userKey]?.client
			);
		} catch (e: any) {
			captureException(e);
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const runAccountInitializationTxAndHandling = async ({
		referrerInfo,
		driftClient,
		userIdToInit,
		toastId,
		runInitializationTransaction,
		poolId,
	}: {
		referrerInfo: ReferrerInfo;
		driftClient: DriftClient;
		userIdToInit: number;
		toastId: string;
		runInitializationTransaction: () => Promise<{
			txSig: string;
		}>;
		poolId?: number;
	}) => {
		dlog('deposit_to_trade', 'running_new_initialization_step');

		let initializationTxSig: string;

		const result = await COMMON_UI_UTILS.initializeAndSubscribeToNewUserAccount(
			driftClient,
			userIdToInit,
			get().wallet.current.adapter.publicKey,
			{
				initializationStep: async () => {
					const txResult = await runInitializationTransaction();

					initializationTxSig = txResult.txSig;

					return true;
				},
				postInitializationStep: async () => {
					notify({
						type: 'info',
						message: `Deposit successful`,
						description: <LoadingStep>Subscribing to account...</LoadingStep>,
						lengthMs: 5000,
						id: toastId,
					});

					if (userIdToInit === 0) {
						notify({
							type: 'success',
							message: referrerInfo
								? 'Referral Code Applied'
								: 'No referral code was applied',
						});
					}

					set((s) => {
						s.referrerParam = null;
						s.referrerParamFailed = false;
					});

					return true;
				},
				handleSuccessStep: async () => {
					await handleCreateDriftUserSuccess(
						userIdToInit,
						toastId,
						poolId && poolId !== MAIN_POOL_ID ? false : true
					);

					await UI_UTILS.sleep(3000);

					if (initializationTxSig[0]) {
						accountsSet((s) => {
							s.userAccountNotInitialized = false;
						});
					}

					return true;
				},
			}
		);

		switch (result) {
			case 'ok':
				return true;
			case 'failed_awaitAccountInitializationChainState':
				notify({
					type: 'error',
					description:
						'The deposit was successful but unable to subscribe to your new account. Please try refreshing the page.',
					lengthMs: 5000,
				});
				return false;
			case 'failed_handleSuccessStep':
			case 'failed_initializationStep':
			case 'failed_postInitializationStep':
				throw new Error(`Unhandled initialization failure : ${result}`);
		}
	};

	const initializeAndDepositCollateralForAccount = async (props: {
		amount: BigNum;
		spotMarketConfig: SpotMarketConfig;
		poolId?: number;
		name?: string;
		fromId?: number;
		maxLeverage?: number | undefined;
	}): Promise<boolean> => {
		const { amount, spotMarketConfig, poolId, name, fromId, maxLeverage } =
			props;

		const accountsState = accountsGet();

		const state = get();
		if (state.isGeoblocked && state.countryCode !== 'US') {
			notify({
				type: 'error',
				message: 'Geoblocked',
				description:
					'You are not allowed to use Drift from a restricted territory',
				lengthMs: 5000,
			});
			return;
		}

		const referrerName = state.referrerParam;

		const toastId = `init_${Date.now()}`;

		const driftClient = get().driftClient.client;

		const userStatsAccount = get().userStatsAccount;

		const userIdToInit = userStatsAccount?.numberOfSubAccountsCreated ?? 0;

		const hasAccountForPool = Object.values(accountsState.accounts).some(
			(account) => account.client.getUserAccount().poolId === poolId
		);

		const newAccountName = name
			? name
			: !hasAccountForPool
			? DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[poolId]
			: undefined;

		if (userIdToInit === 0) {
			notify({
				id: toastId,
				type: 'info',
				message: 'New Drift Account',
				description: (
					<LoadingStep>Depositing and Creating Drift Account</LoadingStep>
				),
				showUntilCancelled: true,
			});
		} else {
			notify({
				id: toastId,
				type: 'info',
				message: 'New Subaccount',
				description: (
					<LoadingStep>{`Creating new subaccount ${
						name ? `named "${name}", ` : ''
					}from ${
						fromId == null || fromId == undefined ? 'wallet' : 'current account'
					}`}</LoadingStep>
				),
				showUntilCancelled: true,
			});
		}

		try {
			const spotAssetDecimals = spotMarketConfig.precisionExp;

			const transferAmount = amount.shiftTo(new BN(spotAssetDecimals));

			// Compile Referrer Info if relevant
			const referrerInfo: ReferrerInfo = await UI_UTILS.getReferrerInfo(
				state.driftClient.client,
				referrerName
			);

			const customMaxMarginRatio =
				UI_UTILS.convertLeverageToMarginRatio(maxLeverage);

			const spotMarketAccount = driftClient.getSpotMarketAccount(
				spotMarketConfig.marketIndex
			);

			const runInitializationTransaction = async () => {
				const result =
					await state.driftClient.client.initializeUserAccountAndDepositCollateral(
						transferAmount.val,
						await UI_UTILS.getTokenAddressForDepositAndWithdraw(
							spotMarketAccount,
							state.wallet.current.adapter.publicKey
						),
						spotMarketConfig.marketIndex,
						userIdToInit,
						newAccountName,
						fromId,
						referrerInfo,
						isDev() ? ZERO : NEW_ACCOUNT_DONATION.val,
						getTxParams({
							marketId: MarketId.createSpotMarket(spotMarketConfig.marketIndex),
							computeUnits: 800_000 as ComputeUnits,
						}),
						customMaxMarginRatio,
						poolId
					);

				return {
					txSig: result[0],
				};
			};

			return runAccountInitializationTxAndHandling({
				referrerInfo,
				driftClient,
				userIdToInit,
				toastId,
				runInitializationTransaction: runInitializationTransaction,
				poolId,
			});
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const handleCreateDriftUserSuccess = async (
		userIdToInit: number,
		toastId?: string,
		switchAccount?: boolean
	) => {
		const state = get();

		const userKey = COMMON_UI_UTILS.getUserKey(
			userIdToInit,
			state.wallet.current.adapter.publicKey
		);

		if (switchAccount !== false) {
			accountsSet((s) => {
				s.currentUserKey = userKey;
			});
		}

		// If successful and user wasn't set to onboarded yet, now they are onboarded
		if (!state.hasCompletedOnboarding) {
			setHasCompletedOnboarding(true);
		}

		if (state.wallet.isMagicAuth) {
			set((s) => {
				s.showMagicLinkFirstTimeHelper = true;
			});
		}
		if (state.wallet.isMetamask) {
			set((s) => {
				s.showMetamaskFirstTimeHelper = true;
			});
		}

		const newUserAccountPubKey =
			await state.driftClient.client?.getUserAccountPublicKey(
				userIdToInit,
				state.wallet.current.adapter.publicKey
			);

		setupUserAccountStateAndListeners(newUserAccountPubKey, {
			newAccountId: userIdToInit,
		});

		if (toastId) {
			notify({
				type: 'success',
				id: toastId,
			});
		}

		accountsSet((s) => {
			s.userAccountNotInitialized = false;
		});

		window.localStorage?.removeItem('referrerParam');
	};

	// TODO : this should be a common util
	const blockchainAccountExists = async (pubKey: PublicKey) => {
		const driftClient = get().driftClient.client;
		const userAccountRPCResponse =
			await driftClient.connection.getParsedAccountInfo(pubKey);
		return userAccountRPCResponse.value !== null;
	};

	const getUserStatsAccountExists = async () => {
		const connectedWallet = get().wallet.current;
		const driftClient = get().driftClient.client;

		const userStatsAccountKey = getUserStatsAccountPublicKey(
			driftClient.program.programId,
			connectedWallet.adapter.publicKey
		);

		return blockchainAccountExists(userStatsAccountKey);
	};

	/**
	 * Look for code to refactor, replacing with this method.
	 * @returns
	 */
	const getUserIdForNewAccountDeposit = async () => {
		const accountStore = accountsGet();
		const driftClient = get().driftClient.client;

		const currentUserAccountState =
			accountStore?.accounts?.[accountStore?.currentUserKey];

		if (!currentUserAccountState) {
			return 0;
		}

		const currentUserClient = currentUserAccountState.client;

		if (!currentUserClient) {
			throw new Error('No user client found for the deposit');
		}

		const userStatsAccountExists = await getUserStatsAccountExists();

		if (!userStatsAccountExists) {
			return 0;
		}

		const userStats = driftClient.userStats;

		if (!userStats) {
			throw new Error('No user stats account on DriftClient when it should be');
		}

		const userStatsAccount = userStats.getAccount();

		const userIdToInit = userStatsAccount.numberOfSubAccountsCreated;

		return userIdToInit;
	};

	const getUserIdForAccountKey = (accountKey: string) => {
		const accountsState = accountsGet();
		const targetAccount = accountsState.accounts[accountKey];
		return targetAccount.userId;
	};

	const _getUserIdForDepositToTrade = async (accountKey?: string) => {
		// TODO: Revisit this I think there are bad assumptions going on here
		if (!accountKey) {
			// Expect that we are doing a deposit into a new account
			const userIdToInit = await getUserIdForNewAccountDeposit();
			if (userIdToInit !== 0) {
				throw new Error(
					'Expected to be creating a new account but userIdToUnit was not 0'
				);
			}
			return userIdToInit;
		} else {
			// Expect that we are depositing into an existing account
			return getUserIdForAccountKey(accountKey);
		}
	};

	const getUserIdForAccountInitialization = async (authority: PublicKey) => {
		try {
			// Check if the user stats account exists and use the next user account Id if it does, otherwise assume creating account zero
			const driftClient = get().driftClient.client;
			const userStatsAccountKey = getUserStatsAccountPublicKey(
				driftClient.program.programId,
				authority
			);

			const dataAndContext =
				await driftClient.program.account.userStats.fetchAndContext(
					userStatsAccountKey,
					'confirmed'
				);

			if (!dataAndContext || !dataAndContext.data) {
				return 0;
			}

			const userStatsAccount = dataAndContext.data as UserStatsAccount;

			return userStatsAccount.numberOfSubAccountsCreated;
		} catch (e: any) {
			console.error(
				`Failed fetching user stats account for authority ${authority.toString()}`,
				e
			);

			return 0;
		}
	};

	/**
	 * Handle a deposit+trade transaction.
	 *
	 * - Takes the deposit params as input props
	 * - Need to gather the requested trade props from the trade form
	 * - Need to determine whether a new account needs to be initialized or if there is an existing one
	 *
	 * @param props
	 */
	const tryDepositToTrade = async (props: {
		depositCollateral: SpotMarketConfig;
		depositAmount: BigNum;
		priceImpactInfo: PriceImpactInfo;
		oraclePrice: BigNum;
		markPrice: BigNum;
		userId?: number;
		isNewAccount?: boolean;
		maxLeverageSelected?: boolean;
		isHighLeverage?: boolean;
		onSuccess?: () => void;
		onTxCreated: () => void;
	}) => {
		const driftClient = get().driftClient.client;

		// # Gather the params for the deposit instructions.
		const authority = get().wallet.current.adapter.publicKey;

		// TODO :: look at this
		let userIdForTx = props.userId ?? 0;

		if (props.isNewAccount) {
			userIdForTx = await getUserIdForAccountInitialization(authority);
		}

		const depositCollateral = props.depositCollateral;
		const depositAmount = props.depositAmount;

		const switchUserIfNecessary = () => {
			if (props.isNewAccount) return;
			if (isNaN(props.userId)) return;

			const accountsState = accountsGet();
			if (
				accountsState.accounts[accountsState.currentUserKey].userId !==
				props.userId
			) {
				updateCurrentSubAccount(props.userId, authority);
			}
		};

		switchUserIfNecessary();

		const toastId = `deposit_to_trade`;
		let auctionToastEventProps: AuctionToastEvent;

		const USE_PLACE_AND_TAKE = false;

		try {
			const depositCollateralTokenAccount =
				await UI_UTILS.getTokenAddressForDepositAndWithdraw(
					driftClient.getSpotMarketAccount(depositCollateral.marketIndex),
					authority
				);

			// # Gather the params for the trade instructions.
			const tradeFormState = get().tradeForm;
			const tradeTargetMarket = get().selectedMarket;

			if (
				!DEPOSIT_TO_TRADE_CONFIG.enabledMarketTypes.includes(
					tradeTargetMarket.marketId.marketType
				)
			) {
				throw new Error(
					`Can't do DepositToTrade for market type ${ENUM_UTILS.toStr(
						tradeTargetMarket.marketId.marketType
					)}`
				);
			}

			const baseSizeStringValue = tradeFormState.baseSizeStringValue;

			const tradeBaseSize = BigNum.fromPrint(
				baseSizeStringValue,
				BASE_PRECISION_EXP
			);

			const tradePrice = BigNum.fromPrint(
				tradeFormState.priceBoxStringValue,
				PRICE_PRECISION_EXP
			);

			const tradeDirection =
				tradeFormState.side === 'buy'
					? PositionDirection.LONG
					: PositionDirection.SHORT;
			const orderType = tradeFormState.orderType;

			if (!DEPOSIT_TO_TRADE_CONFIG.enabledOrderTypes.includes(orderType)) {
				throw new Error(`Can't do DepositToTrade for order type ${orderType}`);
			}

			// # Gather whether a new account needs to be initialized or if there is an existing one.
			const isCreatingNewAccount = props.isNewAccount;

			const depositIxs: TransactionInstruction[] = [];
			const highLeverageModeIxs = [];
			const tradeIxs: TransactionInstruction[] = [];

			// # Put the instructions together into a transaction.
			// ## Deposit Instructions
			if (isCreatingNewAccount) {
				dlog('deposit_to_trade', 'creating_new_account');
				const { ixs } =
					await driftClient.createInitializeUserAccountAndDepositCollateralIxs(
						depositAmount.val,
						depositCollateralTokenAccount,
						depositCollateral.marketIndex,
						userIdForTx
					);
				depositIxs.push(...ixs);
			} else {
				dlog('deposit_to_trade', 'doing_regular_deposit');
				// TODO: get regular deposit ixs
				const ixs = await driftClient.getDepositTxnIx(
					depositAmount.val,
					depositCollateral.marketIndex,
					depositCollateralTokenAccount,
					userIdForTx,
					false
				);
				depositIxs.push(...ixs);
			}

			if (props.isHighLeverage) {
				let mustEnableHighLeverageMode = true;

				if (!isCreatingNewAccount) {
					const user = driftClient.getUser(userIdForTx);
					if (user.isHighLeverageMode()) {
						mustEnableHighLeverageMode = false;
					}
				}

				if (mustEnableHighLeverageMode) {
					const enableHighLeverageIx =
						await driftClient.getEnableHighLeverageModeIx(userIdForTx, {
							isMakingNewAccount: isCreatingNewAccount,
							depositMarketIndex: depositCollateral.marketIndex,
							orderMarketIndex: tradeTargetMarket.marketId.marketIndex,
						});
					highLeverageModeIxs.push(enableHighLeverageIx);
				}
			}

			const settings = syncGetCurrentSettings();

			let orderParams: OptionalOrderParams;
			const isMarketOrder = orderType === 'market';

			let tradeIx: TransactionInstruction;

			const pubKeyForUser = getUserAccountPublicKeySync(
				driftClient.program.programId,
				authority,
				userIdForTx
			);

			const accounts = accountsGet().accounts;
			const spotBalances =
				accounts[COMMON_UI_UTILS.getUserKey(userIdForTx, authority)]
					?.spotBalances || [];

			// 🚨! IMPORTANT ! Don't use the max leverage flag for an existing account with spot balances because it could consume all their balance. Can get into this state when using a wallet with an existing account but the wallet isn't connected to the ui.
			const useMaxLeverage =
				props.maxLeverageSelected &&
				(isCreatingNewAccount || spotBalances.length === 0);

			if (isMarketOrder) {
				// TODO : duplicate code, can we standardise this??
				const tradeFormState = get().tradeForm;

				const preppedParams = ORDER_PREP_UTILS.prepPerpMarketOrderParams(
					{
						side: ENUM_UTILS.match(tradeDirection, PositionDirection.LONG)
							? 'buy'
							: 'sell',
						baseSizeStringValue: baseSizeStringValue,
						orderType: orderType,
						reduceOnly: tradeFormState.reduceOnly,
						slippageTolerance: Number(settings.slippageTolerance),
						postOnly: tradeFormState.postOnly,
						targetMarketIndex: tradeTargetMarket.marketId.marketIndex,
						immediateOrCancel: tradeFormState.immediateOrCancel,
						maxLeverageSelected: useMaxLeverage,
						priceImpact: props.priceImpactInfo,
						bracketOrders: undefined,
						oraclePrice: props.oraclePrice.val,
						markPrice: props.markPrice.val,
						cancelExistingOrders: undefined,
						perpMarketAccount: driftClient.getPerpMarketAccount(
							tradeTargetMarket.marketId.marketIndex
						),
					},
					{
						getUserAccountData: () => {
							const accountsState = accountsGet();
							return accountsState.accounts[accountsState.currentUserKey];
						},
						getDriftClient: () => get().driftClient.client,
						getPriceInfo: priceInfoGetter,
					},
					isCreatingNewAccount
						? {
								accountDoesNotExistYet: true,
								pubKey: pubKeyForUser,
								nextOrderId: 0,
								subAccountId: userIdForTx,
						  }
						: undefined
				);

				orderParams = preppedParams.orderParams;

				if (USE_PLACE_AND_TAKE) {
					auctionToastEventProps = {
						v2Props: {
							identifierNonce: DriftWindow.getAndIncrementNonce(),
							marketId: MarketId.createPerpMarket(
								tradeTargetMarket.marketId.marketIndex
							),
							direction: preppedParams.orderParams.direction,
							baseAmountOrdered: preppedParams.orderParams.baseAssetAmount,
							numOrders: 1,
							auctionEnabled: false,
							includesSlOrder: false,
							includesTpOrder: false,
						},
						toastId,
					};
				} else {
					auctionToastEventProps = preppedParams.auctionToastEventProps;
				}

				if (USE_PLACE_AND_TAKE) {
					const makerInfo = await getMakerInfoForMarketOrder({
						direction: orderParams.direction,
						marketIndex: tradeTargetMarket.marketId.marketIndex,
						marketType: MarketType.PERP,
						driftClient: driftClient,
					});

					dlog('deposit_to_trade', 'using_place_and_take');
					tradeIx = await driftClient.getPlaceAndTakePerpOrderIx(
						orderParams,
						makerInfo,
						undefined,
						userIdForTx
					);
				} else {
					dlog('deposit_to_trade', 'using_default_perp_order');
					tradeIx = await driftClient.getPlacePerpOrderIx(
						orderParams,
						userIdForTx,
						{
							isMakingNewAccount: isCreatingNewAccount,
							depositMarketIndex: depositCollateral.marketIndex,
						}
					);
				}
			} else {
				orderParams = getLimitOrderParams({
					marketIndex: tradeTargetMarket.marketId.marketIndex,
					marketType: MarketType.PERP,
					direction: tradeDirection,
					baseAssetAmount: tradeBaseSize.val,
					price: tradePrice.val,
					reduceOnly: false,
				});

				tradeIx = await driftClient.getPlacePerpOrderIx(
					orderParams,
					userIdForTx,
					{
						isMakingNewAccount: isCreatingNewAccount,
						depositMarketIndex: depositCollateral.marketIndex,
					}
				);
			}

			// ## Trade Instructions

			dlog('deposit_to_trade', 'adding_perp_trade_ixs');
			tradeIxs.push(tradeIx);

			const txParams = getTxParams();

			const depositToTradeTx = await driftClient.buildTransaction(
				[...depositIxs, ...highLeverageModeIxs, ...tradeIxs],
				txParams
			);

			props.onTxCreated();

			let txSig: string;

			dlog(
				'deposit_to_trade',
				`wallet::${get().wallet.current.adapter.publicKey.toString()}`
			);

			if (isMarketOrder) {
				if (isCreatingNewAccount) {
					// This is necessary for the market order toasts to know which events are relevant to the upcoming trade. This is an annoying bit of coupling between them but don't think it's possible to get around.
					get().appEventEmitter.emit(
						'expectedUserAccountForMarketOrderEvents',
						pubKeyForUser
					);
				}
				emitAppEvent('expectedAuctionInit', auctionToastEventProps);
			}

			if (isCreatingNewAccount) {
				dlog('deposit_to_trade', 'running_initialization_chain');
				await runAccountInitializationTxAndHandling({
					referrerInfo: undefined,
					driftClient,
					userIdToInit: userIdForTx,
					toastId,
					runInitializationTransaction: async () => {
						const result = await handleTxChain(
							driftClient.sendTransaction(
								depositToTradeTx,
								undefined,
								driftClient.opts,
								false
							),
							(signedTxData) => {
								if (isMarketOrder) {
									emitAppEvent('expectedAuctionTxSigned', {
										...auctionToastEventProps,
										signedTxData,
									});
								}
							}
						);

						txSig = result.txSig;

						return {
							txSig,
						};
					},
				});
			} else {
				dlog('deposit_to_trade', 'regular_tx_chain');
				const result = await handleTxChain(
					driftClient.sendTransaction(
						depositToTradeTx,
						undefined,
						driftClient.opts,
						false
					),
					(signedTxData) => {
						if (isMarketOrder) {
							emitAppEvent('expectedAuctionTxSigned', {
								...auctionToastEventProps,
								signedTxData,
							});
						}
					}
				);

				txSig = result.txSig;
			}

			if (isMarketOrder) {
				emitAppEvent('expectedAuctionTxConfirmed', auctionToastEventProps);
			}

			// # Handle the transaction.
			notify({
				id: toastId,
				type: 'success',
				message: 'Finished Deposit To Trade',
				description: `Deposited ${depositAmount.prettyPrint()} ${
					depositCollateral.symbol
				} and opened a ${ENUM_UTILS.toStr(tradeDirection)} ${orderType} order.`,
			});

			if (props.onSuccess) {
				props.onSuccess();
			}

			return txSig;
		} catch (e: any) {
			console.error(e);

			if (auctionToastEventProps) {
				emitAppEvent('expectedAuctionTxFailed', auctionToastEventProps);
			}

			props.onTxCreated();

			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});

			// notify({
			// 	id: toastId,
			// 	type: 'error',
			// 	message: 'Failed Deposit To Trade',
			// 	description: e.message,
			// });
		}
	};

	const resetAccountsState = () => {
		accountsSet((s) => {
			s.currentUserKey = '';
			s.accounts = {};
			s.currentPointsTs = 0;
			s.userAccountNotInitialized = false;
		});
	};

	const handleWalletDisconnect = () => {
		notify({
			id: 'wallet_disconnection',
			type: 'info',
			message: 'Disconnected from wallet',
		});

		const state = get();

		set((s) => {
			s.wallet.currentPublicKeyString = undefined;
			s.wallet.isMagicAuth = false;
			s.wallet.isMetamask = false;
			s.wallet.isPasskeys = false;
			s.wallet.isAppKit = false;
		});

		state.driftClient.client?.updateWallet(new Wallet(new Keypair()));

		accountsSet((s) => {
			s.accounts = {};
			s.userAccountNotInitialized = false;
			s.deletedAccounts = [];
		});
	};

	const addLoadingItemToQueue = (props: {
		key: keyof DriftStore['loadingElements'];
		data?: Omit<
			DriftStore['loadingElements'][keyof DriftStore['loadingElements']],
			'isLoading'
		>;
	}) => {
		set((s) => {
			s.loadingElements = {
				...s.loadingElements,
				[props.key]: {
					isLoading: true,
					callback: props.data?.callback,
				},
			};
		});
	};

	const removeLoadingItemFromQueue = (props: {
		key: keyof DriftStore['loadingElements'];
	}) => {
		//trigger callback for when item is cleared from queue
		get().loadingElements[props.key]?.callback?.();

		set((s) => {
			s.loadingElements = {
				...s.loadingElements,
				[props.key]: {
					isLoading: false,
				},
			};
		});
	};

	const emulateAccount = async (props: {
		authority: PublicKey;
		subAccountPubKey?: PublicKey;
	}) => {
		set((s) => {
			s.isEmulatingAccount = true;
		});

		const state = get();

		const newDriftClient = state.driftClient.client;
		const newKeypair = new Keypair({
			publicKey: props.authority.toBytes(),
			secretKey: new Keypair().publicKey.toBytes(),
		});

		const newWallet: BaseMessageSignerWalletAdapter = {
			publicKey: newKeypair.publicKey,
			autoApprove: false,
			connected: true,
			//@ts-ignore
			signTransaction: () => {
				return Promise.resolve();
			},
			//@ts-ignore
			signAllTransactions: () => {
				return Promise.resolve();
			},
			//@ts-ignore
			connect: () => {},
			disconnect: async () => {},
			//@ts-ignore
			on: (_event: string, _fn: () => void) => {},
		};

		const success = await newDriftClient.updateWallet(
			newWallet,
			undefined,
			undefined,
			true
		);

		if (!success) {
			console.log('Error emulating account');
			return;
		}

		const subscribeSuccess = await newDriftClient.subscribe();
		if (!subscribeSuccess) {
			newDriftClient.unsubscribe();
			console.log('Error subscribing to emulated account');
			return;
		}

		const mockUsdcFaucet = new TokenFaucet(
			state.connection.current,
			newWallet,
			new PublicKey(Env.driftClientProgramId),
			new PublicKey(DEFAULT_PUBLIC_KEY.toString())
		);

		set((state) => {
			state.faucet = mockUsdcFaucet;
			state.wallet.current = {
				adapter: newWallet,
				readyState: WalletReadyState.Installed,
			};
		});
		const walletConnectionState = get().wallet.connectionState;

		walletConnectionState.progress('AdapterConnected');
		walletConnectionState.progress('ClientConnected');

		set((s) => {
			s.wallet.connectionState = walletConnectionState;
		});

		await fetchAndSubscribeSubAccounts(props.subAccountPubKey, true);
		await subscribeToUserUSDCBalance(newWallet.publicKey);
		fetchAndSetDeletedAccountPubKeys(newWallet.publicKey);

		walletConnectionState.progress('SubaccountsSubscribed');
	};

	const emulateAccountForSigning = async (props: {
		authority: PublicKey;
		subAccountPubKey?: PublicKey;
	}): Promise<boolean> => {
		set((s) => {
			s.isEmulatingAccount = true;
		});

		const state = get();

		const newDriftClient = state.driftClient.client;

		const success = await newDriftClient.emulateAccount(props.authority);

		if (!success) {
			console.log('Error emulating account');
			return;
		}

		resetAccountsState();

		await fetchAndSubscribeSubAccounts(props.subAccountPubKey, true);
		await subscribeToUserUSDCBalance(props.authority);

		set((s) => {
			s.authorityParam = props.authority?.toString();
		});

		return true;
	};

	const settleFunding = async () => {
		const state = get();
		const accountsState = accountsGet();
		const driftClient = state.driftClient.client;
		const userAccount =
			accountsState.accounts[accountsState.currentUserKey]?.client;

		const toastId = `settlefunding-${Date.now()}`;

		const userPositions = userAccount.getUserAccount().perpPositions;

		if (!userPositions) {
			captureException(
				new Error(
					`Couldn't settle funding because no positions account for user ${userAccount
						.getUserAccountPublicKey()
						.toString()}`
				)
			);
			console.error(`No positions account to settle funding!`);
			notify({
				type: 'error',
				message: `Couldn't settle funding because there is no linked funding account`,
			});
			return;
		}

		notify({
			type: 'info',
			message: 'Awaiting Transaction',
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			const tx = await driftClient.settleFundingPayment(
				userAccount.userAccountPublicKey
			);

			notify({
				type: 'success',
				message: 'Settled funding',
				action: {
					type: 'txnLink',
					txnSig: tx,
				},
				updatePrevious: true,
			});
		} catch (e: any) {
			captureException(e);
			TransactionErrorHandler.handleError({
				error: e,
				fallbackDescription: `Sorry, there was an unknown error settling the funding payment.`,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const settlePnl = async (
		marketIndex: number,
		userPubKey?: PublicKey
	): Promise<boolean> => {
		const state = get();
		const accountsState = accountsGet();
		const driftClient = state.driftClient.client;
		const accountData = accountsState.accounts[accountsState.currentUserKey];
		const toastId = `settlepnl-${Date.now()}`;

		// if userPubKey is passed then settle that user, if not then settle the current connected user
		const pubKeyToSettle = userPubKey ?? accountData.pubKey;
		let userAccountToSettle = accountData.client.getUserAccount();

		if (userPubKey) {
			const user = new User({
				driftClient: driftClient,
				userAccountPublicKey: pubKeyToSettle,
				accountSubscription: {
					type: 'custom',
					userAccountSubscriber: new OneShotUserAccountSubscriber(
						driftClient.program,
						pubKeyToSettle
					),
				},
			});
			await user.subscribe();
			userAccountToSettle = user.getUserAccount();
		}

		notify({
			type: 'info',
			message: 'Awaiting Transaction',
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			const marketIds = [MarketId.createPerpMarket(marketIndex)];

			const optionalIxs = await UI_UTILS.getOracleCrankIxs(
				marketIds,
				get().driftClient.client,
				state.wallet.current.adapter.name,
				undefined,
				false
			);

			const tx = await handleTxChain(
				driftClient.settlePNL(
					pubKeyToSettle,
					userAccountToSettle,
					marketIndex,
					getTxParams({
						marketId: MarketId.createPerpMarket(marketIndex),
					}),
					optionalIxs
				)
			);

			notify({
				type: 'success',
				message: 'Settled P&L',
				id: toastId,
				action: {
					type: 'txnLink',
					txnSig: tx,
				},
				updatePrevious: true,
				showUntilCancelled: false,
			});

			return true;
		} catch (e: any) {
			captureException(e);
			TransactionErrorHandler.handleError({
				error: e,
				fallbackDescription: `Sorry, there was an unknown error settling your PnL.`,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});

			return false;
		}
	};

	const settleAllPnls = async () => {
		const state = get();
		const accountsState = accountsGet();
		const driftClient = state.driftClient.client;
		const accountData = accountsState.accounts[accountsState.currentUserKey];

		const toastId = `settlepnls-${Date.now()}`;

		notify({
			type: 'info',
			message: 'Settling balances',
			description: 'Awaiting Transaction',
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			const marketIndexesWithPnl = accountData.openPerpPositions
				.filter((pos) => !pos.unsettledPnl.eq(ZERO))
				.map((pos) => pos.marketIndex);

			const txSigs = await driftClient.settleMultiplePNLsMultipleTxs(
				accountData.pubKey,
				accountData.client.getUserAccount(),
				marketIndexesWithPnl,
				SettlePnlMode.TRY_SETTLE,
				getTxParams({
					simulatedComputeUnitMultiplier: 1.6,
					useSimulatedComputeUnits: true,
				})
			);

			const connection = state.connection;

			await waitForMultipleTxnsToConfirm(txSigs);

			const transactionResults = await Promise.all(
				txSigs.map((txSig) =>
					connection.current.getTransaction(txSig, {
						maxSupportedTransactionVersion: 0,
					})
				)
			);

			const logs = transactionResults
				.map((txResult) => txResult?.meta.logMessages ?? [])
				.flat();

			const marketIndexesWithOracleError: number[] = [];
			const marketIndexesWithPnlPoolError: number[] = [];
			logs.forEach((log) => {
				const matches = log.match(/Error\s([^\s]+).*market\s([\d]+)/);
				if (matches?.length) {
					const errorType = matches[1];
					const marketIndex = parseInt(matches[2]);
					if (
						SETTLE_MULTIPLE_PNL_ERROR_TYPES.ORACLE_ERROR.includes(errorType)
					) {
						marketIndexesWithOracleError.push(marketIndex);
					}
					if (
						SETTLE_MULTIPLE_PNL_ERROR_TYPES.PNL_POOL_ERROR.includes(errorType)
					) {
						marketIndexesWithPnlPoolError.push(marketIndex);
					}
				}
			});

			const successfullySettledMarketIndexes = marketIndexesWithPnl.filter(
				(marketIndex) =>
					!marketIndexesWithOracleError.includes(marketIndex) &&
					!marketIndexesWithPnlPoolError.includes(marketIndex)
			);

			if (
				successfullySettledMarketIndexes.length < marketIndexesWithPnl.length
			) {
				// Log errors to console for further debugging
				console.log('Error settling pnl in some markets:', logs);
				notify({
					type: successfullySettledMarketIndexes.length > 0 ? 'info' : 'error',
					message:
						successfullySettledMarketIndexes.length > 0
							? 'Settled balances'
							: 'Could not settle balances',
					description: (
						<SettledPnlAlertContent
							successfullySettledMarketIndexes={
								successfullySettledMarketIndexes
							}
							marketIndexesWithOracleError={marketIndexesWithOracleError}
							marketIndexesWithPnlPoolError={marketIndexesWithPnlPoolError}
						/>
					),
					id: toastId,
					action: {
						type: 'txnLink',
						txnSig: txSigs.length > 1 ? txSigs : txSigs[0],
					},
					updatePrevious: true,
					showUntilCancelled: true,
					steps:
						marketIndexesWithPnlPoolError?.length > 0
							? [
									<Select.Settle
										key="settle_dropdown"
										options={marketIndexesWithPnlPoolError.map(
											(mktIndex) => OrderedPerpMarkets[mktIndex]
										)}
									/>,
							  ]
							: undefined,
				});
			} else {
				notify({
					type: 'success',
					message: 'Settled all balances',
					id: toastId,
					action: {
						type: 'txnLink',
						txnSig: txSigs.length > 1 ? txSigs : txSigs[0],
					},
					updatePrevious: true,
					showUntilCancelled: false,
				});
			}
		} catch (e: any) {
			captureEvent('settle_pnl_error', {
				error: e,
			});
			captureException(e);
			TransactionErrorHandler.handleError({
				error: e,
				fallbackDescription: `Sorry, there was an unknown error settling your balances.`,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const settleInsuranceFundRevenue = async (marketIndex: number) => {
		const state = get();
		const driftClient = state.driftClient.client;
		const toastId = `settleInsuranceFundRevenue-${Date.now()}`;

		notify({
			type: 'info',
			message: 'Awaiting Transaction',
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			const tx = await driftClient.settleRevenueToInsuranceFund(marketIndex);

			notify({
				type: 'success',
				message: 'Settled revenue pool to insurance fund',
				action: {
					type: 'txnLink',
					txnSig: tx,
				},
				updatePrevious: true,
			});
		} catch (e: any) {
			captureException(e);
			TransactionErrorHandler.handleError({
				error: e,
				fallbackDescription: `Sorry, there was an unknown error settling the revenue pool.`,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const getMakerInfoForMarketOrder = async ({
		direction,
		marketIndex,
		driftClient,
		marketType = MarketType.PERP,
		numMakers,
	}: {
		direction: PositionDirection;
		marketIndex: number;
		driftClient: DriftClient;
		marketType?: MarketType;
		numMakers?: number;
	}): Promise<MakerInfo[]> => {
		// Return early if RPC hangs
		const TIMEOUT_SECONDS = 4;

		const resultPromise = (async () => {
			if (ENUM_UTILS.match(marketType, MarketType.SPOT)) return undefined;

			const counterPartySide = ENUM_UTILS.match(
				direction,
				PositionDirection.LONG
			)
				? 'ask'
				: 'bid';

			// spot doesnt support multiple makers so force to 1 even if passed
			const makerLimit = ENUM_UTILS.match(marketType, MarketType.PERP)
				? numMakers ?? 4
				: 1;

			// TODO : If we integrate this with the changes in the new market order toasts then there should be a globally available latest slot, instead of needing to fetch it from the RPC here
			const makersResults =
				await ExchangeHistoryClient.getTopMakersKeysAndAccounts({
					marketIndex,
					marketType,
					side: counterPartySide,
					limit: makerLimit,
				});

			if (!makersResults || makersResults.length === 0) {
				return undefined;
			}

			const makerAccountKeys = makersResults.map(
				(maker) => maker.userAccountPubKey
			);
			const makerStatsAccountKeys = makersResults.map((makerAccount) =>
				getUserStatsAccountPublicKey(
					driftClient.program.programId,
					makerAccount.userAccount.authority
				)
			);
			const makerAccounts = makersResults.map((maker) => maker.userAccount);

			return makerAccountKeys.map((makerUserAccountKey, index) => {
				return {
					maker: makerUserAccountKey,
					makerUserAccount: makerAccounts[index],
					makerStats: makerStatsAccountKeys[index],
				};
			});
		})();

		return promiseTimeout(resultPromise, TIMEOUT_SECONDS * 1000);
	};

	const sendPerpMarketOrderViaSignedMsg = async (
		preppedParams: FinalPreppedMarketOrderProps
	) => {
		const driftClient = get().driftClient.client;
		const userAccount = driftClient.getUserAccount();
		const subAccountId = userAccount.subAccountId;
		const marketIndex = preppedParams.orderParams.marketIndex;

		const bracketOrdersParams = preppedParams.bracketOrdersParams;
		const isShort = ENUM_UTILS.match(
			preppedParams.orderParams.direction,
			PositionDirection.SHORT
		);
		const takeProfit = bracketOrdersParams
			? bracketOrdersParams.find(
					(o) =>
						ENUM_UTILS.match(o.orderType, OrderType.TRIGGER_MARKET) &&
						ENUM_UTILS.match(
							o.triggerCondition,
							isShort
								? OrderTriggerCondition.BELOW
								: OrderTriggerCondition.ABOVE
						)
			  )
			: null;
		const stopLoss = bracketOrdersParams
			? bracketOrdersParams.find(
					(o) =>
						ENUM_UTILS.match(o.orderType, OrderType.TRIGGER_MARKET) &&
						ENUM_UTILS.match(
							o.triggerCondition,
							isShort
								? OrderTriggerCondition.ABOVE
								: OrderTriggerCondition.BELOW
						)
			  )
			: null;

		try {
			const currentSlot = DriftWindow.chainClock?.getState(
				TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL
			)?.slot;

			const wallet = get().wallet.current.adapter;

			const {
				hexEncodedOrderMessage,
				signedMsgOrderParamsMessage: orderMessage,
				slotForSignedMsg,
				signedMsgOrderUuid,
			} = await prepSignedMsgOrder(
				preppedParams.orderParams,
				subAccountId,
				driftClient,
				currentSlot,
				stopLoss,
				takeProfit
			);

			const signedMessage = await signOrderMsg(
				wallet,
				hexEncodedOrderMessage,
				preppedParams.orderParams.auctionDuration +
					slotForSignedMsg.toNumber() -
					MSG_SIGNING_BEFORE_AUCTION_END_SLOT_BUFFER, // added slot buffer before end of auction duration, so user is encouraged to sign with enough time for auction
				() => {
					emitAppEvent('signedMsgOrderInit', {
						...preppedParams.signedMsgOrderToastEventProps,
						signedMsgOrderPayload: orderMessage,
					});
				},
				() => {
					emitAppEvent('signedMsgOrderMessageSlotExpired', {
						...preppedParams.signedMsgOrderToastEventProps,
						signedMsgOrderPayload: orderMessage,
					});
				}
			);
			emitAppEvent('signedMsgOrderSigned', {
				...preppedParams.signedMsgOrderToastEventProps,
				signedMsgOrderPayload: orderMessage,
			});
			const hash = digestSignature(Uint8Array.from(signedMessage));
			console.log(
				`Sending signed message order for hash: ${hash}, time: ${Date.now()}, hash: ${hash}`
			);

			const signedMsgUserOrdersAccountPubkey = getSignedMsgUserAccountPublicKey(
				driftClient.program.programId,
				wallet.publicKey
			);
			const swiftRes = await SwiftClient.sendAndConfirmSwiftOrderWS(
				driftClient.connection,
				driftClient,
				marketIndex,
				preppedParams.orderParams.marketType,
				hexEncodedOrderMessage.toString(),
				Buffer.from(signedMessage),
				userAccount.authority,
				signedMsgUserOrdersAccountPubkey,
				signedMsgOrderUuid,
				// this is essentially the number of slots in the auction multiple by slot time and 1000 to convert to ms
				(preppedParams.orderParams.auctionDuration + 15) * 0.4 * 1000, // TODO: slot times are not always 400 ms, we should possibly use the DriftWindow chain clock instead
				wallet.publicKey
			);

			swiftRes.subscribe((event) => {
				if (event.type === 'sent') {
					dlog(
						`market_orders`,
						`Sent signed message order with hash : ${event.hash}`
					);
				}
				if (event.type === 'confirmed') {
					emitAppEvent('signedMsgOrderConfirmed', {
						...preppedParams.signedMsgOrderToastEventProps,
						signedMsgOrderPayload: orderMessage,
						orderId: Number(event.orderId),
					});
				}
				if (event.type === 'expired') {
					emitAppEvent('signedMsgOrderExpired', {
						...preppedParams.signedMsgOrderToastEventProps,
					});
				}
				if (event.type === 'errored') {
					emitAppEvent('signedMsgOrderFailed', {
						...preppedParams.signedMsgOrderToastEventProps,
						message: event.message,
					});
				}
			});
		} catch (e: any) {
			console.error(e);
			captureException(e);
			emitAppEvent(
				'signedMsgOrderFailed',
				preppedParams.signedMsgOrderToastEventProps
			);
		}
	};

	const sendPerpMarketOrder = async (
		preppedParams: FinalPreppedMarketOrderProps,
		txPrepMetricKey: string
	) => {
		const driftClient = get().driftClient.client;

		const marketIndex = preppedParams.orderParams.marketIndex;
		const marketId = MarketId.createPerpMarket(marketIndex);
		const bracketOrdersParams = preppedParams.bracketOrdersParams;
		const auctionToastEventProps = preppedParams.auctionToastEventProps;
		const nextOrderId = preppedParams.nextOrderId;
		const { reduceOnly, baseAssetAmount, direction } =
			preppedParams.orderParams;

		dlog(
			'market_orders::\n',
			'Sending signed message order\n',
			`Auction start: ${preppedParams.orderParams.auctionStartPrice.toNumber()}\n`,
			`Auction end: ${preppedParams.orderParams.auctionEndPrice.toNumber()}\n`,
			`Auction duration: ${preppedParams.orderParams.auctionDuration}\n`,
			`Base asset amount: ${baseAssetAmount}\n`,
			`Direction: ${ENUM_UTILS.toStr(direction)}\n`
		);

		const preppedTxs = await driftClient.prepareMarketOrderTxs(
			preppedParams.orderParams,
			preppedParams.userAccountPublicKey,
			preppedParams.userAccount,
			undefined,
			getTxParams({
				marketId,
			}),
			bracketOrdersParams,
			preppedParams.referrerInfo,
			preppedParams.cancelExistingOrders,
			reduceOnly
		);

		const { signedTxData, signedTxMap } =
			await driftClient.txHandler.getSignedTransactionMap(preppedTxs);
		const signedCancelExistingOrdersTx = signedTxMap.cancelExistingOrdersTx;
		const signedSettlePnlTx = signedTxMap.settlePnlTx;
		const signedFillTx = signedTxMap.fillTx;

		emitAppEvent('expectedAuctionTxSigned', {
			...auctionToastEventProps,
			signedTxData,
		});

		const txSig = await handleTxChain(
			driftClient.sendSignedTx.bind(driftClient)(signedTxMap.marketOrderTx),
			undefined,
			{
				measurePreSignTime: true,
				measurePreSignTimeMetricKey: txPrepMetricKey,
			}
		);

		if (preppedParams.cancelExistingOrders && signedCancelExistingOrdersTx) {
			setAutoCancelOrdersTx([marketIndex], signedCancelExistingOrdersTx);
		}

		if (reduceOnly && signedSettlePnlTx) {
			setAutoSettleTx([marketIndex], signedSettlePnlTx);
		}

		dlog(`market_orders`, `Sent regular market order with txSig : ${txSig}`);

		if (signedFillTx) {
			emitAppEvent('fallbackAuctionOrderSignedTx', {
				tx: signedFillTx as Transaction,
				orderId: nextOrderId,
			});
		}
	};

	const openPerpMarketOrder = async (
		props: PerpTradeFormOutputProps,
		context: InvariantCheckingContext,
		prePreparedTx?: PreppedMarketOrders
	) => {
		const usingSignedMsgOrderRoute = props.isSwiftSelected;

		if (prePreparedTx) {
			dlog(`pre-prepped_transactions`, `perp_has_pre-prepped_tx`);
		} else {
			dlog(`pre-prepped_transactions`, `perp_has_no_pre-prepped_tx`);
		}

		if (handleIsGeoblocked(props.reduceOnly)) {
			return;
		}

		await handleSwitchUser();

		// # PREP ORDER PARAMS
		const preppedParams = ORDER_PREP_UTILS.prepPerpMarketOrderParams(props, {
			getUserAccountData: () => {
				const accountsState = accountsGet();
				return accountsState.accounts[accountsState.currentUserKey];
			},
			getDriftClient: () => get().driftClient.client,
			getPriceInfo: priceInfoGetter,
		});

		const result = doInvariantChecksForContext(
			context,
			preppedParams.orderParams,
			{
				maxLeverageSelected: props.maxLeverageSelected,
			}
		);

		if (!result.success) {
			return;
		}

		const marketIndex = preppedParams.orderParams.marketIndex;
		const auctionToastEventProps = preppedParams.auctionToastEventProps;
		const signedMsgOrderToastEventProps =
			preppedParams.signedMsgOrderToastEventProps;
		const toastId = preppedParams.toastId;

		const txPrepMetricKey = 'perp_market_order';

		let usedPlaceAndTake = false;

		// Put these actions in inner functions here for enhanced readability in the "try place order" secion
		const tryPlaceAndTake = async () => {
			const txSig = await openPerpPlaceAndTake({
				marketIndex,
				...preppedParams,
				prePreppedPlaceAndTakeTxs: prePreparedTx,
			});

			if (txSig) {
				dlog(`market_orders`, `Sent placeAndTake order with txSig : ${txSig}`);
			} else {
				dlog(`market_orders`, `Attempted placeAndTake but failed`);
			}

			usedPlaceAndTake = !!txSig;
		};

		const tryRegularOrder = async () => {
			await sendPerpMarketOrder(preppedParams, txPrepMetricKey);
		};

		try {
			// # TRY PLACE THE ORDER
			// Start the timer for the tx prep metric
			TX_PREP_TIME_METRIC_START_TIME_CACHE.set(txPrepMetricKey, Date.now());

			if (!usingSignedMsgOrderRoute) {
				emitAppEvent('expectedAuctionInit', auctionToastEventProps);
			}

			// Attempt signed message order first if enabled
			let usedSignedMsgOrderRoute = false;
			if (usingSignedMsgOrderRoute) {
				await sendPerpMarketOrderViaSignedMsg(preppedParams);
				usedSignedMsgOrderRoute = true;
			}

			// Attempt place and take if necessary, or fall back to regular order
			if (!usedSignedMsgOrderRoute && preppedParams.attemptPlaceAndTake) {
				await tryPlaceAndTake();
			}

			if (!usedPlaceAndTake && !usingSignedMsgOrderRoute) {
				await tryRegularOrder();
			}

			// Handle successful transaction
			handleMarketOrderSuccess(
				preppedParams.user,
				auctionToastEventProps,
				usingSignedMsgOrderRoute
			);

			// Return
			return true;
		} catch (e: any) {
			if (usingSignedMsgOrderRoute) {
				emitAppEvent('signedMsgOrderFailed', signedMsgOrderToastEventProps);
			} else {
				emitAppEvent('expectedAuctionTxFailed', auctionToastEventProps);
			}
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const sendPerpPlaceAndTakeTx = async (
		placeAndTakeTxs: Awaited<
			ReturnType<
				DriftClient['preparePlaceAndTakePerpOrderWithAdditionalOrders']
			>
		>,
		orderType: OrderType,
		auctionToastEventProps: AuctionToastEvent,
		txPrepMetricKey: string
	) => {
		const driftClient = get().driftClient.client;

		const result = await handleTxChain(
			driftClient.sendTransaction(placeAndTakeTxs.placeAndTakeTx),
			(signedTxData) => {
				const shouldSkip =
					ENUM_UTILS.match(orderType, OrderType.LIMIT) ||
					ENUM_UTILS.match(orderType, OrderType.TRIGGER_LIMIT) ||
					ENUM_UTILS.match(orderType, OrderType.TRIGGER_MARKET);

				if (shouldSkip) {
					return;
				}

				emitAppEvent('expectedAuctionTxSigned', {
					...auctionToastEventProps,
					signedTxData,
				});
			},
			{
				measurePreSignTime: true,
				measurePreSignTimeMetricKey: txPrepMetricKey,
			}
		);

		return { ...result, ...placeAndTakeTxs };
	};

	const setAutoSettleTx = (
		marketIndexes: number[],
		signedSettlePnlTx: Transaction | VersionedTransaction
	) => {
		set((s) => {
			s.pnlsAwaitingAutoSettle = [
				...s.pnlsAwaitingAutoSettle,
				{
					marketIndexesOfPnls: marketIndexes,
					signedSettlePnlsTx: signedSettlePnlTx,
				},
			];
		});
	};

	const setAutoCancelOrdersTx = (
		marketIndexes: number[],
		signedCancelExistingOrdersTx: Transaction | VersionedTransaction
	) => {
		set((s) => {
			s.ordersAwaitingAutoCancel = [
				...s.ordersAwaitingAutoCancel,
				{
					marketIndexesOfOrders: marketIndexes,
					signedCancelExistingOrdersTx,
				},
			];
		});
	};

	const prepPerpPlaceAndTake = async ({
		orderParams,
		subAccountId,
		referrerInfo,
		bracketOrdersParams,
		cancelExistingOrders,
	}: {
		orderParams: OptionalOrderParams;
		subAccountId?: number;
		referrerInfo?: ReferrerInfo;
		bracketOrdersParams?: OptionalOrderParams[];
		cancelExistingOrders?: boolean;
	}) => {
		const marketIndex = orderParams.marketIndex;
		const state = get();
		const driftClient = state.driftClient.client;

		const txParams = getTxParams({
			computeUnits: MAX_COMPUTE_UNITS,
			marketId: MarketId.createPerpMarket(marketIndex),
			simulatedComputeUnitMultiplier: 1.4,
			lowerBoundCu: Env.placeAndTakeLowerBoundCu,
		});

		const makerInfos = await getMakerInfoForMarketOrder({
			direction: orderParams.direction,
			marketIndex,
			marketType: MarketType.PERP,
			driftClient: state.driftClient.client,
		});

		const hasMakers = !!makerInfos && makerInfos?.length > 0;

		if (!hasMakers) return null;

		const triggerSettlePnl = orderParams?.reduceOnly;

		const FALLBACK_ON_FAILED_SIM = true; // This controls whether the drift client should just exit early if the simulated place and take fails. Note: This is intertwined with the logic in the getMakerInfoForMarketOrder method - which currently DOESN'T check for staleness in case of the dlob-server having stale data. The idea for not doing the staleness check is that if it does give a stale response then the sim will fail so we'll skip it because of this parameter. If we want to adjust our strategy we may want to add staleness checking logic in there.

		// add optional crank ixs for the market
		const marketIds = [MarketId.createPerpMarket(marketIndex)];

		const optionalIxs = await UI_UTILS.getOracleCrankIxs(
			marketIds,
			get().driftClient.client,
			state.wallet.current.adapter.name,
			undefined,
			false
		);

		return driftClient.preparePlaceAndTakePerpOrderWithAdditionalOrders(
			orderParams,
			makerInfos,
			referrerInfo,
			bracketOrdersParams,
			txParams,
			subAccountId,
			cancelExistingOrders,
			triggerSettlePnl,
			FALLBACK_ON_FAILED_SIM,
			Env.defaultAuctionDurationPercentage,
			optionalIxs
		);
	};

	const handleMarketOrderSuccess = (
		user: User,
		auctionToastEventProps: AuctionToastEvent,
		usingSignedMsgOrderRoute: boolean
	) => {
		const state = get();
		updateAfterTx(state.driftClient.client, user);
		dlog(`stuck_toasts`, `order_confirmed`);
		// we handle success event for signed message order inside the signed message order function, so we don't need to emit it here again
		if (!usingSignedMsgOrderRoute) {
			emitAppEvent('tradeConfirmation');
			// Emit event for auction state handling
			emitAppEvent('expectedAuctionTxConfirmed', auctionToastEventProps);
		}
	};

	const openPerpPlaceAndTake = async ({
		marketIndex,
		orderParams,
		subAccountId,
		referrerInfo,
		bracketOrdersParams,
		cancelExistingOrders,
		auctionToastEventProps,
		prePreppedPlaceAndTakeTxs,
	}: {
		marketIndex: number;
		orderParams: OptionalOrderParams;
		subAccountId?: number;
		referrerInfo?: ReferrerInfo;
		bracketOrdersParams?: OptionalOrderParams[];
		cancelExistingOrders?: boolean;
		auctionToastEventProps: AuctionToastEvent;
		prePreppedPlaceAndTakeTxs?: Awaited<
			ReturnType<
				DriftClient['preparePlaceAndTakePerpOrderWithAdditionalOrders']
			>
		>;
	}) => {
		console.log('Using placeAndTake');

		const triggerSettlePnl = orderParams?.reduceOnly;

		const txPrepMetricKey = 'perp_place_and_take';
		TX_PREP_TIME_METRIC_START_TIME_CACHE.set(txPrepMetricKey, Date.now());

		let preppedPlaceAndTakeTxs = prePreppedPlaceAndTakeTxs;

		if (!preppedPlaceAndTakeTxs) {
			if (prePreppedPlaceAndTakeTxs === null) {
				// Only record a metric for a missing pre-prepped tx if it was explicitly set to null - otherwise we assume it was never attempted to be prepped so shouldn't be included (e.g. in the close position + trade confirmation modals)
				DriftWindow.metricsBus.next({
					type: 'pre_prepped_transaction',
					value: {
						txType: 'perp_place_and_take',
						usingPrePrepped: false,
					},
				});
			}
			preppedPlaceAndTakeTxs = await prepPerpPlaceAndTake({
				orderParams,
				subAccountId,
				referrerInfo,
				bracketOrdersParams,
				cancelExistingOrders,
			});
		} else {
			DriftWindow.metricsBus.next({
				type: 'pre_prepped_transaction',
				value: {
					txType: 'perp_place_and_take',
					usingPrePrepped: true,
				},
			});
		}

		if (!preppedPlaceAndTakeTxs) {
			return null;
		}

		const result = await sendPerpPlaceAndTakeTx(
			preppedPlaceAndTakeTxs,
			orderParams.orderType,
			auctionToastEventProps,
			txPrepMetricKey
		);

		if (!result) {
			// simulation failed, return empty and fallback to regular order
			console.log('PlaceAndTake failed, falling back to regular order');
			return null;
		}

		const {
			txSig,
			cancelExistingOrdersTx: signedCancelExistingOrdersTx,
			settlePnlTx: signedSettlePnlTx,
		} = result;

		// trigger cancel orders if requested
		if (cancelExistingOrders && signedCancelExistingOrdersTx) {
			setAutoCancelOrdersTx([marketIndex], signedCancelExistingOrdersTx);
		}

		// trigger auto pnl settle if order is reduce only
		if (triggerSettlePnl && signedSettlePnlTx) {
			setAutoSettleTx([marketIndex], signedSettlePnlTx);
		}

		return txSig;
	};

	const _spotPlaceAndTake = async ({
		marketIndex,
		orderParams,
		referrerInfo,
	}: {
		marketIndex: number;
		marketType: MarketType;
		orderParams: OptionalOrderParams;
		user: User;
		subAccountId?: number;
		referrerInfo?: ReferrerInfo;
		bracketOrdersParams?: OptionalOrderParams[];
		cancelExistingOrders?: boolean;
		auctionToastEventProps: AuctionToastEvent;
	}) => {
		const state = get();
		const driftClient = state.driftClient.client;
		const txParams = getTxParams({
			computeUnits: MAX_COMPUTE_UNITS,
			marketId: MarketId.createSpotMarket(marketIndex),
			simulatedComputeUnitMultiplier: 1.4,
			lowerBoundCu: Env.placeAndTakeLowerBoundCu,
		});
		const txPrepTimeMetricKey = 'spot_place_and_take';
		TX_PREP_TIME_METRIC_START_TIME_CACHE.set(txPrepTimeMetricKey, Date.now());

		const serumFulfillmentConfig =
			await driftClient.getSerumV3FulfillmentConfig(
				OrderedSpotMarkets[marketIndex].serumMarket
			);

		const makerInfos = await getMakerInfoForMarketOrder({
			direction: orderParams.direction,
			marketIndex,
			marketType: MarketType.SPOT,
			driftClient: state.driftClient.client,
		});

		const hasMakers = !!makerInfos && makerInfos?.length > 0;

		if (!hasMakers) return null;

		const txSig = await handleTxChain(
			driftClient.placeAndTakeSpotOrder(
				orderParams,
				serumFulfillmentConfig,
				makerInfos[0],
				referrerInfo,
				txParams
			),
			undefined,
			{
				measurePreSignTime: true,
				measurePreSignTimeMetricKey: txPrepTimeMetricKey,
			}
		);

		return txSig;
	};

	const fillOrder = async (
		orderId: number,
		marketIndex: number,
		direction: PositionDirection
	) => {
		try {
			const driftClient = get().driftClient.client;
			const accountsState = accountsGet();

			const userAccount = accountsState.accounts[accountsState.currentUserKey];

			const makers = await getMakerInfoForMarketOrder({
				direction,
				marketIndex,
				driftClient,
			});

			// TODO - needs sdk refactor to work for spot orders
			const txSig = await handleTxChain(
				driftClient.fillPerpOrder(
					userAccount.pubKey,
					userAccount.client.getUserAccount(),
					{
						marketIndex,
						orderId,
					},
					makers,
					undefined,
					getTxParams(),
					0,
					get().wallet?.current?.adapter?.publicKey
				)
			);

			dlog('fill_order_tx', txSig);

			notify({
				message: 'Filled Order',
				action: {
					type: 'txnLink',
					txnSig: txSig,
				},
				type: 'success',
			});
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const closePosition = async (marketIndex: number) => {
		const state = get();
		const accountsState = accountsGet();
		const driftClient = state.driftClient.client;
		const userId = get().driftClient.client.activeSubAccountId;

		const toastId = `close-${marketIndex.toString()}-${Date.now()}`;

		dlog(`stuck_toasts`, `close_position .. toastId : ${toastId}`);

		notify({
			type: 'awaiting',
			message: 'Confirming Trade',
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			const tx = await driftClient.closePosition(marketIndex);

			set((s) => {
				// @ts-ignore
				s.tradeForm.baseSizeStringValue = '';
				// @ts-ignore
				s.tradeForm.quoteSizeStringValue = '';
				s.tradeForm.closingPosition = false;
			});

			notify({
				type: 'success',
				message: 'Transaction Successful',
				action: {
					type: 'txnLink',
					txnSig: tx,
				},
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});

			dlog(`stuck_toasts`, `order_confirmed `);
			emitAppEvent('tradeConfirmation');

			updateAfterTx(
				state.driftClient.client,
				accountsState.accounts[userId]?.client
			);
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId: toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const generateConnection = (rpc: RpcEndpoint) => {
		if (process.env.NEXT_PUBLIC_RPC_OVERRIDE) {
			return new Connection(process.env.NEXT_PUBLIC_RPC_OVERRIDE, {
				commitment: DEFAULT_COMMITMENT_LEVEL,
				wsEndpoint: process.env.NEXT_PUBLIC_RPC_WS_OVERRIDE,
			});
		}

		return new Connection(rpc.value, {
			commitment: DEFAULT_COMMITMENT_LEVEL,
			wsEndpoint: rpc?.wsValue,
		});
	};

	const generateDriftClient = (
		connection: Connection,
		accountLoader: BulkAccountLoader,
		wallet: Wallet,
		env: DriftEnv,
		subscriberType?: SubscriberType
	) => {
		const driftClientConfig: DriftClientConfig = {
			connection: connection,
			wallet,
			programID: new PublicKey(Env.driftClientProgramId),
			accountSubscription: {
				type: subscriberType || 'polling',
				accountLoader: accountLoader,
			},
			perpMarketIndexes: Env.perpMarketIndexes,
			spotMarketIndexes: Env.spotMarketIndexes,
			oracleInfos: Env.oracleInfos,
			userStats: true,
			includeDelegates: true,
			env,
			// dont waste rpc calls loading users for this made up wallet
			skipLoadUsers: true,
			enableMetricsEvents: true,
			txHandlerConfig: {
				blockhashCachingEnabled: Env.useCachedBlockhashFetcherInDriftClient,
			},
			opts: DRIFT_CLIENT_OPTS,
			delistedMarketSetting: DelistedMarketSetting.Unsubscribe,
		};

		return new DriftClient(driftClientConfig);
	};

	const unsubscribeClients = async () => {
		const accountStore = accountsGet();
		const currentDriftClient = get()?.driftClient?.client;
		const currentUserClient =
			accountStore?.accounts?.[accountStore?.currentUserKey]?.client;

		if (currentUserClient) await currentUserClient.unsubscribe();
		if (currentDriftClient) await currentDriftClient.unsubscribe();
	};

	const getClientsForNewConnection = (
		newRpc: RpcEndpoint,
		subscriberType: SubscriberType,
		env: DriftEnv
	) => {
		//@ts-ignore
		const walletInStore = get()?.wallet?.current?.adapter as Wallet;
		const defaultWallet = Env.defaultWallet;

		// There's sometimes a race condition where the wallet is auto-connecting, puts itself in the store, but it's not properly set up / configured. This catches that and uses the default wallet instead. Another handler to protect against this race-condition in the useKeepWalletsInSync hook will make sure the wallet properly connects when it's ready
		const walletToUse =
			walletInStore && walletInStore.publicKey ? walletInStore : defaultWallet;

		const newConnection = generateConnection(newRpc);
		const newAccountLoader = generateAccountLoader(newConnection);

		const newDriftClient = generateDriftClient(
			newConnection,
			newAccountLoader,
			walletToUse,
			Env.sdkEnv,
			subscriberType
		);

		const usingDevnet = env === 'devnet';

		const rpcs = EnvironmentConstants.rpcs[usingDevnet ? 'dev' : 'mainnet'];

		const additionalConnections = rpcs
			.filter(
				(rpc) => rpc.value !== newRpc.value && rpc.allowAdditionalConnection
			)
			.map((rpc) => generateConnection(rpc));

		const additionalTxSenderCallbacks = usingDevnet
			? []
			: [
					(rawTx: string) => {
						fetch(`/api/rpc/sendTransaction`, {
							headers: {
								'Content-Type': 'application/json',
							},
							method: 'POST',
							body: JSON.stringify({ tx: rawTx }),
						});
					},
			  ];

		newDriftClient.txSender = new TX_SENDER_TO_USE({
			connection: newConnection,
			wallet: walletToUse,
			additionalConnections,
			additionalTxSenderCallbacks,
			// @ts-ignore
			confirmationStrategy: Env.confirmationStrategy,
			retrySleep: Env.txSenderRetryInterval,
			txHandler: newDriftClient.txHandler,
		});

		return { newConnection, newAccountLoader, newDriftClient };
	};

	const handleSubscriptionSuccess = ({
		selectedRpc,
		newConnection,
		newAccountLoader,
		newDriftClient,
	}: {
		selectedRpc: RpcEndpoint;
		newConnection: Connection;
		newAccountLoader: BulkAccountLoader;
		newDriftClient: DriftClient;
	}) => {
		set((s) => {
			s.connection.rpc = selectedRpc;
			s.connection.current = newConnection;
			s.connection.accountLoader = newAccountLoader;
			s.driftClient.client = newDriftClient;
			s.currentBulkAccountLoader = newAccountLoader;
		});

		newConnection.getVersion().then((version) => {
			Env.rpcVersion = version;
		});
	};

	// Wrapping these steps in a lambda so that we can easily retry if the initial attemp fails
	const runConnectionSteps = async (
		newRpc: RpcEndpoint,
		subscriberType: SubscriberType,
		env: DriftEnv
	) => {
		try {
			await unsubscribeClients();

			const newClients = getClientsForNewConnection(
				newRpc,
				subscriberType,
				env
			);

			const connectionResult = await subscribeToDriftClientData(
				newClients.newDriftClient
			);

			DriftWindow.recordStartupTimeMetric('driftClientSubscribe');

			if (connectionResult) {
				handleSubscriptionSuccess({
					selectedRpc: newRpc,
					...newClients,
				});
			}

			return connectionResult;
		} catch (e: any) {
			console.log(`Caught error running connection steps`);
			console.error(e);
			return false;
		}
	};

	/**
	 * Initialize or update the app's connection, clearing house, user account, etc. and subscribes to all.
	 * @param newRpc
	 * @param initializing
	 */
	const updateConnection = async (
		newRpc: RpcEndpoint,
		subscriberType: SubscriberType,
		env: DriftEnv,
		context: 'initializing' | 'changeConnection' | 'recoveryAttempt'
	) => {
		set((s) => {
			s.rpcUrl = newRpc.value;
		});

		if (context === 'changeConnection') {
			notify({
				type: 'info',
				message: 'Switching to new RPC',
			});
		}

		let connectedSuccessfully = false;
		const subscriptionResult = await runConnectionSteps(
			newRpc,
			subscriberType,
			env
		);
		connectedSuccessfully = subscriptionResult;

		const handleOpenRpcSettings = () => {
			showModal('showNetworkModal');
		};

		/**
		 * To handle connection failures what do we want to do?
		 * - First: Delay then try again
		 * - Second:
		 * 	- If they're using a custom RPC then notify them that we're having trouble with the custom RPC and exit
		 * 	- If using a standard RPC, then try another available one
		 * 	- Third: Things aren't working .. Notify the user
		 * 	- Is there an alternative status we can display to communicate that connection didn't work, rathe than just showing the "Loading" state which could be confusing. A retry button too perhaps?
		 * 	- Might be good to provide options to fix the state like: Reset Local Storage
		 */

		const toastId = 'RPC_CONNECTION_TOAST';
		const initialConnectionFailed = !subscriptionResult;

		if (!subscriptionResult) {
			let retryLoop = true;
			let retryCount = 0;
			while (retryLoop) {
				const settings = syncGetCurrentSettings();
				const usingCustomRpc = settings.rpcEndpoint?.label === CUSTOM_RPC_LABEL;

				switch (retryCount) {
					case 0: {
						console.log(`CONNECTION RETRY 0 : Retrying ${newRpc.value}`);
						notify({
							type: 'info',
							message: 'Connecting to Drift',
							description: 'Awaiting subscription to blockchain',
							id: toastId,
						});
						// Retry after a delay
						await UI_UTILS.sleep(3000);
						const retryAttemptResult = await runConnectionSteps(
							newRpc,
							subscriberType,
							env
						);
						if (retryAttemptResult) {
							retryLoop = false;
							connectedSuccessfully = true;
						}
						break;
					}
					default: {
						const rpcOffset = retryCount - 1;

						const env = Env.sdkEnv;
						const availableRpcs = UI_UTILS.getRpcOptions(env);

						const exhaustedAllOptions = rpcOffset >= availableRpcs.length;

						if (exhaustedAllOptions) {
							// # Handle unrecoverale RPC issues
							console.log(`CONNECTION FAILED`);
							unsubscribeClients();
							// TODO: We could potentially handle this more gracefully but it's a bigger job than I want to take right now. Think we should offer the user a modal with some options.
							notify({
								type: 'error',
								message: 'RPC Connection Error',
								description:
									'Unable to connect to Drift. Please try refreshing the page or changing the RPC connection settings.',
								id: toastId,
								showUntilCancelled: true,
								action: {
									type: 'custom',
									content: (
										<Button.Secondary
											size="SMALL"
											onClick={handleOpenRpcSettings}
										>
											Change RPC
										</Button.Secondary>
									),
								},
							});
							retryLoop = false;
							break;
						} else {
							// # Handle retry with a new RPC
							const nextRpcToTry = availableRpcs[rpcOffset];
							console.log(
								`CONNECTION RETRY ${retryCount} : Retrying ${nextRpcToTry.value}`
							);

							await UI_UTILS.sleep(1000);

							const retryAttemptResultSuccess = await runConnectionSteps(
								nextRpcToTry,
								subscriberType,
								env
							);

							if (usingCustomRpc && retryAttemptResultSuccess) {
								notify({
									type: 'warning',
									message: 'Custom RPC Error',
									description:
										'There was an issue connecting to Drift using your custom RPC configuration, switched to default RPC config.',
									showUntilCancelled: true,
									id: toastId,
								});
							}

							if (retryAttemptResultSuccess) {
								retryLoop = false;
								GLOBAL_SETTINGS_CLIENT.updateSettings({
									rpcEndpoint: nextRpcToTry,
								});
								connectedSuccessfully = true;
							}
						}
						break;
					}
				}
				retryCount++;
			}
		}

		if (connectedSuccessfully) {
			if (initialConnectionFailed) {
				notify({
					type: 'success',
					message: 'Connected to Drift',
					description: '',
					id: toastId,
				});
			}
			emitAppEvent('rpcAndClientSubscribed');
		}
	};

	const switchMarket = ({
		marketIndex,
		marketType,
		goToTradePage,
	}: {
		marketIndex: number;
		marketType: MarketType;
		goToTradePage?: boolean;
	}) => {
		const newMarket = new UIMarket(marketIndex, marketType);
		const currentMarket = get().selectedMarket?.current;
		const tradePageTab = get().userInfoTable.currentTab;

		if (goToTradePage) {
			router.push(`/${newMarket.market.symbol}`);
		} else if (!newMarket.equals(currentMarket)) {
			if (
				window.location.pathname === '/' ||
				window.location.pathname === `/${currentMarket.market.symbol}`
			) {
				console.log(newMarket, currentMarket, window.location.pathname);
				// We don't want to use router.push here because that causes a full remount of the page
				// instead we just manipulate the history. More info here: https://github.com/vercel/next.js/discussions/50711
				window.history.pushState(null, '', `/${newMarket.market.symbol}`);
			}
		}

		const newMarketIsSpot = matchEnum(newMarket?.marketType, MarketType.SPOT);
		const currentMarketIsSpot = matchEnum(
			currentMarket?.marketType,
			MarketType.SPOT
		);

		// DPE-1073: Switch tab between positions and balances when switching between perp and spot
		// If the tab was already on something else or the market type didn't change, then don't switch it
		if (
			!currentMarketIsSpot &&
			newMarketIsSpot &&
			tradePageTab === 'positions'
		) {
			set((s) => {
				s.userInfoTable.currentTab = 'balances';
			});
		} else if (
			currentMarketIsSpot &&
			!newMarketIsSpot &&
			tradePageTab === 'balances'
		) {
			set((s) => {
				s.userInfoTable.currentTab = 'positions';
			});
		}

		if (!newMarket.equals(currentMarket)) {
			setSelectedMarket(newMarket);
			// reset the saved limit price only when market actually changes
			set((s) => {
				s.tradeForm.savedLimitPrice = undefined;
			});
		}
	};

	const showEditOrderModal = (
		orderToEdit: UISerializableOrder & {
			accountName?: string;
		}
	) => {
		set((s) => {
			s.popups.editOrderPopupOptions.orderToEdit = orderToEdit;
			s.modals.showEditOrderModal = true;
		});
	};

	const hideEditOrderModal = () => {
		set((s) => {
			s.popups.editOrderPopupOptions.orderToEdit = null;
			s.popups.editOrderPopupOptions.popupTargetId = undefined;
			s.modals.showEditOrderModal = false;
		});
	};

	const editOpenOrder = async ({
		existingOrder,
		side,
		oraclePrice,
		newBaseAmount,
		newLimitPrice,
		newOraclePriceOffset,
		newOrderType,
		newTriggerPrice,
		priceImpactInfo,
		slippageTolerance,
	}: {
		existingOrder: UISerializableOrder & { accountName?: string };
		side: 'buy' | 'sell';
		oraclePrice: BN;
		newBaseAmount?: BigNum;
		newLimitPrice?: BigNum;
		newOraclePriceOffset?: number;
		newOrderType?: UIOrderTypeValue;
		newTriggerPrice?: BigNum;
		priceImpactInfo?: PriceImpactInfo;
		slippageTolerance?: number;
	}): Promise<boolean> => {
		const state = get();
		if (state.isGeoblocked) {
			notify({
				type: 'error',
				message: 'Geoblocked',
				description:
					'You are not allowed to use Drift from a restricted territory',
				lengthMs: 5000,
			});
			return;
		}

		const toastId = `${newOrderType.value}-${side}-${Date.now()}`;

		const newOrderIsLimit = ENUM_UTILS.match(
			newOrderType.orderType,
			OrderType.LIMIT
		);

		const isSpot = ENUM_UTILS.match(existingOrder.marketType, MarketType.SPOT);

		const toastMessageOptions = {
			baseSize: newBaseAmount,
			direction: side,
			orderType: newOrderType.value,
			marketName: new UIMarket(
				existingOrder.marketIndex,
				existingOrder.marketType
			).symbol,
			priceOne: newOrderIsLimit
				? newLimitPrice.toNum()
				: newTriggerPrice.toNum(),
			priceTwo: newOrderIsLimit ? 0 : newTriggerPrice.toNum(),
			editingOrder: true,
			isSpot,
		};

		const marketId = new MarketId(
			existingOrder.marketIndex,
			existingOrder.marketType
		);

		const stepSize = UI_UTILS.getStepSizeForMarket(
			marketId,
			state.driftClient.client
		);

		const editingOrderMessage = getOrderToastMessage({
			stage: 'placing',
			marketId,
			stepSize,
			...toastMessageOptions,
		});

		notify({
			id: toastId,
			type: 'info',
			message: editingOrderMessage.message,
			description: editingOrderMessage.description,
			subDescription: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});
		try {
			const driftClient = state.driftClient.client;

			const orderTypeChanged = !ENUM_UTILS.match(
				newOrderType.orderType,
				existingOrder.orderType
			);
			const newOrderTypeRequiresTrigger =
				orderTypeChanged &&
				(ENUM_UTILS.match(newOrderType.orderType, OrderType.TRIGGER_LIMIT) ||
					ENUM_UTILS.match(newOrderType.orderType, OrderType.TRIGGER_MARKET));

			const orderIsTP =
				newOrderType.value === 'takeProfitLimit' ||
				newOrderType.value === 'takeProfitMarket';
			const newTriggerCondition = newOrderTypeRequiresTrigger
				? side === 'buy'
					? orderIsTP
						? OrderTriggerCondition.BELOW
						: OrderTriggerCondition.ABOVE
					: orderIsTP
					? OrderTriggerCondition.ABOVE
					: OrderTriggerCondition.BELOW
				: undefined;

			const modifyOrderOptions = {
				orderId: existingOrder.orderId,
				newBaseAmount: new BN(newBaseAmount.toString()),
				newLimitPrice: new BN(newLimitPrice.toString()),
				newOraclePriceOffset: newOraclePriceOffset * PRICE_PRECISION.toNumber(),
				newOrderType: newOrderType.orderType,
				newTriggerPrice: new BN(newTriggerPrice.toString()),
				newTriggerCondition: newTriggerCondition,
				isSpot: ENUM_UTILS.match(existingOrder.marketType, MarketType.SPOT),
			};

			let additionalOptions = {};
			const orderTypeChangedToMarket =
				orderTypeChanged &&
				ENUM_UTILS.match(newOrderType.orderType, OrderType.MARKET);

			const settings = syncGetCurrentSettings();

			const { bestPrice, entryPrice, worstPrice } = priceImpactInfo;

			if (orderTypeChangedToMarket) {
				const marketId = new MarketId(
					existingOrder.marketIndex,
					existingOrder.marketType
				);

				const allowInfSlippage = slippageTolerance == undefined;

				const { markPrice } = priceInfoGetter(marketId);

				const priceObject = COMMON_UI_UTILS.getPriceObject({
					oraclePrice,
					bestOffer: bestPrice,
					entryPrice,
					worstPrice,
					markPrice: markPrice?.val,
					direction: existingOrder.direction,
				});

				const startPriceFromSettings =
					priceObject[
						TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
							settings.auctionStartPriceOffsetFrom,
							marketId
						)
					];

				const limitPrice = COMMON_UI_UTILS.getMarketOrderLimitPrice({
					direction: existingOrder.direction,
					baselinePrice: startPriceFromSettings,
					slippageTolerance: allowInfSlippage ? undefined : slippageTolerance,
				});

				const auctionParams = COMMON_UI_UTILS.getMarketAuctionParams({
					direction: existingOrder.direction,
					startPriceFromSettings,
					endPriceFromSettings: priceObject[settings.auctionEndPriceOffsetFrom],
					limitPrice,
					duration: settings.auctionDuration ?? DEFAULT_MARKET_AUCTION_DURATION,
					auctionStartPriceOffset:
						TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffset(
							settings?.auctionStartPriceOffset,
							marketId
						),
					auctionEndPriceOffset: settings?.auctionEndPriceOffset,
				});

				// todo - should this apply to edit orders?
				// if (settings.oracleOffsetOrdersEnabled) {
				// 	const oracleAuctionParams = deriveOracleAuctionParams({
				// 		direction: existingOrder.direction,
				// 		oraclePrice: startPrices.oracle,
				// 		auctionStartPrice: auctionParams.auctionStartPrice,
				// 		auctionEndPrice: auctionParams.auctionEndPrice,
				// 		limitPrice,
				// 	});

				// 	auctionParams = {
				// 		...auctionParams,
				// 		...oracleAuctionParams,
				// 	};
				// }

				additionalOptions = {
					...auctionParams,
					newLimitPrice: allowInfSlippage ? undefined : limitPrice,
				};
			} else if (newOrderIsLimit) {
				const marketId = new MarketId(
					existingOrder.marketIndex,
					existingOrder.marketType
				);

				const { bestBid, bestAsk, markPrice } = priceInfoGetter(marketId);

				const isLong = ENUM_UTILS.match(
					existingOrder.direction,
					PositionDirection.LONG
				);

				const priceObject = COMMON_UI_UTILS.getPriceObject({
					oraclePrice,
					bestOffer: isLong ? bestAsk.val : bestBid.val,
					entryPrice,
					worstPrice,
					markPrice: markPrice?.val,
					direction: existingOrder.direction,
				});

				let priceBands;
				if (marketId.isPerp) {
					const oracleData = oracleInfoGetter(marketId);
					priceBands = oraclePriceBands(
						driftClient.getPerpMarketAccount(marketId.marketIndex),
						oracleData
					);
				}

				const limitAuctionParams: AuctionParams =
					existingOrder.postOnly || newOrderType.value === 'oracleLimit'
						? EMPTY_AUCTION_PARAMS
						: COMMON_UI_UTILS.getLimitAuctionParams({
								direction: existingOrder.direction,
								inputPrice: newLimitPrice,
								startPriceFromSettings:
									priceObject[
										TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
											settings.auctionStartPriceOffsetFrom,
											marketId
										)
									],
								duration: DEFAULT_LIMIT_AUCTION_DURATION,
								auctionStartPriceOffset:
									TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffset(
										settings?.auctionStartPriceOffset,
										marketId
									),
								oraclePriceBands: priceBands,
						  });

				if (
					!limitAuctionParams?.auctionEndPrice?.toNumber?.() &&
					!limitAuctionParams?.auctionStartPrice?.toNumber?.()
				) {
					limitAuctionParams.auctionDuration = null;
				}

				additionalOptions = {
					...limitAuctionParams,
					newLimitPrice: newLimitPrice.val,
				};
			}

			// Should never happen but redundantly confirm we're using the correct price impact info
			if (
				priceImpactInfo &&
				(priceImpactInfo.marketIndex !== existingOrder.marketIndex ||
					!ENUM_UTILS.match(
						priceImpactInfo.marketType,
						existingOrder.marketType
					))
			) {
				throw new Error('Price impact error, please try again');
			}

			const orderParams = {
				...modifyOrderOptions,
				...additionalOptions,
			};

			const marketId = new MarketId(
				existingOrder.marketIndex,
				existingOrder.marketType
			);

			const tx = await driftClient.modifyOrder(
				orderParams,
				getTxParams({
					marketId,
				})
			);

			const editedOrderMessage = getOrderToastMessage({
				stage: 'placed',
				marketId,
				stepSize: UI_UTILS.getStepSizeForMarket(marketId, driftClient),
				...toastMessageOptions,
			});

			notify({
				type: 'success',
				message: editedOrderMessage.message,
				description: editedOrderMessage.description,
				id: toastId,
				subDescription: '',
				action: {
					type: 'txnLink',
					txnSig: tx,
				},
				updatePrevious: true,
			});

			if (state.modals.showEditOrderModal) {
				hideEditOrderModal();
			}

			return true;
		} catch (error: any) {
			console.log(error);
			TransactionErrorHandler.handleError({
				error,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const openSpotMarketOrder = async (props: SpotTradeFormOutputProps) => {
		const marketId = MarketId.createSpotMarket(props.targetMarketIndex);
		const { userAccount, auctionToastEventProps, ...preppedParams } =
			await ORDER_PREP_UTILS.prepSpotMarketOrderParams(props, {
				getUserAccountData: () => {
					const accountsState = accountsGet();
					return accountsState.accounts[accountsState.currentUserKey];
				},
				getDriftClient: () => get().driftClient.client,
				getPriceInfo: priceInfoGetter,
			});

		const txPrepMetricKey = 'spot_market_order';
		TX_PREP_TIME_METRIC_START_TIME_CACHE.set(txPrepMetricKey, Date.now());

		const state = get();
		const accountsState = accountsGet();
		const driftClient = state.driftClient.client;
		await driftClient.switchActiveUser(
			userAccount.subAccountId,
			userAccount.authority
		);

		const toastId = auctionToastEventProps.toastId;

		dlog(
			`stuck_toasts`,
			`placing_spot_market_order_toast .. toastId : ${toastId}`
		);

		try {
			emitAppEvent('expectedAuctionInit', auctionToastEventProps);

			const transactionToPerform = driftClient.placeSpotOrder;

			const tx = await handleTxChain(
				transactionToPerform.bind(driftClient)(
					preppedParams.orderParams,
					getTxParams({
						marketId,
					})
				),
				(signedTxData) => {
					emitAppEvent('expectedAuctionTxSigned', {
						...auctionToastEventProps,
						signedTxData,
					});
				},
				{
					measurePreSignTime: true,
					measurePreSignTimeMetricKey: txPrepMetricKey,
				}
			);

			emitAppEvent('expectedAuctionTxConfirmed', auctionToastEventProps);

			console.log(`Sent market order with txSig : ${tx}`);

			set((s) => {
				// @ts-ignore
				s.tradeForm.baseSizeStringValue = '';
				// @ts-ignore
				s.tradeForm.quoteSizeStringValue = '';
				s.tradeForm.closingPosition = false;
			});

			dlog(`stuck_toasts`, `order_confirmed`);
			emitAppEvent('tradeConfirmation');

			const userKey = COMMON_UI_UTILS.getUserKey(
				userAccount.subAccountId,
				userAccount.authority
			);

			updateAfterTx(
				state.driftClient.client,
				accountsState.accounts[userKey]?.client
			);

			return true;
		} catch (e: any) {
			emitAppEvent('expectedAuctionTxFailed', auctionToastEventProps);
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const openSpotTradeFormOrder = async ({
		side,
		baseSizeStringValue,
		orderType,
		priceBoxStringValue,
		secondaryPriceBoxStringValue,
		reduceOnly,
		slippageTolerance,
		postOnly,
		targetMarketIndex,
		immediateOrCancel,
		currentPositionBaseSize,
		currentPositionDirection,
		priceImpact,
		maxLeverageSelected,
		oraclePrice,
		spotMarketAccount,
	}: Partial<DriftStore['tradeForm']> & {
		targetMarketIndex: number;
		currentPositionBaseSize: BN;
		currentPositionDirection: 'long' | 'short';
		oraclePrice: BN;
		spotMarketAccount: SpotMarketAccount;
	}) => {
		const marketId = MarketId.createSpotMarket(targetMarketIndex);

		const { bestBid, bestAsk, markPrice } = priceInfoGetter(marketId);

		if (orderType === 'market') {
			return openSpotMarketOrder({
				side,
				baseSizeStringValue,
				orderType,
				priceBoxStringValue,
				secondaryPriceBoxStringValue,
				reduceOnly,
				slippageTolerance,
				postOnly,
				targetMarketIndex,
				immediateOrCancel,
				currentPositionDirection,
				priceImpact,
				maxLeverageSelected,
				oraclePrice,
				markPrice: markPrice?.val,
				spotMarketAccount,
				currentPositionBaseSize,
			});
		}

		const state = get();
		if (state.isGeoblocked && !reduceOnly) {
			notify({
				type: 'error',
				message: 'Geoblocked',
				description:
					'You are not allowed to use Drift from a restricted territory',
				lengthMs: 5000,
			});
			return;
		}

		const accountsState = accountsGet();

		const driftClient = state.driftClient.client;
		const basePrecisionToUse =
			OrderedSpotMarkets[targetMarketIndex].precisionExp;
		const user = accountsState.accounts[accountsState.currentUserKey];

		const stepSizeBn = spotMarketAccount.orderStepSize;
		const baseSizeBn = BigNum.fromPrint(
			baseSizeStringValue,
			basePrecisionToUse
		).val;
		const baseSizeAfterStep = baseSizeBn.div(stepSizeBn).mul(stepSizeBn);
		const baseSize = BigNum.from(baseSizeAfterStep, basePrecisionToUse);

		const priceBoxValue = Number(priceBoxStringValue);
		const secondaryPriceBoxValue = Number(secondaryPriceBoxStringValue);

		const toastId = `${orderType}-${side}-${Date.now()}`;

		const targetMarket = CurrentSpotMarkets.find(
			(market) => market.marketIndex === targetMarketIndex
		);

		const currentPositionIsShort = currentPositionDirection == 'short';

		const targetDirection =
			side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT;

		let useMaxLeverageFlag = false;
		if (maxLeverageSelected) {
			// insure max lev flag is correctly set by checking max usdc trade size is within 1% of the passed base size
			const tradeQuoteSize = baseSize
				.shiftTo(PRICE_PRECISION_EXP)
				.mul(BigNum.fromPrint(priceBoxStringValue, PRICE_PRECISION_EXP))
				.shiftTo(QUOTE_PRECISION_EXP);
			const userMaxTradeSizeUsdc = BigNum.from(
				user.client.getMaxTradeSizeUSDCForSpot(
					targetMarketIndex,
					targetDirection
				),
				QUOTE_PRECISION_EXP
			);

			const onePercent = userMaxTradeSizeUsdc.scale(1, 100);
			useMaxLeverageFlag = userMaxTradeSizeUsdc
				.sub(tradeQuoteSize)
				.abs()
				.lt(onePercent);
		}

		try {
			const placingOrderMessage = getOrderToastMessage({
				marketId,
				stepSize: UI_UTILS.getStepSizeForMarket(marketId, driftClient),
				stage: 'placing',
				baseSize,
				direction: side,
				orderType,
				marketName: targetMarket.symbol,
				priceOne: priceBoxValue,
				priceTwo: secondaryPriceBoxValue,
				isSpot: true,
			});

			notify({
				id: toastId,
				type: 'info',
				message: placingOrderMessage.message,
				description: placingOrderMessage.description,
				subDescription: <ConfirmTransactionStep />,
				showUntilCancelled: true,
			});

			let orderBaseSize = baseSize.val;

			// Reduce only can be any size
			// // Ensure order base size isn't larger than current position size if reduce-only is enabled
			// if (reduceOnly) {
			// 	//// We use absolute values for base size when creating an order - use absolute value of currentPositionSize
			// 	orderBaseSize = BN.min(orderBaseSize, currentPositionBaseSize.abs());
			// }

			orderBaseSize = useMaxLeverageFlag
				? MAX_LEVERAGE_ORDER_SIZE
				: orderBaseSize;

			const inputPrice = BigNum.fromPrint(
				priceBoxStringValue,
				PRICE_PRECISION_EXP
			);

			const settings = syncGetCurrentSettings();

			const isLong = ENUM_UTILS.match(targetDirection, PositionDirection.LONG);

			const { entryPrice, worstPrice } = priceImpact;

			const startPrices = COMMON_UI_UTILS.getPriceObject({
				oraclePrice,
				bestOffer: isLong ? bestAsk.val : bestBid.val,
				entryPrice,
				worstPrice,
				markPrice: markPrice?.val,
				direction: targetDirection,
			});

			const limitAuctionParams = postOnly
				? EMPTY_AUCTION_PARAMS
				: COMMON_UI_UTILS.getLimitAuctionParams({
						direction: targetDirection,
						inputPrice,
						startPriceFromSettings:
							startPrices[
								TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
									settings.auctionStartPriceOffsetFrom,
									marketId
								)
							],
						duration: DEFAULT_LIMIT_AUCTION_DURATION,
						auctionStartPriceOffset:
							TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffset(
								settings?.auctionStartPriceOffset,
								marketId
							),
				  });

			const orderParams = orderTypeSwitch<Exclude<UIOrderType, 'market'>>(
				orderType,
				{
					limit: getLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.SPOT,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						price: NumLib.formatNum.toRawBn(priceBoxValue, PRICE_PRECISION),
						reduceOnly,
						postOnly: postOnly
							? PostOnlyParams.MUST_POST_ONLY
							: PostOnlyParams.NONE,
						immediateOrCancel,
						...limitAuctionParams,
					}),
					stopMarket: getTriggerMarketOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.SPOT,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: NumLib.formatNum.toRawBn(
							priceBoxValue,
							PRICE_PRECISION
						),
						triggerCondition: currentPositionIsShort
							? OrderTriggerCondition.ABOVE
							: OrderTriggerCondition.BELOW,
						reduceOnly,
					}),
					stopLimit: getTriggerLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.SPOT,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: NumLib.formatNum.toRawBn(
							priceBoxValue,
							PRICE_PRECISION
						),
						price: NumLib.formatNum.toRawBn(
							secondaryPriceBoxValue,
							PRICE_PRECISION
						),
						triggerCondition: currentPositionIsShort
							? OrderTriggerCondition.ABOVE
							: OrderTriggerCondition.BELOW,
						reduceOnly,
					}),
					takeProfitMarket: getTriggerMarketOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.SPOT,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: NumLib.formatNum.toRawBn(
							priceBoxValue,
							PRICE_PRECISION
						),
						triggerCondition: currentPositionIsShort
							? OrderTriggerCondition.BELOW
							: OrderTriggerCondition.ABOVE,
						reduceOnly,
					}),
					takeProfitLimit: getTriggerLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.SPOT,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: NumLib.formatNum.toRawBn(
							priceBoxValue,
							PRICE_PRECISION
						),
						price: NumLib.formatNum.toRawBn(
							secondaryPriceBoxValue,
							PRICE_PRECISION
						),
						triggerCondition: currentPositionIsShort
							? OrderTriggerCondition.BELOW
							: OrderTriggerCondition.ABOVE,
						reduceOnly,
					}),
					// these are routed through the market orders function
					oracle: undefined,
					oracleLimit: getLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.SPOT,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						price: ZERO,
						reduceOnly,
						postOnly: postOnly
							? PostOnlyParams.MUST_POST_ONLY
							: PostOnlyParams.NONE,
						immediateOrCancel,
						oraclePriceOffset: priceBoxValue * PRICE_PRECISION.toNumber(),
					}),
					// scaled orders not support for spot yet
					scaledOrders: undefined,
				}
			);

			const transactionToPerform = driftClient.placeSpotOrder;

			const txSig = await handleTxChain(
				transactionToPerform.bind(driftClient)(
					orderParams,
					getTxParams({
						marketId,
					})
				)
			);

			// TODO - implement sendLimitOrderAndGetSignedFillTx in sdk to enable auction toasts for limit

			const completedOrderMessage = getOrderToastMessage({
				marketId,
				stepSize: UI_UTILS.getStepSizeForMarket(marketId, driftClient),
				stage: 'placed',
				baseSize,
				direction: side,
				orderType,
				marketName: targetMarket.symbol,
				priceOne: priceBoxValue,
				priceTwo: secondaryPriceBoxValue,
				isSpot: true,
			});

			notify({
				type: 'success',
				message: completedOrderMessage.message,
				description: completedOrderMessage.description,
				id: toastId,
				subDescription: '',
				action: {
					type: 'txnLink',
					txnSig: txSig.toString(),
				},
				updatePrevious: true,
			});
			return true;
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	/*
	 * Close positions in multiple perp markets, used to "close all positions" in one approval.
	 *
	 * This closes positionsToClose with a market order for 100% of their current size.
	 */
	const closeMultiplePerpPositions = async ({
		positionsToCloseInfo,
		cancelExistingOrders,
	}: {
		positionsToCloseInfo: {
			oraclePrice: BN;
			priceImpactInfo: PriceImpactInfo;
			position: OpenPosition;
			slippage: number;
		}[];
		cancelExistingOrders?: boolean;
	}) => {
		const settings = syncGetCurrentSettings();
		const state = get();
		const accountsState = accountsGet();
		const driftClient = state.driftClient.client;
		const placeMarketOrderIxes: TransactionInstruction[] = [];
		const cancelExistingOrdersIxes: TransactionInstruction[] = [];
		const toastId = `close-all-${Date.now()}`;
		const numPositionsToClose = positionsToCloseInfo.length;
		const user = accountsState.accounts[accountsState.currentUserKey]?.client;

		notify({
			id: toastId,
			type: 'info',
			message: `Market closing ${numPositionsToClose} positions`,
			subDescription: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		await Promise.all(
			positionsToCloseInfo.map(
				async ({ priceImpactInfo, position, oraclePrice, slippage }) => {
					const marketIndex = position.marketIndex;
					const baseAmount = position.baseSize.abs();
					const allowInfSlippage = settings.slippageTolerance == undefined;
					const isPredictionMarket = UIMarket.checkIsPredictionMarket(
						PERP_MARKETS_LOOKUP[marketIndex]
					);

					const { bestPrice, entryPrice, worstPrice } = isPredictionMarket
						? invertPriceImpact(priceImpactInfo)
						: priceImpactInfo;

					const { markPrice } = priceInfoGetter(
						MarketId.createPerpMarket(marketIndex)
					);

					const closeDirection =
						position.direction === 'long'
							? PositionDirection.SHORT
							: PositionDirection.LONG;

					try {
						// Should never happen but redundantly confirm we're using the correct price impact info
						if (
							priceImpactInfo.marketIndex !== marketIndex ||
							!ENUM_UTILS.match(priceImpactInfo.marketType, MarketType.PERP)
						) {
							throw new Error('Price impact error, please try again');
						}

						const auctionPriceCaps = isPredictionMarket
							? PREDICTION_MARKET_AUCTION_PRICE_CAPS
							: undefined;

						const marketAccount = driftClient.getPerpMarketAccount(marketIndex);

						const orderParams = COMMON_UI_UTILS.deriveMarketOrderParams({
							marketType: MarketType.PERP,
							marketIndex,
							direction: closeDirection,
							baseAmount,
							reduceOnly: true,
							allowInfSlippage,
							worstPrice,
							auctionDuration:
								settings?.auctionDuration ?? DEFAULT_MARKET_AUCTION_DURATION,
							auctionStartPriceOffset:
								TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffset(
									settings?.auctionStartPriceOffset,
									MarketId.createPerpMarket(marketIndex)
								),
							auctionEndPriceOffset: settings?.auctionEndPriceOffset,
							maxLeverageSelected: false,
							maxLeverageOrderSize: MAX_LEVERAGE_ORDER_SIZE,
							oraclePrice,
							markPrice: markPrice?.val,
							bestPrice,
							entryPrice,
							auctionStartPriceOffsetFrom:
								TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
									settings?.auctionStartPriceOffsetFrom ?? 'marketBased',
									MarketId.createPerpMarket(marketIndex)
								),
							auctionEndPriceOffsetFrom: settings?.auctionEndPriceOffsetFrom,
							slippageTolerance: slippage,
							isOracleOrder: settings?.oracleOffsetOrdersEnabled,
							auctionPriceCaps: auctionPriceCaps,
							additionalEndPriceBuffer: UI_UTILS.getAdditionalEndPriceBuffer(
								marketAccount as PerpMarketAccount
							),
						});

						const placePerpOrderIx = await driftClient.getPlacePerpOrderIx(
							orderParams
						);

						placeMarketOrderIxes.push(placePerpOrderIx);

						if (cancelExistingOrders) {
							const cancelOrdersIx = await driftClient.getCancelOrdersIx(
								MarketType.PERP,
								marketIndex,
								null
							);
							cancelExistingOrdersIxes.push(cancelOrdersIx);
						}
					} catch (e: any) {
						TransactionErrorHandler.handleError({
							error: e,
							toastId,
							wallet: get()?.wallet?.current?.adapter,
						});
					}
				}
			)
		);

		// get settle pnl ixes, don't need to call multiple times since sdk has a function for this
		const marketIndexesToSettle = positionsToCloseInfo.map(
			({ position }) => position.marketIndex
		);
		const settlePnlIxes = await driftClient.getSettlePNLsIxs(
			[
				{
					settleeUserAccountPublicKey: user.userAccountPublicKey,
					settleeUserAccount: user.getUserAccount(),
				},
			],
			marketIndexesToSettle
		);

		try {
			const txParams = getTxParams();
			const txesToSend = [];

			const placeMarketOrdersTx = await driftClient.buildTransaction(
				placeMarketOrderIxes,
				txParams
			);
			txesToSend.push(placeMarketOrdersTx);

			const settlePnlsIx = await driftClient.buildTransaction(
				settlePnlIxes,
				txParams
			);
			txesToSend.push(settlePnlsIx);

			if (cancelExistingOrders) {
				const cancelExistingOrdersTx = await driftClient.buildTransaction(
					cancelExistingOrdersIxes,
					txParams
				);
				txesToSend.push(cancelExistingOrdersTx);
			}

			const [
				signedPlaceMarketOrdersTx,
				signedSettlePnlsTx,
				signedCancelExistingOrdersTx,
			] = await driftClient.provider.wallet.signAllTransactions(txesToSend);

			notify({
				id: toastId,
				type: 'info',
				message: `Market closing ${numPositionsToClose} position${
					numPositionsToClose > 1 ? 's' : ''
				}`,
				subDescription: <ConfirmTransactionStep />,
				showUntilCancelled: true,
			});

			const { txSig } = await handleTxChain(
				driftClient.sendTransaction(
					signedPlaceMarketOrdersTx,
					undefined,
					driftClient.opts,
					true
				)
			);

			if (signedSettlePnlsTx) {
				setAutoSettleTx(marketIndexesToSettle, signedSettlePnlsTx);
			}

			if (cancelExistingOrders && signedCancelExistingOrdersTx) {
				setAutoCancelOrdersTx(
					positionsToCloseInfo.map((pos) => pos.position.marketIndex),
					signedCancelExistingOrdersTx
				);
			}

			notify({
				type: 'success',
				message: `Market closing ${numPositionsToClose} position${
					numPositionsToClose > 1 ? 's' : ''
				}`,
				description: `Market orders placed in ${numPositionsToClose} market${
					numPositionsToClose > 1 ? 's' : ''
				}`,
				lengthMs: 5000,
				id: toastId,
				subDescription: '',
				action: {
					type: 'txnLink',
					txnSig: txSig,
				},
				updatePrevious: true,
			});
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const handleIsGeoblocked = (reduceOnly: boolean) => {
		const state = get();
		if (state.isGeoblocked && !reduceOnly) {
			notify({
				type: 'error',
				message: 'Geoblocked',
				description:
					'You are not allowed to use Drift from a restricted territory',
				lengthMs: 5000,
			});
			return true;
		}
		return false;
	};

	const handleSwitchUser = async () => {
		const accountsState = accountsGet();
		const driftClient = get().driftClient.client;
		const user = accountsState.accounts[accountsState.currentUserKey];
		const userAccount = user.client.getUserAccount();

		// Switch Active User
		await driftClient.switchActiveUser(
			userAccount.subAccountId,
			userAccount.authority
		);
	};

	type PreppedMarketOrders = Awaited<
		ReturnType<DriftClient['preparePlaceAndTakePerpOrderWithAdditionalOrders']>
	>;

	const openPerpTradeFormOrder = async (
		{
			side,
			baseSizeStringValue,
			orderType,
			priceBoxStringValue,
			secondaryPriceBoxStringValue,
			reduceOnly,
			slippageTolerance,
			postOnly,
			targetMarketIndex,
			immediateOrCancel,
			maxLeverageSelected,
			priceImpact,
			bracketOrders,
			oraclePrice,
			markPrice,
			cancelExistingOrders,
			perpMarketAccount,
			scaledOrders,
			isSwiftSelected,
		}: PerpTradeFormOutputProps,
		context: InvariantCheckingContext,
		prePreparedTx?: PreppedMarketOrders
	) => {
		if (orderType === 'market') {
			return openPerpMarketOrder(
				{
					side,
					baseSizeStringValue,
					orderType,
					reduceOnly,
					slippageTolerance,
					postOnly,
					targetMarketIndex,
					immediateOrCancel,
					maxLeverageSelected,
					priceImpact,
					bracketOrders,
					oraclePrice,
					markPrice,
					cancelExistingOrders,
					perpMarketAccount,
					isSwiftSelected,
				},
				context,
				prePreparedTx
			);
		}

		const state = get();

		if (handleIsGeoblocked(reduceOnly)) {
			return;
		}

		const accountsState = accountsGet();
		const marketId = MarketId.createPerpMarket(targetMarketIndex);
		const currentSettings = syncGetCurrentSettings();

		const { bestBid, bestAsk } = priceInfoGetter(marketId);

		const driftClient = state.driftClient.client;

		const stepSizeBn = perpMarketAccount.amm.orderStepSize;
		const stepSizeBigNum = BigNum.from(stepSizeBn, BASE_PRECISION_EXP);
		const baseSizeBigNum = BigNum.fromPrint(
			baseSizeStringValue,
			BASE_PRECISION_EXP
		);
		const baseSizeBn = baseSizeBigNum.val;
		const baseSizeAfterStep = baseSizeBn.div(stepSizeBn).mul(stepSizeBn);
		const baseSize = BigNum.from(baseSizeAfterStep, BASE_PRECISION_EXP);

		const priceBoxValue = Number(priceBoxStringValue);
		const secondaryPriceBoxValue = Number(secondaryPriceBoxStringValue);
		const priceBoxBn = NumLib.formatNum.toRawBn(priceBoxValue, PRICE_PRECISION);
		const secondaryPriceBoxBn = NumLib.formatNum.toRawBn(
			secondaryPriceBoxValue,
			PRICE_PRECISION
		);
		const isScaledOrders = orderType === 'scaledOrders';
		const scaledOrdersValues = isScaledOrders
			? calculateScaledOrderSizes(
					scaledOrders.sizeDistribution,
					baseSize,
					stepSizeBigNum,
					scaledOrders.orderCount
			  )
			: [];
		const scaledOrdersPrices = isScaledOrders
			? calculatePriceDistribution(
					priceBoxBn,
					secondaryPriceBoxBn,
					scaledOrders.orderCount
			  )
			: [];

		const user = accountsState.accounts[accountsState.currentUserKey];

		await handleSwitchUser();

		const toastId = `${orderType}-${side}-${Date.now()}`;

		const targetMarket = CurrentPerpMarkets.find(
			(market) => market.marketIndex === targetMarketIndex
		);

		const targetDirection =
			side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT;

		let useMaxLeverageFlag = false;

		if (maxLeverageSelected) {
			// insure max lev flag is correctly set by checking max usdc trade size is within 1% of the passed base size
			const tradeQuoteSize = baseSize
				.shiftTo(PRICE_PRECISION_EXP)
				.mul(BigNum.from(oraclePrice, PRICE_PRECISION_EXP)) // multiply by oracle price to get quote size because margin system is based on oracle price and not trade price
				.shiftTo(QUOTE_PRECISION_EXP);

			const userMaxTradeSizeUsdc = BigNum.from(
				user.client.getMaxTradeSizeUSDCForPerp(
					targetMarketIndex,
					targetDirection
				).tradeSize,
				QUOTE_PRECISION_EXP
			);
			const onePercent = userMaxTradeSizeUsdc.scale(1, 100);

			useMaxLeverageFlag = userMaxTradeSizeUsdc
				.sub(tradeQuoteSize)
				.abs()
				.lt(onePercent);
		} else {
			useMaxLeverageFlag = false;
		}

		const numOrders = bracketOrders
			? bracketOrders.stopLoss && bracketOrders.takeProfit
				? 3
				: bracketOrders.stopLoss || bracketOrders.takeProfit
				? 2
				: isScaledOrders
				? scaledOrders.orderCount
				: 1
			: undefined;

		const auctionToastEventProps: AuctionToastEvent = {
			v2Props: {
				identifierNonce: DriftWindow.getAndIncrementNonce(),
				marketId: MarketId.createPerpMarket(targetMarketIndex),
				direction: targetDirection,
				baseAmountOrdered: baseSize.val,
				numOrders,
				auctionEnabled:
					currentSettings?.auctionDuration > 0 &&
					!currentSettings.placeAndTakeEnabled,
				includesSlOrder: !!bracketOrders?.stopLoss,
				includesTpOrder: !!bracketOrders?.takeProfit,
			},
			toastId,
		};

		try {
			const placingOrderMessage = getOrderToastMessage({
				marketId,
				stepSize: UI_UTILS.getStepSizeForMarket(marketId, driftClient),
				stage: 'placing',
				baseSize,
				direction: side,
				orderType,
				marketName: targetMarket.symbol,
				priceOne: priceBoxValue,
				priceTwo: secondaryPriceBoxValue,
				numOrders,
			});

			let orderBaseSize = baseSize.val;

			const bracketOrderBaseSize = baseSize.val;

			// Reduce only can be any size
			// // Ensure order base size isn't larger than current position size if reduce-only is enabled
			// if (reduceOnly) {
			// 	//// We use absolute values for base size when creating an order - use absolute value of currentPositionSize
			// 	orderBaseSize = BN.min(orderBaseSize, currentPositionSize.abs());
			// }

			orderBaseSize = useMaxLeverageFlag
				? MAX_LEVERAGE_ORDER_SIZE
				: orderBaseSize;

			const inputPrice = BigNum.fromPrint(
				priceBoxStringValue,
				PRICE_PRECISION_EXP
			);

			const settings = syncGetCurrentSettings();

			const tradeIsLong = ENUM_UTILS.match(
				targetDirection,
				PositionDirection.LONG
			);

			// use the hypothetical market order entry price to determine if auction params needed
			const { entryPrice, worstPrice } = priceImpact;

			const startPrices = COMMON_UI_UTILS.getPriceObject({
				oraclePrice,
				bestOffer: tradeIsLong ? bestAsk.val : bestBid.val,
				entryPrice,
				worstPrice,
				markPrice,
				direction: targetDirection,
			});

			const oracleData = oracleInfoGetter(marketId);
			const priceBands = oraclePriceBands(
				driftClient.getPerpMarketAccount(marketId.marketIndex),
				oracleData
			);

			const limitAuctionParams = postOnly
				? EMPTY_AUCTION_PARAMS
				: COMMON_UI_UTILS.getLimitAuctionParams({
						direction: targetDirection,
						inputPrice,
						startPriceFromSettings:
							startPrices[
								TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
									settings.auctionStartPriceOffsetFrom,
									marketId
								)
							],
						duration: DEFAULT_LIMIT_AUCTION_DURATION,
						auctionStartPriceOffset:
							TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffset(
								settings?.auctionStartPriceOffset,
								marketId
							),
						oraclePriceBands: priceBands,
				  });

			const orderParams: OptionalOrderParams | OptionalOrderParams[] =
				orderTypeSwitch<Exclude<UIOrderType, 'market'>>(orderType, {
					limit: getLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						price: priceBoxBn,
						reduceOnly,
						postOnly: postOnly
							? PostOnlyParams.MUST_POST_ONLY
							: PostOnlyParams.NONE,
						immediateOrCancel,
						...limitAuctionParams,
					}),
					stopMarket: getTriggerMarketOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: priceBoxBn,
						// A STOP order has trigger condition ABOVE when Trade Direction is LONG. & vice-versa
						triggerCondition: tradeIsLong
							? OrderTriggerCondition.ABOVE
							: OrderTriggerCondition.BELOW,
						reduceOnly,
					}),
					stopLimit: getTriggerLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: priceBoxBn,
						price: secondaryPriceBoxBn,
						// A STOP order has trigger condition ABOVE when Trade Direction is LONG. & vice-versa
						triggerCondition: tradeIsLong
							? OrderTriggerCondition.ABOVE
							: OrderTriggerCondition.BELOW,
						reduceOnly,
					}),
					takeProfitMarket: getTriggerMarketOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: priceBoxBn,
						// A TAKE PROFIT order has trigger condition BELOW when Trade Direction is LONG. & vice-versa
						triggerCondition: tradeIsLong
							? OrderTriggerCondition.BELOW
							: OrderTriggerCondition.ABOVE,
						reduceOnly,
					}),
					takeProfitLimit: getTriggerLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						triggerPrice: priceBoxBn,
						price: secondaryPriceBoxBn,
						// A TAKE PROFIT order has trigger condition BELOW when Trade Direction is LONG. & vice-versa
						triggerCondition: tradeIsLong
							? OrderTriggerCondition.BELOW
							: OrderTriggerCondition.ABOVE,
						reduceOnly,
					}),
					// these are routed through the market orders function
					oracle: undefined,
					oracleLimit: getLimitOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: targetDirection,
						baseAssetAmount: orderBaseSize,
						price: ZERO,
						reduceOnly,
						postOnly: postOnly
							? PostOnlyParams.MUST_POST_ONLY
							: PostOnlyParams.NONE,
						immediateOrCancel,
						oraclePriceOffset: priceBoxValue * PRICE_PRECISION.toNumber(),
					}),
					scaledOrders: scaledOrdersValues.map((order, index) =>
						getLimitOrderParams({
							marketIndex: targetMarketIndex,
							marketType: MarketType.PERP,
							direction: targetDirection,
							baseAssetAmount: order.val,
							price: scaledOrdersPrices[index],
							reduceOnly,
							postOnly: postOnly
								? PostOnlyParams.MUST_POST_ONLY
								: PostOnlyParams.NONE,
							immediateOrCancel,
							...limitAuctionParams,
						})
					),
				});

			const result = doInvariantChecksForContext(
				context,
				Array.isArray(orderParams) ? orderParams[0] : orderParams,
				{
					maxLeverageSelected,
				}
			);

			if (!result.success) {
				return;
			}

			const auctionIsEmpty = isAuctionEmpty(limitAuctionParams);
			const usingSignedMsgOrderRoute = isSwiftSelected && !auctionIsEmpty;

			notify({
				id: toastId,
				type: 'info',
				message: placingOrderMessage.message,
				description: placingOrderMessage.description,
				subDescription: (
					<ConfirmTransactionStep
						subDescription={
							isSwiftSelected && auctionIsEmpty
								? 'Swift is not used due to set limit price being outside the range that would require an auction'
								: ''
						}
					/>
				),
				showUntilCancelled: true,
				bgType: usingSignedMsgOrderRoute ? 'swift' : undefined,
				toastBadge: usingSignedMsgOrderRoute ? <SwiftToastBadge /> : undefined,
			});

			const bracketOrdersParams: OptionalOrderParams[] = [];

			if (orderType == 'limit' && bracketOrders) {
				if (bracketOrders.takeProfit) {
					const takeProfitParams = getTriggerMarketOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: bracketOrders.takeProfit.direction,
						baseAssetAmount: bracketOrderBaseSize,
						triggerPrice: bracketOrders.takeProfit.price.val,
						// A TAKE PROFIT order has trigger condition BELOW when Trade Direction is LONG. This is the BRACKET order so it's in the opposite direction of the actual trade
						triggerCondition: !tradeIsLong
							? OrderTriggerCondition.BELOW
							: OrderTriggerCondition.ABOVE,
						reduceOnly: true,
					});

					bracketOrdersParams.push(takeProfitParams);
				}

				if (bracketOrders.stopLoss) {
					const stopLossParams = getTriggerMarketOrderParams({
						marketIndex: targetMarketIndex,
						marketType: MarketType.PERP,
						direction: bracketOrders.stopLoss.direction,
						baseAssetAmount: bracketOrderBaseSize,
						triggerPrice: bracketOrders.stopLoss.price.val,
						// A STOP order has trigger condition ABOVE when Trade Direction is LONG. & vice-versa
						triggerCondition: !tradeIsLong
							? OrderTriggerCondition.ABOVE
							: OrderTriggerCondition.BELOW,
						reduceOnly: true,
					});

					bracketOrdersParams.push(stopLossParams);
				}
			}

			if (usingSignedMsgOrderRoute) {
				// swift here yo
				const [stopLossOrderParams, takeProfitOrderParams] =
					bracketOrdersParams;

				const orderParamsForSignedMsg = Array.isArray(orderParams)
					? orderParams[0]
					: orderParams;

				const wallet = get().wallet.current.adapter;
				const { hexEncodedOrderMessage, slotForSignedMsg, signedMsgOrderUuid } =
					await prepSignedMsgOrder(
						// TODO: is this the right way to handle this?
						orderParamsForSignedMsg,
						user?.userId,
						driftClient,
						DriftWindow.chainClock?.getState(
							TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL
						)?.slot,
						stopLossOrderParams ?? undefined,
						takeProfitOrderParams ?? undefined
					);

				const signedOrderMsgEndSlot =
					orderParamsForSignedMsg.auctionDuration +
					slotForSignedMsg.toNumber() -
					MSG_SIGNING_BEFORE_AUCTION_END_SLOT_BUFFER;
				const signedMessage = await signOrderMsg(
					wallet,
					hexEncodedOrderMessage,
					signedOrderMsgEndSlot, // added slot buffer before end of auction duration, so user is encouraged to sign with enough time for auction
					() => {
						notify({
							type: 'awaiting',
							message: 'Signing order message',
							description: 'Signing the order message in your wallet.',
							id: toastId,
							subDescription: '',
							updatePrevious: true,
							showUntilCancelled: true,
							bgType: 'swift',
							customIcon: (
								<IconCircleBg bgColor="bg-interactive-secondary-bg">
									<SlotExpiryProgress
										endSlot={signedOrderMsgEndSlot}
										startSlot={slotForSignedMsg.toNumber()}
									/>
								</IconCircleBg>
							),
							toastBadge: <SwiftToastBadge />,
						});
					},
					() => {
						notify({
							type: 'error',
							message: 'Message Expired',
							description: 'Message expired for signing. Slot is too old.',
							customIcon: (
								<IconCircleBg bgColor="bg-negative-red-secondary-bg">
									<ErrorFilled size={24} />
								</IconCircleBg>
							),
							id: toastId,
							subDescription: '',
							updatePrevious: true,
							bgType: 'swift',
							toastBadge: <SwiftToastBadge />,
						});
					}
				);
				notify({
					type: 'success',
					message: 'Order message signed successfully',
					description: 'Order message signed successfully',
					id: toastId,
					subDescription: '',
					updatePrevious: true,
					bgType: 'swift',
					toastBadge: <SwiftToastBadge />,
					customIcon: null,
				});
				const hash = digestSignature(Uint8Array.from(signedMessage));
				console.log(
					`Sending signed message order for hash: ${hash}, time: ${Date.now()}, hash: ${hash}`
				);

				const signedMsgUserOrdersAccountPubkey =
					getSignedMsgUserAccountPublicKey(
						driftClient.program.programId,
						user.authority
					);
				const swiftRes = await SwiftClient.sendAndConfirmSwiftOrderWS(
					driftClient.connection,
					driftClient,
					targetMarketIndex,
					MarketType.PERP,
					hexEncodedOrderMessage.toString(),
					Buffer.from(signedMessage),
					user.authority,
					signedMsgUserOrdersAccountPubkey,
					signedMsgOrderUuid,
					// this is essentially the number of slots in the auction multiple by slot time and 1000 to convert to ms
					(orderParamsForSignedMsg.auctionDuration + 15) * 0.4 * 1000, // TODO: slot times are not always 400 ms, we should possibly use the DriftWindow chain clock instead
					wallet.publicKey
				);

				swiftRes.subscribe((event) => {
					if (event.type === 'sent') {
						dlog(
							`market_orders`,
							`Sent signed message order with hash : ${event.hash}`
						);
					}
					if (event.type === 'confirmed') {
						notify({
							type: 'success',
							message: 'Order placed successfully',
							description: 'Order placed successfully',
							id: toastId,
							subDescription: '',
							updatePrevious: true,
							bgType: 'swift',
						});
					}
					if (event.type === 'expired') {
						notify({
							type: 'error',
							message: 'Order message expired',
							description:
								'Order message expired - limit order was likely not placed on-chain.',
							id: toastId,
							subDescription: '',
							updatePrevious: true,
						});
					}
					if (event.type === 'errored') {
						notify({
							type: 'error',
							message: 'Order message failed',
							description: 'Order message failed',
							id: toastId,
							subDescription: '',
							updatePrevious: true,
						});
					}
				});
			} else {
				let txSig;

				// if limit order has auction params that means it's crossing. send it thru placeAndTake if the user has it turned on
				const attemptPlaceAndTake =
					orderType === 'limit' &&
					limitAuctionParams.auctionDuration &&
					settings.placeAndTakeEnabled &&
					driftClient.txVersion === 0;

				let usedPlaceAndTake = false;

				if (attemptPlaceAndTake) {
					const referrerInfo = driftClient.getUserStats()?.getReferrerInfo();

					txSig = await openPerpPlaceAndTake({
						marketIndex: targetMarketIndex,
						orderParams: orderParams as OptionalOrderParams,
						subAccountId: user?.userId,
						referrerInfo,
						bracketOrdersParams,
						cancelExistingOrders,
						auctionToastEventProps,
					});

					if (txSig) {
						console.log(`Sent placeAndTake order with txSig : ${txSig}`);
					}

					usedPlaceAndTake = !!txSig;
				}

				if (!usedPlaceAndTake) {
					const marketId = MarketId.createPerpMarket(targetMarketIndex);
					const optionalIxs = await UI_UTILS.getOracleCrankIxs(
						[marketId],
						get().driftClient.client,
						state.wallet.current.adapter.name,
						undefined,
						false
					);

					txSig = await handleTxChain(
						driftClient.placeOrders(
							// TODO: typecasting here to OrderParams[] to fix type error is fishy
							[
								...(Array.isArray(orderParams) ? orderParams : [orderParams]),
								...bracketOrdersParams,
							] as OrderParams[],
							getTxParams({
								marketId,
							}),
							undefined,
							optionalIxs
						)
					);
				}

				// TODO - implement sendLimitOrderAndGetSignedFillTx in sdk to enable auction toasts for limit

				const completedOrderMessage = getOrderToastMessage({
					marketId,
					stepSize: UI_UTILS.getStepSizeForMarket(marketId, driftClient),
					stage: 'placed',
					baseSize,
					direction: side,
					orderType,
					marketName: targetMarket.symbol,
					priceOne: priceBoxValue,
					priceTwo: secondaryPriceBoxValue,
					numOrders,
				});

				notify({
					type: 'success',
					message: completedOrderMessage.message,
					description: completedOrderMessage.description,
					id: toastId,
					subDescription: usingSignedMsgOrderRoute
						? 'Swift was not used due to set limit price being outside the range that would require an auction'
						: '',
					action: {
						type: 'txnLink',
						txnSig: txSig.toString(),
					},
					updatePrevious: true,
				});
			}

			set((s) => {
				s.tradeForm.bracketOrders = undefined;
			});

			return true;
		} catch (e: any) {
			if (e.message === 'Auction slot expired') {
				//don't do transaction error handler
			} else {
				TransactionErrorHandler.handleError({
					error: e,
					toastId,
					wallet: get()?.wallet?.current?.adapter,
				});
			}
			return false;
		}
	};

	const cancelOrder = async (orderId: number) => {
		const state = get();

		const driftClient = state.driftClient.client;

		const toastId = `cancelOrder-${orderId}-${Date.now()}`;

		try {
			notify({
				type: 'info',
				message: `Cancelling order`,
				id: toastId,
				description: <ConfirmTransactionStep />,
				showUntilCancelled: true,
			});

			const methodToCall = driftClient.cancelOrder;

			const txPromise = methodToCall.bind(driftClient)(orderId, getTxParams());

			await handleTxChain(txPromise);

			notify({
				type: 'success',
				message: 'Order cancelled successfully',
				description: '',
				id: toastId,
				updatePrevious: true,
			});
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const cancelAllOrders = async ({
		orderIdsToCancel,
	}: {
		/**
		 * If orderIdsToCancel is provided, all orders matching the provided orderIds will be canceled. Otherwise all orders will be canceled.
		 */
		orderIdsToCancel?: number[];
	}) => {
		const state = get();

		const driftClient = state.driftClient.client;

		const toastId = `cancelOrder-all-${Date.now()}`;

		const account = driftClient.getUserAccount();

		const openOrders = account.orders.filter((order) =>
			matchEnum(order.status, OrderStatus.OPEN)
		);

		const filteredOrders = orderIdsToCancel
			? openOrders.filter((order) => orderIdsToCancel.includes(order.orderId))
			: openOrders;
		const numberOfOrders = filteredOrders.length;

		try {
			notify({
				type: 'info',
				message: `Cancelling ${
					numberOfOrders === Infinity ? 'all' : numberOfOrders
				} orders`,
				id: toastId,
				description: <ConfirmTransactionStep />,
				showUntilCancelled: true,
			});

			await handleTxChain(
				driftClient.cancelOrdersByIds(
					filteredOrders.map((order) => order.orderId),
					getTxParams()
				)
			);

			notify({
				type: 'success',
				message: 'Orders cancelled successfully',
				description: '',
				id: toastId,
				updatePrevious: true,
			});
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	// Call this after user has submitted email or social login for magic auth
	const connectToMagicAuth = async (loginOptions: MagicAuthLoginOptions) => {
		const endpoint = get().connection.current.rpcEndpoint;

		const magicWallet = createMagicWalletAdapter(endpoint);

		// disconnect and error handlers are added in useKeepWalletsInSync
		magicWallet.on('connect', linkWalletToDriftClient);

		set((state) => {
			state.wallet.current.adapter = magicWallet;
			state.wallet.isMagicAuth = true;
		});

		return await magicWallet.connect(loginOptions);
	};

	const connectToAppKitWallet = async (
		appKitProvider: BaseMessageSignerWalletAdapter
	) => {
		const appKitWallet = createAppKitAdapter(appKitProvider);

		// disconnect and error handlers are added in useKeepWalletsInSync
		appKitWallet.on('connect', linkWalletToDriftClient);

		set((state) => {
			state.wallet.current.adapter = appKitWallet;
			state.wallet.isAppKit = true;
		});

		return await appKitWallet.connect();
	};

	const connectToMetamask = async () => {
		const mmSnapAdapter = new SnapWalletAdapter(Env.mmSnapId);

		// disconnect and error handlers are added in useKeepWalletsInSync
		mmSnapAdapter.on('connect', linkWalletToDriftClient);

		set((state) => {
			// @ts-expect-error
			state.wallet.current.adapter = mmSnapAdapter;
			state.wallet.isMetamask = true;
		});

		return await mmSnapAdapter.connect();
	};

	const connectToPasskeys = async () => {
		const adapter = createPasskeysAdapter(PASSKEYS_WALLET_INSTANCE);

		// disconnect and error handlers are added in useKeepWalletsInSync
		adapter.on('connect', linkWalletToDriftClient);

		set((state) => {
			state.wallet.current.adapter = adapter;
			state.wallet.isPasskeys = true;
		});

		return await adapter.connect();
	};

	const connectWithCustomWallet = async (
		customWalletName: DriftCustomWalletName,
		opts?: BaseMessageSignerWalletAdapter | MagicAuthLoginOptions
	) => {
		switch (customWalletName) {
			case DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME:
				return connectToAppKitWallet(opts as BaseMessageSignerWalletAdapter);
			case DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME:
				return connectToMagicAuth(opts as MagicAuthLoginOptions);
			case DRIFT_CUSTOM_WALLET_OPTIONS.PASSKEYS_WALLET_ADAPTER_NAME:
				return connectToPasskeys();
			case DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME:
				return connectToMetamask();
			default: {
				throw new Error(`Unhandled custom wallet name: ${customWalletName}`);
			}
		}
	};

	const emitAppEvent: DriftStore['appEventEmitter']['emit'] = (
		...params: any[]
	) => {
		get().appEventEmitter.emit(params[0], params[1]);
	};

	const hideAllModals = () => {
		set((s) => {
			Object.keys(s.modals).forEach((key) => {
				// @ts-ignore
				s.modals[key as keyof DriftStoreModals] = false;
			});
		});
	};

	const showModal = (modalToShow: DriftModalKey, value = true) => {
		set((s) => {
			// @ts-ignore
			s.modals[modalToShow] = value;
		});
	};

	const switchModal = (
		modalToShow: DriftModalKey,
		modalToHide: DriftModalKey
	) => {
		set((s) => {
			// @ts-ignore
			s.modals[modalToShow] = true;
			// @ts-ignore
			s.modals[modalToHide] = false;
		});
	};

	const setSelectedMarket = (market: UIMarket) => {
		set((s) => {
			s.selectedMarket.current = market;
			s.selectedMarket.name = market.marketName;
			s.selectedMarket.marketId = market.marketId;
		});
	};

	const linkWalletToDriftClient = async () => {
		// Skip regular connection logic when using account emulation
		const urlSearchParams = new URLSearchParams(window.location.search);

		if (
			urlSearchParams.has('authority') ||
			urlSearchParams.has('userAccount')
		) {
			return;
		}

		if (UI_UTILS.isWindowDefined()) {
			const trackedMode = window.localStorage.getItem('tracked_lite_pro_mode');
			const tradeMode = window.localStorage.getItem('tradeMode');

			if (!trackedMode || trackedMode !== tradeMode) {
				captureEvent('tracked_lite_pro_mode', {
					mode: tradeMode,
				});
				window.localStorage.setItem('tracked_lite_pro_mode', tradeMode);
			}
		}

		const driftClient = get().driftClient.client;

		if (!driftClient?.isSubscribed) {
			if (isDev()) {
				// Drift client should always be subscribed here.
				throw `Drift Client not subscribed when it should be`;
			}
			return;
		}

		// Unsubscribe user accounts before resetting state so event emitter doesn't update state afterwards in race-condition
		await driftClient.unsubscribeUsers();
		resetAccountsState();

		const wallet = get().wallet.current;
		const connection = get().connection.current;

		const authority = wallet.adapter.publicKey;

		const connectionStateForWallet = get().wallet.connectionState;

		const subAccountStorageKey = UI_UTILS.getSubAccountStorageKey(
			authority,
			Env.sdkEnv
		);
		const storedSubAccountIdValue = subAccountStorageKey
			? window?.localStorage?.getItem(subAccountStorageKey) ?? undefined
			: undefined;

		const subAccountId = storedSubAccountIdValue
			? Number(storedSubAccountIdValue)
			: undefined;

		const success = await driftClient.updateWallet(
			wallet.adapter as IWallet,
			undefined,
			subAccountId,
			true,
			undefined
		);

		if (!success) {
			console.log('Error subscribing to users for new wallet');
		}

		const mockUsdcFaucet = new TokenFaucet(
			connection,
			wallet.adapter as IWallet,
			new PublicKey(Env.driftClientProgramId),
			new PublicKey(DEFAULT_PUBLIC_KEY.toString())
		);

		// add faucet to state
		set((state) => {
			state.faucet = mockUsdcFaucet;
		});

		let userAccountExists = false;

		try {
			await fetchAndSubscribeSubAccounts(authority);
			fetchAndSetDeletedAccountPubKeys(authority);

			let initialAccountId = driftClient.activeSubAccountId;
			const initialAuthority = driftClient.authority;

			try {
				// we can do this to determine if user exists instead of doing await user.exists() since we only just fetched subaccounts above
				userAccountExists = !!driftClient
					.getUser(initialAccountId)
					.getUserAccount();
			} catch (err: any) {
				userAccountExists = false;
			}

			// ensure that the user account exists before setting the user key
			const authorityHasDriftUsers = driftClient.getUsers().length > 0;
			if (!userAccountExists && authorityHasDriftUsers) {
				const firstUser = driftClient.getUsers()[0];
				initialAccountId = firstUser.getUserAccount().subAccountId;
			}

			const userKey = COMMON_UI_UTILS.getUserKey(
				initialAccountId,
				initialAuthority
			);

			accountsSet((s) => {
				s.currentUserKey = userKey;
			});
		} catch (err: any) {
			// user account doesn't exist
			// TODO : I think this isn't true anymore - it gracefully handles there being no user account and we should let the error bubble if we encounter one here.
		}

		// subscribe to user account data
		if (userAccountExists) {
			subscribeToUserUSDCBalance();
		} else {
			removeLoadingItemFromQueue({ key: 'marginInfo' });
		}

		set((s) => {
			// If wallet connects and this is an existing "user", i.e. this wallet has connected before on another browser
			// We can end onboarding after the connect step and not go to the deposit modal
			if (userAccountExists && !s.hasCompletedOnboarding) {
				if (window && window.localStorage && window.localStorage.setItem) {
					setLocalStorageItem('hasCompletedOnboarding', `${true}`);
				}
				s.hasCompletedOnboarding = true;
			}
		});

		const authorityString = authority.toString();

		connectionStateForWallet.progress('ClientConnected');
		connectionStateForWallet.progress('AdapterConnected');

		set((s) => {
			s.wallet.connectionState = connectionStateForWallet;
			s.wallet.currentPublicKeyString = authorityString;
		});

		connectionStateForWallet.progress('SubaccountsSubscribed');
	};

	const handleWalletError = (e: any) => {
		console.log('handleWalletError');

		showModal('showWalletConnectionModal', false);

		if (e.name === 'WalletSignTransactionError') {
			// User just cancelled the tx, do nothing
			return;
		}

		if (e.name === 'WalletDisconnectedError') {
			// notify({
			// 	type: 'error',
			// 	message: 'Wallet connection error',
			// 	description:
			// 		'There was an error with the connection to your wallet, please try again.',
			// });
			return;
		}
	};

	const fetchAndSubscribeSubAccounts = async (
		authority?: PublicKey,
		emulate?: boolean
	): Promise<boolean> => {
		if (
			get().wallet.current?.adapter?.connected &&
			get().driftClient.isSubscribed &&
			get().driftClient.client.wallet !== Env.defaultWallet
		) {
			const driftClient = get().driftClient.client;

			const accounts = COMMON_UI_UTILS.fetchCurrentSubaccounts(driftClient);

			if (accounts.length === 0) {
				accountsSet((s) => {
					s.userAccountNotInitialized = true;
				});
				return false;
			}

			await setupUserAccountStateAndListeners(authority, undefined, emulate);

			accountsSet((s) => {
				s.userAccountNotInitialized = false;
			});

			return true;
		} else {
			return false;
		}
	};

	/* Fetches and sets deleted subaccounts, so users can still export the history. Returns false if there are none */
	const fetchAndSetDeletedAccountPubKeys = (authority: PublicKey): boolean => {
		const driftClient = get().driftClient.client;

		const setNewDeletedKeys = (ids: number[]) => {
			accountsSet((s) => {
				s.deletedAccounts = ids.map((subAccountId) => {
					return {
						pubKey: getUserAccountPublicKeySync(
							driftClient.program.programId,
							authority,
							subAccountId
						),
						userId: subAccountId,
						userKey: COMMON_UI_UTILS.getUserKey(subAccountId, authority),
						name: `Deleted Subaccount ${subAccountId}`,
					};
				});
			});
		};

		const createIdArray = (n: number): number[] =>
			Array.from({ length: n }, (_, i) => i);

		if (!authority) {
			return false;
		}

		try {
			// if userStats already set, check it for deleted accounts. if not, try to search for it
			if (driftClient.userStats && driftClient.userStats.isSubscribed) {
				const statsAccount = driftClient.userStats.getAccount();
				const numAccountsCreated = statsAccount.numberOfSubAccountsCreated;
				const numAccountsCurrent = statsAccount.numberOfSubAccounts;

				if (numAccountsCreated == numAccountsCurrent) {
					return false;
				}

				// if more created than current, then they have some deleted. find the missing subaccount ids from their user list
				const subAccountIds = Array.from(driftClient.users.values()).map(
					(user) => user.getUserAccount().subAccountId
				);

				const deletedIds = createIdArray(numAccountsCreated).filter(
					(num) => !subAccountIds.includes(num)
				);

				setNewDeletedKeys(deletedIds);

				return true;
			}

			const userStatsAccountPublicKey = getUserStatsAccountPublicKey(
				driftClient.program.programId,
				authority
			);

			const userStatsAccount = new UserStats({
				driftClient,
				userStatsAccountPublicKey,
				accountSubscription: { type: 'websocket' },
			});

			// If no user stats account, they truly never used Drift
			if (!userStatsAccount) {
				return false;
			}

			// Otherwise, they used Drift but deleted all accounts
			const accountsCreated =
				userStatsAccount.getAccount().numberOfSubAccountsCreated;

			setNewDeletedKeys(createIdArray(accountsCreated));

			return true;
		} catch (e: any) {
			dlog(`deleted_accounts`, `Error fetching deleted accounts: ${e}`);
			return false;
		}
	};

	const addInsuranceFundStake = async (opts: {
		amount: BN;
		marketIndex: number;
		/**
		 * If true, also tries to create a new IF account at the same time. Use first time the user does it.
		 */
		initializeStakeAccount?: boolean;
		/**
		 * If true, tries to withdraw the amount from the current subaccount instead of using wallet balance
		 */
		fromSubaccount?: boolean;
		/**
		 * if fromSubaccount, pass userId of the subaccount
		 */
		userId?: number;
		/**
		 * if fromSubaccount, pass userKey of the subaccount
		 */
		userKey?: string;
	}) => {
		const state = get();
		const accountsState = accountsGet();

		if (opts.userId !== undefined) {
			await state.driftClient.client.switchActiveUser(opts.userId);
		}

		const spotMarketConfig = SPOT_MARKETS_LOOKUP[opts.marketIndex];
		const toastId = `create-if-account-${opts.marketIndex}`;

		notify({
			id: toastId,
			type: 'awaiting',
			message: `Staking ${spotMarketConfig?.symbol}`,
			description: opts.initializeStakeAccount
				? 'Creating staking account and adding funds'
				: undefined,
			showUntilCancelled: true,
		});

		try {
			const authority = get().wallet?.current?.adapter?.publicKey;
			const driftClient = get().driftClient.client;

			const spotMarketAccount = driftClient.getSpotMarketAccount(
				spotMarketConfig.marketIndex
			);

			const tokenAccount = await UI_UTILS.getTokenAddressForDepositAndWithdraw(
				spotMarketAccount,
				authority
			);

			const txid = await driftClient.addInsuranceFundStake({
				...opts,
				collateralAccountPublicKey: tokenAccount,
				txParams: getTxParams(),
			});

			notify({
				id: toastId,
				type: 'success',
				message: `Staked ${spotMarketConfig?.symbol}`,
				action: {
					type: 'txnLink',
					txnSig: txid,
				},
				showUntilCancelled: false,
			});

			if (opts.userId !== undefined) {
				updateAfterTx(
					state.driftClient.client,
					accountsState.accounts[opts?.userKey].client
				);
			}

			return true;
		} catch (err: any) {
			console.error(err);
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	/**
	 * Request to unstake from an insurance fund vault. After the cooldown the assets can be withdrawn using the `withdrawInsuranceFundUnstakedAssets` action
	 */
	const requestInsuranceFundUnstake = async (opts: {
		displayAmount: string;
		withdrawalDate: string;
		amount: BN;
		marketIndex: number;
	}) => {
		const spotMarketConfig = SPOT_MARKETS_LOOKUP[opts.marketIndex];
		const toastId = `request-unstake-from-if-${opts.marketIndex}`;

		notify({
			id: toastId,
			type: 'info',
			message: 'Requesting Unstake',
			description: `Unstaking ${opts.displayAmount} ${spotMarketConfig?.symbol}`,
			subDescription: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const driftClient = get().driftClient.client;

			const txid = await driftClient.requestRemoveInsuranceFundStake(
				opts.marketIndex,
				opts.amount,
				getTxParams()
			);

			notify({
				id: toastId,
				type: 'success',
				message: `Requested Unstake`,
				description: `${opts.displayAmount} ${spotMarketConfig?.symbol} will be available to withdraw on ${opts.withdrawalDate}`,
				subDescription: '',
				action: {
					type: 'txnLink',
					txnSig: txid,
				},
				showUntilCancelled: false,
			});

			return true;
		} catch (err: any) {
			console.log(err);
			notify({
				id: toastId,
				type: 'error',
				message: `Error Requesting Unstake`,
				description: `${err}`,
				showUntilCancelled: false,
			});
			return false;
		}
	};

	const cancelInsuranceFundUnstakeRequest = async (opts: {
		marketIndex: number;
	}) => {
		const spotMarketConfig = SPOT_MARKETS_LOOKUP[opts.marketIndex];
		const toastId = `cancel-unstake-from-if-${opts.marketIndex}`;

		notify({
			id: toastId,
			type: 'info',
			message: `Canceling ${spotMarketConfig?.symbol} Unstake Request`,
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const driftClient = get().driftClient.client;

			const txid = await driftClient.cancelRequestRemoveInsuranceFundStake(
				opts.marketIndex,
				getTxParams()
			);

			notify({
				id: toastId,
				type: 'success',
				message: `Canceled ${spotMarketConfig?.symbol} Unstake Request`,
				action: {
					type: 'txnLink',
					txnSig: txid,
				},
				showUntilCancelled: false,
			});

			return true;
		} catch (err: any) {
			console.log(err);
			notify({
				id: toastId,
				type: 'error',
				message: `Error Canceling ${spotMarketConfig?.symbol} Unstake Request`,
				description: `${err}`,
				showUntilCancelled: false,
			});
			return false;
		}
	};

	/**
	 * Withdraw assets available from an insurance fund unstake request after the cooldown period is over
	 */
	const withdrawInsuranceFundUnstakedAssets = async (opts: {
		marketIndex: number;
	}) => {
		const spotMarketConfig = SPOT_MARKETS_LOOKUP[opts.marketIndex];
		const toastId = `withdraw-unstaked-assets-${opts.marketIndex}`;

		notify({
			id: toastId,
			type: 'info',
			message: `Withdrawing Unstaked ${spotMarketConfig?.symbol}`,
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const authority = get().wallet?.current?.adapter?.publicKey;
			const driftClient = get().driftClient.client;

			const spotMarketAccount = driftClient.getSpotMarketAccount(
				spotMarketConfig.marketIndex
			);

			const tokenAccount = await UI_UTILS.getTokenAddressForDepositAndWithdraw(
				spotMarketAccount,
				authority
			);

			const txid = await driftClient.removeInsuranceFundStake(
				opts.marketIndex,
				tokenAccount,
				getTxParams()
			);

			notify({
				id: toastId,
				type: 'success',
				message: `Withdrew Unstaked ${spotMarketConfig?.symbol}`,
				action: {
					type: 'txnLink',
					txnSig: txid,
				},
				showUntilCancelled: false,
			});
			return true;
		} catch (err: any) {
			console.log(err);
			notify({
				id: toastId,
				type: 'error',
				message: `Error Withdrawing Unstaked ${spotMarketConfig?.symbol}`,
				description: `${err}`,
				showUntilCancelled: false,
			});
			return false;
		}
	};

	const addPerpLpShares = async (opts: {
		userAccount: UserAccount;
		amount: BN;
		marketIndex: number;
	}) => {
		const perpMarketConfig = OrderedPerpMarkets[opts.marketIndex];
		const toastId = `add-perp-lp-shares-${opts.marketIndex}-${Date.now()}`;

		console.log(
			'add lp shares: ',
			BigNum.from(opts.amount, BASE_PRECISION_EXP).print()
		);

		notify({
			id: toastId,
			type: 'info',
			message: `Adding Liquidity to ${perpMarketConfig?.symbol} BAL`,
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const driftClient = get().driftClient.client;

			await driftClient.switchActiveUser(
				opts.userAccount.subAccountId,
				opts.userAccount.authority
			);

			const txid = await handleTxChain(
				driftClient.addPerpLpShares(
					opts.amount,
					opts.marketIndex,
					getTxParams({
						marketId: MarketId.createPerpMarket(opts.marketIndex),
						simulatedComputeUnitMultiplier:
							Env.computeUnitBufferMultiplier + 0.3,
					})
				)
			);

			notify({
				id: toastId,
				type: 'success',
				message: `Added Liquidity to ${perpMarketConfig?.symbol}`,
				action: {
					type: 'txnLink',
					txnSig: txid,
				},
				showUntilCancelled: false,
			});
			return true;
		} catch (err: any) {
			console.log(err);
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const removePerpLpShares = async (opts: {
		userAccount: UserAccount;
		amount: BN;
		marketIndex: number;
	}) => {
		const perpMarketConfig = OrderedPerpMarkets[opts.marketIndex];
		const toastId = `remove-perp-lp-shares-${opts.marketIndex}-${Date.now()}`;

		notify({
			id: toastId,
			type: 'info',
			message: `Removing Liquidity from ${perpMarketConfig?.symbol} LP`,
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const driftClient = get().driftClient.client;

			await driftClient.switchActiveUser(
				opts.userAccount.subAccountId,
				opts.userAccount.authority
			);

			const txid = await handleTxChain(
				driftClient.removePerpLpShares(
					opts.marketIndex,
					opts.amount,
					getTxParams({
						marketId: MarketId.createPerpMarket(opts.marketIndex),
					})
				)
			);

			notify({
				id: toastId,
				type: 'success',
				message: `Removed Liquidity from ${perpMarketConfig?.symbol}`,
				action: {
					type: 'txnLink',
					txnSig: txid,
				},
				showUntilCancelled: false,
			});
			return true;
		} catch (err: any) {
			console.log(err);
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const settleLpPosition = async (opts: {
		marketIndex: number;
		user?: User;
	}) => {
		const perpMarketConfig = OrderedPerpMarkets[opts.marketIndex];
		const toastId = `settle-perp-lp-shares-${opts.marketIndex}`;
		const user =
			opts.user ?? accountsGet().accounts[accountsGet().currentUserKey]?.client;

		notify({
			id: toastId,
			type: 'info',
			message: `Settling ${perpMarketConfig?.symbol} BAL position`,
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const driftClient = get().driftClient.client;

			const userAccount = user.getUserAccount();

			await driftClient.switchActiveUser(
				userAccount.subAccountId,
				userAccount.authority
			);

			const txid = await handleTxChain(
				driftClient.settleLP(
					user.userAccountPublicKey,
					opts.marketIndex,
					getTxParams({
						marketId: MarketId.createPerpMarket(opts.marketIndex),
					})
				)
			);

			notify({
				id: toastId,
				type: 'success',
				message: `Settled ${perpMarketConfig?.symbol} BAL position`,
				action: {
					type: 'txnLink',
					txnSig: txid,
				},
				showUntilCancelled: false,
			});
			return true;
		} catch (err: any) {
			console.log(err);
			notify({
				id: toastId,
				type: 'error',
				message: `Error settling ${perpMarketConfig?.symbol} BAL position`,
				description: `${err}`,
				showUntilCancelled: false,
			});
			return false;
		}
	};

	const tryFaucetAirdrop = async (spotMarket: SpotMarketConfig) => {
		const notificationId = `airdrop${Date.now()}`;

		try {
			let airdropAmount = 10000;

			switch (spotMarket.symbol) {
				case 'SOL':
					airdropAmount = 1;
					break;
				case 'BTC':
					airdropAmount = 0.1;
					break;
				default:
					break;
			}

			notify({
				type: 'info',
				message: `Airdropping ${airdropAmount} ${spotMarket.symbol}`,
				id: notificationId,
				showUntilCancelled: true,
			});

			const connection = get().connection.current;
			const wallet = get().wallet.current.adapter as IWallet;

			const isSol = spotMarket.symbol === 'SOL';

			if (isSol) {
				await connection.requestAirdrop(
					wallet.publicKey,
					airdropAmount * LAMPORTS_PER_SOL
				);
			} else {
				const faucet = new TokenFaucet(
					connection,
					wallet,
					new PublicKey('V4v1mQiAdLz4qwckEb45WqHYceYizoib39cDBHSWfaB'),
					spotMarket.mint
				);

				const airdropAmountBn = new BN(
					airdropAmount * 10 ** spotMarket.precisionExp.toNumber()
				);

				const spotMarketAccount = get().driftClient.client.getSpotMarketAccount(
					spotMarket.marketIndex
				);

				const expectedTokenAccountAddress =
					await UI_UTILS.getTokenAddressForDepositAndWithdraw(
						spotMarketAccount,
						wallet.publicKey
					);

				const tokenAccountInfo = await connection.getAccountInfo(
					expectedTokenAccountAddress
				);

				const tokenAccountExists = !!tokenAccountInfo;

				if (tokenAccountExists) {
					await faucet.mintToUser(expectedTokenAccountAddress, airdropAmountBn);
				} else {
					await faucet.createAssociatedTokenAccountAndMintTo(
						wallet.publicKey,
						airdropAmountBn
					);
				}
			}

			notify({
				type: 'success',
				message: `Airdropping ${airdropAmount} ${spotMarket.symbol}`,
				id: notificationId,
				updatePrevious: true,
			});
		} catch (e: any) {
			console.error(e);
			notify({
				type: 'error',
				message: `Airdrop failed`,
				description:
					'Please try again. If this problem persists contact the Drift team',
				id: notificationId,
				updatePrevious: true,
			});
		}
	};

	type CollateralModalIntent =
		| {
				bankIndex?: number;
				accountKey?: string;
				defaultToBiggestAsset?: false;
		  }
		| {
				defaultToBiggestAsset?: true;
				accountKey?: string;
				bankIndex?: undefined;
		  }; // This type enforces that someone doesn't try to manually use an intent bankIndex AND the defaultToBiggestAsset argument at the same time

	type ProcessedCollateralModalIntentProps = {
		modalName: CollateralModalName;
		intent: Pick<
			DriftStore['modalsProps']['intentState'],
			| 'modalCollateralPoolId'
			| 'modalCollateralIndex'
			| 'modalTargetAccountKey'
			| 'modalDefaultLargestBalance'
		>;
	};

	const processCollateralModalIntentProps = (
		modalName: CollateralModalName,
		intent: {
			bankIndex?: number;
			accountKey?: string;
			defaultToBiggestAsset?: boolean;
		}
	): ProcessedCollateralModalIntentProps => {
		let modalNameToUse = modalName;

		const currentAccountKey = accountsGet().currentUserKey;
		let accountKeyToUse: string | null =
			intent?.accountKey ?? currentAccountKey;
		const accountToUseData = accountsGet().accounts[accountKeyToUse];
		const accountToUsePoolId = accountToUseData?.poolId;

		let bankIndexToUse = intent?.bankIndex ?? 0;

		const bankToUsePoolId = SPOT_MARKETS_LOOKUP[bankIndexToUse].poolId;

		let poolIdToUse;

		// Handle case where we're triggering a collateral modal but no current account exists
		if (!accountToUseData) {
			return {
				modalName: 'newSubaccount',
				intent: {
					modalCollateralPoolId:
						intent?.bankIndex === undefined ? MAIN_POOL_ID : bankToUsePoolId,
					modalCollateralIndex:
						intent?.bankIndex === undefined ? 0 : bankIndexToUse,
					modalTargetAccountKey: null,
					modalDefaultLargestBalance: intent?.defaultToBiggestAsset ?? null,
				},
			};
		}

		if (bankToUsePoolId !== accountToUsePoolId) {
			if (intent?.bankIndex !== undefined && intent?.accountKey !== undefined) {
				// If both bank index and account key were specified and they don't have matching pool ids, something is wrong.
				throw new Error(
					'Intent bank index pool id does not match intent account pool id'
				);
			}

			// By default prefer using the Pool ID for the account
			let accountLeads = true;

			if (intent.bankIndex !== undefined) {
				// If the bank index was specified, choose it instead of the account
				accountLeads = false;
			}

			if (accountLeads) {
				// Account leads, find the first bank index with the matching pool id for the account
				const bankIndexWithMatchingPoolId = SPOT_MARKETS_LOOKUP.findIndex(
					(market) => market.poolId === accountToUsePoolId
				);
				bankIndexToUse = bankIndexWithMatchingPoolId;
				poolIdToUse = accountToUsePoolId;
			} else {
				// Bank index leads, find the account with the matching pool id for the bank
				const accountWithMatchingPoolId = Object.values(
					accountsGet().accounts
				).find((account) => account.poolId === bankToUsePoolId);

				if (!accountWithMatchingPoolId) {
					// If we don't have an account with the matching pool id, we need to force the new subaccount modal
					modalNameToUse = 'newSubaccount';
					accountKeyToUse = null;
				} else {
					accountKeyToUse = accountWithMatchingPoolId?.userKey;
				}

				poolIdToUse = bankToUsePoolId;
			}
		} else {
			poolIdToUse = accountToUsePoolId;
		}

		return {
			modalName: modalNameToUse,
			intent: {
				modalCollateralPoolId: poolIdToUse,
				modalCollateralIndex: bankIndexToUse,
				modalTargetAccountKey: accountKeyToUse,
				modalDefaultLargestBalance: intent?.defaultToBiggestAsset ?? null,
			},
		};
	};

	type CollateralModalName =
		| 'deposit'
		| 'withdraw'
		| 'borrow'
		| 'transfer'
		| 'closeBorrow'
		| 'newSubaccount';

	const getModalNameFromCollateralModalNameIntent = (
		modalName: CollateralModalName
	) => {
		let modalName_: keyof DriftStore['modals'];

		switch (modalName) {
			case 'deposit':
				modalName_ = 'showDepositModal';
				break;
			case 'withdraw':
				modalName_ = 'showWithdrawModal';
				break;
			case 'borrow':
				modalName_ = 'showBorrowModal';
				break;
			case 'transfer':
				modalName_ = 'showCollateralTransferModal';
				break;
			case 'closeBorrow':
				modalName_ = 'showCloseBorrowModal';
				break;
			case 'newSubaccount':
				modalName_ = 'showNewSubaccountModal';
				break;
			default: {
				const never: never = modalName;
				throw new Error(`Invalid collateral modal name: ${never}`);
			}
		}

		return modalName_;
	};

	const hideCollateralModal = (modalName: CollateralModalName) => {
		set((s) => {
			s.modals[getModalNameFromCollateralModalNameIntent(modalName)] = false;
			// Using @ts-ignore here because this is where we KNOW we're allowed to overwrite these values :: this helps enforce all logic flowing through unified logic
			// @ts-expect-error - Readonly property assignment
			s.modalsProps.intentState.modalCollateralIndex = null;
			// @ts-expect-error - Readonly property assignment
			s.modalsProps.intentState.modalCollateralPoolId = null;
			// @ts-expect-error - Readonly property assignment
			s.modalsProps.intentState.modalTargetAccountKey = null;
			// @ts-expect-error - Readonly property assignment
			s.modalsProps.intentState.modalDefaultLargestBalance = null;
		});
	};

	const updateCollateralModalIntentState = (
		intent: Partial<DriftStore['modalsProps']['intentState']>
	) => {
		set((s) => {
			// @ts-expect-error - Readonly property assignment
			s.modalsProps.intentState = {
				...s.modalsProps.intentState,
				...intent,
			};
		});
	};

	/**
	 * All calls to display a "modal collateral" should go through this function. You should give it the MINIMAL intent for what you are trying to do, and it will handle setting the intent state in the store and displaying the modal. For example : If the context of a component is to trigger a deposit for a particular bank - you only need to give the bank index, this function will determine the best userAccount to use, whether the poolId matches, whether an account doesn't exist so a "new subaccount" modal needs to display instead, etc.
	 * @param modalName
	 * @param intent
	 */
	const showCollateralModal = (
		modalName: CollateralModalName,
		intent?: CollateralModalIntent
	) => {
		const { modalName: modalName_, intent: intentProps } =
			processCollateralModalIntentProps(modalName, intent);

		set((s) => {
			s.modals[getModalNameFromCollateralModalNameIntent(modalName_)] = true;
			// @ts-expect-error - Readonly property assignment
			s.modalsProps.intentState = {
				...s.modalsProps.intentState,
				...intentProps,
			};
		});
	};

	const renameSubaccount = async (accountId: number, newName: string) => {
		const driftClient = get().driftClient.client;

		const toastId = `renamingSubaccount${accountId}`;

		notify({
			type: 'info',
			id: toastId,
			message: 'Renaming subaccount',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			await driftClient.updateUserName(newName, accountId);

			notify({
				id: toastId,
				type: 'success',
				message: 'Account name updated',
				showUntilCancelled: false,
			});

			return true;
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const setHasCompletedOnboarding = (newValue: boolean) => {
		if (window && window.localStorage && window.localStorage.setItem) {
			setLocalStorageItem('hasCompletedOnboarding', `${newValue}`);
		}
		set((s) => {
			s.hasCompletedOnboarding = newValue;
			s.modals.showOnboardingCompleteModal = newValue;
		});
	};

	const updateUserMarginTradingEnabled = async (
		updates: {
			marginTradingEnabled: boolean;
			subAccountId: number;
		}[]
	) => {
		const driftClient = get().driftClient.client;
		const firstUserKey = COMMON_UI_UTILS.getUserKey(
			updates[0].subAccountId,
			driftClient.authority
		);
		const firstUserName = accountsGet().accounts[firstUserKey].name;

		const awaitingMessage =
			updates.length === 1
				? `${
						updates[0].marginTradingEnabled ? 'Enabling' : 'Disabling'
				  } margin trading for account "${firstUserName}"`
				: `Updating margin trading for multiple subaccounts`;
		const toastId = `margin_enable_${updates[0].subAccountId}`;

		notify({
			type: 'info',
			message: awaitingMessage,
			description: <ConfirmTransactionStep />,
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			const result = await driftClient.updateUserMarginTradingEnabled(updates);

			if (!result) {
				notify({
					type: 'error',
					message: `Margin trading failed to update`,
					id: toastId,
					updatePrevious: true,
					showUntilCancelled: false,
				});
			} else {
				accountsSet((s) => {
					updates.forEach(({ marginTradingEnabled, subAccountId }) => {
						const accountKey = COMMON_UI_UTILS.getUserKey(
							subAccountId,
							driftClient.authority
						);

						s.accounts[accountKey].marginEnabled = marginTradingEnabled;
					});
				});
				notify({
					id: toastId,
					type: 'success',
					message: 'Update successful',
					updatePrevious: true,
					showUntilCancelled: false,
				});
			}

			return result;
		} catch (err: any) {
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const createReferralLink = async (referrerName: string) => {
		const state = get();
		if (state.isGeoblocked) {
			notify({
				type: 'error',
				message: 'Geoblocked',
				description:
					'You are not allowed to use Drift from a restricted territory',
				lengthMs: 5000,
			});
			return;
		}

		const toastId = `create-referral-link-${referrerName}`;

		notify({
			type: 'info',
			id: toastId,
			message: 'Creating referral link',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const driftClient = state.driftClient.client;
			await driftClient.initializeReferrerName(referrerName);

			notify({
				type: 'success',
				message: `Referral link created`,
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});

			return true;
		} catch (e: any) {
			console.error(e);
			notify({
				type: 'error',
				message: `Could not create referral link`,
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			return false;
		}
	};

	const updateSubaccountDelegate = async (
		accountId: number,
		delegate: string
	) => {
		const driftClient = get().driftClient.client;

		const toastId = `updatingSubaccountDelegate${accountId}`;

		notify({
			type: 'info',
			id: toastId,
			message: 'Updating Subaccount delegate',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			await driftClient.updateUserDelegate(new PublicKey(delegate), accountId);
			const isRemoval = delegate.toString() === DEFAULT_PUBLIC_KEY.toString();
			const description = isRemoval
				? undefined
				: `Delegated to ${UI_UTILS.abbreviateAddress(new PublicKey(delegate))}`;

			notify({
				id: toastId,
				type: 'success',
				message: `Account delegate successfully ${
					isRemoval ? 'removed' : 'updated'
				}`,
				description,
				showUntilCancelled: false,
			});

			accountsSet((s) => {
				s.accounts[
					COMMON_UI_UTILS.getUserKey(accountId, driftClient.authority)
				].delegate = new PublicKey(delegate);
				s.accounts[
					COMMON_UI_UTILS.getUserKey(accountId, driftClient.authority)
				].isDelegatedAway = !isRemoval;
			});

			return true;
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	/**
	 * Accepts a Jupiter API `QuoteResponse`, else fetches a new quote from Jupiter.
	 * It will use the `QuoteResponse` to create a swap transcaction from the Drift user account.
	 * @returns {boolean} - Whether the swap was successful
	 */
	const swap = async (
		userKey: string,
		amount: BN,
		fromMarketIndex: number,
		toMarketIndex: number,
		swapMode: SwapMode,
		quote: QuoteResponse,
		onlyDirectRoutes = false,
		slippageBps = 100
	): Promise<boolean> => {
		const toastId = UI_UTILS.getSwapToastId(fromMarketIndex, toMarketIndex);

		if (!quote) {
			notify({
				type: 'error',
				message: 'Swap Failed',
				description: 'Could not fetch Jupiter quote. Please try again',
				id: toastId,
			});

			return false;
		}

		const state = get();
		const accountsState = accountsGet();
		const accountData = accountsState.accounts[userKey];

		state.driftClient.client.switchActiveUser(
			accountData.userId,
			accountData.authority
		);

		const jupiterClient = new JupiterClient({
			connection: state.connection.current,
		});

		const txParams = getTxParams({
			computeUnits: MAX_COMPUTE_UNITS,
			useSimulatedComputeUnits: false,
		});

		const fromSpotMarket =
			state.driftClient.client.getSpotMarketAccount(fromMarketIndex);
		const toSpotMarket =
			state.driftClient.client.getSpotMarketAccount(toMarketIndex);

		const fromSpotMarketDecimals = fromSpotMarket.decimals;
		const toSpotMarketDeciamls = toSpotMarket.decimals;

		const humanReadableAmount =
			swapMode === 'ExactIn'
				? BigNum.from(amount, fromSpotMarketDecimals)
				: BigNum.from(amount, toSpotMarketDeciamls);

		const inMarketSymbol = OrderedSpotMarkets[fromMarketIndex].symbol;
		const outMarketSymbol = OrderedSpotMarkets[toMarketIndex].symbol;

		const inSwapLabel =
			swapMode === 'ExactIn'
				? `${humanReadableAmount.prettyPrint()} ${inMarketSymbol}`
				: inMarketSymbol;

		const outSwapLabel =
			swapMode === 'ExactOut'
				? `${humanReadableAmount.prettyPrint()} ${outMarketSymbol}`
				: outMarketSymbol;

		try {
			const toastProps = {
				id: toastId,
				type: 'info' as const,
				message: 'Preparing Swap',
				description: `Swapping ${inSwapLabel} to ${outSwapLabel}`,
				subDescription: <ConfirmTransactionStep />,
				showUntilCancelled: true,
				timeoutIfProlongedTxn: true,
				updatePrevious: true,
				overrideBlockSubsequentToasts: true,
				blockSubsequentToasts: false,
				action: undefined as any,
			};
			notify(toastProps);

			await handleTxChain(
				state.driftClient.client.swap({
					jupiterClient,
					outMarketIndex: toMarketIndex,
					inMarketIndex: fromMarketIndex,
					amount,
					slippageBps,
					swapMode,
					txParams,
					v6: {
						quote,
					},
					onlyDirectRoutes,
				}),
				() =>
					notify({
						...toastProps,
						type: 'info',
						message: 'Sending Swap',
						subDescription: <SentTransactionStep />,
					})
			);

			return true;
		} catch (err: any) {
			const customErrorHandler = (err: AnchorError) => {
				// # Transaction size too large
				if (
					(err?.message?.match(/\(max: encoded\/raw \d+\/\d+\)/) && true) ||
					(err?.message?.match(/Transaction locked too many accounts/) && true)
				) {
					notify({
						type: 'error',
						message: 'Transaction error.',
						description: `Some swaps on Drift will fail if the swap route is too complex. Please try again using the "Use direct route" option.`,
						id: toastId,
						updatePrevious: true,
						subDescription: undefined,
					});

					return true;
				}

				return false;
			};

			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				customErrorHandler,
				wallet: get()?.wallet?.current?.adapter,
			});

			return false;
		}
	};

	const walletSwap = async ({
		quote,
	}: {
		quote: QuoteResponse & {
			inputSymbol: string;
			inputTokenDecimals: number;
			outputSymbol: string;
			outputTokenDecimals: number;
		};
	}) => {
		const state = get();

		const jupiterClient = new JupiterClient({
			connection: state.connection.current,
		});

		const toastId = 'wallet-swap-toast';

		const numericInAmount = BigNum.from(
			quote.inAmount,
			quote.inputTokenDecimals
		).toNum();

		try {
			notify({
				type: 'awaiting',
				message: `Swapping ${numericInAmount} ${quote.inputSymbol}`,
				description: `Minimum received: ${BigNum.from(
					(+quote.outAmount * (1 - quote.slippageBps / 10000)).toFixed(0),
					quote.outputTokenDecimals
				).toNum()} ${quote.outputSymbol}`,
				id: toastId,
				showUntilCancelled: true,
			});

			const transaction = await jupiterClient.getSwap({
				quote,
				userPublicKey: state.driftClient.client.authority,
				slippageBps: quote.slippageBps,
			});

			const { txSig } = await state.driftClient.client.sendTransaction(
				transaction
			);

			notify({
				type: 'success',
				message: `Swap successful`,
				description: `Swapped ${numericInAmount} ${quote.inputSymbol} to ${quote.outputSymbol}`,
				id: toastId,
				action: {
					type: 'txnLink',
					txnSig: txSig,
				},
			});

			return txSig;
		} catch (err: any) {
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const nativeUnstakeDSOL = async ({
		withdrawTx,
		deactivateTx,
		signers,
		amountToUnstakeFormatted,
	}: {
		withdrawTx: VersionedTransaction;
		deactivateTx: VersionedTransaction;
		signers: Signer[];
		amountToUnstakeFormatted: string;
	}) => {
		const state = get();
		const toastId = 'native-unstake-toast';
		try {
			notify({
				type: 'awaiting',
				message: `Unstaking ${amountToUnstakeFormatted} dSOL`,
				description: `Unstaked ${amountToUnstakeFormatted} dSOL into a native stake account.`,
				id: toastId,
				showUntilCancelled: true,
			});

			const { txSig: withdrawTxSig } =
				await state.driftClient.client.sendTransaction(withdrawTx, signers);
			const { txSig: deactivateTxSig } =
				await state.driftClient.client.sendTransaction(deactivateTx);
			notify({
				type: 'success',
				message: `Unstaked ${amountToUnstakeFormatted} dSOL`,
				description: `Unstaked ${amountToUnstakeFormatted} dSOL into a native, deactivated stake account. You can withdraw SOL after next epoch`,
				id: toastId,
				action: {
					type: 'txnLink',
					txnSig: [withdrawTxSig, deactivateTxSig],
				},
			});
		} catch (err) {
			console.error('Error unstaking dSOL', err);
			notify({
				type: 'error',
				id: toastId,
				message: 'Unstake Failed',
				description: 'Please try again later.',
			});
		}
	};

	const swapStakeAccountForDSOL = async ({
		inAmount,
		quotedAmount,
		stakeAccount,
		voteAccount,
		outMint,
	}: {
		inAmount: string;
		quotedAmount: string;
		stakeAccount: PublicKey;
		voteAccount: PublicKey;
		outMint: string;
	}) => {
		const state = get();
		const toastId = 'stake-account-swap-toast';

		// const priorityFee = getPriorityFeeToUse();

		try {
			notify({
				type: 'awaiting',
				message: `Converting stake account to dSOL`,
				id: toastId,
				showUntilCancelled: true,
			});

			const body = JSON.stringify({
				amount: inAmount,
				input: voteAccount,
				mode: 'ExactIn',
				outputLstMint: outMint,
				priorityFee: {
					Auto: {
						max_unit_price_micro_lamports: 3000,
						unit_limit: 1000000,
					},
				},
				quotedAmount,
				signer: state?.wallet?.current?.adapter?.publicKey,
				srcAcc: stakeAccount,
				swapSrc: 'Stakedex',
			});

			const sanctumSwapResponse = await fetch(
				'https://sanctum-s-api.fly.dev/v1/swap',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body,
				}
			);

			const data = await sanctumSwapResponse.json();

			const tx = Transaction.from(bs58.decode(data.tx));

			console.log('tx', tx);

			const txSig = '';

			// const { txSig } = await state.driftClient.client.sendTransaction(tx);

			notify({
				type: 'success',
				message: `Swap successful`,
				description: `Stake account converted to dSOL`,
				id: toastId,
				action: {
					type: 'txnLink',
					txnSig: txSig,
				},
			});

			return txSig;
		} catch (err: any) {
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
		}
	};

	const deactivateStakeAccount = async (stakeAccount: PublicKey) => {
		const toastId = `deactivate-stake-account-${Date.now()}`;

		notify({
			id: toastId,
			type: 'info',
			message: 'Deactivating Stake Account',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const state = get();
			const driftClient = state.driftClient.client;
			const wallet = state.wallet?.current?.adapter?.publicKey;

			if (!wallet) {
				throw new Error('Wallet not connected');
			}

			const tx = StakeProgram.deactivate({
				authorizedPubkey: wallet,
				stakePubkey: stakeAccount,
			});

			const txBuilt = await driftClient.buildTransaction(tx.instructions);

			const txSig = await driftClient.sendTransaction(txBuilt);

			notify({
				id: toastId,
				type: 'success',
				message: 'Stake Account Deactivated',
				description: 'Your stake account has been deactivated',
				action: {
					type: 'txnLink',
					txnSig: txSig.txSig,
				},
				showUntilCancelled: false,
			});

			return txSig;
		} catch (err: any) {
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return null;
		}
	};

	const waitForTxnToConfirm = async (txSig: string) => {
		const connection = get().connection.current;

		const txConfirmationManager = new TransactionConfirmationManager(
			connection
		);

		const confirmedTxResult =
			await txConfirmationManager.confirmTransactionWebSocket(txSig);

		return confirmedTxResult;
	};

	const waitForMultipleTxnsToConfirm = async (txSigs: string[]) => {
		const connection = get().connection.current;

		const txConfirmationManager = new TransactionConfirmationManager(
			connection
		);
		return Promise.all(
			txSigs.map((txSig) =>
				txConfirmationManager.confirmTransactionWebSocket(txSig)
			)
		);
	};

	const getEncodedMessageSignature = async (
		signedTs: number
	): Promise<string | undefined> => {
		const state = get();
		const wallet = state.wallet.current.adapter as MessageSignerWalletAdapter;

		if (
			!wallet ||
			!wallet.signMessage ||
			// @ts-ignore
			WALLETS_WITH_NO_SIGN_MESSAGE_METHOD.includes(wallet.name)
		)
			return;

		const publicKey = state.wallet.current.adapter.publicKey;

		try {
			const message =
				COMMON_UI_UTILS.getSignatureVerificationMessageForSettings(
					publicKey,
					signedTs
				);

			const signature = await wallet.signMessage(message);

			if (!COMMON_UI_UTILS.verifySignature(signature, message, publicKey)) {
				notify({
					type: 'error',
					description: 'Message signature invalid',
					id: 'invalid_signature',
					updatePrevious: true,
				});

				return;
			}

			const stringSignature = bs58.encode(signature);

			return stringSignature;
		} catch (e: any) {
			notify({
				type: 'error',
				description: `Sign Message failed: ${e?.message}`,
				id: 'signature_error',
				updatePrevious: true,
			});
			return;
		}
	};

	const updateUserMaxMarginRatio = async (
		updates: {
			newLeverage: number;
			subAccountId: number;
		}[]
	) => {
		if (!updates.length) return;

		const driftClient = get().driftClient.client;

		const firstUserKey = COMMON_UI_UTILS.getUserKey(
			updates[0].subAccountId,
			driftClient.authority
		);
		const firstUserName = accountsGet().accounts[firstUserKey].name;

		const toastId = `updatingMaxMarginRatio_${firstUserKey}`;

		notify({
			type: 'info',
			id: toastId,
			message: 'Updating Max Leverage',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const updatesToPass = updates.map(({ newLeverage, subAccountId }) => {
				return {
					marginRatio: UI_UTILS.convertLeverageToMarginRatio(newLeverage),
					subAccountId,
				};
			});

			await driftClient.updateUserCustomMarginRatio(
				updatesToPass,
				getTxParams()
			);

			const message =
				updatesToPass.length === 1
					? `Max leverage for account "${firstUserName}" successfully updated to ${
							updates[0].newLeverage ? `${updates[0].newLeverage}x` : `No Limit`
					  }`
					: `Max leverage for multiple subaccounts successfully updated.`;

			notify({
				id: toastId,
				type: 'success',
				message,
			});

			return true;
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const updateUserMarginMode = async (
		updates: {
			marginMode: MarginMode;
			subAccountId: number;
			removeMaxLeverage?: boolean;
		}[]
	) => {
		if (!updates.length) return;

		const driftClient = get().driftClient.client;

		const firstUserKey = COMMON_UI_UTILS.getUserKey(
			updates[0].subAccountId,
			driftClient.authority
		);

		const toastId = `updatingMaxMarginRatio_${firstUserKey}`;

		notify({
			type: 'info',
			id: toastId,
			message: 'Updating Margin Mode',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const ixs: TransactionInstruction[] = [];

			await Promise.all(
				updates.map(async ({ marginMode, subAccountId, removeMaxLeverage }) => {
					if (matchEnum(marginMode, MarginMode.HIGH_LEVERAGE)) {
						ixs.push(
							await driftClient.getEnableHighLeverageModeIx(subAccountId)
						);
						if (removeMaxLeverage) {
							ixs.push(
								await driftClient.getUpdateUserCustomMarginRatioIx(
									undefined,
									subAccountId
								)
							);
						}
					} else {
						const subAccountKey = await driftClient.getUserAccountPublicKey(
							subAccountId
						);
						ixs.push(
							await driftClient.getDisableHighLeverageModeIx(
								subAccountKey,
								driftClient.getUserAccount(subAccountId)
							)
						);
					}
				})
			);

			const tx = await driftClient.buildTransaction(ixs);

			await driftClient.sendTransaction(tx);

			notify({
				id: toastId,
				type: 'success',
				message: 'Margin Mode Updated',
			});

			return true;
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const reclaimRent = async (userId: number) => {
		const toastId = `reclaimRent_${userId}`;

		const driftClient = get().driftClient.client;

		try {
			notify({
				type: 'info',
				message: 'Claiming excess rent',
				description: <ConfirmTransactionStep />,
				id: toastId,
				showUntilCancelled: true,
			});

			const txSig = await driftClient.reclaimRent(userId);

			await waitForTxnToConfirm(txSig);

			notify({
				type: 'success',
				message: 'Excess rent reclaimed',
				id: toastId,
				showUntilCancelled: false,
			});
			get()?.appEventEmitter?.emit?.('claimedExcessRent');
		} catch (e: any) {
			const driftError = tryGetDriftErrorCode(e);

			if (driftError && driftError.name === 'CantReclaimRent') {
				notify({
					type: 'success',
					message: 'No excess rent to claim',
					description:
						'There is no excess rent in this account to claim. If you want to claim the full amount of rent, you will need to delete the subaccount.',
					id: toastId,
					showUntilCancelled: false,
				});
			} else {
				TransactionErrorHandler.handleError({
					error: e,
					toastId,
					wallet: get()?.wallet?.current?.adapter,
				});
				return false;
			}
		}
	};

	const updateDlpAdvancedFlags = async (
		updates: {
			advancedLp: boolean;
			subAccountId: number;
		}[]
	) => {
		const driftClient = get().driftClient.client;

		const toastId = `updatingDlpAdvancedFlag_${updates
			.map((update) => update.subAccountId)
			.join(',')}`;

		notify({
			type: 'info',
			id: toastId,
			message: `Updating Auto-Derisk BAL flag for ${updates.length} account(s).`,
			description: <ConfirmTransactionStep />,
		});

		try {
			const txSig = await driftClient.updateUserAdvancedLp(updates);

			if (txSig) {
				notify({
					id: toastId,
					type: 'success',
					message: `Updated successfully.`,
				});
				return true;
			} else {
				notify({
					id: toastId,
					type: 'error',
					message: `There was an error updating Auto-Derisk BAL flag, please try again.`,
				});
				return false;
			}
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const updateReduceOnlyFlags = async (
		updates: {
			reduceOnly: boolean;
			subAccountId: number;
		}[]
	) => {
		const driftClient = get().driftClient.client;

		const toastId = `updatingReduceOnlyFlag_${updates
			.map((update) => update.subAccountId)
			.join(',')}`;

		notify({
			type: 'info',
			id: toastId,
			message: `Updating Reduce Only flag for ${updates.length} account(s).`,
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const txSig = await driftClient.updateUserReduceOnly(updates);

			if (txSig) {
				notify({
					id: toastId,
					type: 'success',
					message: `Updated successfully.`,
					showUntilCancelled: false,
				});
				return true;
			} else {
				notify({
					id: toastId,
					type: 'error',
					message: `Therfe was an error updating Reduce Only flag, please try again.`,
					showUntilCancelled: false,
				});
				return false;
			}
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	// Update all subaccount flags / margin settings in one transaction
	const updateSubaccountSettings = async (
		updates: Record<
			number,
			{
				maxLeverage?: number; // Displayed as "Max Leverage"
				marginMode?: MarginMode; // High leverage mode enabled/disabled
				marginTradingEnabled?: boolean; // Spot margin enabled
				advancedLp?: boolean; // Displayed as "Auto-derisk BAL"
				reduceOnly?: boolean;
				pmm?: boolean;
			}
		>
	) => {
		const driftClient = get().driftClient.client;

		const toastId = `updatingSettings_${Object.keys(updates).join(',')}`;

		notify({
			type: 'info',
			id: toastId,
			message: 'Updating margin settings',
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		const ixs: TransactionInstruction[] = [];

		try {
			await Promise.all(
				Object.keys(updates).map(async (key) => {
					const subAccountId = Number(key);
					// Must check if the key exists here because the value of maxLeverage will be set to undefined if the user is changing it to "no limit"
					if (Object.keys(updates[subAccountId]).includes('maxLeverage')) {
						const marginRatio = UI_UTILS.convertLeverageToMarginRatio(
							updates[subAccountId].maxLeverage
						);
						ixs.push(
							await driftClient.getUpdateUserCustomMarginRatioIx(
								marginRatio,
								subAccountId
							)
						);
					}
					if (Object.keys(updates[subAccountId]).includes('marginMode')) {
						if (
							matchEnum(
								updates[subAccountId].marginMode,
								MarginMode.HIGH_LEVERAGE
							)
						) {
							ixs.push(
								await driftClient.getEnableHighLeverageModeIx(subAccountId)
							);
						} else {
							const subAccountKey = await driftClient.getUserAccountPublicKey(
								subAccountId
							);
							ixs.push(
								await driftClient.getDisableHighLeverageModeIx(
									subAccountKey,
									driftClient.getUserAccount(subAccountId)
								)
							);
						}
					}
					if (
						Object.keys(updates[subAccountId]).includes('marginTradingEnabled')
					) {
						ixs.push(
							await driftClient.getUpdateUserMarginTradingEnabledIx(
								updates[subAccountId].marginTradingEnabled,
								subAccountId
							)
						);
					}
					if (Object.keys(updates[subAccountId]).includes('advancedLp')) {
						ixs.push(
							await driftClient.getUpdateAdvancedDlpIx(
								updates[subAccountId].advancedLp,
								subAccountId
							)
						);
					}
					if (Object.keys(updates[subAccountId]).includes('reduceOnly')) {
						ixs.push(
							await driftClient.getUpdateUserReduceOnlyIx(
								updates[subAccountId].reduceOnly,
								subAccountId
							)
						);
					}
					if (Object.keys(updates[subAccountId]).includes('pmm')) {
						ixs.push(
							await driftClient.getUpdateUserProtectedMakerOrdersIx(
								subAccountId,
								updates[subAccountId].pmm
							)
						);
					}
				})
			);

			const tx = await driftClient.buildTransaction(ixs);

			const txSig = await driftClient.sendTransaction(tx);

			if (txSig) {
				notify({
					id: toastId,
					type: 'success',
					message: `Updated margin settings successfully.`,
				});
				return true;
			} else {
				notify({
					id: toastId,
					type: 'error',
					message: `There was an error updating margin settings, please try again.`,
				});
				return false;
			}
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const updateFundingRate = async (perpMarketIndex: number) => {
		const driftClient = get().driftClient.client;
		const market = UIMarket.createPerpMarket(perpMarketIndex);

		const toastId = `updatingFundingRate_${perpMarketIndex}`;

		notify({
			type: 'info',
			id: toastId,
			message: `Triggering funding rate update for ${market.symbol}`,
			description: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		try {
			const txSig = await driftClient.updateFundingRate(
				perpMarketIndex,
				market.market.oracle,
				getTxParams()
			);

			if (txSig) {
				notify({
					id: toastId,
					type: 'success',
					message: `Updated successfully.`,
					showUntilCancelled: false,
				});
				return true;
			} else {
				notify({
					id: toastId,
					type: 'error',
					message: `There was an error updating funding, please try again.`,
					showUntilCancelled: false,
				});
				return false;
			}
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const withdrawDustPositions = async () => {
		const toastId = 'withdrawDustPositions';

		try {
			const driftClient = get().driftClient.client;

			let withdrawCount: number;

			const txSig = await driftClient.withdrawAllDustPositions(
				undefined,
				undefined,
				{
					dustPositionCountCallback: (count) => {
						withdrawCount = count;

						if (count === 0) {
							notify({
								id: toastId,
								type: 'error',
								message: `No dust positions to withdraw`,
							});
							return true;
						}

						notify({
							id: toastId,
							type: 'info',
							message: `Withdrawing ${count} dust positions`,
							description: <ConfirmTransactionStep />,
							showUntilCancelled: true,
						});
					},
				}
			);

			if (txSig) {
				notify({
					id: toastId,
					type: 'success',
					message: `Withdrew ${withdrawCount ?? ''} dust positions`,
					showUntilCancelled: false,
				});
				return true;
			}
		} catch (e: any) {
			TransactionErrorHandler.handleError({
				error: e,
				toastId,
				wallet: get()?.wallet?.current?.adapter,
			});
			return false;
		}
	};

	const updateCurrentSubAccount = (
		newSubaccountId: number,
		authority: PublicKey
	) => {
		const driftClient = get().driftClient.client;
		const userKey = COMMON_UI_UTILS.getUserKey(newSubaccountId, authority);
		accountsSet((s) => {
			s.currentUserKey = userKey;
		});
		driftClient.switchActiveUser(newSubaccountId, authority);
	};

	const handleUpdateTransactionPreflights = (skipPreFlights: boolean) => {
		const driftClient = get().driftClient.client;

		// This ensures any future drift clients get use the correct preflight settings
		DRIFT_CLIENT_OPTS.skipPreflight = skipPreFlights;

		dlog(
			'feature_flags',
			'updating_skipPreflight_on_driftClient',
			skipPreFlights
		);

		if (!driftClient) {
			// If we don't currently have a drift client then it should be sufficient to return here after we've updated the DRIFT_CLIENT_OPTS
			return;
		}

		if (!driftClient.opts) {
			driftClient.opts = {
				skipPreflight: skipPreFlights,
			};
		} else {
			driftClient.opts = {
				...driftClient.opts,
				skipPreflight: skipPreFlights,
			};
		}
	};

	/**
	 * Used mainly for mapping UI's tx params to Vault SDK's tx params
	 */
	const mapTxParams = (txParams: TxParams) => {
		return {
			cuLimit: txParams.computeUnits,
			cuPriceMicroLamports: txParams.computeUnitsPrice,
		};
	};

	const depositVault = async (
		vault: Vault,
		vaultClient: VaultClient,
		amount: BN,
		vaultDepositor?: VaultDepositor
	): Promise<TransactionSignature | undefined> => {
		if (!vaultDepositor && vault.permissioned) {
			notify({
				type: 'error',
				message: 'You are not authorized to deposit into this vault',
			});
			return undefined;
		}

		const authority = get().driftClient.client.authority;
		const toastId = 'depositVault';

		try {
			notify({
				type: 'awaiting',
				message: 'Awaiting transaction confirmation',
				id: toastId,
				showUntilCancelled: true,
			});

			let txSig: TransactionSignature;

			if (!vaultDepositor) {
				if (!authority) {
					notify({
						type: 'error',
						message: 'Please connect your wallet to deposit into this vault',
						id: toastId,
						updatePrevious: true,
						showUntilCancelled: false,
					});
					return undefined;
				}

				const vaultDepositorPubkey = VaultDepositorAccount.getAddressSync(
					VAULT_PROGRAM_ID,
					vault.pubkey,
					authority
				);

				txSig = await vaultClient.deposit(
					vaultDepositorPubkey,
					amount,
					{
						authority,
						vault: vault.pubkey,
					},
					mapTxParams(getTxParams())
				);
			} else {
				txSig = await vaultClient.deposit(
					vaultDepositor.pubkey,
					amount,
					undefined,
					mapTxParams(getTxParams())
				);
			}

			await waitForTxnToConfirm(txSig);

			notify({
				type: 'success',
				message: 'Deposit successful!',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});

			return txSig;
		} catch (e: any) {
			console.error(e);
			notify({
				type: 'error',
				message: 'There was an error depositing into this vault',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			return undefined;
		}
	};

	const requestVaultWithdrawal = async (
		vaultDepositorPubKey: PublicKey,
		userSharesPercentage: BN,
		vaultClient: VaultClient
	): Promise<TransactionSignature | undefined> => {
		if (!vaultClient) {
			console.error('No vault client provided');
			return undefined;
		}

		const toastId = 'requestVaultWithdrawal';

		try {
			notify({
				type: 'awaiting',
				message: 'Awaiting transaction confirmation',
				id: toastId,
				showUntilCancelled: true,
			});
			const txSig = await vaultClient.requestWithdraw(
				vaultDepositorPubKey,
				userSharesPercentage,
				WithdrawUnit.SHARES_PERCENT,
				mapTxParams(getTxParams())
			);

			await waitForTxnToConfirm(txSig);

			notify({
				type: 'success',
				message: 'Withdrawal request successful',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});

			return txSig;
		} catch (err: any) {
			console.error(err);
			notify({
				type: 'error',
				message: 'There was an error requesting a withdrawal from this vault',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			return undefined;
		}
	};

	const cancelVaultWithdrawalRequest = async (
		vaultDepositorPubKey: PublicKey,
		vaultClient: VaultClient
	): Promise<TransactionSignature | undefined> => {
		if (!vaultClient || !vaultDepositorPubKey) {
			console.error('No vault client or vault depositor pubkey provided');
			return undefined;
		}

		const toastId = 'cancelVaultWithdrawalRequest';

		try {
			notify({
				type: 'awaiting',
				message: 'Awaiting transaction confirmation',
				id: toastId,
				showUntilCancelled: true,
			});
			const txSig = await vaultClient.cancelRequestWithdraw(
				vaultDepositorPubKey,
				mapTxParams(getTxParams())
			);

			await waitForTxnToConfirm(txSig);

			notify({
				type: 'success',
				message: 'Withdrawal request canceled',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});

			return txSig;
		} catch (err: any) {
			console.error(err);
			notify({
				type: 'error',
				message:
					'There was an error canceling a withdrawal request from this vault',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			return undefined;
		}
	};

	const withdrawFromVault = async (
		vaultDepositor: VaultDepositor,
		vaultClient: VaultClient
	): Promise<TransactionSignature | undefined> => {
		if (!vaultClient || !vaultDepositor) {
			console.error('No vault client or vault depositor provided');
			return undefined;
		}

		const toastId = 'withdrawFromVault';

		try {
			notify({
				type: 'awaiting',
				message: 'Awaiting transaction confirmation',
				id: toastId,
				showUntilCancelled: true,
			});
			const txSig = await vaultClient.withdraw(
				vaultDepositor.pubkey,
				mapTxParams(getTxParams())
			);

			await waitForTxnToConfirm(txSig);

			notify({
				type: 'success',
				message: 'Withdrawal successful',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});

			return txSig;
		} catch (err: any) {
			console.error(err);
			notify({
				type: 'error',
				message: 'There was an error withdrawing from this vault',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			return undefined;
		}
	};

	const updateUserFuelBonus = async () => {
		const driftClient = get().driftClient.client;
		const userAccount = driftClient.getUserAccount();
		const userAccountPublicKey = await driftClient.getUserAccountPublicKey(
			userAccount.subAccountId
		);

		const toastId = `update_fuel_bonus_${userAccountPublicKey.toString()}`;

		notify({
			id: toastId,
			type: 'info',
			message: 'Updating Fuel Bonus',
			subDescription: <ConfirmTransactionStep />,
			showUntilCancelled: true,
		});

		const txSig = await driftClient.updateUserFuelBonus(
			userAccountPublicKey,
			userAccount,
			driftClient.authority
		);

		notify({
			type: 'success',
			message: 'Fuel Bonus Updated',
			id: toastId,
			subDescription: '',
			action: {
				type: 'txnLink',
				txnSig: txSig,
			},
			updatePrevious: true,
		});
	};

	return {
		updateCurrentSubAccount,
		connectWithCustomWallet,
		fetchMarginAccountInfo,
		withdrawCollateral,
		depositCollateralForExistingAccount,
		initializeAndDepositCollateralForAccount,
		subscribeToUserUSDCBalance,
		handleWalletDisconnect,
		addLoadingItemToQueue,
		removeLoadingItemFromQueue,
		emulateAccount,
		emulateAccountForSigning,
		settleFunding,
		settlePnl,
		settleInsuranceFundRevenue,
		closePosition,
		closeMultiplePerpPositions,
		switchMarket,
		openPerpTradeFormOrder,
		openSpotTradeFormOrder,
		cancelOrder,
		linkWalletToDriftClient,
		handleWalletError,
		emitAppEvent,
		showModal,
		switchModal,
		hideAllModals,
		transferCollateral,
		tryFaucetAirdrop,
		hideCollateralModal,
		showCollateralModal,
		updateCollateralModalIntentState,
		renameSubaccount,
		setHasCompletedOnboarding,
		fillOrder,
		deleteUser,
		updateUserMarginTradingEnabled,
		editOpenOrder,
		showEditOrderModal,
		hideEditOrderModal,
		cancelAllOrders,
		settleAllPnls,
		createReferralLink,
		updateSubaccountDelegate,
		addInsuranceFundStake,
		requestInsuranceFundUnstake,
		cancelInsuranceFundUnstakeRequest,
		withdrawInsuranceFundUnstakedAssets,
		nativeUnstakeDSOL,
		deactivateStakeAccount,
		swap,
		addPerpLpShares,
		removePerpLpShares,
		settleLpPosition,
		updateConnection,
		getEncodedMessageSignature,
		updateUserMaxMarginRatio,
		reclaimRent,
		updateDlpAdvancedFlags,
		waitForTxnToConfirm,
		updateReduceOnlyFlags,
		updateFundingRate,
		withdrawDustPositions,
		walletSwap,
		prepPerpPlaceAndTake,
		tryDepositToTrade,
		handleUpdateTransactionPreflights,
		depositVault,
		requestVaultWithdrawal,
		cancelVaultWithdrawalRequest,
		withdrawFromVault,
		updateUserMarginMode,
		updateSubaccountSettings,
		updateUserFuelBonus,
		swapStakeAccountForDSOL,
		fetchAndSubscribeSubAccounts,
	};
};

export default DriftStoreActions;
