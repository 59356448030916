import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { MarketOrderToastState } from 'src/components/MarketOrderToasts/MarketOrderToastStateTypes';
import {
	DEFAULT_COMMITMENT_LEVEL,
	SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY,
	TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL,
} from 'src/constants/constants';
import { MSG_SIGNING_BEFORE_AUCTION_END_SLOT_BUFFER } from 'src/constants/signedOrderMsgs';
import { dlog } from 'src/dev';
import { getPctCompletion } from 'src/utils/math';
import { DriftWindow } from 'src/window/driftWindow';

export type ProgressState = {
	start: number;
	end: number;
	current: number;
	direction: 'end-full' | 'end-empty';
	type: 'neutral' | 'warn' | 'strong-warn';
};

export const getProgressStateForStage = (
	stage: keyof MarketOrderToastState['toastStages'],
	data: MarketOrderToastState
): ProgressState | null => {
	dlog('v2_auctions', `getProgressStateForStage stage: ${stage} data:`, data);
	if (stage === 'transactionConfirmation') {
		if (data.toastStages.transactionConfirmation.type !== 'in_progress') {
			return null;
		}

		if (data.orderInfo.orderRoute === 'signedMsg') {
			if (
				data.toastStages.transactionConfirmation.message ===
					'Order Submitted' ||
				data.toastStages.transactionConfirmation.message === 'Placing order'
			) {
				return null;
			}

			const slot = DriftWindow.chainClock?.getState(
				TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL
			)?.slot;

			if (
				!slot ||
				!data.orderInfo.auctionStartSlot ||
				!data.orderInfo.auctionDuration
			) {
				return null;
			}

			let type: ProgressState['type'] = 'neutral';

			const start = data.orderInfo.auctionStartSlot;
			const end =
				data.orderInfo.auctionStartSlot +
				data.orderInfo.auctionDuration -
				MSG_SIGNING_BEFORE_AUCTION_END_SLOT_BUFFER;
			const current = slot;

			const pctCompletion = getPctCompletion(start, end, current);

			if (pctCompletion >= 50) {
				type = 'warn';
			}

			if (pctCompletion >= 80) {
				type = 'strong-warn';
			}

			const progress: ProgressState = {
				start,
				end,
				current,
				type,
				direction: 'end-empty',
			};

			return progress;
		} else {
			const blockHeight = DriftWindow.chainClock?.getState(
				TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL
			)?.blockHeight;

			if (!blockHeight || !data.orderInfo.transactionExpiryBlockHeight) {
				return null;
			}

			let type: ProgressState['type'] = 'neutral';

			const start =
				data.orderInfo.transactionExpiryBlockHeight -
				SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY;
			const end = data.orderInfo.transactionExpiryBlockHeight;
			const current = blockHeight;

			const pctCompletion = getPctCompletion(start, end, current);

			if (pctCompletion >= 50) {
				type = 'warn';
			}

			if (pctCompletion >= 80) {
				type = 'strong-warn';
			}

			const progress: ProgressState = {
				start,
				end,
				current,
				type,
				direction: 'end-empty',
			};

			return progress;
		}
	}

	if (stage === 'jitAuction') {
		if (data.toastStages.jitAuction.type !== 'in_progress') {
			return null;
		}

		if (!data.orderInfo.auctionStartSlot || !data.orderInfo.auctionDuration) {
			return null;
		}

		const slot = DriftWindow.chainClock?.getState(
			DEFAULT_COMMITMENT_LEVEL
		)?.slot;

		const progress: ProgressState = {
			start: data.orderInfo.auctionStartSlot,
			end: data.orderInfo.auctionStartSlot + data.orderInfo.auctionDuration,
			current: slot,
			type: 'neutral' as ProgressState['type'],
			direction: 'end-empty',
		};

		return progress;
	}

	if (stage === 'orderCompletion') {
		if (data.toastStages.orderCompletion.type !== 'in_progress') {
			return null;
		}

		if (!data.orderInfo.maxTs || !data.orderInfo.startTs) {
			return null;
		}

		let type: ProgressState['type'] = 'neutral';

		const start = data.orderInfo.startTs?.toNumber();
		const end = data.orderInfo.maxTs.toNumber();
		const current = DriftWindow.chainClock?.getState(
			DEFAULT_COMMITMENT_LEVEL
		)?.ts;

		const pctCompletion = getPctCompletion(start, end, current);

		if (pctCompletion >= 50) {
			type = 'warn';
		}

		if (pctCompletion >= 80) {
			type = 'strong-warn';
		}

		const progress: ProgressState = {
			type,
			start,
			end,
			current,
			direction: 'end-empty',
		};

		return progress;
	}

	return null;
};

const ProgressIndicator = (props: ProgressState) => {
	const startEndDelta = props.end - props.start;
	const currentDelta = props.current - props.start;
	let progressValue = Math.min((currentDelta / startEndDelta) * 100, 100);

	if (props.direction === 'end-empty') {
		progressValue = 100 - progressValue;
	}

	const strokeColour =
		props.type === 'neutral'
			? 'var(--interactive-link)'
			: props.type === 'warn'
			? 'var(--warn-yellow)'
			: 'var(--red-50)';

	return (
		<div style={{ width: 16, height: 16 }}>
			<CircularProgressbar
				value={progressValue}
				strokeWidth={50}
				styles={buildStyles({
					pathColor: strokeColour,
					trailColor: 'transparent',
					pathTransitionDuration: 0.15,
				})}
			/>
		</div>
	);
};

export default ProgressIndicator;
