'use client';

import { memo, MouseEvent, useState } from 'react';
import useDriftActions from 'src/hooks/useDriftActions';
import usePrefferredWalletSetting from 'src/hooks/usePrefferredWalletSetting';
import { notify } from 'src/utils/notifications';
import Text from '../Text/Text';
import Env from 'src/environmentVariables/EnvironmentVariables';
import useMagicAuthSavedEmail from 'src/hooks/useMagicAuthSavedEmail';
import Button from '../Button';
import TextField from '../Inputs/TextField';
import LabelledInput from '../Inputs/LabelledInput';
import { MagicAuthLoginOptions } from 'src/utils/magicAuthAdapter';
import { Apple, Google, Twitter } from '@drift-labs/icons';
import useCurrentWalletAdapter from '../../hooks/useCurrentWalletAdapter';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useCurrentWalletConnectionState from '../../hooks/useCurrentWalletConnectionState';
import { DRIFT_CUSTOM_WALLET_OPTIONS } from 'src/constants/wallets';
import { ONBOARDING_CONNECTION_STEP_LOCK_KEY } from 'src/utils/WalletConnectionState';

function MagicAuthLoginPanel({
	createAccount,
	onSwitchToConnectionPage,
	onSwitchToSignup,
	onSwitchToLogin,
}: {
	createAccount?: boolean;
	onSwitchToConnectionPage: () => void;
	onSwitchToSignup: () => void;
	onSwitchToLogin: () => void;
}) {
	const actions = useDriftActions();
	const wallet = useCurrentWalletAdapter();
	const connected = useWalletIsConnected();
	const [magicAuthSavedEmail, setMagicAuthSavedEmail] =
		useMagicAuthSavedEmail();
	const [emailAddress, setEmailAddress] = useState(magicAuthSavedEmail);
	const [_preferredWalletUrl, setPreferredWalletUrl] =
		usePrefferredWalletSetting();
	const { captureEvent } = usePostHogCapture();
	const walletConnectionState = useCurrentWalletConnectionState();

	const handleClickConnectLink = (e: MouseEvent) => {
		e.preventDefault();
		onSwitchToConnectionPage();
	};

	const handleClickLoginLink = (e: MouseEvent) => {
		e.preventDefault();
		onSwitchToLogin();
	};

	const handleClickSignUp = (e: MouseEvent) => {
		e.preventDefault();
		onSwitchToSignup();
	};

	const handleMagicAuthLogin = async (loginOptions: MagicAuthLoginOptions) => {
		if (connected) {
			await wallet.disconnect();
		}

		try {
			setPreferredWalletUrl(
				DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME
			);

			walletConnectionState.instance.update(
				'Connecting',
				true,
				ONBOARDING_CONNECTION_STEP_LOCK_KEY
			);

			await actions.connectWithCustomWallet(
				DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME,
				loginOptions
			);

			if (loginOptions.email) {
				setMagicAuthSavedEmail(emailAddress);
			}

			captureEvent('magic_auth_login', {
				auth_type: loginOptions.email ? 'email' : 'other',
				success: true,
			});
		} catch (e: any) {
			const errorMatches = `${e?.message}`.match(
				/Magic RPC Error: \[(.*)] (.*)/
			);

			console.error(e);

			captureEvent('magic_auth_login', {
				auth_type: loginOptions.email ? 'email' : 'other',
				success: false,
			});

			if (errorMatches?.length) {
				notify({
					type: 'error',
					message: 'Error connecting to Magic Link',
					description: `${errorMatches[2]} (Error Code: ${errorMatches[1]})`,
				});
			} else {
				notify({
					type: 'error',
					message: 'Error connecting to Magic Link',
				});
			}
		}
	};

	return (
		<div className="w-full h-full">
			<Text.H1 className="text-text-emphasis">
				{createAccount
					? 'Sign Up'
					: `Passwordless ${Env.enableSocialLogin ? '& Social ' : ''}Login`}
			</Text.H1>
			{createAccount && (
				<div className="mt-4 leading-4">
					<Text.BODY3>
						Using Passwordless (e-mail){' '}
						{Env.enableSocialLogin ? 'and Social Sign Up ' : 'Sign Up '}will
						create a new, self-custody{' '}
						<a href="https://magic.link/" target="_blank" rel="noreferrer">
							Magic Wallet
						</a>{' '}
						for you to connect to Drift and manage your assets.{' '}
						<a
							href="https://docs.drift.trade/getting-started/passwordless-login"
							target="_blank"
							rel="noreferrer"
						>
							Learn more about what to expect.
						</a>
					</Text.BODY3>
				</div>
			)}
			<div className="mt-6">
				<LabelledInput label="Email">
					<TextField.Email
						type="string"
						placeholder="Enter your email address"
						value={emailAddress}
						onChange={setEmailAddress}
					/>
				</LabelledInput>
			</div>
			{Env.enableSocialLogin && (
				<>
					<div className="flex flex-row items-center py-4 space-between">
						<div className="h-[1px] bg-container-border w-full flex-shrink"></div>
						<div className="px-4">
							<Text.BODY3 className="tracking-normal whitespace-nowrap text-text-secondary">
								or continue with
							</Text.BODY3>
						</div>
						<div className="h-[1px] bg-container-border w-full flex-shrink"></div>
					</div>
					<div className="flex flex-row items-center space-between text-text-secondary">
						<button
							onClick={() => handleMagicAuthLogin({ socialPlatform: 'google' })}
							className="h-[36px] bg-container-bg hover:bg-container-bg-hover border border-container-border w-full text-center rounded mr-1 text-text-label flex flex-row items-center justify-center"
						>
							<Google size={18} />
						</button>
						<button
							onClick={() => handleMagicAuthLogin({ socialPlatform: 'apple' })}
							className="h-[36px] bg-container-bg hover:bg-container-bg-hover border border-container-border w-full text-center rounded mx-1 text-text-label flex flex-row items-center justify-center"
						>
							<Apple size={20} />
						</button>
						<button
							onClick={() =>
								handleMagicAuthLogin({ socialPlatform: 'twitter' })
							}
							className="h-[36px] bg-container-bg hover:bg-container-bg-hover border border-container-border w-full text-center rounded ml-1 text-text-label flex flex-row items-center justify-center"
						>
							<Twitter size={17} />
						</button>
					</div>
				</>
			)}
			<div className="mt-8">
				<Button.Secondary
					highlight
					size={'MEDIUM'}
					className={'w-full'}
					onClick={() => handleMagicAuthLogin({ email: emailAddress })}
				>
					<Text.BODY1>{createAccount ? 'Sign Up' : 'Log In'}</Text.BODY1>
				</Button.Secondary>
			</div>
			{createAccount ? (
				<div className="mt-8 leading-4 text-center">
					<Text.BODY3>
						Already signed up or have a Solana wallet?
						<br />
						<a
							href="/onboarding/login-with-email"
							onClick={handleClickLoginLink}
						>
							Log In
						</a>{' '}
						or{' '}
						<a href="/onboarding/connect" onClick={handleClickConnectLink}>
							Connect Wallet
						</a>
					</Text.BODY3>
				</div>
			) : (
				<div className="mt-8 leading-4 text-center text-text-default">
					<Text.BODY3>
						Don&apos;t have an account?
						<br />
						<a href="/onboarding/signup-with-email" onClick={handleClickSignUp}>
							Sign Up
						</a>
					</Text.BODY3>
				</div>
			)}
		</div>
	);
}

export default memo(MagicAuthLoginPanel);
