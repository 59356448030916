import { AuctionParams, EMPTY_AUCTION_PARAMS } from '@drift/common';

// TODO: should move this to common-ts
export function isAuctionEmpty(auctionParams: AuctionParams) {
	return (
		auctionParams.auctionStartPrice ===
			EMPTY_AUCTION_PARAMS.auctionStartPrice &&
		auctionParams.auctionEndPrice === EMPTY_AUCTION_PARAMS.auctionEndPrice &&
		auctionParams.auctionDuration === EMPTY_AUCTION_PARAMS.auctionDuration
	);
}
