import produce from 'immer';
import { create } from 'zustand';
import {
	BN,
	BigNum,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import {
	SPOT_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP_BY_POOL_ID,
} from 'src/environmentVariables/EnvironmentVariables';

export interface WalletBalancesStore {
	set: (x: (s: WalletBalancesStore) => void) => void;
	get: () => WalletBalancesStore;
	balances: {
		[symbol: string]: {
			base: BigNum; // base is the amount of the asset that you have
			quote: BigNum; // quote is the USDC value of  amount of the asset that you have
			loading: boolean;
		};
	};
	getBiggestAssetMarket: (poolId: number) => SpotMarketConfig;
	getBalance: (
		symbol: string,
		marketPrecisionExp: BN
	) => {
		base: BigNum;
		quote: BigNum;
		loading: boolean;
	};
}

const useWalletBalancesStore = create<WalletBalancesStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	balances: {},
	getBiggestAssetMarket: (poolId: number): SpotMarketConfig => {
		const relevantSpotMarketsForPoolId = SPOT_MARKETS_LOOKUP_BY_POOL_ID[poolId];

		try {
			const relevantSymbolsForBalancesWithPoolId = Object.keys(
				get().balances
			).filter((symbol) =>
				relevantSpotMarketsForPoolId.some((market) => market.symbol === symbol)
			);

			if (relevantSymbolsForBalancesWithPoolId.length === 0) {
				relevantSpotMarketsForPoolId[0];
			}

			let currentBiggestAssetSymbol = relevantSymbolsForBalancesWithPoolId[0];

			if (!currentBiggestAssetSymbol) {
				throw new Error('No relevant assets found');
			}

			relevantSymbolsForBalancesWithPoolId.forEach((symbol) => {
				if (
					get().balances[symbol].quote.gt(
						get().balances[currentBiggestAssetSymbol].quote
					)
				) {
					currentBiggestAssetSymbol = symbol;
				}
			});

			const spotMarketConfig = SPOT_MARKETS_LOOKUP.find(
				(market) => market.symbol === currentBiggestAssetSymbol
			);

			if (!spotMarketConfig) {
				throw new Error('No relevant asset found');
			}

			return spotMarketConfig;
		} catch (e) {
			console.error(e);
			return relevantSpotMarketsForPoolId[0];
		}
	},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getBalance: (symbol: string, marketPrecisionExp: BN) =>
		get().balances[symbol] ?? {
			base: BigNum.zero(marketPrecisionExp),
			quote: BigNum.zero(QUOTE_PRECISION_EXP),
			loading: false,
		},
}));

export default useWalletBalancesStore;
