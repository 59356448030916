import { ShieldCheck } from '@drift-labs/icons';

export const ProtectedMakerIcon = ({ size = 22 }: { size?: number }) => {
	return (
		<div className="h-full w-full rounded-sm bg-button-secondary-bg flex items-center justify-center">
			<ShieldCheck
				size={size}
				gradientColors={['#E8A2A0', '#9468F1', '#71CCE9']}
			/>
		</div>
	);
};
