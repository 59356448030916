'use client';

import { useCallback, useEffect, useState } from 'react';
import { defaultUserSettings } from 'src/environmentVariables/EnvironmentVariables';
import UI_UTILS from './uiUtils';

const LOCAL_STORAGE_CHANGE_EVENT_NAME = 'localStorageChanged';
type LocalStorageUpdateEvent = CustomEvent<{ key: string; value: string }>;

export function useLocalStorageState(
	key: string,
	defaultState?: string
): [string, (newState: string) => void] {
	const [state, setState] = useState(
		!UI_UTILS.isWindowDefined()
			? defaultState ?? null
			: window.localStorage.getItem(key) ?? defaultState ?? null
	);

	useEffect(() => {
		const storedState = window.localStorage.getItem(key);
		if (storedState) {
			setState(storedState);
		} else {
			setState(defaultState);
		}
	}, [key]);

	const setLocalStorageState = useCallback(
		(newState: string) => {
			const changed = state !== newState;
			if (!changed) {
				return;
			}
			setState(newState);
			if (newState === null) {
				window.localStorage.removeItem(key);
			} else {
				setLocalStorageItem(key, newState, true);
			}

			window.dispatchEvent(
				new CustomEvent<{ key: string; value: string }>(
					LOCAL_STORAGE_CHANGE_EVENT_NAME,
					{
						detail: {
							key,
							value: newState,
						},
					}
				)
			);
		},
		[state, key]
	);

	useEffect(() => {
		const handler = (ev: LocalStorageUpdateEvent) => {
			if (ev.detail.key === key) {
				setState(ev.detail.value);
			}
		};
		window.addEventListener(
			LOCAL_STORAGE_CHANGE_EVENT_NAME,
			handler as EventListener
		);

		return () => {
			window.removeEventListener(
				LOCAL_STORAGE_CHANGE_EVENT_NAME,
				handler as EventListener
			);
		};
	}, []);

	return [state, setLocalStorageState];
}

export const getLocalStorageSizeKB = () => {
	let allStrings = '';
	for (const key in window.localStorage) {
		// eslint-disable-next-line no-prototype-builtins
		if (window.localStorage.hasOwnProperty(key)) {
			allStrings += window.localStorage[key];
		}
	}
	return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) : 0;
};

export function useBooleanLocalStorageState(
	key: string,
	defaultState: boolean
) {
	const [currentState, setCurrentState] = useLocalStorageState(
		key,
		JSON.stringify(defaultState)
	);

	// merge with default state for cases when we add new settings after user has already saved values to their local storage
	const initialParsedState = JSON.parse(currentState);

	const [parsedState, setParsedState] = useState<boolean>(initialParsedState);

	useEffect(() => {
		setCurrentState(JSON.stringify(parsedState));
	}, [parsedState]);

	return [parsedState, setParsedState] as [boolean, (val: boolean) => void];
}

export const setLocalStorageItem = (
	key: string,
	newValue: string,
	eventHandled = false
) => {
	const oldValue = localStorage.getItem(key);
	localStorage.setItem(key, newValue);

	const event = new StorageEvent('storage', {
		key,
		oldValue,
		newValue: newValue,
	});

	if (!eventHandled) {
		window.dispatchEvent(event);
	}
};

export const setValueInStorageStateObject = (
	storageKey: string,
	newValueKey: string,
	newValue: any
) => {
	const parsedStoredValue = JSON.parse(localStorage.getItem(storageKey));

	const newStoredValue = {
		...defaultUserSettings,
		...parsedStoredValue,
		[newValueKey]: newValue,
	};

	setLocalStorageItem(storageKey, JSON.stringify(newStoredValue));
};

export const setJsonStorageState = (storageKey: string, newValue: any) => {
	setLocalStorageItem(storageKey, JSON.stringify(newValue));
};

export const getJsonStorageState = (storageKey: string) => {
	return JSON.parse(localStorage.getItem(storageKey));
};

export function useParsedLocalStorageState<T>(
	key: string,
	defaultState: T
): [T, (newState: T) => void] {
	const [currentState, setCurrentState] = useLocalStorageState(
		key,
		JSON.stringify(defaultState)
	);

	// merge with default state for cases when we add new settings after user has already saved values to their local storage
	const initialParsedState = {
		...defaultState,
		...JSON.parse(currentState),
	};

	const [parsedState, setParsedState] = useState<T>(initialParsedState);

	useEffect(() => {
		setCurrentState(JSON.stringify(parsedState));
	}, [parsedState]);

	useEffect(() => {
		const handler = (ev: StorageEvent) => {
			if (ev.key === key) {
				setParsedState(JSON.parse(ev.newValue));
			}
		};
		window.addEventListener('storage', handler);

		return () => {
			window.removeEventListener('storage', handler);
		};
	}, []);

	return [parsedState, setParsedState];
}
