import { OracleSource } from '@drift-labs/sdk';

export const ORACLE_SOURCES_ENABLED_FOR_SIGNED_MSG_ORDERS: OracleSource[] = [
	OracleSource.PYTH_LAZER,
	OracleSource.PYTH_LAZER_1K,
	OracleSource.PYTH_LAZER_1M,
	OracleSource.PYTH_LAZER_STABLE_COIN,
];

export const MSG_SIGNING_BEFORE_AUCTION_END_SLOT_BUFFER = 5;
