import {
	BN,
	BigNum,
	L2OrderBook,
	MarketType,
	OraclePriceData,
	PhoenixSubscriber,
	SerumSubscriber,
} from '@drift-labs/sdk';
import { MarketId, MarketKey, UIMarket } from '@drift/common';
import produce from 'immer';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { create } from 'zustand';
import { dlog } from '../dev';

export enum DlobListeningSelection {
	DLOB_SERVER_WEBSOCKET = 'DLOB_SERVER_WEBSOCKET',
	DLOB_SERVER_POLLING = 'DLOB_SERVER_POLLING',
	BLOCKCHAIN = 'BLOCKCHAIN',
}

const WEBSOCKETS_ENABLED = Env.enableDlobWebsocket;
const FALLBACK_TO_WEBSOCKETS_ENABLED = false;

export const PREFERRED_SELECTED_MARKET_LISTENING_SELECTION = WEBSOCKETS_ENABLED
	? DlobListeningSelection.DLOB_SERVER_WEBSOCKET
	: DlobListeningSelection.DLOB_SERVER_POLLING;

export const PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION =
	DlobListeningSelection.DLOB_SERVER_POLLING;

const DEFAULT_L2_ORDERBOOK: L2OrderBook = {
	bids: [],
	asks: [],
};

export type L2OrderBookForMarket = L2OrderBook & {
	marketIndex: number | undefined;
	marketType: MarketType;
};

// TODO: is there a better name for this
export type DerivedMarketTradeState = {
	markPrice: BigNum;
	fallbackBid: BigNum;
	fallbackAsk: BigNum;
	bestBid: BigNum;
	bestAsk: BigNum;
	updateSlot: number;
	spreadQuote: BigNum;
	spreadPct: number;
};

export type MarketState = {
	marketId: MarketId;
	marketSlot?: number;
	orderbook: L2OrderBook;
	oracle: {
		price: BigNum;
		slot: BN;
		confidence: BN;
		hasSufficientNumberOfDataPoints: boolean;
		twap?: BigNum;
		twapConfidence?: BN;
		maxPrice?: BigNum;
	};
	derivedState: DerivedMarketTradeState;
};

type StateLookup<T> = {
	[marketKey: MarketKey]: T;
};

export type MarketSubscriptionState = {
	listeningSelection: DlobListeningSelection;
	preferredListeningSelection: DlobListeningSelection;
	lastSubscriptionChangeTime: number;
	upgradeAttemptCount: number;
};

export interface MarketStateStore {
	set: (x: (s: MarketStateStore) => void) => void;
	get: () => MarketStateStore;
	marketSubscriptionState: StateLookup<MarketSubscriptionState>;
	marketDataState: StateLookup<MarketState>;
	getDlobStateForMarket: (marketId: MarketId) => L2OrderBook;
	getMarketDataForMarket: (marketId: MarketId) => MarketState;
	getSubscriptionStateForMarket: (
		marketId: MarketId
	) => MarketSubscriptionState;
	phoenixEnabled: boolean;
	serumEnabled: boolean;
	phoenixSubscribers: {
		[marketKey: string]: PhoenixSubscriber;
	};
	serumSubscribers: {
		[marketKey: string]: SerumSubscriber;
	};
	latestUpdateSlot: number;
	getPhoenixSubscriberForMarket: (marketId: MarketId) => PhoenixSubscriber;
	getSerumSubscriberForMarket: (marketId: MarketId) => SerumSubscriber;
	getOracleDataForMarket: (marketId: MarketId) => MarketState['oracle'];
	getRawOracleDataForMarket: (marketId: MarketId) => OraclePriceData;
	getMarkPriceForMarket: (marketId: MarketId) => BigNum;
	getMarketHasLiquidity: (marketId: MarketId) => boolean;
	setListeningSelection: (
		marketId: MarketId,
		newSelection: DlobListeningSelection
	) => void;
	fallbackDatasource: (
		marketId: MarketId,
		fallbackFrom?: DlobListeningSelection
	) => void;
	upgradeDatasource: (marketId: MarketId) => void;
}

const DEFAULT_MARKET_DATA_STATE = {};

const useMarketStateStore = create<MarketStateStore>(
	(set, get): MarketStateStore => {
		const setState = (fn: (s: MarketStateStore) => void) => set(produce(fn));

		const getMarketStateForMarket = (marketId: MarketId) => {
			if (!marketId) return undefined;

			return get().marketDataState[marketId.key];
		};

		const getSubscriptionStateForMarket = (marketId: MarketId) => {
			if (!marketId) return undefined;

			return get().marketSubscriptionState[marketId.key];
		};

		const setListeningSelection = (
			marketId: MarketId,
			newListeningSelection: DlobListeningSelection
		) => {
			setState((s) => {
				s.marketSubscriptionState[marketId.key].listeningSelection =
					newListeningSelection;
				s.marketSubscriptionState[marketId.key].lastSubscriptionChangeTime =
					Date.now();
			});
		};

		return {
			set: setState,
			get: () => get(),
			// Should default to the DLOB_SERVER .. A hook ensures that we switch to blockchain listening if the dlob server is unhealthy
			marketDataState: DEFAULT_MARKET_DATA_STATE,
			marketSubscriptionState: DEFAULT_MARKET_DATA_STATE,
			latestUpdateSlot: undefined,
			getDlobStateForMarket: (marketId: MarketId) => {
				if (!marketId) {
					return DEFAULT_L2_ORDERBOOK;
				}

				const marketState = getMarketStateForMarket(marketId);

				const dlobStateForMarket = marketState?.orderbook;

				if (!dlobStateForMarket) {
					return DEFAULT_L2_ORDERBOOK;
				}

				return dlobStateForMarket;
			},
			getSubscriptionStateForMarket: (marketId: MarketId) => {
				if (!marketId) {
					return undefined;
				}

				const subscriptionState = getSubscriptionStateForMarket(marketId);

				return subscriptionState;
			},
			getMarketDataForMarket: (marketId: MarketId) => {
				const marketState = getMarketStateForMarket(marketId);

				return marketState;
			},
			getOracleDataForMarket: (marketId: MarketId) => {
				const marketState = getMarketStateForMarket(marketId);
				const oracleState = marketState?.oracle;

				return oracleState;
			},
			getRawOracleDataForMarket: (marketId: MarketId) => {
				const marketState = getMarketStateForMarket(marketId);
				const oracleState = marketState?.oracle;

				if (!oracleState) return undefined;
				return {
					price: oracleState.price.val,
					slot: oracleState.slot,
					confidence: oracleState.confidence,
					hasSufficientNumberOfDataPoints:
						oracleState.hasSufficientNumberOfDataPoints,
					twap: oracleState.twap?.val,
					twapConfidence: oracleState.twapConfidence,
					maxPrice: oracleState.maxPrice?.val,
				};
			},
			getMarkPriceForMarket: (marketId: MarketId) => {
				const marketState = getMarketStateForMarket(marketId);
				const markPrice = marketState?.derivedState?.markPrice;

				return markPrice;
			},
			getMarketHasLiquidity: (marketId: MarketId) => {
				if (marketId.isPerp) return true;

				const marketState = getMarketStateForMarket(marketId);

				const dlobStateForMarket = marketState?.orderbook;

				// don't hide market because orderbook hasn't loaded yet
				if (!dlobStateForMarket) return true;

				const hasLiquidity =
					dlobStateForMarket.asks?.length > 0 &&
					dlobStateForMarket.bids?.length > 0;

				return hasLiquidity;
			},
			phoenixSubscribers: {},
			serumSubscribers: {},
			getPhoenixSubscriberForMarket: (marketId: MarketId) => {
				const phoenixSubscribers = get().phoenixSubscribers;
				const marketKey = marketId.key;
				return phoenixSubscribers[marketKey];
			},
			getSerumSubscriberForMarket: (marketId: MarketId) => {
				const serumSubscribers = get().serumSubscribers;
				const marketKey = marketId.key;
				return serumSubscribers[marketKey];
			},
			phoenixEnabled: true,
			serumEnabled: true,
			setListeningSelection,
			fallbackDatasource: (marketId: MarketId) => {
				const currentListeningSelection =
					getSubscriptionStateForMarket(marketId)?.listeningSelection;

				const from = currentListeningSelection;
				let to;

				// prediction markets shouldnt go to blockchain mode ever
				const isPredictionMarket =
					UIMarket.fromMarketId(marketId).isPredictionMarket;

				switch (currentListeningSelection) {
					case DlobListeningSelection.BLOCKCHAIN: {
						if (WEBSOCKETS_ENABLED && FALLBACK_TO_WEBSOCKETS_ENABLED) {
							to = DlobListeningSelection.DLOB_SERVER_WEBSOCKET;
							setListeningSelection(
								marketId,
								DlobListeningSelection.DLOB_SERVER_WEBSOCKET
							);
						} else {
							to = DlobListeningSelection.DLOB_SERVER_POLLING;
							setListeningSelection(
								marketId,
								DlobListeningSelection.DLOB_SERVER_POLLING
							);
						}
						break;
					}
					case DlobListeningSelection.DLOB_SERVER_POLLING: {
						// If the market is a prediction market, we should never fallback to blockchain since there is not liquidity on AMM
						if (!isPredictionMarket) {
							to = DlobListeningSelection.BLOCKCHAIN;
							setListeningSelection(
								marketId,
								DlobListeningSelection.BLOCKCHAIN
							);
						} else {
							to = DlobListeningSelection.DLOB_SERVER_POLLING;
							dlog(
								`market_subscriptions`,
								`Skipping fallback to blockchain for prediction market ${marketId.key}`
							);
						}
						break;
					}
					case DlobListeningSelection.DLOB_SERVER_WEBSOCKET: {
						to = DlobListeningSelection.DLOB_SERVER_POLLING;
						setListeningSelection(
							marketId,
							DlobListeningSelection.DLOB_SERVER_POLLING
						);
						break;
					}
				}

				dlog(
					`market_subscriptions`,
					`Falling Back Subscription for ${marketId.key} .. ${from}=>${to}`
				);
			},
			upgradeDatasource: (marketId: MarketId) => {
				/**
				 * The purpose of this method is to try set the listening selection to the preferred datasource.
				 */

				const currentSubscriptionStateForMarket =
					getSubscriptionStateForMarket(marketId);

				const currentListeningSelection =
					currentSubscriptionStateForMarket.listeningSelection;
				const preferredListeningSelection =
					currentSubscriptionStateForMarket.preferredListeningSelection;

				if (currentListeningSelection === preferredListeningSelection) {
					// Already listening to the preferred datasource, so just reset the lastSubscriptionChangeTime to avoid fall-up triggering again soon
					setState((s) => {
						s.marketSubscriptionState[marketId.key].lastSubscriptionChangeTime =
							Date.now();
					});
					return;
				}

				dlog(
					`market_subscriptions`,
					`Upgrading Subscription for ${marketId.key} .. ${currentListeningSelection}=>${preferredListeningSelection}`
				);

				setState((s) => {
					// @ts-ignore TODO: it says this doesn't exist on the type!!!
					s.upgradeAttemptCount = s.upgradeAttemptCount + 1;
					s.marketSubscriptionState[marketId.key].listeningSelection =
						preferredListeningSelection;
					s.marketSubscriptionState[marketId.key].lastSubscriptionChangeTime =
						Date.now();
				});
			},
		};
	}
);

export default useMarketStateStore;
