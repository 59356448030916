import produce from 'immer';
import { create } from 'zustand';
import { IfData } from '../../utils/insuranceFund';

type IfDataLookup = Record<number, IfData>;

export interface IfDataStore {
	set: (x: (s: IfDataStore) => void) => void;
	get: () => IfDataStore;
	setIfData: (marketIndex: number, ifData: IfData) => void;
	bulkSetIfData: (props: [marketIndex: number, ifData: IfData][]) => void;
	getIfDataForMarket: (marketIndex: number) => IfData;
	IFData: IfDataLookup;
}

const useIfDataStore = create<IfDataStore>((set, get): IfDataStore => {
	const setState = (fn: (s: IfDataStore) => void) => set(produce(fn));

	const setIfData = (marketIndex: number, ifData: IfData) => {
		setState((state) => {
			state.IFData[marketIndex] = ifData;
		});
	};

	const bulkSetIfData = (props: [marketIndex: number, ifData: IfData][]) => {
		setState((state) => {
			for (const [marketIndex, ifData] of props) {
				state.IFData[marketIndex] = ifData;
			}
		});
	};

	return {
		set: setState,
		get: () => get(),
		setIfData,
		getIfDataForMarket: (marketIndex: number) => get().IFData[marketIndex],
		bulkSetIfData,
		IFData: {},
	};
});

export default useIfDataStore;
