import invariant from 'tiny-invariant';
import { UIOrderType } from '@drift/common';
import {
	BigNum,
	L2OrderBook,
	OrderType,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import { TradeFormMessageType, TradeFormState } from 'src/@types/types';
import { MAX_PREDICTION_PRICE_NUM } from 'src/constants/math';
import { calculateNotionalSize } from './scaledOrders';

const getOppositeDirection = (side: 'long' | 'short') => {
	return side === 'long' ? 'short' : 'long';
};

/**
 * The limit is considered the unexpected side of the trigger if it can't fill immediately from the trigger.
 * @param currentOrderDirection
 * @param limitPrice
 * @param triggerPrice
 * @returns
 */
const limitIsUnexpectedSideOfTrigger = (
	currentPositionIsZero: boolean,
	currentPositionDirection: 'long' | 'short',
	currentOrderDirection: 'long' | 'short',
	limitPrice: number,
	triggerPrice: number
) => {
	invariant(
		currentPositionIsZero || currentPositionDirection !== currentOrderDirection,
		`limitIsUnexpectedSideOfTrigger assumes that TP/SL orders can't be opened in the same direction as the current position`
	);

	const warningLimitIsInWrongDirectionFromExpected =
		(currentOrderDirection === 'long' && limitPrice < triggerPrice) || // If order is long, we're stop-limiting a short position. We expect that they want to buy as soon as their stop loss gets hit. if the limit is BELOW the trigger, then it won't be able to buy immediately.
		(currentOrderDirection === 'short' && limitPrice > triggerPrice); // If order is short, we're stop-limiting a long. We expect that they want to sell as soon as their stop loss gets hit. if the limit is ABOVE the trigger, then it won't be able sell immediately.

	return warningLimitIsInWrongDirectionFromExpected;
};

/**
 * A take-profit/stop-loss order is considered in a bad direction if it's the same direction as the already opened position.
 * @param currentPositionIsZero
 * @param currentPositionDirection
 * @param currentOrderDirection
 * @returns
 */
const isBadTPSLDirection = (
	currentPositionIsZero: boolean,
	currentPositionDirection: 'long' | 'short' | 'buy' | 'sell',
	currentOrderDirection: 'long' | 'short' | 'buy' | 'sell'
) => {
	if (!currentPositionIsZero) {
		const errorOrderWrongDirection =
			currentPositionDirection === currentOrderDirection;

		if (errorOrderWrongDirection) {
			return true;
		}
	}

	return false;
};

const getBadTPSLMessageProps = (
	isSpot: boolean,
	currentPositionDirection: 'long' | 'short',
	currentOrderDirection: 'long' | 'short'
) => {
	return {
		shouldShowMessage: true,
		messageTitle: 'Wrong order direction',
		message: isSpot
			? `You currently have a ${
					currentPositionDirection === 'long' ? 'deposit' : 'borrow'
			  } balance. You should create a ${
					currentOrderDirection === 'long' ? 'sell' : 'buy'
			  } order to close your position. This order will increase your position.`
			: `Your current position is ${currentPositionDirection}. You should create a ${getOppositeDirection(
					currentOrderDirection
			  )} to close a ${currentPositionDirection} position. This order will increase your position.`,
		messageType: TradeFormMessageType.warn,
		skipMessageInConfirmationModal: false,
		disableTradeButton: false,
	};
};

const getImmediatelyExecutingStopMessageProps = (
	oraclePrice: number,
	triggerPrice: number,
	orderType: UIOrderType,
	currentOrderDirection: 'long' | 'short'
) => {
	return {
		shouldShowMessage: true,
		messageTitle: 'This order will start to execute immediately',
		message: `The current oracle price is ${
			oraclePrice > triggerPrice ? 'above' : 'below'
		} the trigger oracle price.`,
		messageLinkDescription: 'Learn more about Stop Orders',
		messageLink: 'https://docs.drift.trade/trading/all-order-types',
		messageType: TradeFormMessageType.warn,
		skipMessageInConfirmationModal: false,
		disableTradeButton: false,
		messageId: `${orderType}_${currentOrderDirection}_immediatewarning`,
	};
};

const getImmediateActionableStopLimitMessageProps = (
	marketPrice: number,
	limitPrice: number,
	currentOrderDirection: 'long' | 'short'
) => {
	const isLongOrder = currentOrderDirection === 'long';

	// triggered but not executed
	let messageTitle = 'This will place a limit order immediately';
	let message = `The current oracle price is ${
		isLongOrder ? 'below' : 'above'
	} the trigger oracle price.`;

	// triggered and executed
	if (
		(marketPrice <= limitPrice && currentOrderDirection === 'long') ||
		(marketPrice >= limitPrice && currentOrderDirection === 'short')
	) {
		messageTitle = 'This order will start to execute immediately';
		message = `The current market price is ${
			marketPrice > limitPrice ? 'above' : 'below'
		} the limit price.`;
	}

	return {
		shouldShowMessage: true,
		messageTitle,
		message,
		messageLinkDescription: 'Learn more about Stop Orders',
		messageLink: 'https://docs.drift.trade/trading/all-order-types',
		messageType: TradeFormMessageType.warn,
		skipMessageInConfirmationModal: false,
		disableTradeButton: false,
		messageId: `stopLimit_${currentOrderDirection}_immediatewarning`,
	};
};

const getImmediatelyExecutingTakeProfitMessageProps = (
	oraclePrice: number,
	triggerPrice: number,
	orderType: OrderType,
	currentOrderDirection: 'long' | 'short'
) => {
	return {
		shouldShowMessage: true,
		messageTitle: 'This order will start to execute immediately',
		message: `The current oracle price is ${
			oraclePrice > triggerPrice ? 'above' : 'below'
		} the trigger oracle price.`,
		messageLinkDescription: 'Learn more about Take Profit Orders',
		messageLink: 'https://docs.drift.trade/trading/all-order-types',
		messageType: TradeFormMessageType.warn,
		skipMessageInConfirmationModal: false,
		messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
	};
};

const getImmediateActionableTakeProfitLimitMessageProps = (
	marketPrice: number,
	limitPrice: number,
	currentOrderDirection: 'long' | 'short'
) => {
	const isLongOrder = currentOrderDirection === 'long';

	// triggered but not executed
	let messageTitle = 'This will place a limit order immediately';
	let message = `The current oracle price is ${
		isLongOrder ? 'above' : 'below'
	} the trigger oracle price.`;

	// triggered and executed
	if (
		(marketPrice >= limitPrice && currentOrderDirection === 'long') ||
		(marketPrice <= limitPrice && currentOrderDirection === 'short')
	) {
		messageTitle = 'This order will start to execute immediately';
		message = `The current market price is ${
			marketPrice > limitPrice ? 'above' : 'below'
		} the limit price.`;
	}

	return {
		shouldShowMessage: true,
		messageTitle,
		message,
		messageLinkDescription: 'Learn more about Take Profit Orders',
		messageLink: 'https://docs.drift.trade/trading/all-order-types',
		messageType: TradeFormMessageType.warn,
		skipMessageInConfirmationModal: false,
		disableTradeButton: false,
		messageId: `takeProfitLimit_${currentOrderDirection}_immediatewarning`,
	};
};

const getUnexpectedLimitSideOfTriggerMessageProps = (
	limitPrice: number,
	triggerPrice: number
) => {
	return {
		shouldShowMessage: true,
		messageTitle: 'Check Limit Price',
		message: `The limit price is ${
			limitPrice > triggerPrice ? 'above' : 'below'
		} the trigger price. The order won't be able to fill immediately once it triggers.`,
		messageType: TradeFormMessageType.warn,
		skipMessageInConfirmationModal: false,
		disableTradeButton: false,
	};
};

/**
 * Check if the current market state already satisfies triggering the stop loss
 *
 * See docs
 * https://docs.drift.trade/order-types#D9KN6
 *
 * @param orderIsLong
 * @param oraclePrice
 * @param triggerPrice
 * @returns
 */
const isImmediateTriggeringStop = (
	orderIsLong: boolean,
	oraclePrice: number,
	triggerPrice: number
) => {
	if (orderIsLong && oraclePrice > triggerPrice) {
		// If order is STOP & LONG, then the TRIGGER CONDITION is ABOVE. So if ORACLE PRICE > TRIGGER PRICE, the order will fill immediately
		return true;
	} else if (!orderIsLong && oraclePrice < triggerPrice) {
		// If order is STOP & SHORT, then the TRIGGER CONDITION is BELOW. So if ORACLE PRICE < TRIGGER PRICE, the order will fill immediately
		return true;
	}

	return false;
};

/**
 * Check if the current market state already satisfied triggering the take profit
 *
 * See docs
 * https://docs.drift.trade/order-types#Bt7aa
 *
 * @param orderIsLong
 * @param oraclePrice
 * @param triggerPrice
 * @returns
 */
const isImmediateTriggeringTakeProfit = (
	orderIsLong: boolean,
	oraclePrice: number,
	triggerPrice: number
) => {
	if (orderIsLong && oraclePrice < triggerPrice) {
		// If order is TAKE PROFIT & LONG, the the TRIGGER CONDITION is BELOW. So if ORACLE PRICE < TRIGGER PRICE, then the order will fill immediately
		return true;
	} else if (!orderIsLong && oraclePrice > triggerPrice) {
		// If order is TAKE PROFIT & SHORT, the the TRIGGER CONDITION is ABOVE. So if ORACLE PRICE > TRIGGER PRICE, then the order will fill immediately
		return true;
	}

	return false;
};

// TODO : message and messageTitle are "required" , but aren't both being used in the ClosePositionPopup ?? Makes no sense
export type MessageInfo = Pick<
	TradeFormState,
	| 'messageType'
	| 'shouldShowMessage'
	| 'message'
	| 'skipMessageInConfirmationModal'
	| 'messageLink'
	| 'messageLinkDescription'
	| 'messageTitle'
	| 'messageId'
> & { disableTradeButton?: boolean };

/**
 * Checks for order violations
 * @param orderType
 * @param isSpot
 * @param priceBoxValue
 * @param orderDirection
 * @param marketPrice
 * @param postOnly
 * @param userIsShort
 * @param baseSize
 * @param secondaryPriceboxValue
 * @param currentPositionDirection
 * @returns
 */
export const getMessageInfoForOrderViolation = (
	orderType: UIOrderType,
	isSpot: boolean,
	priceBoxValue: number,
	marketPrice: number,
	postOnly: boolean,
	secondaryPriceboxValue: number,
	currentOrderDirection: 'long' | 'short',
	currentPositionDirection: 'long' | 'short',
	oraclePrice: number,
	marketSymbol: string,
	isSellPredictionMarket: boolean,
	l2State: L2OrderBook,
	currentPositionIsZero?: boolean,
	baseSize?: number,
	stepSize?: number
): MessageInfo => {
	const orderIsLong = currentOrderDirection === 'long';

	if (
		marketSymbol &&
		baseSize &&
		stepSize &&
		baseSize > 0 &&
		stepSize > 0 &&
		baseSize < stepSize
	) {
		return {
			message: `Order size must be greater than ${stepSize} ${marketSymbol}`,
			shouldShowMessage: true,
			messageTitle: `Order size must be greater than ${stepSize} ${marketSymbol}`,
			messageType: TradeFormMessageType.error,
			skipMessageInConfirmationModal: false,
			messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
			disableTradeButton: true,
		};
	}

	// Candle order-type-specific cases
	switch (orderType) {
		case 'limit': {
			const limitPrice = priceBoxValue;
			const bestBid =
				(l2State.bids[0]?.price.toNumber() ?? 0) / PRICE_PRECISION.toNumber();
			const bestAsk =
				(l2State.asks[0]?.price.toNumber() ?? 0) / PRICE_PRECISION.toNumber();

			const messageTitle = postOnly
				? 'This post only order may fail'
				: 'This order will start to execute immediately';

			if (orderIsLong) {
				if (limitPrice >= bestAsk && l2State.asks.length > 0) {
					return {
						messageTitle,
						shouldShowMessage: true,
						message: `The limit price is above or equal to the current orderbook ask price`,
						// messageLinkDescription: 'Learn more about Limit Orders',
						// messageLink: 'https://docs.drift.trade',
						messageType: TradeFormMessageType.warn,
						skipMessageInConfirmationModal: false,
						messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
					};
				}
			} else {
				if (
					(isSellPredictionMarket
						? limitPrice >= MAX_PREDICTION_PRICE_NUM - bestBid
						: limitPrice <= bestBid) &&
					l2State.bids.length > 0
				) {
					return {
						messageTitle,
						shouldShowMessage: true,
						message: `The limit price is below the current ask price`,
						// messageLinkDescription: 'Learn more about Limit Orders',
						// messageLink: 'https://docs.drift.trade',
						messageType: TradeFormMessageType.warn,
						skipMessageInConfirmationModal: false,
						messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
					};
				}
			}
			break;
		}
		case 'stopMarket': {
			const triggerPrice = priceBoxValue;

			if (
				isBadTPSLDirection(
					currentPositionIsZero,
					currentPositionDirection,
					currentOrderDirection
				)
			) {
				return getBadTPSLMessageProps(
					isSpot,
					currentPositionDirection,
					currentOrderDirection
				);
			}

			if (isImmediateTriggeringStop(orderIsLong, marketPrice, triggerPrice)) {
				return getImmediatelyExecutingStopMessageProps(
					oraclePrice,
					triggerPrice,
					orderType,
					currentOrderDirection
				);
			}
			break;
		}
		case 'stopLimit': {
			const triggerPrice = priceBoxValue;
			const limitPrice = secondaryPriceboxValue;

			if (
				isBadTPSLDirection(
					currentPositionIsZero,
					currentPositionDirection,
					currentOrderDirection
				)
			) {
				return getBadTPSLMessageProps(
					isSpot,
					currentPositionDirection,
					currentOrderDirection
				);
			}

			if (isImmediateTriggeringStop(orderIsLong, oraclePrice, triggerPrice)) {
				return getImmediateActionableStopLimitMessageProps(
					marketPrice,
					limitPrice,
					currentOrderDirection
				);
			}

			if (
				limitIsUnexpectedSideOfTrigger(
					currentPositionIsZero,
					currentPositionDirection,
					currentOrderDirection,
					limitPrice,
					triggerPrice
				)
			) {
				return getUnexpectedLimitSideOfTriggerMessageProps(
					limitPrice,
					triggerPrice
				);
			}

			break;
		}
		case 'takeProfitMarket': {
			const triggerPrice = priceBoxValue;

			if (
				isBadTPSLDirection(
					currentPositionIsZero,
					currentPositionDirection,
					currentOrderDirection
				)
			) {
				return getBadTPSLMessageProps(
					isSpot,
					currentPositionDirection,
					currentOrderDirection
				);
			}

			if (
				isImmediateTriggeringTakeProfit(orderIsLong, oraclePrice, triggerPrice)
			) {
				return getImmediatelyExecutingTakeProfitMessageProps(
					oraclePrice,
					triggerPrice,
					orderType,
					currentOrderDirection
				);
			}
			break;
		}
		case 'takeProfitLimit': {
			const triggerPrice = priceBoxValue;
			const limitPrice = secondaryPriceboxValue;

			if (
				isBadTPSLDirection(
					currentPositionIsZero,
					currentPositionDirection,
					currentOrderDirection
				)
			) {
				return getBadTPSLMessageProps(
					isSpot,
					currentPositionDirection,
					currentOrderDirection
				);
			}

			if (
				isImmediateTriggeringTakeProfit(orderIsLong, oraclePrice, triggerPrice)
			) {
				return getImmediateActionableTakeProfitLimitMessageProps(
					marketPrice,
					limitPrice,
					currentOrderDirection
				);
			}

			if (
				limitIsUnexpectedSideOfTrigger(
					currentPositionIsZero,
					currentPositionDirection,
					currentOrderDirection,
					limitPrice,
					triggerPrice
				)
			) {
				return getUnexpectedLimitSideOfTriggerMessageProps(
					limitPrice,
					triggerPrice
				);
			}
			break;
		}
		case 'oracleLimit': {
			const offset = priceBoxValue;
			const limitPrice = oraclePrice + offset;
			const bestBid =
				(l2State.bids[0]?.price.toNumber() ?? 0) / PRICE_PRECISION.toNumber();
			const bestAsk =
				(l2State.asks[0]?.price.toNumber() ?? 0) / PRICE_PRECISION.toNumber();

			const messageTitle = postOnly
				? 'This post only order may fail'
				: 'This order will start to execute immediately';

			if (orderIsLong) {
				if (limitPrice >= bestAsk && l2State.asks.length > 0) {
					return {
						messageTitle,
						shouldShowMessage: true,
						message: `The limit price is above or equal to the current orderbook ask price`,
						// messageLinkDescription: 'Learn more about Limit Orders',
						// messageLink: 'https://docs.drift.trade',
						messageType: postOnly
							? TradeFormMessageType.warn
							: TradeFormMessageType.info,
						skipMessageInConfirmationModal: false,
						messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
					};
				}
			} else {
				if (limitPrice <= bestBid && l2State.bids.length > 0) {
					return {
						messageTitle,
						shouldShowMessage: true,
						message: `The limit price is below or equal to the current bid price`,
						// messageLinkDescription: 'Learn more about Limit Orders',
						// messageLink: 'https://docs.drift.trade',
						messageType: postOnly
							? TradeFormMessageType.warn
							: TradeFormMessageType.info,
						skipMessageInConfirmationModal: false,
						messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
					};
				}
			}
			break;
		}
		case 'scaledOrders': {
			const startPrice = priceBoxValue;
			const endPrice = secondaryPriceboxValue;
			const bestBid = BigNum.from(
				l2State.bids[0]?.price ?? ZERO,
				PRICE_PRECISION_EXP
			).toNum();
			const bestAsk = BigNum.from(
				l2State.asks[0]?.price ?? ZERO,
				PRICE_PRECISION_EXP
			).toNum();

			const messageTitle = postOnly
				? 'Some post only orders will fail'
				: 'Some orders will start to execute immediately';

			if (orderIsLong) {
				const limitPrice = Math.max(startPrice, endPrice);
				if (limitPrice >= bestAsk) {
					return {
						messageTitle,
						shouldShowMessage: true,
						message: `The limit price is above or equal to the current ask price`,
						messageType: TradeFormMessageType.warn,
						skipMessageInConfirmationModal: false,
						messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
						disableTradeButton: postOnly,
					};
				}
			} else {
				const limitPrice = Math.min(startPrice, endPrice);
				if (
					isSellPredictionMarket
						? limitPrice >= MAX_PREDICTION_PRICE_NUM - bestBid
						: limitPrice <= bestBid
				) {
					return {
						messageTitle,
						shouldShowMessage: true,
						message: `The limit price is below the current ask price`,
						messageType: TradeFormMessageType.warn,
						skipMessageInConfirmationModal: false,
						messageId: `${orderType}${currentOrderDirection}_immediatewarning`,
						disableTradeButton: postOnly,
					};
				}
			}
			break;
		}
		default:
			break;
	}

	return undefined;
};

export const calculateTradeFormNotionalSize = (
	orderType: UIOrderType,
	baseSize: BigNum,
	priceBoxOneValue: string,
	priceBoxTwoValue: string,
	estimatedPrice: BigNum,
	orderCount?: number,
	sizeDistribution?: 'flat' | 'ascending' | 'descending',
	orderStepSize?: BigNum
): BigNum => {
	const priceBoxOneBigNum = BigNum.fromPrint(
		priceBoxOneValue,
		PRICE_PRECISION_EXP
	);
	const priceBoxTwoBigNum = BigNum.fromPrint(
		priceBoxTwoValue,
		PRICE_PRECISION_EXP
	);

	if (
		orderType === 'scaledOrders' &&
		(!orderCount || !sizeDistribution || !orderStepSize)
	) {
		return BigNum.zero(PRICE_PRECISION_EXP);
	}

	return orderType === 'market' || orderType === 'oracle'
		? estimatedPrice.mul(baseSize).shift(baseSize.precision.neg())
		: orderType === 'scaledOrders'
		? BigNum.from(
				calculateNotionalSize(
					sizeDistribution,
					baseSize,
					orderStepSize,
					orderCount,
					priceBoxOneBigNum.val,
					priceBoxTwoBigNum.val
				),
				PRICE_PRECISION_EXP.add(baseSize.precision)
		  ).shift(baseSize.precision.neg())
		: orderType === 'stopMarket' ||
		  orderType === 'limit' ||
		  orderType === 'takeProfitMarket'
		? baseSize.mul(priceBoxOneBigNum).shift(baseSize.precision.neg())
		: baseSize.mul(priceBoxTwoBigNum).shift(baseSize.precision.neg());
};

export const getPriceBoxHeading = (orderType: UIOrderType) => {
	switch (orderType) {
		case 'market':
			return '';
		case 'limit':
			return 'Limit Price';
		case 'stopLimit':
		case 'stopMarket':
		case 'takeProfitMarket':
		case 'takeProfitLimit':
			return 'Trigger Oracle Price';
		case 'oracle':
			return 'Oracle Market';
		case 'oracleLimit':
			return 'Oracle Price Offset';
		case 'scaledOrders':
			return 'Start Price';
		default:
			console.warn(`getPriceBoxHeading: Unhandled order type: ${orderType}`);
			return '';
	}
};

export function orderTypeSwitch<T = UIOrderType>(
	orderType: T,
	cases: {
		//@ts-ignore
		[key in T]: any;
	}
) {
	return cases[orderType];
}

export const getShouldShowSecondaryPriceBox = (orderType: UIOrderType) => {
	switch (orderType) {
		case 'market':
		case 'stopMarket':
		case 'takeProfitMarket':
		case 'oracleLimit':
		case 'oracle':
		case 'limit':
			return false;
		default:
			return true;
	}
};
