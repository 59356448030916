"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.grpcSignedMsgUserOrdersAccountSubscriber = void 0;
const grpcProgramAccountSubscriber_1 = require("../accounts/grpcProgramAccountSubscriber");
const memcmp_1 = require("../memcmp");
const signedMsgUserAccountSubscriber_1 = require("./signedMsgUserAccountSubscriber");
class grpcSignedMsgUserOrdersAccountSubscriber extends signedMsgUserAccountSubscriber_1.SignedMsgUserOrdersAccountSubscriber {
    constructor({ grpcConfigs, driftClient, commitment, resubOpts, decodeFn, resyncIntervalMs, }) {
        super({
            driftClient,
            commitment,
            resubOpts,
            decodeFn,
            resyncIntervalMs,
        });
        this.grpcConfigs = grpcConfigs;
    }
    async subscribe() {
        if (!this.subscriber) {
            this.subscriber =
                await grpcProgramAccountSubscriber_1.grpcProgramAccountSubscriber.create(this.grpcConfigs, 'SingedMsgUserOrdersAccountMap', 'SignedMsgUserOrders', this.driftClient.program, this.decodeFn, {
                    filters: [(0, memcmp_1.getSignedMsgUserOrdersFilter)()],
                }, this.resubOpts);
        }
        await this.subscriber.subscribe((_accountId, account, context) => {
            this.tryUpdateSignedMsgUserOrdersAccount(account, 'decoded', context.slot);
        });
        if (this.resyncIntervalMs) {
            const recursiveResync = () => {
                this.resyncTimeoutId = setTimeout(() => {
                    this.fetch()
                        .catch((e) => {
                        console.error('Failed to resync in OrderSubscriber');
                        console.log(e);
                    })
                        .finally(() => {
                        if (!this.resyncTimeoutId)
                            return;
                        recursiveResync();
                    });
                }, this.resyncIntervalMs);
            };
            recursiveResync();
        }
    }
    async unsubscribe() {
        if (!this.subscriber)
            return;
        await this.subscriber.unsubscribe();
        this.subscriber = undefined;
        if (this.resyncTimeoutId !== undefined) {
            clearTimeout(this.resyncTimeoutId);
            this.resyncTimeoutId = undefined;
        }
    }
}
exports.grpcSignedMsgUserOrdersAccountSubscriber = grpcSignedMsgUserOrdersAccountSubscriber;
