"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAGIC_FUEL_START_TS = exports.FUEL_SHARE_PRECISION = exports.FUEL_SHARE_PRECISION_EXP = exports.VAULT_SHARES_PRECISION_EXP = void 0;
const sdk_1 = require("@drift-labs/sdk");
exports.VAULT_SHARES_PRECISION_EXP = new sdk_1.BN(6);
// arbitrarily large fuel per share precision
exports.FUEL_SHARE_PRECISION_EXP = new sdk_1.BN(18);
exports.FUEL_SHARE_PRECISION = sdk_1.TEN.pow(exports.FUEL_SHARE_PRECISION_EXP);
// some arbitrary timestamp to identify VaultDepositors created after fuel distribution started.
exports.MAGIC_FUEL_START_TS = 123;
