import { DriftEnv } from '@drift-labs/sdk';
import { createWallet } from '@passkeys/core';
import Env from 'src/environmentVariables/EnvironmentVariables';

const PRODUCTION_APP_ID = '11235d81-5c8d-462e-9088-53e0c52d6030';
const PREVIEW_APP_ID = '830af944-cdf9-4bb6-b94c-ec6cefcebbb8';

const setupPasskeysWallet = (_env: DriftEnv) => {
	return createWallet({
		appId: _env === 'mainnet-beta' ? PRODUCTION_APP_ID : PREVIEW_APP_ID,
		providers: {
			solana: {
				walletStandard: false,
			},
		},
	});
};

export const PASSKEYS_WALLET_INSTANCE = setupPasskeysWallet(Env.sdkEnv);
