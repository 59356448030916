import React from 'react';
import { twMerge } from 'tailwind-merge';
import GradientText from '../Utils/GradientText';
import { Typo } from '../Text/Typo';
import { NewMarket } from '@drift-labs/icons';
import Tooltip from '../Tooltip/Tooltip';

export const LabelChip = (props: {
	label: string;
	negative?: boolean;
	positive?: boolean;
	className?: string;
	type?: 'success' | 'warning' | 'new' | 'gradient' | 'gradientText';
	icon?: React.ReactNode;
}) => {
	return (
		<Typo.B6
			className={twMerge(
				'px-1 py-[2px] rounded-sm flex items-center gap-1 pointer-events-none select-none',
				props.type === 'gradient' &&
					'background-app-gradient text-neutrals-110',
				props.type === 'warning' &&
					'text-warn-yellow bg-brand-yellow-secondary-bg',
				props.type === 'success' &&
					'text-positive-green bg-positive-green-secondary-bg',
				props.type === 'new' &&
					'bg-interactive-secondary-bg text-interactive-link',
				props.negative &&
					'bg-negative-red-secondary-bg text-text-negative-red-button',
				props.positive &&
					'bg-positive-green-secondary-bg text-text-positive-green-button',
				props?.className
			)}
		>
			{props.icon && props.icon}
			{props.type === 'gradientText' ? (
				<GradientText>{props.label}</GradientText>
			) : (
				<span>{props.label}</span>
			)}
		</Typo.B6>
	);
};

export const NewIconChip = (props: {
	className?: string;
	iconOnly?: boolean;
}) => {
	return (
		<Tooltip content="New" disabled={!props.iconOnly}>
			<Typo.T5
				className={twMerge(
					'flex items-center gap-1 px-1 py-[2px] rounded-sm',
					'bg-interactive-secondary-bg text-interactive-link',
					props.className
				)}
			>
				<NewMarket />
				{!props.iconOnly && <span>New</span>}
			</Typo.T5>
		</Tooltip>
	);
};
