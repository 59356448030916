import React, { PropsWithChildren, ReactNode } from 'react';
import Utility from './Utility';

const LabelledInput = (
	props: PropsWithChildren<{
		label: string | ReactNode;
		quickAction?: ReactNode;
	}>
) => {
	return (
		<div className="flex flex-col items-start w-full">
			<div className="flex items-center justify-between w-full">
				<Utility.FORMLABEL01 label={props.label} />
				{props.quickAction}
			</div>
			<Utility.VERTSPACERXS />
			{props.children}
		</div>
	);
};

export default LabelledInput;
