"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProtectedMakerParamsMap = exports.getProtectedMakerParams = void 0;
const __1 = require("..");
function getProtectedMakerParams(perpMarket) {
    let dynamicOffset;
    if (perpMarket.protectedMakerDynamicDivisor > 0) {
        dynamicOffset = __1.BN.max(perpMarket.amm.oracleStd, perpMarket.amm.markStd).divn(perpMarket.protectedMakerDynamicDivisor);
    }
    else {
        dynamicOffset = 0;
    }
    return {
        tickSize: perpMarket.amm.orderTickSize,
        limitPriceDivisor: perpMarket.protectedMakerLimitPriceDivisor,
        dynamicOffset: dynamicOffset,
    };
}
exports.getProtectedMakerParams = getProtectedMakerParams;
function getProtectedMakerParamsMap(perpMarkets) {
    const map = {
        perp: new Map(),
        spot: new Map(),
    };
    for (const perpMarket of perpMarkets) {
        const marketIndex = perpMarket.marketIndex;
        const protectedMakerParams = getProtectedMakerParams(perpMarket);
        map.perp.set(marketIndex, protectedMakerParams);
    }
    return map;
}
exports.getProtectedMakerParamsMap = getProtectedMakerParamsMap;
