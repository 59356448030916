import { useMemo, useState } from 'react';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import { getPctCompletion } from 'src/utils/math';
import { DriftWindow } from 'src/window/driftWindow';
import ProgressIndicator from '../Toasts/MarketOrders/ProgressIndicator';
import { useInterval } from 'react-use';

export const SlotExpiryProgress = ({
	endSlot,
	startSlot,
}: {
	endSlot: number;
	startSlot: number;
}) => {
	const [currentSlot, setCurrentSlot] = useState(
		DriftWindow.chainClock?.getState(DEFAULT_COMMITMENT_LEVEL)?.slot
	);

	useInterval(() => {
		setCurrentSlot(
			DriftWindow.chainClock?.getState(DEFAULT_COMMITMENT_LEVEL)?.slot
		);
	}, 1000);

	const pctComplete = getPctCompletion(startSlot, endSlot, currentSlot);
	const strokeColour = useMemo<'warn' | 'neutral' | 'strong-warn'>(() => {
		if (pctComplete >= 80) {
			return 'strong-warn';
		}

		if (pctComplete >= 50) {
			return 'warn';
		}

		return 'neutral';
	}, [pctComplete]);
	return (
		<ProgressIndicator
			start={endSlot}
			end={startSlot}
			current={currentSlot}
			direction="end-full"
			type={strokeColour}
		/>
	);
};
