import { BN } from '@coral-xyz/anchor';
import { BaseMessageSignerWalletAdapter } from '@solana/wallet-adapter-base';
import { TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL } from 'src/constants/constants';
import { DriftWindow } from 'src/window/driftWindow';
import {
	DriftClient,
	generateSignedMsgUuid,
	getOrderParams,
	OptionalOrderParams,
	OrderParams,
	SignedMsgOrderParamsMessage,
} from '@drift-labs/sdk';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { ENUM_UTILS } from '@drift/common';
import { dlog } from 'src/dev';
import { notify } from './notifications';

export async function signOrderMsg(
	wallet: BaseMessageSignerWalletAdapter,
	hexEncodedOrderMessage: Buffer,
	expirationSlot: number,
	onSignatureRequest: (signedMessage: Buffer) => void,
	onExpired: () => void
): Promise<Uint8Array<ArrayBufferLike>> {
	return new Promise((resolve, reject) => {
		onSignatureRequest(hexEncodedOrderMessage);

		const interval = setInterval(() => {
			const currentSlot = DriftWindow.chainClock?.getState(
				TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL
			)?.slot;
			if (!currentSlot) return;

			if (currentSlot >= expirationSlot) {
				onExpired();
				clearInterval(interval);
				reject(new Error('Auction slot expired'));
			}
		}, 1000);

		wallet
			.signMessage(new Uint8Array(hexEncodedOrderMessage))
			.then((signedMessage) => {
				clearInterval(interval);
				resolve(signedMessage);
			})
			.catch((error) => {
				clearInterval(interval);
				reject(error);
			});
	});
}

type prepSignedMsgOrderReturn = {
	hexEncodedOrderMessage: Buffer;
	signedMsgOrderParamsMessage: SignedMsgOrderParamsMessage;
	slotForSignedMsg: BN;
	signedMsgOrderUuid: Uint8Array;
};

export async function prepSignedMsgOrder(
	optionalOrderParams: OptionalOrderParams,
	subAccountId: number,
	driftClient: DriftClient,
	currentSlot: number,
	stopLossOrderParams?: OptionalOrderParams,
	takeProfitOrderParams?: OptionalOrderParams
): Promise<prepSignedMsgOrderReturn> {
	const orderParams: OrderParams = getOrderParams(optionalOrderParams);

	const slotForSignedMsg = new BN(
		currentSlot + Env.signedMessageOrderSlotBuffer
	);
	const signedMsgOrderUuid = generateSignedMsgUuid();
	const orderMessage: SignedMsgOrderParamsMessage = {
		signedMsgOrderParams: orderParams,
		uuid: signedMsgOrderUuid,
		slot: slotForSignedMsg,
		subAccountId,
		stopLossOrderParams: stopLossOrderParams
			? {
					baseAssetAmount: stopLossOrderParams.baseAssetAmount,
					triggerPrice: stopLossOrderParams.triggerPrice,
			  }
			: null,
		takeProfitOrderParams: takeProfitOrderParams
			? {
					baseAssetAmount: takeProfitOrderParams.baseAssetAmount,
					triggerPrice: takeProfitOrderParams.triggerPrice,
			  }
			: null,
	};
	dlog(
		'swift_market_orders::\n',
		'Sending signed message order\n',
		`Auction start: ${optionalOrderParams.auctionStartPrice?.toString()}\n`,
		`Auction end: ${optionalOrderParams.auctionEndPrice?.toString()}\n`,
		`Auction duration: ${optionalOrderParams.auctionDuration}\n`,
		`Base asset amount: ${optionalOrderParams.baseAssetAmount?.toString()}\n`,
		`Direction: ${ENUM_UTILS.toStr(optionalOrderParams.direction)}\n`
	);
	const encodedOrderMessage =
		driftClient.encodeSignedMsgOrderParamsMessage(orderMessage);
	return {
		hexEncodedOrderMessage: Buffer.from(encodedOrderMessage.toString('hex')),
		signedMsgOrderParamsMessage: orderMessage,
		slotForSignedMsg,
		signedMsgOrderUuid,
	};
}

export const initializeSignedMsgUserOrdersAccount = async (
	driftClient: DriftClient,
	walletAdapter: BaseMessageSignerWalletAdapter
): Promise<boolean> => {
	const toastId =
		'swift-toggle-on-toast-' +
		Date.now() +
		'-' +
		walletAdapter.publicKey.toBase58();
	notify({
		message: 'Initializing Swift account...',
		type: 'info',
		id: toastId,
	});
	try {
		const [txSig] = await driftClient.initializeSignedMsgUserOrders(
			walletAdapter.publicKey,
			8
		);
		if (txSig) {
			notify({
				message: 'Swift account initialized',
				type: 'success',
				id: toastId,
			});
			return true;
		} else {
			notify({
				message: 'Failed to initialize Swift account',
				type: 'error',
				id: toastId,
			});
			return false;
		}
	} catch (error) {
		console.error(error);
		notify({
			message: 'Failed to initialize Swift account',
			type: 'error',
			id: toastId,
		});
		return false;
	}
};
