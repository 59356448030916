import { twMerge } from 'tailwind-merge';

export const IconCircleBg = ({
	children,
	bgColor,
	className,
}: {
	children: React.ReactNode;
	bgColor: string;
	className?: string;
}) => {
	return (
		<div
			className={twMerge(
				'flex items-center justify-center p-1 rounded-full',
				bgColor,
				className
			)}
		>
			{children}
		</div>
	);
};
