import produce from 'immer';
import UI_UTILS from 'src/utils/uiUtils';
import { create } from 'zustand';

// Base types for feature flags
export interface BaseFeatureFlag {
	/** @private Internal flag state - use flagEnabled() instead */
	readonly _enabled: boolean;
	rawPayload?: unknown;
}

export interface BaseFeatureFlagWithPayload<T> extends BaseFeatureFlag {
	readonly payload: T;
}

// Special feature flags that need custom handling
export interface HighlightedAssetsFlag extends BaseFeatureFlag {
	assets: string[];
}
export interface HiddenSpotMarketsFlag extends BaseFeatureFlag {
	markets: string[];
}

// Define the feature flag configurations
export interface FeatureFlagConfigs {
	SKIP_TRANSACTION_PREFLIGHTS: BaseFeatureFlag;
	HIGHLIGHTED_ASSETS: HighlightedAssetsFlag;
	ENABLE_UI_INVARIANT_CHECKS: BaseFeatureFlag;
	PREFER_EVENTS_SERVER_EVENTS: BaseFeatureFlag;
	// Add new feature flags here by extending this interface
	// NEW_FEATURE_FLAG: BaseFeatureFlag;
	ENABLE_SIGNED_MSG_ORDERS: BaseFeatureFlag;
	ENABLE_NEW_CANDLES_API: BaseFeatureFlag;
	DISABLE_BLOCKCHAIN_EVENTS_FOR_MARKET_ORDER_TOASTS: BaseFeatureFlag;
	DISABLE_DATA_API_EVENTS_FOR_MARKET_ORDER_TOASTS: BaseFeatureFlag;
	DISABLE_ACCOUNT_ORDER_STATE_TOAST_SYNCING: BaseFeatureFlag;
	FUEL_PARAMS: BaseFeatureFlagWithPayload<{
		START_TS: number;
		END_TS: number;
	}>;
	HIDDEN_SPOT_MARKETS: HiddenSpotMarketsFlag;
	ENABLE_USER_VAULT_FUEL_DISPLAY: BaseFeatureFlag;
	ENABLE_VERIFIED_VAULTS: BaseFeatureFlag;
}

// Derive the feature flag key type from the configs
export type FeatureFlagKey = keyof FeatureFlagConfigs;

// Type helper to get the flag type for a specific key
export type FlagTypeForKey<K extends FeatureFlagKey> = FeatureFlagConfigs[K];

// The store's flag state type
type FlagState = {
	[K in FeatureFlagKey]: FlagTypeForKey<K>;
};

// Default values for all feature flags
export const FEATURE_FLAG_DEFAULTS: FlagState = {
	SKIP_TRANSACTION_PREFLIGHTS: {
		_enabled: false,
	},
	HIGHLIGHTED_ASSETS: {
		_enabled: false,
		assets: [],
	},
	ENABLE_UI_INVARIANT_CHECKS: {
		_enabled: false,
	},
	PREFER_EVENTS_SERVER_EVENTS: {
		_enabled: false,
	},
	ENABLE_SIGNED_MSG_ORDERS: {
		_enabled: false,
	},
	ENABLE_NEW_CANDLES_API: {
		_enabled: false, // NOTE :: Once we turn this on permanently we should remove the debug logging currently being done in the new clients, in common-ts
	},
	DISABLE_BLOCKCHAIN_EVENTS_FOR_MARKET_ORDER_TOASTS: {
		_enabled: false,
	},
	DISABLE_DATA_API_EVENTS_FOR_MARKET_ORDER_TOASTS: {
		_enabled: false,
	},
	DISABLE_ACCOUNT_ORDER_STATE_TOAST_SYNCING: {
		_enabled: false,
	},
	FUEL_PARAMS: {
		_enabled: false,
		payload: null,
	},
	HIDDEN_SPOT_MARKETS: {
		_enabled: false,
		markets: [],
	},
	ENABLE_USER_VAULT_FUEL_DISPLAY: {
		_enabled: false,
	},
	ENABLE_VERIFIED_VAULTS: {
		_enabled: false,
	},
};

const getFlagOverrideKey = (flagKey: FeatureFlagKey) => {
	return `flag_override_${flagKey}`;
};

export const setFlagOverride = (flagKey: FeatureFlagKey, value: boolean) => {
	if (UI_UTILS.isWindowDefined()) {
		window.localStorage?.setItem(getFlagOverrideKey(flagKey), value.toString());
	}
};

export const clearFlagOverride = (flagKey: FeatureFlagKey) => {
	if (UI_UTILS.isWindowDefined()) {
		window.localStorage?.removeItem(getFlagOverrideKey(flagKey));
	}
};

export const getFlagOverrideValue = (flagKey: FeatureFlagKey) => {
	if (!UI_UTILS.isWindowDefined()) return false;

	const localStorageValue = window.localStorage?.getItem(
		getFlagOverrideKey(flagKey)
	);

	if (localStorageValue === undefined || localStorageValue === null)
		return undefined;

	return localStorageValue === 'true';
};

export interface FeatureFlagStore {
	set: (x: (s: FeatureFlagStore) => void) => void;
	get: () => FeatureFlagStore;
	flags: FlagState;
	flagEnabled: (flagKey: FeatureFlagKey, skipOverride?: boolean) => boolean;
	getFlagPayload: <K extends FeatureFlagKey>(
		flagKey: K
	) => FlagTypeForKey<K>['rawPayload'];
}

const useFeatureFlagStore = create<FeatureFlagStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	flags: FEATURE_FLAG_DEFAULTS,
	flagEnabled: (flagKey: FeatureFlagKey, ignoreOverride?: boolean) => {
		const baseValue = get().flags[flagKey]?._enabled ?? false;

		if (ignoreOverride) return baseValue;

		const flagOverride = getFlagOverrideValue(flagKey);

		if (flagOverride !== undefined) return flagOverride;

		return baseValue;
	},
	getFlagPayload: <K extends FeatureFlagKey>(flagKey: K) =>
		get().flags[flagKey]?.rawPayload,
}));

export default useFeatureFlagStore;
