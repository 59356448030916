"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToBN = exports.convertToNumber = void 0;
const __1 = require("../");
const numericConstants_1 = require("../constants/numericConstants");
const convertToNumber = (bigNumber, precision = numericConstants_1.PRICE_PRECISION) => {
    if (!bigNumber)
        return 0;
    return (bigNumber.div(precision).toNumber() +
        bigNumber.mod(precision).toNumber() / precision.toNumber());
};
exports.convertToNumber = convertToNumber;
function convertToBN(value, precision) {
    // Get the whole part using Math.floor
    const wholePart = Math.floor(value);
    // Get decimal part by subtracting whole part and multiplying by precision
    const decimalPart = Math.round((value - wholePart) * precision.toNumber());
    // Combine: wholePart * PRECISION + decimalPart
    return new __1.BN(wholePart).mul(precision).add(new __1.BN(decimalPart));
}
exports.convertToBN = convertToBN;
