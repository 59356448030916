'use client';

import React from 'react';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useCurrentWalletIconSrc from 'src/hooks/useCurrentWalletIconSrc';
import { JSX } from 'react';
import { useIsUserProtectedMakerMode } from 'src/hooks/user/useIsUserProtectedMakerMode';
import { twMerge } from 'tailwind-merge';
import { ProtectedMakerIcon } from './ProtectedMakerIcon';
const CurrentWalletProviderIcon = (props: {
	className?: string;
	style?: React.CSSProperties;
}): JSX.Element => {
	const iconSrc = useCurrentWalletIconSrc();

	const isProtectedMakerMode = useIsUserProtectedMakerMode();

	const isMagicAuth = useDriftStore((s) => s.wallet.isMagicAuth);

	const currentWallet = useDriftStore((s) => s.wallet.current);

	const connected = currentWallet.adapter.connected;

	if (!connected) return <div></div>;

	if (isMagicAuth) {
		return (
			<div className={props.className} style={props.style}>
				{isProtectedMakerMode ? (
					<ProtectedMakerIcon />
				) : (
					<img src={iconSrc} alt={'Magic Wallet'} className="w-full h-full" />
				)}
			</div>
		);
	}

	if (!currentWallet) return <div></div>;

	return (
		<div className={twMerge(props.className, 'relative')} style={props.style}>
			{isProtectedMakerMode ? (
				<ProtectedMakerIcon />
			) : (
				<img src={iconSrc} className="w-full h-full" />
			)}
		</div>
	);
};

export default CurrentWalletProviderIcon;
