'use client';

import { PublicKey } from '@drift-labs/sdk';
import React, {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import Env, {
	defaultUserSettings,
	UserSettings,
} from '../environmentVariables/EnvironmentVariables';
import useDriftStore, {
	DriftAppEventEmitter,
} from '../stores/DriftStore/useDriftStore';
import { notify } from '../utils/notifications';
import { WalletAdapter } from '@solana/wallet-adapter-base';
import { EnvironmentConstants } from '@drift/common';
import useMaxMarginRatioAndLeverage from 'src/hooks/useMaxMarginRatioAndLeverage';
import { Updater, useImmer } from 'use-immer';
import { DriftUISettingsHandler } from '../utils/settings';
import useLocalStorageState, {
	useLocalStorageStringState,
} from 'src/hooks/useLocalStorageState';
import useTradeModeSetting, { TradeMode } from 'src/hooks/useTradeModeSetting';
import useLayoutConfigSetting from 'src/hooks/useLayoutConfigSetting';
import useAppEventEmitter from 'src/hooks/useAppEventEmitter';

const SHOW_SETTINGS_TRANSFORMED_WARNING = true; // This will disable showing warnings when the automatic versioned-settings transformation changes a user's settings.

/**
 * EXPLANATION OF EXPECTED SETTINGS FUNCTIONALITY.
 *
 * THERE ARE TWO TYPES OF SETTINGS WE CAN STORE:
 * - LOCAL SETTINGS
 * - SYNCED USER SETTINGS (WE'LL CALL THESE USER SETTINGS FROM NOW)
 *
 * WHEN A USER HAS SYNCING ENABLED:
 * - ONLY THE NECESSARY SYNCED SETTINGS ARE STORED REMOTELY (SEE extractSyncSettings).
 * - THE OTHER SETTINGS (see extractNonSyncSettings) ARE STILL SAVED REMOTELY
 *
 * WHEN A USER DOESN'T HAVE SYNCING ENABLED:
 * - ALL SETTINGS GET SAVED LOCALLY
 *
 * THE OVERALL CURRENT SETTINGS ARE DETERMINED BY MERGING DEFAULT SETTINGS + USER + LOCAL SETTINGS
 *
 * // TODO: HANDLE GODO UX FOR THESE
 * WHEN A USER TURNS SYNCRONISED SETTINGS OFF:
 * - CLEAR ANY PREVIOUSLY SYNCED SETTINGS FROM LOCAL MEMORY
 *
 * WHEN A USER TURNS SYNCRONISED SETTINGS ON:
 * - WE ONLY SAVE "NEW" SETTINGS CHANGES
 */

/* -------------------------------------------------------------------------- */
/*                             CONSTANTS AND TYPES                            */
/* -------------------------------------------------------------------------- */

const USER_SETTINGS_STORAGE_LOCATION = `settings`;

const DEFAULTS_NEEDING_UPDATE: {
	key: keyof UserSettings;
	oldDefault: any;
	newDefault: any;
}[] = [];
const UPDATED_FIELDS_KEY = 'updatedFields';

/* -------------------------------------------------------------------------- */
/*                               HELPER METHODS                               */
/* -------------------------------------------------------------------------- */

const syncSetLocalUserSettings = (newSetting: Partial<UserSettings>) => {
	window.localStorage.setItem(
		USER_SETTINGS_STORAGE_LOCATION,
		JSON.stringify(newSetting)
	);
};

const syncGetLocalUserSettings = (): Partial<UserSettings> => {
	const currentUserSettings = window.localStorage.getItem(
		USER_SETTINGS_STORAGE_LOCATION
	);
	if (!currentUserSettings) return {};
	return JSON.parse(currentUserSettings);
};

/**
 * Returns true if the setting is the default value - hasn't been manually changed by the user
 * @param settingKey
 * @returns
 */
export const useSettingIsDefaultValue = (settingKey: keyof UserSettings) => {
	const [updatedFieldsState] = useLocalStorageStringState(UPDATED_FIELDS_KEY);

	const updatedFields = useMemo(() => {
		return updatedFieldsState?.split(',') ?? [];
	}, [updatedFieldsState]);

	const result = useMemo(() => {
		return !updatedFields.includes(settingKey);
	}, [settingKey, updatedFields]);

	return result;
};

// const extractSettings = (settings: Partial<UserSettings>) => {
// 	return Object.entries(settings).reduce((previousResult, currentEntry) => {
// 		const key = currentEntry[0] as keyof UserSettings;
// 		const value = currentEntry[1];
// 		return {
// 			...previousResult,
// 			[key]: value,
// 		};
// 	}, {}) as Partial<UserSettings>;
// };

/* When we decide to update the value of a default setting, it only applies to the user if they have no cache.
 * Call this function as a workaround to update any defaults
 * Only update if they are still on the old default value which would indicate they probably haven't changed it
 * If the setting gets genuinely updated we save a flag in local storage that tells us not to overwrite here
 * Note: this gets called when we load user's local settings, it will still get overridden if they have remotely saved settings
 */
const checkNewDefaults = (
	currentSettings: Partial<UserSettings>
): Partial<UserSettings> => {
	const updatedFields =
		localStorage.getItem(UPDATED_FIELDS_KEY)?.split(',') ?? [];
	const newUpdates: Partial<UserSettings> = {};

	DEFAULTS_NEEDING_UPDATE.forEach(({ key, oldDefault, newDefault }) => {
		const hasUpdated = updatedFields.includes(key);
		const currentValueIsOldDefault = currentSettings[key] === oldDefault;
		if (!hasUpdated && currentValueIsOldDefault) {
			newUpdates[key] = newDefault as never;
		}
	});

	return Object.entries(newUpdates)?.length > 0
		? { ...currentSettings, ...newUpdates }
		: currentSettings;
};

const markManuallyChangedSettings = (
	newSettings: Partial<UserSettings>,
	oldSettings: Partial<UserSettings>
) => {
	const updatedFields =
		localStorage.getItem(UPDATED_FIELDS_KEY)?.split(',') ?? [];
	const newUpdatedFieldsList: string[] = [];

	Object.keys(newSettings).forEach((key) => {
		const hasAlreadyBeenUpdated = updatedFields.includes(key);
		const isChanged =
			oldSettings[key as keyof UserSettings] !== undefined &&
			oldSettings[key as keyof UserSettings] !==
				newSettings[key as keyof UserSettings];
		if (isChanged && !hasAlreadyBeenUpdated) {
			newUpdatedFieldsList.push(key);
		}
	});

	if (newUpdatedFieldsList?.length > 0) {
		localStorage.setItem(
			UPDATED_FIELDS_KEY,
			updatedFields.concat(newUpdatedFieldsList).join(',')
		);
	}
};

/* -------------------------------------------------------------------------- */
/*                               SETTINGS CLIENT                              */
/* -------------------------------------------------------------------------- */

export class SettingsClient {
	private currentAuthority: PublicKey;

	// Local settings are the default settings + any settings changes made while an authority isn't connected
	private currentLocalSettings: Partial<UserSettings> = {};

	// Gets set to true if the page was loaded with empty user settings
	public isNewUser: boolean;

	get overallSettingsState() {
		return {
			...defaultUserSettings,
			...this.currentLocalSettings,
		};
	}

	get wallet() {
		return this.getWalletAdapter();
	}

	constructor(
		/**
		 * The uiSettingsStateUpdateHandler is responsible for making sure that the new settings are reflected in the UIs state. Local methods within this class are responsible for making sure that the settings actually get SAVED (either to localstate, or the history server)
		 */
		private uiSettingsStateUpdateHandler: Updater<UserSettings>,
		private getWalletAdapter: () => WalletAdapter,
		private appEventEmitter: DriftAppEventEmitter | undefined
	) {}

	private setSettings = (newSettings: Partial<UserSettings>) => {
		this.uiSettingsStateUpdateHandler((state) => {
			Object.entries(newSettings).forEach(([key, value]) => {
				state[key as keyof UserSettings] = value as never;
			});
		});
	};

	/**
	 * Used to clear the currently stored user settings. Does not sync them or anything like that.
	 */
	private localResetUserSettings = () => {
		this.currentLocalSettings = {};
	};

	private fetchAndSyncLocalSettings = () => {
		const localSettings = syncGetLocalUserSettings();
		this.currentLocalSettings = localSettings;

		this.isNewUser = Object.keys(this.currentLocalSettings).length === 0;

		// update rpc to default if there is an rpc for the wrong environment in local storage
		const currentEnvIsMainnet = Env.sdkEnv === 'mainnet-beta';
		const rpcValueIsMainnet = (value: string) =>
			EnvironmentConstants.rpcs.mainnet.some((rpc) => rpc.value === value);
		const rpcValueIsDevnet = (value: string) =>
			EnvironmentConstants.rpcs.dev.some((rpc) => rpc.value === value);

		const updateRpcToDefault =
			!this.currentLocalSettings.rpcEndpoint ||
			(currentEnvIsMainnet &&
				rpcValueIsDevnet(this.currentLocalSettings.rpcEndpoint.value)) ||
			(!currentEnvIsMainnet &&
				rpcValueIsMainnet(this.currentLocalSettings.rpcEndpoint.value));

		if (updateRpcToDefault) {
			this.currentLocalSettings.rpcEndpoint = {
				label: Env.defaultRpcLabel,
				value: Env.defaultRpcAddress,
			};
		}

		// apply any new default settings
		this.currentLocalSettings = checkNewDefaults(this.currentLocalSettings);
	};

	private saveLocalSettings = async (newSettings: Partial<UserSettings>) => {
		console.log(`SAVING LOCAL SETTINGS`);
		console.log(newSettings);
		syncSetLocalUserSettings(newSettings);
		this.currentLocalSettings = {
			...this.currentLocalSettings,
			...newSettings,
		};
	};

	public updateSettings = async (newSettings: Partial<UserSettings>) => {
		// force versioned txns on if place and take being enabled, otherwise place and take won't work
		const placeAndTakeIsBeingEnabled =
			newSettings.placeAndTakeEnabled === true &&
			this.overallSettingsState.placeAndTakeEnabled === false;
		if (
			placeAndTakeIsBeingEnabled &&
			!this.overallSettingsState.enableVersionedTx
		) {
			newSettings = { ...newSettings, enableVersionedTx: true };
			notify({
				type: 'info',
				message:
					'Versioned Transactions enabled for compatibility with Place and Take.',
			});
		}

		// force place and take off if versioned tx being turned off
		const versionedTxIsBeingDisabled =
			newSettings.enableVersionedTx === false &&
			this.overallSettingsState.enableVersionedTx === true;
		if (
			versionedTxIsBeingDisabled &&
			this.overallSettingsState.placeAndTakeEnabled
		) {
			newSettings = { ...newSettings, placeAndTakeEnabled: false };
			notify({
				type: 'info',
				message:
					'Place and Take turned off for compatibility with Legacy Transactions.',
			});
		}

		// save changed setting key to local storage to tell us not to override it with a new default later on
		markManuallyChangedSettings(newSettings, this.overallSettingsState);

		const mergedNewSettings = {
			...this.overallSettingsState,
			...newSettings,
		};

		const processedNewSettingsResult =
			DriftUISettingsHandler.apply(mergedNewSettings);
		const processedNewSettings = processedNewSettingsResult.transformedSettings;

		this.saveLocalSettings(processedNewSettings);

		this.uiSettingsStateUpdateHandler(this.overallSettingsState);
	};

	public handleInitialiseSettings = async (authority?: PublicKey) => {
		if (!authority) {
			this.setSettings({
				version: undefined,
			});
		}

		this.currentAuthority = authority;

		// Sync local settings
		await this.fetchAndSyncLocalSettings();

		let newSettings = this.overallSettingsState;

		if (authority) {
			const versionTransformedSettings = DriftUISettingsHandler.apply(
				this.overallSettingsState
			);

			if (versionTransformedSettings.transformationWasApplied) {
				newSettings = versionTransformedSettings.transformedSettings;

				const warningSeenKey = `settings_transform_applied::${authority.toString()}::${
					newSettings.version
				}`;

				const warningSeen = localStorage.getItem(warningSeenKey);

				await this.updateSettings(newSettings);

				if (SHOW_SETTINGS_TRANSFORMED_WARNING && !warningSeen) {
					notify({
						type: 'info',
						message: 'Settings Updated',
						description: versionTransformedSettings.transformationDescription,
						action: {
							label: 'Open Settings',
							callback: () => {
								this.appEventEmitter?.emit(
									'showModal',
									newSettings.version > 1
										? 'showSettingsModal'
										: 'showNetworkModal' // TODO : Change this when we add more settings versions
								);
							},
						},
						showUntilCancelled: true,
					});

					localStorage.setItem(warningSeenKey, 'true');
				}
			}
		}

		// Update the UI state with the newly synced settings
		this.uiSettingsStateUpdateHandler(newSettings);
	};
}

export let GLOBAL_SETTINGS_CLIENT = new SettingsClient(
	() => {},
	() => undefined,
	undefined
);

/* -------------------------------------------------------------------------- */
/*                          SETTINGS CLIENT PROVIDER                          */
/* -------------------------------------------------------------------------- */

type SettingsContextState = {
	settingsClient: SettingsClient;
	currentSettings: UserSettings;
};

export const SettingsClientContext = createContext<SettingsContextState>({
	currentSettings: defaultUserSettings,
	settingsClient: undefined,
});

const SettingsProvider = (props: PropsWithChildren<any>) => {
	const [hasSeenTradeModeModal, setHasSeenTradeModeModal] =
		useLocalStorageState('hasSeenTradeModeModal', false);
	const [_tradeMode, setTradeMode] = useTradeModeSetting();
	const [_layout, setLayout] = useLayoutConfigSetting();
	const setState = useDriftStore((s) => s.set);
	const getState = useDriftStore((s) => s.get);
	const appEventEmitter = useAppEventEmitter();
	const walletAuthority = useDriftStore(
		(s) => s.wallet?.current?.adapter?.publicKey
	);
	const [currentSettings, setCurrentSettings] = useImmer(defaultUserSettings);
	const getWalletAdapter = () => getState()?.wallet?.current.adapter;
	const overrideTxVersion = useDriftStore((s) => s.wallet.overrideTxVersion);

	const driftClient = useDriftStore((s) => s.driftClient.client);

	const settingsClient = useMemo(() => {
		return new SettingsClient(
			setCurrentSettings,
			getWalletAdapter,
			appEventEmitter
		);
	}, [setCurrentSettings]);

	const customMarginRatio = useMaxMarginRatioAndLeverage()[0];

	const contextState = useMemo(() => {
		return {
			currentSettings,
			settingsClient: settingsClient,
		};
	}, [currentSettings, settingsClient]);

	useEffect(() => {
		GLOBAL_SETTINGS_CLIENT = settingsClient;
		settingsClient.handleInitialiseSettings(walletAuthority);
	}, [walletAuthority, settingsClient]);

	// Default to lite mode and show the pop up for new users
	useEffect(() => {
		if (
			// SETTINGS_CLIENT.isNewUser &&
			setTradeMode &&
			setLayout &&
			!hasSeenTradeModeModal &&
			Env.enableLiteMode
		) {
			setTradeMode(TradeMode.LITE);
			setLayout('defaultLite');
			setState((s) => {
				s.modals.showTradeModeSelectionModal = true;
			});
			setHasSeenTradeModeModal(true);
		}
	}, [
		// SETTINGS_CLIENT.isNewUser,
		Env.enableLiteMode,
		hasSeenTradeModeModal,
		setTradeMode,
		setLayout,
	]);

	// sync settings across tabs
	useEffect(() => {
		function storageListener(event: StorageEvent) {
			try {
				if (event.key === USER_SETTINGS_STORAGE_LOCATION) {
					const newSettings = JSON.parse(event.newValue);
					if (newSettings) {
						settingsClient.updateSettings(newSettings);
					}
				}
			} catch (e) {
				console.error('Error syncing settings across tabs', e);
			}
		}
		window.addEventListener('storage', storageListener);
	}, []);

	// max margin ratio comes from the user account not local storage, and is the only subaccount specific setting so far.
	// keep it in sync here
	useEffect(() => {
		setCurrentSettings((state) => {
			state.customMarginRatio = customMarginRatio;
		});
	}, [customMarginRatio]);

	// keep this in sync - driftClient automatically sets this based on wallet compatibility but users can still use this to troubleshoot
	useEffect(() => {
		if (driftClient) {
			if (overrideTxVersion !== undefined) {
				driftClient.txVersion = overrideTxVersion;
				return;
			}
		}
	}, [
		currentSettings?.enableVersionedTx,
		driftClient,
		driftClient?.txVersion,
		overrideTxVersion,
	]);

	return (
		<SettingsClientContext value={contextState}>
			{props.children}
		</SettingsClientContext>
	);
};

export const useSettingsContext = () => {
	const context = useContext(SettingsClientContext);

	if (!context) {
		throw new Error('useSettingsClient must be used within a SettingsProvider');
	}

	return context;
};

export default React.memo(SettingsProvider);
