'use client';

import { SnapWalletAdapter } from '@drift-labs/snap-wallet-adapter';
import { WalletName, WalletReadyState } from '@solana/wallet-adapter-base';
import React, { useEffect, useRef, useState } from 'react';
import Text from './Text/Text';
import { ChevronDown, Info } from '@drift-labs/icons';
import useWalletsToDisplay, {
	WalletToDisplayInfo,
} from 'src/hooks/useWalletsToDisplay';
import { WALLET_PROVIDER_BG_COLORS } from 'src/constants/constants';
import {
	DRIFT_CUSTOM_WALLET_ICONS,
	DriftWalletName,
} from 'src/constants/wallets';
import { DRIFT_CUSTOM_WALLET_OPTIONS } from 'src/constants/wallets';
import { twMerge } from 'tailwind-merge';
import Tooltip from './Tooltip/Tooltip';
import UI_UTILS from '../utils/uiUtils';
import { LabelChip } from './Chips/LabelChip';
import MarketIcon from './Utils/MarketIcon';
import Env, { DriftTheme } from 'src/environmentVariables/EnvironmentVariables';
import useTryConnectAppKit from 'src/hooks/useTryConnectAppKit';
import useDriftTheme from 'src/hooks/useDriftTheme';
import useCurrentWalletConnectionState from 'src/hooks/useCurrentWalletConnectionState';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import ArcLoadingSpinner from './Icons/LoadingSpinner/ArcLoadingSpinner';

const snapAdapterInstance = new SnapWalletAdapter();

const buttonClassName =
	'flex flex-row items-center justify-between w-full px-4 py-3 mb-3 border border-input-border rounded last:mb-0 bg-button-secondary-bg hover:bg-button-secondary-bg-hover';

const walletsToHideDetectedTextFor = ['WalletConnect'];

const walletsToAddPaddingFor = ['MathWallet', 'WalletConnect'];

const checkShowDetectedLabel = (wallet: WalletToDisplayInfo) => {
	const walletProvider = wallet.provider;

	if (
		walletProvider.readyState === WalletReadyState.Loadable &&
		walletProvider.adapter.name === 'Solflare'
	) {
		return false;
	}

	if (walletsToHideDetectedTextFor.includes(walletProvider.adapter.name)) {
		return false;
	}

	return walletProvider.readyState === WalletReadyState.Installed;
};

export const WalletButton = (props: {
	wallet: WalletToDisplayInfo;
	onChooseWallet?: (selection: string) => void;
	hideDetectedLabel?: boolean;
}) => {
	const currentlySelectedWallet = useDriftStore((s) => s.wallet.current);
	const walletConnectionState = useCurrentWalletConnectionState();
	const walletProvider = props.wallet.provider;
	const featuresAutoConfirm = props?.wallet?.featuresAutoConfirm;
	const featuredHighlight = props?.wallet?.featuredHighlight;

	const isConnecting =
		walletConnectionState.instance.is('Connecting') &&
		currentlySelectedWallet.adapter.name === walletProvider.adapter.name;

	return (
		<button
			onClick={() => props.onChooseWallet(walletProvider.adapter.name)}
			key={walletProvider.adapter.name}
			className={buttonClassName}
			data-testid={`connect-wallet-button-${walletProvider.adapter.name}`}
		>
			<div className="flex flex-row items-center justify-between w-full">
				<div className="flex flex-row items-center space-x-4">
					<div
						className={twMerge(
							`w-6 h-6`,
							walletsToAddPaddingFor.includes(walletProvider.adapter.name)
								? 'p-1'
								: '',
							WALLET_PROVIDER_BG_COLORS[walletProvider.adapter.name]
								? `rounded-full overflow-hidden bg-[${
										WALLET_PROVIDER_BG_COLORS[walletProvider.adapter.name]
								  }]`
								: ''
						)}
					>
						<img src={walletProvider.adapter.icon} className="w-full h-full" />
					</div>
					<div className="text-left text-md text-text-default">
						{walletProvider.adapter.name}
					</div>
					{featuresAutoConfirm && (
						<span className="inline">
							<Tooltip
								content={
									'This wallet has the ability to Auto-Confirm transactions for frictionless trading'
								}
							>
								<LabelChip type="gradient" label="Auto-Confirm" />
							</Tooltip>
						</span>
					)}
					{featuredHighlight && <LabelChip label="Featured" type="gradient" />}
				</div>
				{isConnecting ? (
					<div className="flex flex-row items-center">
						<ArcLoadingSpinner />{' '}
						<div className="ml-2 text-text-label">{`Connecting...`}</div>
					</div>
				) : (
					!props.hideDetectedLabel &&
					checkShowDetectedLabel(props.wallet) && (
						<div className="ml-2 text-text-label">{`Detected`}</div>
					)
				)}
			</div>
		</button>
	);
};

const MagicLinkButton = (props: {
	onChooseWallet: (walletName: DriftWalletName) => void;
}) => {
	return (
		<button
			onClick={() =>
				props.onChooseWallet(
					DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME
				)
			}
			className={buttonClassName}
		>
			<div className="flex flex-row items-center">
				<div className="w-6 h-6 mr-4">
					<img
						src={DRIFT_CUSTOM_WALLET_ICONS.MAGIC_AUTH_WALLET_ADAPTER}
						alt={'Magic Wallet'}
						className="w-full h-full"
					/>
				</div>
				<div className="text-left text-md text-text-default">
					{'Email via Magic Link'}
				</div>
			</div>

			{<div className="ml-2 text-text-label"></div>}
		</button>
	);
};

const ReownWalletButton = (props: {
	onChooseWallet: (walletName: DriftWalletName) => void;
}) => {
	const tryConnectWithAppKit = useTryConnectAppKit({
		onConnectedCb: () => {
			props.onChooseWallet(
				DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME
			);
		},
	});

	const isLightTheme = useDriftTheme() === DriftTheme.light;
	return (
		<button onClick={tryConnectWithAppKit} className={buttonClassName}>
			<div className="flex flex-row items-center">
				<div className="w-6 h-6 mr-4">
					<img
						src={
							isLightTheme
								? '/assets/icons/reown-logo-negative.svg'
								: '/assets/icons/reown-logo.svg'
						}
						className="w-full h-full"
					/>
				</div>
				<div className="text-left text-md text-text-default">
					Social Login with Reown
				</div>
			</div>
		</button>
	);
};

const PasskeysButton = (props: {
	onChooseWallet: (walletName: DriftWalletName) => void;
}) => {
	const currentlySelectedWallet = useDriftStore((s) => s.wallet.current);
	const walletConnectionState = useCurrentWalletConnectionState();

	const isConnecting =
		walletConnectionState.instance.is('Connecting') &&
		currentlySelectedWallet.adapter.name ===
			DRIFT_CUSTOM_WALLET_OPTIONS.PASSKEYS_WALLET_ADAPTER_NAME;

	return (
		<button
			onClick={() =>
				props.onChooseWallet(
					DRIFT_CUSTOM_WALLET_OPTIONS.PASSKEYS_WALLET_ADAPTER_NAME
				)
			}
			className={buttonClassName}
		>
			<div className="flex flex-row items-center">
				<div className="w-6 h-6 mr-4">
					<img
						src={DRIFT_CUSTOM_WALLET_ICONS.PASSKEYS_WALLET_ADAPTER}
						alt={DRIFT_CUSTOM_WALLET_OPTIONS.PASSKEYS_WALLET_ADAPTER_NAME}
						className="w-full h-full"
					/>
				</div>
				<div className="text-left text-md text-text-default">{'Passkeys'}</div>
			</div>

			{isConnecting ? (
				<div className="flex flex-row items-center">
					<ArcLoadingSpinner />{' '}
					<div className="ml-2 text-text-label">{`Connecting...`}</div>
				</div>
			) : (
				<div className="text-left text-md text-text-default"></div>
			)}
		</button>
	);
};

const OtherWalletsDropdown = (props: {
	wallets: WalletToDisplayInfo[];
	onChooseWallet?: (selection: string) => void;
	showMetamaskIfDetected?: boolean;
}) => {
	const contentDivRef = useRef<HTMLDivElement>(null);
	const [expanded, setExpanded] = useState(false);
	const [maxHeight, setMaxHeight] = useState(0);

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		if (contentDivRef?.current?.offsetHeight) {
			setMaxHeight(contentDivRef.current?.offsetHeight);
		}
	}, [contentDivRef.current?.offsetHeight]);

	const metamaskDetected =
		snapAdapterInstance.readyState === WalletReadyState.Installed ||
		snapAdapterInstance.readyState === WalletReadyState.Loadable;

	const metamaskOutdated =
		snapAdapterInstance.readyState === WalletReadyState.Unsupported;

	const metamaskButton = (
		<button
			onClick={() =>
				props.onChooseWallet(
					DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME
				)
			}
			className={twMerge(buttonClassName, 'hidden sm:flex')}
		>
			<div className="flex flex-row items-center">
				<div className="w-6 h-6 mr-4">
					<img src={SnapWalletAdapter.icon} className="w-full h-full" />
				</div>
				<div className="text-left text-md text-text-default">{'MetaMask'}</div>
			</div>

			{metamaskDetected && (
				<div className="ml-2 text-text-label">{`Detected`}</div>
			)}
			{metamaskOutdated && (
				<Tooltip
					allowHover
					content={
						<div onClick={(e) => e.stopPropagation()}>
							Drift was not able to connect to MetaMask snaps. This is most
							likely because of another wallet extension conflicting with the
							MetaMask extension, or MetaMask being out of date.
							<br />
							<br />
							<a
								href="https://docs.drift.trade/getting-started/wallet-setup/metamask-setup#metamask-setup"
								target="_blank"
								rel="noreferrer"
							>
								Learn More
							</a>
						</div>
					}
				>
					<div className="flex flex-row items-center ml-2 text-text-label">
						{`Conflict Detected`}{' '}
						<Info size={16} className="ml-2 relative top-[1px]" />
					</div>
				</Tooltip>
			)}
		</button>
	);

	return (
		<div className="w-full py-6">
			<button
				className="flex flex-row items-center justify-between w-full pt-4 border-t border-container-border"
				onClick={toggleExpanded}
			>
				<Text.BODY1>More wallets</Text.BODY1>
				<div className="flex items-center">
					{props.wallets.slice(0, 3).map((wallet) => {
						const bgColor =
							WALLET_PROVIDER_BG_COLORS[wallet.provider.adapter.name];
						return (
							<div
								className={twMerge(
									`w-6 h-6 ml-[-8px] rounded-full overflow-hidden flex flex-row items-center justify-center`,
									walletsToAddPaddingFor.includes(wallet.provider.adapter.name)
										? 'p-1'
										: ''
								)}
								key={wallet.provider.adapter.name}
								style={{
									backgroundColor: bgColor || 'transparent',
								}}
							>
								<img
									src={wallet.provider.adapter.icon}
									className={
										wallet.provider.adapter.name === 'Backpack'
											? 'w-[70%] h-[70%]'
											: 'w-full h-full'
									}
								/>
							</div>
						);
					})}
					<div className="ml-4">
						<div
							className="flex items-center duration-300"
							style={{
								transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
							}}
						>
							<ChevronDown />
						</div>
					</div>
				</div>
			</button>
			<div
				className="w-full overflow-hidden transition-[max-height] duration-300"
				style={{ maxHeight: expanded ? `${maxHeight}px` : '0px' }}
			>
				<div ref={contentDivRef} className="pt-4">
					{props.showMetamaskIfDetected && metamaskButton}
					{props.wallets.map((walletProvider) => (
						<WalletButton
							onChooseWallet={() =>
								props.onChooseWallet(walletProvider.provider.adapter.name)
							}
							wallet={walletProvider}
							key={walletProvider.provider.adapter.name}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

const ConnectWalletButtonsList = (props: {
	showMetamaskIfDetected?: boolean;
	showUndetectedWallets?: boolean;
	showEmail?: boolean;
	onSelectedWallet?: (walletName: DriftWalletName) => void;
}) => {
	const { walletsToShow, hiddenWallets, emailWallets } = useWalletsToDisplay();

	const handleSelectWallet = (name: DriftWalletName) => {
		props.onSelectedWallet(name);
	};

	const hasNoInstalledWallet =
		walletsToShow.length === 0 ||
		!walletsToShow.find(
			(wallet) => wallet.provider.readyState === WalletReadyState.Installed
		);

	return (
		<>
			<div className="mb-4">
				<Text.BODY1>Select a wallet</Text.BODY1>
			</div>

			{hasNoInstalledWallet && (
				<>
					<button
						onClick={() =>
							UI_UTILS.openUrl(
								'https://www.drift.trade/learn/best-solana-wallets'
							)
						}
						className={buttonClassName}
					>
						<div className="flex flex-row items-center">
							<div className="w-6 h-6 mr-4">
								<MarketIcon marketSymbol="SOL" className="w-full h-full" />
							</div>
							<div className="text-left text-md text-text-default">
								Get a Solana Wallet
							</div>
						</div>
					</button>
				</>
			)}

			{/* Show regular Wallets to Show */}
			{walletsToShow.map((walletProvider) => (
				<WalletButton
					wallet={walletProvider}
					onChooseWallet={() =>
						handleSelectWallet(walletProvider.provider.adapter.name)
					}
					key={walletProvider.provider.adapter.name}
				/>
			))}

			<div className="flex flex-col gap-4 pt-6 mt-6 border-t border-container-border">
				<Text.BODY1>Or log in with</Text.BODY1>

				<div className="flex flex-col">
					<PasskeysButton onChooseWallet={handleSelectWallet} />
					{Env.showAppKitWallet && (
						<ReownWalletButton onChooseWallet={handleSelectWallet} />
					)}
					{props.showEmail && (
						<MagicLinkButton onChooseWallet={handleSelectWallet} />
					)}
					{emailWallets.map((walletProvider) => (
						<WalletButton
							wallet={walletProvider}
							onChooseWallet={() =>
								handleSelectWallet(walletProvider.provider.adapter.name)
							}
							key={walletProvider.provider.adapter.name}
							hideDetectedLabel
						/>
					))}
				</div>
			</div>

			{!props.showUndetectedWallets && hiddenWallets.length > 0 && (
				<OtherWalletsDropdown
					wallets={hiddenWallets}
					showMetamaskIfDetected={props.showMetamaskIfDetected}
					onChooseWallet={(walletName) =>
						handleSelectWallet(walletName as WalletName)
					}
				/>
			)}
		</>
	);
};

export default React.memo(ConnectWalletButtonsList);
