import { ENUM_UTILS, MarketId } from '@drift/common';
import { DriftClient, OracleSource } from '@drift-labs/sdk';
import {
	OrderedPerpMarkets,
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import { TransactionInstruction } from '@solana/web3.js';

export const getPythLazerUpdateIxs = async (
	marketIds: MarketId[],
	driftClient: DriftClient,
	preceedingIxsCount: number
): Promise<TransactionInstruction[]> => {
	const feedIds = [
		...new Set(
			marketIds
				.map((marketId) => {
					return marketId.isPerp
						? OrderedPerpMarkets[marketId.marketIndex]
						: OrderedSpotMarkets[marketId.marketIndex];
				})
				.filter((mktConfig) => isPythLazer(mktConfig.oracleSource))
				.map((mktConfig) => mktConfig.pythLazerId)
		),
		// TODO: do we need a maxCount here?
	].slice(0, 1);

	if (feedIds.length === 0) {
		console.log('No Pyth Lazer oracles to crank');
		return [];
	}

	if (feedIds.length > 2) {
		console.warn(
			'More than 2 Pyth lazer oracles to update before withdrawal, only the first 2 will be updated'
		);
	}

	const response = await fetch(
		`/api/pyth-lazer/crank?feedIds=${feedIds.join(',')}`
	);

	const result = await response.json();

	if (result.error) {
		console.error(
			`Error getting pyth lazer update from HermesClient: `,
			result.error
		);
		return [];
	}

	try {
		const crankIxs = await driftClient.getPostPythLazerOracleUpdateIxs(
			feedIds,
			result.data,
			undefined,
			preceedingIxsCount + 1
		);

		return crankIxs;
	} catch (e) {
		console.error(`Error getting pyth lazer crank ix from driftClient: `, e);
		return [];
	}
};

export const isPythLazer = (oracleSource: OracleSource) => {
	return (
		ENUM_UTILS.match(OracleSource.PYTH_LAZER, oracleSource) ||
		ENUM_UTILS.match(OracleSource.PYTH_LAZER_1K, oracleSource) ||
		ENUM_UTILS.match(OracleSource.PYTH_LAZER_1M, oracleSource) ||
		ENUM_UTILS.match(OracleSource.PYTH_LAZER_STABLE_COIN, oracleSource)
	);
};
