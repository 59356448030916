'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	QUOTE_PRECISION_EXP,
	SpotBalanceType,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import { ENUM_UTILS, MAIN_POOL_ID } from '@drift/common';
import {
	CurrentSpotMarkets,
	SPOT_MARKETS_LOOKUP,
} from '../environmentVariables/EnvironmentVariables';
import useWalletBalancesStore from '../stores/useWalletBalancesStore';
import useAccountSpotBalances from './useAccountBankBalances';
import useIfDataStore from '../stores/ifDataStore/useIfDataStore';
import { useMemo } from 'react';

export type BankRenderingInfo = {
	base: BigNum;
	quote: BigNum;
	baseValue: number;
	quoteValue: number;
	market: SpotMarketConfig;
	highlighted?: boolean;
	walletBaseValue?: number;
	accountBaseValue?: number;
};

/**
 * Returns a list of banks/spot markets, sorted by configurable options. The purpose is to easily pull the list of assets to display in the UI, e.g. in the Deposit form or the Asset Balances table. Encapsulates any logic like sorting the banks by user's balance, or highlighting particular assets.
 *
 * Using DEFAULT will
 * @returns
 */
const useBanksToRender = (props: {
	banks?: SpotMarketConfig[];
	poolId?: number;
	sortBy: 'default' | 'accountBalance' | 'walletBalance' | 'ifStakeBalance';
	userKey: string;
	opts?: {
		highlightType?: 'forceDespiteBalance' | 'higherPriorityIfZeroBalance';
		highlightBanks?: SpotMarketConfig[]; // Of any bank balances which are 0, highlighted banks will be sorted first
		prioritySort?: Record<SpotMarketConfig['marketIndex'], number>; // Of any bank balances which are 0, banks to sort first but won't be highlighted
	};
}): BankRenderingInfo[] => {
	const BanksToUse = props.banks
		? props.banks
		: CurrentSpotMarkets.filter(
				(market) => market.poolId === (props.poolId || MAIN_POOL_ID)
		  );

	const banksToHighlightSymbol = (
		props.opts?.highlightBanks
			? props.opts?.highlightBanks?.map((bank) => bank?.symbol)
			: []
	).filter((a) => !!a);

	// # Default mapped bank info
	const allBanksDefault: BankRenderingInfo[] = BanksToUse.map((bank) => ({
		base: BigNum.zero(BASE_PRECISION_EXP),
		quote: BigNum.zero(QUOTE_PRECISION_EXP),
		baseValue: 0,
		quoteValue: 0,
		market: bank,
	}));

	// # Map user account balances to bank info
	const mappedAccountBalances = useAccountSpotBalances(
		props.userKey,
		SpotBalanceType.DEPOSIT
	).map((balance) => {
		const isBorrow = ENUM_UTILS.match(
			balance.balanceType,
			SpotBalanceType.BORROW
		);

		return {
			base: isBorrow ? BigNum.zero(BASE_PRECISION_EXP) : balance.balance,
			quote: isBorrow ? BigNum.zero(QUOTE_PRECISION_EXP) : balance.quoteValue,
			baseValue: isBorrow ? 0 : balance.balance.toNum(),
			quoteValue: isBorrow ? 0 : balance.quoteValue.toNum(),
			market: balance.asset,
		};
	});

	// # Map wallet balances to bank info
	const allWalletBalances = useWalletBalancesStore((s) => s.balances);
	const mappedWalletBalances: BankRenderingInfo[] = BanksToUse.map((bank) => {
		const balances = allWalletBalances[bank.symbol];

		return {
			base: balances?.base ?? BigNum.zero(BASE_PRECISION_EXP),
			quote: balances?.quote ?? BigNum.zero(QUOTE_PRECISION_EXP),
			baseValue: balances?.base.toNum() ?? 0,
			quoteValue: balances?.quote.toNum() ?? 0,
			market: bank,
		};
	});

	// # Map IF Stake balances to bank info
	const ifStakeData = useIfDataStore((s) => s.IFData);
	const mappedIfStakeBalances = useMemo(() => {
		return Object.entries(ifStakeData).map(([_marketIndex, ifData]) => {
			return {
				base: ifData.userStake.currentStakeBigNum,
				quote: ifData.userStake.currentStakeNotionalValue,
				baseValue: ifData.userStake.currentStakeBigNum.toNum(),
				quoteValue: ifData.userStake.currentStakeNotionalValue.toNum(),
				market: SPOT_MARKETS_LOOKUP[ifData.marketIndex],
			};
		});
	}, [ifStakeData]);

	// # Map all banks, merging with balances based on the sort option
	const mappedRenderingInfo = allBanksDefault.map((bank) => {
		const accountBalance = mappedAccountBalances.find(
			(balance) => balance.market.symbol === bank.market.symbol
		);
		const walletBalance = mappedWalletBalances.find(
			(balance) => balance.market.symbol === bank.market.symbol
		);
		const ifStakeBalance = mappedIfStakeBalances.find(
			(balance) => balance.market.symbol === bank.market.symbol
		);

		let balanceToUse: BankRenderingInfo;

		switch (props.sortBy) {
			case 'accountBalance':
				balanceToUse = accountBalance;
				break;
			case 'walletBalance':
				balanceToUse = walletBalance;
				break;
			case 'ifStakeBalance':
				balanceToUse = ifStakeBalance;
				break;
			case 'default':
				balanceToUse = bank;
				break;
			default: {
				const exhaustiveCheck: never = props.sortBy;
				throw new Error(
					`Unhandled sortBy option in useBanksToRender: ${exhaustiveCheck}`
				);
			}
		}

		return {
			...bank,
			base: balanceToUse?.base ?? BigNum.zero(BASE_PRECISION_EXP),
			quote: balanceToUse?.quote ?? BigNum.zero(QUOTE_PRECISION_EXP),
			baseValue: balanceToUse?.baseValue ?? 0,
			quoteValue: balanceToUse?.quoteValue ?? 0,
			walletBaseValue: walletBalance?.baseValue ?? 0,
			accountBaseValue: accountBalance?.baseValue ?? 0,
		};
	});

	const highlightType =
		props.opts?.highlightType ?? 'higherPriorityIfZeroBalance';

	const sortedRenderingInfo = mappedRenderingInfo.sort((a, b) => {
		const highlightA = banksToHighlightSymbol.includes(a.market.symbol);
		const highlightB = banksToHighlightSymbol.includes(b.market.symbol);

		const bothBalancesZero = a.quoteValue === 0 && b.quoteValue === 0;
		const balanceSortScore = b.quoteValue - a.quoteValue;
		const highlightSortScore =
			highlightA && !highlightB ? -1 : !highlightA && highlightB ? 1 : 0;

		const priorityA =
			props.opts?.prioritySort?.[a.market.marketIndex] ??
			Number.MAX_SAFE_INTEGER;
		const priorityB =
			props.opts?.prioritySort?.[b.market.marketIndex] ??
			Number.MAX_SAFE_INTEGER;
		const prioritySortScore = priorityA - priorityB;

		if (highlightType === 'higherPriorityIfZeroBalance') {
			if (bothBalancesZero) {
				return highlightSortScore || prioritySortScore;
			}
			return balanceSortScore;
		}

		if (highlightType === 'forceDespiteBalance') {
			if (highlightSortScore !== 0) {
				return highlightSortScore;
			}
			return balanceSortScore || prioritySortScore;
		}
	});

	return sortedRenderingInfo.map((bank) => ({
		...bank,
		highlighted: banksToHighlightSymbol.includes(bank.market.symbol),
	}));
};

export default useBanksToRender;
