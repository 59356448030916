import {
	PREDICTION_MARKET_CATEGORY,
	PredictionMarketConfig,
} from 'src/@types/predictionMarkets';

const PREDICTION_MARKETS_S3_BUCKET_MARKETS =
	'https://drift-public.s3.eu-central-1.amazonaws.com/prediction-markets/markets';

/*
 * Prediction Market Configs
 * Add a new UI config here to add a new prediction market. Ensure that the market has been added to SDK.
 * - `devnetMarketIndex` is for devnet, `mainnetMarketIndex` is for mainnet. If both are not provided, the market will be still be displayed, but as "Coming Soon".
 * - `resolutionDateUnix` is the date when the market will be resolved. Usually a rough date would suffice.
 * - `imgSrc` is the image that will be displayed on the prediction market card. This should be uploaded under the drift-public/prediction-markets bucket
 * - `previewImgSrc` is the image that will be displayed on the prediction market card on preview links. *This is not in used yet.*
 *
 * When adding a new market, ensure that the resolution details is added to `BetMarketDetailsDrawer.tsx`
 */

export const TRUMP_WIN_2024_BET = {
	title: 'Will Donald Trump win the 2024 Presidential Election?',
	shortTitle: 'Trump wins 2024 election',
	resolutionDateUnix: 1730937600000, // Nov 7th
	category: PREDICTION_MARKET_CATEGORY.US_ELECTIONS,
	devnetMarketIndex: 26,
	mainnetMarketIndex: 36,
	symbol: 'TRUMP-WIN-2024-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/TRUMP-WIN-2024-BET.webp`,
};

const KAMALA_POPULAR_VOTE_2024_BET = {
	title: 'Will Kamala Harris win the popular vote in 2024?',
	shortTitle: 'Kamala wins 2024 pop. vote',
	resolutionDateUnix: 1730937600000, // Nov 7th
	category: PREDICTION_MARKET_CATEGORY.US_ELECTIONS,
	symbol: 'KAMALA-POPULAR-VOTE-2024-BET',
	devnetMarketIndex: 27,
	mainnetMarketIndex: 37,
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/KAMALA-POPULAR-VOTE-2024-BET.webp`,
};

const FED_CUT_50_SEPT_2024_BET = {
	title:
		'Will the Fed cut interest rates by at least 50bps at the September 2024 Federal Reserve meeting?',
	shortTitle: 'Fed Cuts Rate by +50bps',
	resolutionDateUnix: 1726682400000, // Sept 19th
	mainnetMarketIndex: 38,
	symbol: 'FED-CUT-50-SEPT-2024-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/FED-CUT-50-SEPT-2024-BET.webp`,
};

const REPUBLICAN_POPULAR_AND_WIN_BET = {
	title: 'Will the Republican Party win the election and popular vote?',
	shortTitle: 'Republican Party wins election & pop. vote',
	resolutionDateUnix: 1730937600000,
	mainnetMarketIndex: 39,
	category: PREDICTION_MARKET_CATEGORY.US_ELECTIONS,
	symbol: 'REPUBLICAN-POPULAR-AND-WIN-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/REPUBLICAN-POPULAR-AND-WIN-BET.webp`,
};

export const BREAKPOINT_IGGYERIC_BET = {
	title: 'Will Iggy Azalea win the celebcoin debate at Solana Breakpoint 2024?',
	shortTitle: 'Iggy wins celebcoin debate',
	resolutionDateUnix: 1726891200000,
	mainnetMarketIndex: 40,
	symbol: 'BREAKPOINT-IGGYERIC-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/BREAKPOINT-IGGYERIC-BET.webp`,
};

const DEMOCRATS_WIN_MICHIGAN_BET = {
	title: 'Will the Democrats win in Michigan?',
	shortTitle: 'Democrats win Michigan',
	resolutionDateUnix: 1730937600000,
	mainnetMarketIndex: 41,
	category: PREDICTION_MARKET_CATEGORY.US_ELECTIONS,
	symbol: 'DEMOCRATS-WIN-MICHIGAN-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/DEMOCRATS-WIN-MICHIGAN-BET.webp`,
};

const LANDO_F1_SGP_WIN_BET = {
	title: 'Will Lando Norris win the 2024 Singapore GP?',
	shortTitle: 'Lando wins Singapore GP',
	resolutionDateUnix: 1727092800000,
	mainnetMarketIndex: 43,
	category: PREDICTION_MARKET_CATEGORY.FORMULA_1,
	symbol: 'LANDO-F1-SGP-WIN-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/LANDO-F1-SGP-WIN-BET.webp`,
};

const WARWICK_FIGHT_WIN_BET = {
	title: 'Will Kain Warwick win against David Hoffman?',
	shortTitle: 'Warwick wins against Hoffman',
	resolutionDateUnix: 1728734400000,
	mainnetMarketIndex: 46,
	category: PREDICTION_MARKET_CATEGORY.SPORTS,
	symbol: 'WARWICK-FIGHT-WIN-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/WARWICK-FIGHT-WIN-BET.webp`,
};

const WLF_5B_1W_BET = {
	title:
		"Will Trump's World Liberty Financial token reach $5b FDV a week after launch?",
	shortTitle: 'WLF reaches $5B FDV',
	resolutionDateUnix: 1741627430000, // TO-DO: update when determined (this is just arbitrary date Mar 10)
	mainnetMarketIndex: 48,
	category: PREDICTION_MARKET_CATEGORY.ALL,
	symbol: 'WLF-5B-1W-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/WLF-5B-1W-BET.webp`,
	resolutionDateUnknown: true,
};

const VRSTPN_WIN_F1_24_DRVRS_CHMP_BET = {
	title: 'Will Verstappen win the 2024 F1 Drivers Championship?',
	shortTitle: 'Verstappen wins 2024 F1 Drivers Championship',
	resolutionDateUnix: 1733702400000,
	mainnetMarketIndex: 49,
	category: PREDICTION_MARKET_CATEGORY.FORMULA_1,
	symbol: 'VRSTPN-WIN-F1-24-DRVRS-CHMP-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/VRSTPN-WIN-F1-24-DRVRS-CHMP-BET.webp`,
};

const LNDO_WIN_F1_24_US_GP_BET = {
	title: 'Will Lando Norris win the 2024 US GP?',
	shortTitle: 'Lando wins 2024 US GP',
	resolutionDateUnix: 1729555200000,
	mainnetMarketIndex: 50,
	category: PREDICTION_MARKET_CATEGORY.FORMULA_1,
	symbol: 'LNDO-WIN-F1-24-US-GP-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/LNDO-WIN-F1-24-US-GP-BET.webp`,
};

const SUPERBOWL_LIX_LIONS_BET = {
	title: 'Will the Detroit Lions win Super Bowl LIX?',
	shortTitle: 'Lions win Super Bowl LIX',
	resolutionDateUnix: 1739206800000,
	mainnetMarketIndex: 57,
	category: PREDICTION_MARKET_CATEGORY.SPORTS,
	symbol: 'SUPERBOWL-LIX-LIONS-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/SUPERBOWL-LIX-LIONS-BET.webp`,
	newUntilTs: 1734048000000,
};

const SUPERBOWL_LIX_CHIEFS_BET = {
	title: 'Will the Kansas City Chiefs win Super Bowl LIX?',
	shortTitle: 'Chiefs win Super Bowl LIX',
	resolutionDateUnix: 1739206800000,
	mainnetMarketIndex: 58,
	category: PREDICTION_MARKET_CATEGORY.SPORTS,
	symbol: 'SUPERBOWL-LIX-CHIEFS-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/SUPERBOWL-LIX-CHIEFS-BET.webp`,
	newUntilTs: 1734048000000,
};
const NBAFINALS25_OKC_BET = {
	title: 'Will the Oklahoma City Thunder win the 2025 NBA Finals?',
	shortTitle: 'Thunder win 2025 NBA Finals',
	resolutionDateUnix: 1750636800000,
	mainnetMarketIndex: 67,
	category: PREDICTION_MARKET_CATEGORY.SPORTS,
	symbol: 'NBAFINALS25-OKC-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/NBAFINALS25-OKC-BET.png`,
	newUntilTs: 1744048000000,
};

const NBAFINALS25_BOS_BET = {
	title: 'Will the Boston Celtics win the 2025 NBA Finals?',
	shortTitle: 'Celtics win 2025 NBA Finals',
	resolutionDateUnix: 1750636800000,
	mainnetMarketIndex: 68,
	category: PREDICTION_MARKET_CATEGORY.SPORTS,
	symbol: 'NBAFINALS25-BOS-BET',
	imgSrc: `${PREDICTION_MARKETS_S3_BUCKET_MARKETS}/NBAFINALS25-BOS-BET.png`,
	newUntilTs: 1744048000000,
};

export const PREDICTION_MARKET_CONFIGS: PredictionMarketConfig[] = [
	TRUMP_WIN_2024_BET,
	KAMALA_POPULAR_VOTE_2024_BET,
	LANDO_F1_SGP_WIN_BET,
	FED_CUT_50_SEPT_2024_BET,
	REPUBLICAN_POPULAR_AND_WIN_BET,
	DEMOCRATS_WIN_MICHIGAN_BET,
	BREAKPOINT_IGGYERIC_BET,
	WARWICK_FIGHT_WIN_BET,
	WLF_5B_1W_BET,
	VRSTPN_WIN_F1_24_DRVRS_CHMP_BET,
	LNDO_WIN_F1_24_US_GP_BET,
	SUPERBOWL_LIX_LIONS_BET,
	SUPERBOWL_LIX_CHIEFS_BET,
	NBAFINALS25_OKC_BET,
	NBAFINALS25_BOS_BET,
].map((config) => new PredictionMarketConfig(config));
