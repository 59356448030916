import { ENUM_UTILS, MarketId } from '@drift/common';
import { DriftClient, OracleSource } from '@drift-labs/sdk';
import {
	OrderedPerpMarkets,
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import { TransactionInstruction } from '@solana/web3.js';

export const getSwitchboardUpdateIxs = async (
	marketIds: MarketId[],
	driftClient: DriftClient
): Promise<TransactionInstruction[]> => {
	const oracles = [
		...new Set(
			marketIds
				.map((marketId) => {
					return marketId.isPerp
						? OrderedPerpMarkets[marketId.marketIndex]
						: OrderedSpotMarkets[marketId.marketIndex];
				})
				.filter((mktConfig) =>
					ENUM_UTILS.match(
						mktConfig.oracleSource,
						OracleSource.SWITCHBOARD_ON_DEMAND
					)
				)
				.map((mktConfig) => mktConfig.oracle)
		),
	];

	if (oracles.length === 0) {
		console.log('No Switchboard oracles to crank');
		return [];
	}

	if (oracles.length > 2) {
		console.warn(
			'More than 2 switchboard oracles to update before withdrawal, only the first 2 will be updated'
		);
	}

	try {
		const switchboardUpdateIxs = await Promise.all(
			oracles
				.slice(0, 2)
				.map(
					async (oracle) =>
						await driftClient.getPostSwitchboardOnDemandUpdateAtomicIx(
							oracle,
							undefined
						)
				)
		);

		return switchboardUpdateIxs;
	} catch (e) {
		console.error(`Error getting switchboard update ixs from driftClient: `, e);
		return [];
	}
};
