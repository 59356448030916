import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { isUserProtectedMaker } from '@drift-labs/sdk';

export const useIsUserProtectedMakerMode = () => {
	const userAccount = useDriftAccountStore((s) =>
		s.getCurrentUserAccount()?.client?.getUserAccount()
	);
	if (!userAccount) return false;
	return isUserProtectedMaker(userAccount);
};
