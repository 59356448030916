"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNode = exports.SignedMsgOrderNode = exports.TriggerOrderNode = exports.MarketOrderNode = exports.FloatingLimitOrderNode = exports.RestingLimitOrderNode = exports.TakingLimitOrderNode = exports.OrderNode = void 0;
const __1 = require("..");
const NodeList_1 = require("./NodeList");
class OrderNode {
    constructor(order, userAccount, isProtectedMaker, protectedMakerParams, isSignedMsg = false) {
        this.haveFilled = false;
        this.haveTrigger = false;
        // Copy the order over to the node
        this.order = { ...order };
        this.userAccount = userAccount;
        this.sortValue = this.getSortValue(order);
        this.isProtectedMaker = isProtectedMaker;
        this.protectedMakerParams = protectedMakerParams;
        this.isSignedMsg = isSignedMsg;
    }
    getLabel() {
        let msg = `Order ${(0, NodeList_1.getOrderSignature)(this.order.orderId, this.userAccount)}`;
        msg += ` ${(0, __1.isVariant)(this.order.direction, 'long') ? 'LONG' : 'SHORT'} `;
        msg += `${(0, __1.convertToNumber)(this.order.baseAssetAmount, __1.AMM_RESERVE_PRECISION).toFixed(3)}`;
        if (this.order.price.gt(__1.ZERO)) {
            msg += ` @ ${(0, __1.convertToNumber)(this.order.price, __1.PRICE_PRECISION).toFixed(3)}`;
        }
        if (this.order.triggerPrice.gt(__1.ZERO)) {
            msg += ` ${(0, __1.isVariant)(this.order.triggerCondition, 'below') ? 'BELOW' : 'ABOVE'}`;
            msg += ` ${(0, __1.convertToNumber)(this.order.triggerPrice, __1.PRICE_PRECISION).toFixed(3)}`;
        }
        return msg;
    }
    getPrice(oraclePriceData, slot) {
        return (0, __1.getLimitPrice)(this.order, oraclePriceData, slot, undefined, this.isProtectedMaker ? this.protectedMakerParams : undefined);
    }
    isBaseFilled() {
        return this.order.baseAssetAmountFilled.eq(this.order.baseAssetAmount);
    }
    isVammNode() {
        return false;
    }
}
exports.OrderNode = OrderNode;
class TakingLimitOrderNode extends OrderNode {
    getSortValue(order) {
        return order.slot;
    }
}
exports.TakingLimitOrderNode = TakingLimitOrderNode;
class RestingLimitOrderNode extends OrderNode {
    getSortValue(order) {
        let sortValue = order.price;
        if (this.protectedMakerParams && this.isProtectedMaker) {
            const offset = sortValue.divn(1000);
            if ((0, __1.isVariant)(order.direction, 'long')) {
                sortValue = sortValue.sub(offset);
            }
            else {
                sortValue = sortValue.add(offset);
            }
        }
        return sortValue;
    }
}
exports.RestingLimitOrderNode = RestingLimitOrderNode;
class FloatingLimitOrderNode extends OrderNode {
    getSortValue(order) {
        return new __1.BN(order.oraclePriceOffset);
    }
}
exports.FloatingLimitOrderNode = FloatingLimitOrderNode;
class MarketOrderNode extends OrderNode {
    getSortValue(order) {
        return order.slot;
    }
}
exports.MarketOrderNode = MarketOrderNode;
class TriggerOrderNode extends OrderNode {
    getSortValue(order) {
        return order.triggerPrice;
    }
}
exports.TriggerOrderNode = TriggerOrderNode;
// We'll use the signedMsg uuid for the order id since it's not yet on-chain
class SignedMsgOrderNode extends OrderNode {
    constructor(order, userAccount) {
        super(order, userAccount, false, undefined, true);
    }
    getSortValue(order) {
        return order.slot;
    }
}
exports.SignedMsgOrderNode = SignedMsgOrderNode;
function createNode(nodeType, order, userAccount, isProtectedMaker, protectedMakerParams) {
    switch (nodeType) {
        case 'floatingLimit':
            return new FloatingLimitOrderNode(order, userAccount, isProtectedMaker, protectedMakerParams);
        case 'protectedFloatingLimit':
            return new FloatingLimitOrderNode(order, userAccount, isProtectedMaker, protectedMakerParams);
        case 'restingLimit':
            return new RestingLimitOrderNode(order, userAccount, isProtectedMaker, protectedMakerParams);
        case 'takingLimit':
            return new TakingLimitOrderNode(order, userAccount, isProtectedMaker, protectedMakerParams);
        case 'market':
            return new MarketOrderNode(order, userAccount, isProtectedMaker, undefined);
        case 'trigger':
            return new TriggerOrderNode(order, userAccount, isProtectedMaker, undefined);
        case 'signedMsg':
            return new SignedMsgOrderNode(order, userAccount);
        default:
            throw Error(`Unknown DLOBNode type ${nodeType}`);
    }
}
exports.createNode = createNode;
