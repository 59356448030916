export const getPctCompletion = (
	start: number,
	end: number,
	current: number
) => {
	const totalProgressSize = end - start;
	const currentProgressSize = current - start;

	return (currentProgressSize / totalProgressSize) * 100;
};
