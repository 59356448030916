"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMinimalEd25519VerifyIx = void 0;
const web3_js_1 = require("@solana/web3.js");
const BufferLayout = __importStar(require("@solana/buffer-layout"));
const ED25519_INSTRUCTION_LEN = 16;
const SIGNATURE_LEN = 64;
const PUBKEY_LEN = 32;
const MAGIC_LEN = 4;
const MESSAGE_SIZE_LEN = 2;
const readUint16LE = (data, offset) => {
    return data[offset] | (data[offset + 1] << 8);
};
const ED25519_INSTRUCTION_LAYOUT = BufferLayout.struct([
    BufferLayout.u8('numSignatures'),
    BufferLayout.u8('padding'),
    BufferLayout.u16('signatureOffset'),
    BufferLayout.u16('signatureInstructionIndex'),
    BufferLayout.u16('publicKeyOffset'),
    BufferLayout.u16('publicKeyInstructionIndex'),
    BufferLayout.u16('messageDataOffset'),
    BufferLayout.u16('messageDataSize'),
    BufferLayout.u16('messageInstructionIndex'),
]);
/**
 * Constructs a minimal Ed25519 verification instruction that references the data
 * inside the drift custom instruction (e.g. postPythLazerOracleUpdate, placeSignedMsgTakerOrder).
 *
 * @param customInstructionIndex The index of the custom instruction in the transaction (e.g. if tx contains compute budget limit, compute budget price, ed25519 verify, custom ix, this would be 3).
 * @param messageOffset The offset within the custom instruction data where the signed message begins.
 * @param customInstructionData The entire instruction data array for the custom instruction.
 */
function createMinimalEd25519VerifyIx(customInstructionIndex, messageOffset, customInstructionData, magicLen) {
    const signatureOffset = messageOffset + (magicLen === undefined ? MAGIC_LEN : magicLen);
    const publicKeyOffset = signatureOffset + SIGNATURE_LEN;
    const messageDataSizeOffset = publicKeyOffset + PUBKEY_LEN;
    const messageDataOffset = messageDataSizeOffset + MESSAGE_SIZE_LEN;
    const messageDataSize = readUint16LE(customInstructionData, messageDataSizeOffset - messageOffset);
    const instructionData = Buffer.alloc(ED25519_INSTRUCTION_LEN);
    ED25519_INSTRUCTION_LAYOUT.encode({
        numSignatures: 1,
        padding: 0,
        signatureOffset,
        signatureInstructionIndex: customInstructionIndex,
        publicKeyOffset,
        publicKeyInstructionIndex: customInstructionIndex,
        messageDataOffset,
        messageDataSize: messageDataSize,
        messageInstructionIndex: customInstructionIndex,
    }, instructionData);
    return new web3_js_1.TransactionInstruction({
        keys: [],
        programId: web3_js_1.Ed25519Program.programId,
        data: instructionData,
    });
}
exports.createMinimalEd25519VerifyIx = createMinimalEd25519VerifyIx;
