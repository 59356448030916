import {
	BaseMessageSignerWalletAdapter,
	WalletName,
	WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
	PublicKey,
	Transaction,
	TransactionVersion,
	VersionedTransaction,
} from '@solana/web3.js';

export class AppKitAdapter extends BaseMessageSignerWalletAdapter {
	signMessage(_message: Uint8Array): Promise<Uint8Array> {
		return this.provider.signMessage(_message);
	}
	url: string;
	readyState: WalletReadyState;
	connecting: boolean;
	supportedTransactionVersions?: ReadonlySet<TransactionVersion>;

	private provider: BaseMessageSignerWalletAdapter;

	public name = 'unset' as WalletName;
	public icon: any = undefined;
	public publicKey: PublicKey;

	private _connected = false;

	public get connected() {
		return this._connected;
	}

	public set connected(value) {
		this._connected = value;
	}

	public constructor(appKitProvider: BaseMessageSignerWalletAdapter) {
		super();
		this.provider = appKitProvider;
		this.name = appKitProvider.name as WalletName;
		this.icon = appKitProvider.icon;
		this.publicKey = appKitProvider.publicKey;
	}

	public async signTransaction<T extends Transaction | VersionedTransaction>(
		tx: T
	) {
		return this.provider.signTransaction(tx);
	}

	public async signAllTransactions<
		T extends Transaction | VersionedTransaction
	>(txes: T[]) {
		return this.provider.signAllTransactions(txes);
	}

	// Extra step required for this adapter:
	public connect = async () => {
		await this.provider.connect();
		this._connected = true;
		this.emit('connect', this.publicKey);
		return;
	};

	public disconnect = async () => {
		await this.provider.disconnect();
		this._connected = false;
		this.emit('disconnect');
		return;
	};
}

const createAppKitAdapter = (
	appkitWalletProvider: BaseMessageSignerWalletAdapter
) => {
	const adapter = new AppKitAdapter(appkitWalletProvider);
	return adapter;
};

export default createAppKitAdapter;
